<template>
  <div class="login-form h-overflow company-login-bg" >
    <form>
        <div class="logo grid-col-1 a-i-center">
          <div class="flex-center pof txt-7xl t-white-txt"><i class="pof portfel-text-horizontal"></i></div>
        </div>
      <div class="before-send" v-if="!sent">

          <div class="flex-center">
            <div class="txt-sm1 w-margin pd-b-10 reg-container" :class="$t('font')">
              <p class="t-white-txt">{{$t('forget_password_text')}}</p>
            </div>
          </div>

          <div :class="$t('font')" style="min-width: 320px; top:300px; z-index: 10;">
              <div class="input-group w-margin">
                <div class="input-desc t-white-txt" :class="$t('font')">{{$t('email')}} <span class="red-light-txt">*</span>
                  <span class="red-light-txt" v-if="errors.email">{{ errors.email[0] }}</span></div>
                <div class="input-area"><input type="text" autocomplete="email" v-model="form.email"></div>
              </div>

              <div class="flex-center mg-t-10 mg-b-10">
                <div class="mg-b-10">
                  <button class=" btn btn-md  t-black-txt mg-t-30" :class="{'yellow':emailValid,'grey7':!emailValid}" @click.prevent="sendResetLink"> <span :class="$t('font_b')">{{$t('send_link')}}</span></button>
                </div>
              </div>
          </div>

        </div>
      <div class="flex-center" v-if="sent">
        <div class="flex-center">
          <div class="txt-sm1 w-margin pd-b-10 reg-container" :class="$t('font')">
            <p class="t-white-txt">{{$t('link_sent_please_check_email')}}</p>
          </div>
        </div>
      </div>
      <div class="cyrcle-button cyrcle-button-sm yellow  home-button" style="color:black !important;" v-on:click="$router.push({name:'login'})">
        <i class="far fa-chevron-left"></i>
      </div>

    </form>
  </div>
</template>
<script>

    // import support from "../../../support/support";
    import User from "@/apis/user";
  //  import Config from '@/config'

    import support, {validate} from "@/support/support";



    export default {
        components:{



        },
        data(){
            return {
                lang:'ka',
                sent:false,
                form:{
                    email:'',
                    scope:1,
                    //device_log:support.deviceLog(),
                },
                errors: [],
                state: this.val,

            }
        },
        mounted() {
           this.$store.dispatch('hideLoading');
            support.setColorMode();
            let email = localStorage.getItem('email');
            this.lang = this.getLang;
            if(email){
                this.form.email = email;
                this.state =true;
            }



        },
        computed:{
            getLang(){
                return support.getLang();
            },
            emailValid(){
              return validate(this.form.email,'email');
            }
        },
        watch:{
            val() {
                this.toggle(this.val)
            },
        },
        methods:{
            setLang(lang){
                this.lang = lang;
                support.setLang(lang);
                return window.location.reload();
            },
            onClick() {
                this.toggle(!this.state)
                this.emit()
            },
            toggle(state) {
                this.state = state
            },
            emit() {
                this.$emit('input', this.state)
            },

          sendResetLink() {
              if(!this.emailValid){
                return false;
              }
                this.$store.dispatch('showLoading');
                User.sendResetLink(this.form)
                    .then(response => {
                        this.$store.dispatch('hideLoading');
                        this.sent = response;
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                          this.$store.dispatch('hideLoading');
                          this.$store.dispatch('addNotification', {
                            type: 'error',
                            message: error.response.data.message
                          }, { root: true });
                            this.errors = error.response.data.errors;
                        }
                    });
            }

        }

    }
</script>
<style scoped>
body {
  background-color:#331754;
  background-image: linear-gradient(-225deg, #EB0C8D 0%, #007CF8 100%);
}
.company-login-bg {
  background-color:#331754;
  background-image: linear-gradient(-225deg, #EB0C8D 0%, #007CF8 100%);
}

.login-form{
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-top: 0;
  padding-top: env(safe-area-inset-top, 0);
}
.notification-group {
  padding: 0;
  margin: 0;
}
#logo{
  z-index: 0;
}
.logo,.w-logo{
  overflow: hidden;
  z-index: 1;
  position:relative;
}
.selection-type-selector-login {
  display: grid;
  grid-template-columns: 50px 50px 50px;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  vertical-align: center;
  align-items: center;
}
.selection-type-item-login {
  text-decoration: underline;
  font-size: var(--txt-md1);
  display: inline-flex;
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;

  padding: 6px 0px 3px 0px;
  color: white;
}
.selection-center-login{
  border-radius: 0px 0px 0px 0px;
}
.selection-left-login{
  border-radius: 10px 0px 0px 10px;
}
.selection-right-login{
  border-radius: 0px 10px 10px 0px;
}
.selected-login {

  color: var(--blue);
}

.reg-container{
  -webkit-backdrop-filter: blur(40px);
  -moz-filter: blur(40px);
  -o-filter: blur(40px);
  -ms-filter: blur(40px);
  backdrop-filter: blur(40px);
  -webkit-box-shadow: -12px 20px 25px -13px rgba(0,0,0,0.75);
  -moz-box-shadow: -12px 20px 25px -13px rgba(0,0,0,0.75);
  border-radius: 20px;
  box-shadow: -12px 20px 25px -13px rgba(0,0,0,0.75);
  padding: 20px 20px 20px 20px;

}
.forgotcss {
  width: 150px;
  text-align: right;
  float: right;
  padding-top: 4rem;
  padding-right: 10px;
}
</style>
<template>
    <div>
     <div class="heading-group w-button" v-if="showSelectButton">
       <div :class="$t('font')">
         <h2 class="en">{{product.label}}</h2>
         <p class="txt-lg1"><b>{{ $t('price') }}: </b><span class="en-b txt-xxl">{{product.price}} £</span> / {{$t('month')}} </p>
       </div>
       <div v-if="canBuy() && !packageSelected && !currentPlan" class="mg-b-4 grid-row-2 a-i-center j-i-center">

         <div  class="yellow txt-4xl t-center pd-10 radius mg-b-4 cursor" v-on:click="selectPackage()">
           <spinner v-show="showSpinner"></spinner>
           <i v-show="!showSpinner" class="fal fa-check-circle"></i>
         </div>
         <span :class="$t('font')" class="txt-xs1 ru t-center">{{$t('change_package')}}</span>

       </div>
       <div v-if="!canBuy() && !packageSelected && !currentPlan" class="mg-b-4 grid-row-2 a-i-center j-i-center">
         <div  class="grey7 txt-4xl t-center pd-10 radius mg-b-4 ">
           <i class="fal fa-times-circle"></i>
         </div>
         <span :class="$t('font')" class="txt-xs">{{$t('cant_select')}}</span>
       </div>
       <div v-if="currentPlan" class="mg-b-4 grid-row-2 a-i-center j-i-center">

         <div  class="green txt-4xl t-center pd-10 radius mg-b-4 " >
           <i class="fal fa-check-circle"></i>
         </div>
         <span :class="$t('font')" class="txt-xs">{{$t('selected')}}</span>

       </div>
     </div>

      <div v-show="section" class="space-div--40"></div>
      <div v-show="section" class="w-b-line"></div>
      <div v-show="section" class="space-div--20"></div>


      <div class="group grid-col-1 mg-b-20 mg-t-20">
          <div class="grid-sm-lg g-c-gap-20 a-i-center">
            <div class="txt-xxl green-txt" v-if="product.limit_branch !==0"><i class="fal fa-check"></i></div>
            <div class="txt-xxl red-light-txt" v-if="product.limit_branch ===0"><i class="fal fa-times"></i></div>
            <div class="grid-row-2 g-r-gap-10">
               <div class="grid-col-2 a-i-center">
                 <div class="flex-start txt-b">{{$t('limit_branch')}}</div>
                 <div class="flex-end blue-txt en-b"> {{ limitText(product.limit_branch) }} / <span :class="{'red-txt':+balance.branches>=+product.limit_branch}">{{balance.branches}}</span></div>
               </div>
               <div class="grey1-txt txt-sm1">{{$t('limit_branch_desc')}}</div>
            </div>
          </div>
      </div>

      <div class="group grid-col-1 mg-b-20 mg-t-20 ">
        <div class="grid-sm-lg g-c-gap-20 a-i-center">
          <div class="txt-xxl green-txt" v-if="product.limit_employee !==0"><i class="fal fa-check"></i></div>
          <div class="txt-xxl red-light-txt" v-if="product.limit_employee ===0"><i class="fal fa-times"></i></div>
          <div class="grid-row-2 g-r-gap-10 ">
            <div class="grid-col-2 a-i-center">
              <div class="flex-start txt-b">{{$t('limit_employee')}}</div>
              <div class="flex-end blue-txt en-b">{{ limitText(product.limit_employee) }} / <span :class="{'red-txt':+balance.employees>=+product.limit_employee}">{{balance.employees}}</span></div>
            </div>
            <div class="grey1-txt txt-sm1">{{$t('limit_employee_desc')}}</div>
          </div>
        </div>
      </div>

      <div class="group grid-col-1 mg-b-20 mg-t-20 ">
        <div class="grid-sm-lg g-c-gap-20 a-i-center">
          <div class="txt-xxl green-txt" v-if="product.discount_card !==0"><i class="fal fa-check"></i></div>
          <div class="txt-xxl red-light-txt" v-if="product.discount_card ===0"><i class="fal fa-times"></i></div>
          <div class="grid-row-2 g-r-gap-10 ">
            <div class="grid-col-2 a-i-center">
              <div class="flex-start txt-b">{{$t('create_discount_model')}}</div>
              <div class="flex-end blue-txt en-b">{{ limitText(product.discount_card) }} / <span :class="{'red-txt':+balance.discount_created>=+product.discount_card}">{{balance.discount_created}}</span></div>
            </div>
            <div class="grey1-txt txt-sm1">{{$t('discount_card_desc')}}</div>
          </div>
        </div>
      </div>



      <div class="group grid-col-1 mg-b-20 mg-t-20 ">
        <div class="grid-sm-lg g-c-gap-20 a-i-center">
          <div class="txt-xxl green-txt" v-if="product.discount_dynamic !==0"><i class="fal fa-check"></i></div>
          <div class="txt-xxl red-light-txt" v-if="product.discount_dynamic ===0"><i class="fal fa-times"></i></div>
          <div class="grid-row-2 g-r-gap-10 ">
            <div class="grid-col-2 a-i-center">
              <div class="flex-start txt-b">{{$t('create_dynamic_discount_model')}}</div>
              <div class="flex-end blue-txt en-b">{{ limitText(product.discount_dynamic) }} / <span :class="{'red-txt':+balance.discount_dynamic>=+product.discount_dynamic}">{{balance.discount_dynamic}}</span></div>
            </div>
            <div class="grey1-txt txt-sm1">{{$t('discount_dynamic_desc')}}</div>
          </div>
        </div>
      </div>

      <div class="group grid-col-1 mg-b-20 mg-t-20 ">
        <div class="grid-sm-lg g-c-gap-20 a-i-center">
          <div class="txt-xxl green-txt" v-if="product.discount_partners !==0"><i class="fal fa-check"></i></div>
          <div class="txt-xxl red-light-txt" v-if="product.discount_partners ===0"><i class="fal fa-times"></i></div>
          <div class="grid-row-2 g-r-gap-10 ">
            <div class="grid-col-2 a-i-center">
              <div class="flex-start txt-b">{{$t('partnership_module')}}</div>
              <div class="flex-end blue-txt en-b">{{ limitText(product.discount_partners) }} / <span :class="{'red-txt':+balance.partners>=+product.discount_partners}">{{balance.partners}}</span></div>
            </div>
            <div class="grey1-txt txt-sm1">{{$t('discount_partners_desc')}}</div>
          </div>
        </div>
      </div>



      <div class="group grid-col-1 mg-b-20 mg-t-20 ">
        <div class="grid-sm-lg g-c-gap-20 a-i-center">
          <div class="txt-xxl green-txt" v-if="product.limit_trans_count !==0"><i class="fal fa-check"></i></div>
          <div class="txt-xxl red-light-txt" v-if="product.limit_trans_count ===0"><i class="fal fa-times"></i></div>
          <div class="grid-row-2 g-r-gap-10 ">
            <div class="grid-col-2 a-i-center">
              <div class="flex-start txt-b">{{$t('limit_trans_count')}}</div>
              <div class="flex-end blue-txt en-b">{{ limitText(product.limit_trans_count) }} / <span :class="{'red-txt':(+balance.collector_transactions+balance.discount_transactions)>=+product.limit_trans_count}">{{balance.collector_transactions+balance.discount_transactions}}</span></div>
            </div>
            <div class="grey1-txt txt-sm1">{{$t('limit_trans_count_desc')}}</div>
          </div>
        </div>
      </div>

      <div class="group grid-col-1 mg-b-20 mg-t-20 ">
        <div class="grid-sm-lg g-c-gap-20 a-i-center">
          <div class="txt-xxl green-txt" v-if="product.stats_count >0"><i class="fal fa-check"></i></div>
          <div class="txt-xxl red-light-txt" v-if="product.stats_count <=0"><i class="fal fa-times"></i></div>
          <div class="grid-row-2 g-r-gap-10 ">
            <div class="grid-col-2 a-i-center">
              <div class="flex-start txt-b">{{$t('limit_logo_design')}}</div>
              <div class="flex-end blue-txt en-b"><span v-if="product.stats_count>0">{{$t('yes')}}</span> <span v-if="product.stats_count<=0">{{$t('no')}}</span> </div>
            </div>
            <div class="grey1-txt txt-sm1">{{$t('limit_logo_design_desc')}}</div>
          </div>
        </div>
      </div>








    </div>
</template>
<script>
    import Spinner from "@/components/STATIC/spinner";
    export default {
      components: {Spinner},
      props:{
        product:Object,
        balance:Object,
        isActivePlan:Boolean,
        currency:String,
        isSelected: {
          default:false,
        },
        isCurrentPlan:{
          default:false,
        },
        section: {
          default:false,
        },
        showSelectButton:{
          default: true,
        }

      },
      data(){
        return {
          packageSelected:false,
          showSpinner:false,
        }
      },
      mounted() {

      // console.log(this.product);
      },
      watch:{
        isSelected:function(oldValue,newValue){

          if(oldValue!==newValue) {

            if (this.isSelected === this.product.id){
              this.showSpinner = false;
              return this.packageSelected = true;
            }else{
              return this.packageSelected = false;
            }
          }
        }

      },
      computed:{
        currentPlan (){
          return this.isCurrentPlan === this.product.id;
        }
      },
      methods:{
        limitText(limit){
          if (limit ==-1) {
            return this.$t('unlimited');
          }
          if(limit==0){
            return this.$t('no');
          }
          return limit;
        },
        selectPackage(){
            this.showSpinner = true;
            /*
            * Function Emits Action And components back result of action in isSelected prop;
            */
            return this.$emit('changePackage',this.product.id);
        },
        canBuy(){
          let balance = this.balance;
          let product = this.product;

          if((balance.collector_given+balance.discount_given)>=product.give_cards_count && product.give_cards_count !==-1){
            return false;
          }
          if(balance.branches>product.limit_branch && product.limit_branch !==-1){

            return false;
          }
          if((balance.collector_transactions+balance.discount_transactions)>=product.limit_trans_count && product.limit_trans_count !==-1){

            return false;
          }
          if(balance.customers>product.customer_count && product.customer_count !==-1){

            return false;
          }
          if(balance.discount_created>product.discount_card && product.discount_card!==-1){
            return false;
          }
          if(balance.discount_dynamic>product.discount_dynamic && product.discount_dynamic!==-1){

            return false;
          }
          if(balance.employees>product.limit_employee && product.limit_employee!==-1){

            return false;
          }
          if(balance.partners>product.discount_partners && product.discount_partners!==-1){
            return false;
          }
          if(balance.points_given>=product.sell_points_count && product.sell_points_count!==-1){

            return false;
          }
          return true;

        },



      }
    }
</script>
<style>
.notification-group {
  grid-template-rows: repeat( auto-fit, minmax(20px,35px) ) !important;
}
</style>
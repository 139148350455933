<template>
  <div>


    <div class="split-view">

      <div class="view-list">

        <div v-if="!showLeftSpinner">


          <div class="card-wraper mg-b-10">
            <div class="wallet-card" :style="css">
              <div class="card-skeleton" >
                <div class="company-logo en"><i :class="this.form.logo_link" :style="companyNameIconCol"></i></div>
                <div class="company-name txt-lg1" :style="companyNameCol">{{form.show_label}}</div>

                <div class="card-type" :style="cardTypeCol" v-if="card_type==1">%</div>
                <div class="card-type" :style="cardTypeCol" v-if="card_type==2"><i class="fal fa-coins"></i></div>

                <div class="pay-button yellow grey6-txt"><i class="fal fa-qrcode" style="font-size: var(--txt-4xl);"></i></div>
                <div class="account-number"><span class="acc">{{ addSpace(card_number)}}</span></div>
                <div class="valid-date ka ">
                  <span class="ka-b">{{$t('active')}}</span><br>
                  <span class="en">mm/yy</span>
                </div>
                <card-logo></card-logo>
              </div>
            </div>
          </div>
          <div class="txt-xl t-black-txt radius grey7 txt-b pd-10">
            <span>.grcolor{{cssName}}{<br>
              {{css}}<br>
              }<br>
               <span v-if="companyNameCol.length>0">
              .grcolor{{cssName}} >.card-skeleton > .company-name {
              color: {{companyNameColor}};
              }
              </span>
              <br>

             <span v-if="companyNameIconCol.length>0">
            .grcolor{{cssName}} > .card-skeleton > .company-logo {
            color: {{companyIconColor}};
            }
          </span>
              <br>
           <span v-if="cardTypeCol.length>0">
            .grcolor{{cssName}} >.card-skeleton > .card-type {
            color: {{cardTypeColor}};
            }
          </span>
            </span>
          </div>
          <div class="heading-group--sub">

            <span class="txt-xl" :class="$t('font')">Edit Text Colors</span>
            <span class="color-button" v-on:click="enableCustomColors =!enableCustomColors"><i class="fal fa-paint-brush-alt"></i></span>
          </div>
          <div v-if="enableCustomColors">
          <div class="space-div--20"></div>

          <input type="color" style="height: 30px; width: 30px;" v-model="companyNameColor">
          <input type="button" class="blue radius btn-sm pd-4" value="Activate Name COLOR" @click="setCompanyColor=!setCompanyColor"/>
          <div class="space-div--20"></div>

          <input type="color" v-model="companyIconColor">
          <input type="button" class="blue radius btn-sm pd-4" value="Activate Logo COLOR" @click="setCompanyIconColor=!setCompanyIconColor"/>

          <div class="space-div--20"></div>

          <input type="color" v-model="cardTypeColor">
          <input type="button" class="blue radius btn-sm pd-4" value="Activate Type Icon COLOR" @click="setCardTypeColor=!setCardTypeColor"/>
          </div>


          <div class="space-div--20"></div>
          <input-slot label="COLOR NUMBER" :req="true" :message="errorMessage('cssname')">
            <input type="text" name="cssname" autocomplete="false" v-model="cssName">
          </input-slot>

          <textarea rows="15" v-model="css" style="border:1px solid black; height: 100% !important; font-size: var(--txt-lg);">
          </textarea>

        </div>

      </div>
      <div class="view-action">

        <div v-if="!showRightSpinner">
          <div class="card-wraper mg-b-10">
            <div class="wallet-card" :class="form.color">
              <div class="card-skeleton" >
                <div class="company-logo en"><i :class="this.form.logo_link"></i></div>
                <div class="company-name txt-lg1" :class="{'ka':form.label_en==0,'en-r':form.label_en==1}">{{form.show_label}}</div>

                <div class="card-type" v-if="card_type==1">%</div>
                <div class="card-type" v-if="card_type==2"><i class="fal fa-coins"></i></div>

                <div class="pay-button yellow grey6-txt"><i class="fal fa-qrcode" style="font-size: var(--txt-4xl);"></i></div>
                <div class="account-number"><span class="acc">{{ addSpace(card_number)}}</span></div>
                <div class="valid-date ka ">
                  <span class="ka-b">{{$t('active')}}</span><br>
                  <span class="en">mm/yy</span>
                </div>
                <card-logo></card-logo>
              </div>
            </div>
          </div>
          <div class="heading-group--sub">
            <p class="txt-lg1 en-b blue-txt">{{form.color}}</p>
            <span class="txt-xl" :class="$t('font')">{{$t('card_design')}}</span>
            <span class="color-button" v-on:click="showColors =!showColors"><i class="fal fa-paint-brush-alt"></i></span>
          </div>
          <div class="value-description">
            <span>{{$t('card_design_desc_txt')}}</span>
          </div>
          <div class="space-div--20"></div>


          <div class="card-color-group mg-b-10 pd-b-5" v-if="showColors">
            <div :class="color"  v-for="(color,index) in colorPalette" :key="index"
                 @click="form.color = color"
                 class="t-center pd-t-2">
              <i  v-if="form.color===color" class="fas fa-check t-white-txt txt-xl"></i>
            </div>

          </div>


          <input-slot label="card_display_name" :req="true" :message="errorMessage('show_label')">
            <input type="text" name="card_display_name" autocomplete="false" v-model="form.show_label">
          </input-slot>
          <div class="value-description">
            <span>{{$t('card_display_name_desc')}}</span>
          </div>
          <div class="space-div--40"></div>
          <input-switch
              :label="$t('name_latin_letters')"
              v-on:stateChange="updateLabelEn()"
              :set-state="form.label_en"
          ></input-switch>
          <div class="value-description">
            <span>{{$t('name_latin_letters_desc')}}</span>
          </div>

          <div class="space-div--40"></div>
          <Fontaswome_logos :edit-logo="form.logo_link" @onSet="setlogo"></Fontaswome_logos>









        </div>
      </div>
    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";


// import vSelect from 'vue-select'


import inputSlot from "@/components/STATIC/inputSlot";
import cardLogo from "@/components/STATIC/cardLogo";
import inputSwitch from "@/components/STATIC/inputSwitch";
// import {validate,notValidMsg} from "@/support/support";
import support, {colorPalette, validator, displayErrors} from "@/support/support";
import Launchpad from "@/components/launchpad";



import Fontaswome_logos from "@/components/component/fontaswome_logos";
import {loading} from "@/support/helpers";
export default {
  components:{
    Fontaswome_logos,



    Launchpad,
    // vSelect,
    cardLogo,


    inputSlot,
    inputSwitch,

  },
  data(){
    return {

      css:'background-color: #d08712;' +
          'background-image: radial-gradient(#5a115a 1.1px,#d08712 0);' +
          'background-size: 22px 22px;' +
          'opacity: 1;' +
          '',
      cssName:'',
      showLeftSpinner:false,
      showRightSpinner:false,
      showColors:false,
      card_type: 2,
      card_number: '2000000000000000',
      publicLinkReload:false,
      companyNameColor:"#000000",
      setCompanyColor:false,
      companyIconColor:"#000000",
      setCompanyIconColor:false,
      cardTypeColor:"#000000",
      setCardTypeColor:false,
      enableCustomColors:false,

      formErrors: {},
      fetchErrors:{},

      form:{
        id :'',
        active_from :'',
        active_due :'',
        shareable :'',
        active :'',
        minimum_purchase :'',
        spend_minimum_limit :'',
        sell_rate :'',
        buy_rate :'',
        minimum_purchase_power :'',
        color :'grcolor1',
        show_label :'',
        label_en :'',
        expire_lost:'',
        logo_link:'pof portfel',
      }
    }
  },
  mounted(){
    this.resetToDefaults();
    this.showLeftSpinner = true;
    this.showRightSpinner = true;


    this._getAllDataFromCloud().then(()=>{
      this.showLeftSpinner = false;
      this.showRightSpinner = false;
      this.form = Object.assign({},this.CLOUD);
    }).catch(()=>{
      this.showLeftSpinner = false;
      this.showRightSpinner = false;
    });
  },
  computed:{
    ...mapState('_collector',['CLOUD']),
    ...mapState('_general',{GCLOUD:'CLOUD'}),

    pointsEnabled(){
      return this.GCLOUD.SETTINGS.points_enabled;
    },
    companyNameCol(){
      if(this.setCompanyColor){
        return 'color:'+this.companyNameColor;
      }else{
        return '';
      }
    },
    cardTypeCol(){
      if(this.setCardTypeColor){
        return 'color:'+this.cardTypeColor;
      }else{
        return '';
      }
    },
    companyNameIconCol(){
      if(this.setCompanyIconColor){
        return 'color:'+this.companyIconColor;
      }else{
        return "";
      }
    },
    error(){
      return this.errors;
    },
    colorPalette(){
        return colorPalette(this.CLOUD.color_palette_count)
    },
    canSubmit(){
      let valuesToValidate = [
        {
          name:'show_label',
          input:this.form.show_label,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'spend_minimum_limit',
          input:this.form.spend_minimum_limit,
          rule:'min:num',
          message:this.$t('min_num'),
        },
        {
          name:'buy_rate',
          input:this.form.buy_rate,
          rule:'min:num',
          message:this.$t('min_num'),
        },
        {
          name:'sell_rate',
          input:this.form.sell_rate,
          rule:'min:num',
          message:this.$t('min_num'),
        },
        {
          name:'minimum_purchase',
          input:this.form.minimum_purchase,
          rule:'min:zero',
          message:this.$t('min_zero'),
        },
      ];
      return this.hasErrors(validator(valuesToValidate));
    },




  },
  methods:{
    ...mapActions('_collector',['_getAllDataFromCloud','_updateCloud']),
    ...mapActions('_general',['_toggleSettings']),

    togglePoints(){
      let form = {
        toggle:'points',
        state: this.form.active,
      }
      return this._toggleSettings(form);
    },
    /* Error Handling */
    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    hasErrors(errors){ //METHOD FOR Can Submit
      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
    /* END Error Handling */
    addSpace(value) {
      if (value.length > 0) {
        return support.space(value);
      } else {
        return '';
      }
    },

    labelOrError(label,errorMsg){
      if(errorMsg){
        return '<span class="red-light-txt">* '+errorMsg+'</span>';
      }
      return this.$t(label);
    },

    resetToDefaults(){
      this.publicLinkReload = false;
      this.showLeftSpinner = false;
      this.showRightSpinner = false;
      this.showDeleteBtn = false;
      this.resetForm();
    },
    resetForm(){
      this.formErrors= {};
      this.fetchErrors={};
      return this.form= {
        id :'',
        active_from :'',
        active_due :'',
        shareable :'',
        active :'',
        minimum_purchase :'',
        spend_minimum_limit :'',
        sell_rate :'',
        buy_rate :'',
        minimum_purchase_power :'',
        color :'',
        show_label :'',
        label_en :'',
        logo_link :'pof portfel',
        expire_lost:'',
      };

    },

    setForEdit(id){
      this.resetToDefaults();
      this.showDeleteBtn = id;
      return this.form = Object.assign({},this.getItemById(id));
    },

    saveForm(){
      if(!this.canSubmit){
        return false;
      }
      /*
      * Update Global Setting of Points enabled also
      */

      this.showRightSpinner = true;
      this.showLeftSpinner = true;
      this._updateCloud(this.form).then(()=>{
        return window.location.reload();
      }).catch(error=>{
        this.fetchErrors = error.errors;
        this.showRightSpinner = false;
        this.showLeftSpinner = false;
      })

    },
    updateCanShare(){
      this.form.shareable = !this.form.shareable;
    },
    updateExpireLost(){
      this.form.expire_lost = !this.form.expire_lost;
    },
    updateActive(){
      this.form.active = !this.form.active;
      this.togglePoints();
    },
    updateLabelEn(){
      this.form.label_en = !this.form.label_en;
    },
    setlogo(e){
      this.form.logo_link = e;
    },
    reload(){
      loading();
      return window.location.reload(true);
    }

  }
}
</script>

<style scoped>

@import "../assets/css/cardsPage.css";
#chat-application{
  visibility: hidden !important;
}
</style>
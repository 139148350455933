<template>
  <div>
    <div class="heading-group bg">
      <h6 :class="$t('font_b')" class=" txt-3xl mg-t-20 pd-l-8 txt-uppercase">{{$t('inbox')}}</h6>
    </div>
    <div class="split-view">
      <div class="view-list">

        <!-- Heading -->
        <div class="group grid-col-2 a-i-center pd-b-20 w-b-line">
          <div class="flex-start grey5-txt " :class="$t('font')"><h1>{{$t('filter_data')}}</h1></div>
          <div class="flex-end">
            <vue-json-to-csv :json-data="exportMessages" csv-title="export">
              <div class="grid-row-2 t-center cursor">
                <div class="txt-4xl green-txt"><i class="fal fa-file-excel"></i></div>
                <div :class="$t('font')" class="txt-xs1">{{$t('export')}}</div>
              </div>
            </vue-json-to-csv>
          </div>
        </div>


        <!-- Keyword -->
        <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="toggleKeyword()" :set-state="searchFilter" :label="$t('search_keyword')" :desc="$t('keyword_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p f-i-h" v-if="searchFilter">
            <div class="input-group wd-100p input-group-no-label w-tb-margin">
              <div class="input-area"><input type="text"  :placeholder="$t('keyword')" v-model="submitForm.searchKeyword"></div>
            </div>
          </div>
        </div>


        <!-- Date -->
        <div class="group grid-row-2  g-r-gap-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="toggleDateFilter()" :set-state="dateFilter" :label="$t('date')" :desc="$t('date_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p " v-if="dateFilter" style="padding-bottom: 30px !important;">
            <div class="grid-row-2 f-i-h g-r-gap-20 wd-100p">

              <div class="grid-col-3 a-i-center" style="height: 24px; padding-top: 30px;">
                <div class="selection-type-item selection-left cursor" :class="{'yellow':selectedToday}" v-on:click="today()">{{$t('today')}}</div>
                <div class="selection-type-item selection-center cursor" :class="{'yellow':selectedYesterday}" v-on:click="yesterday()">{{$t('yesterday')}}</div>
                <div class="selection-type-item selection-right cursor" :class="{'yellow':selectedCustomDate}" v-on:click="custom()">{{$t('custom_date')}}</div>
              </div>

              <div class="grid-col-2 g-c-gap-20 j-c-end" style="padding-bottom: 30px;" v-if="selectedCustomDate">
                <div class="flex-start">
                  <div class="grid-row-2">
                    <div class="grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_from')}}</div>
                    <div><div class="input-area"><input v-model="submitForm.dateFrom" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
                  </div>

                </div>
                <div class="flex-end">
                  <div class="grid-row-2">
                    <div class=" grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_to')}}</div>
                    <div><div class="input-area"><input v-model="submitForm.dateTo" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        <!-- Branch -->
        <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="toggleBranchFilter()" :set-state="branchFilter" :label="$t('branch')" :desc="$t('branch_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p f-i-h" v-if="branchFilter">
            <div class="input-group wd-100p input-group-no-label w-tb-margin">
              <div class="input-area">
                <v-select :options="branchData" @input="branchSelected"></v-select>
              </div>
            </div>
          </div>
        </div>


        <!-- Employee -->
        <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="toggleEmployeeFilter()" :set-state="employeeFilter" :label="$t('employee')" :desc="$t('employee_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p f-i-h" v-if="employeeFilter">
            <div class="input-group wd-100p input-group-no-label w-tb-margin">
              <div class="input-area cursor">
                <v-select :options="employeeData" label="email" @input="employeeSelected"></v-select>
              </div>
            </div>
          </div>
        </div>



        <div class="group flex-center flex-center-self" style="margin-top: 60px !important; margin-bottom: 40px !important;">
          <div v-if="!showLeftSpinner" class="yellow pd-20 txt-b cursor" style="border-radius: 30px !important;" v-on:click="showFilteredData()"><i class="fal fa-filter"></i> {{$t('show_filtered_data')}}</div>
          <div  v-if="showLeftSpinner" class="txt-7xl grey3-txt container t-center"><spinner></spinner></div>
        </div>

      </div>






      <div class="view-action">
        <!-- Heading -->
        <div class="group grid-col-1 a-i-center pd-b-20 w-b-line">
          <div class="flex-start grey5-txt wd-100p" :class="$t('font')">
            <div class="grid-row-2">
              <div class="pd-b-10"><h1>{{$t('messages')}}</h1></div>
              <div class="pd-l-2 pd-t-10 txt-md"><span class="blue-txt cursor pd-r-10" v-on:click="showUnread=false">{{$t('all')}} ({{messageCount}})</span> <span v-if="unreadCount>0" class="blue-txt cursor" v-on:click="toggleShowUnread()"> {{$t('unread')}} ({{unreadCount}}) </span></div>
            </div>
          </div>

        </div>

        <!-- Spinner -->
        <div  v-if="showRightSpinner" class="txt-7xl grey3-txt container t-center mg-t-30"><spinner></spinner></div>


        <div class="message-group" v-if="messages.length>0 && !showRightSpinner" >
          <transition-group name="list" tag="div">
            <div class="message-group-item" v-for="message in messages" :key="message.id">
              <div class="message-pre blue-txt txt-xs cursor" @click="toggleRead(message.id,!message.read)"><i class="fas fa-circle" v-if="Boolean(message.read)==0"></i></div>
              <div class="message-head txt-md cursor" :class="$t('font_b')" @click="tglFooterNDelete(message.id)">{{message.sender_name}}<i class="fal fa-info-circle blue-txt txt-md pd-l-3"></i> <i class="fas fa-heart red-light-txt pd-l-3" v-if="message.sender_likes==1"></i><i class="fal fa-heart pd-l-3" v-if="message.sender_likes==0"></i></div>
              <div class="message-pos en-sb txt-lg" ><i class=" red-light-txt fas fa-minus-circle cursor" @click="deleteMessage(message.id)" v-if="showFooterNDelete===message.id"></i></div>
              <div class="message-content en txt-sm1 black-txt" @click="tglFooterNDelete(message.id)" :class="{'w-b-line':showFooterNDelete===message.id}">{{message.message}}</div>
              <div class="message-footer en-r txt-sm grey1-txt" v-if="showFooterNDelete===message.id" v-on:click="tglFooterNDelete(message.id)">
                <div class="message-sub-left" :class="$t('font')"><i class="fal fa-map-marker-check"></i> {{message.last_place}}</div>
                <div class="message-sub-right txt-xs"><i class="fal fa-calendar-alt"></i> {{message.message_date}}</div>
                <div class="message-sub-left" :class="$t('font')"><i class="fal fa-user"></i> {{message.last_employee}}</div>
                <div class="message-sub-right txt-xs"><a :href="`tel:${message.senders_phone}`" target="_blank" style="text-decoration: none;" class="blue-txt"><i class="fal fa-mobile blue-txt" style="margin-right:3px;"></i> {{space(message.senders_phone,3)}}</a></div>
              </div>
            </div>
          </transition-group>
        </div>
        <div class="flex-center-raw wd-100p" v-if="hasPages && !showRightSpinner">
          <div class="flex-center txt-lg1 blue-txt">
            <div @click="pagPrev()" class="pd-r-10"><i class="fal fa-chevron-circle-left"></i></div>
            <div class="grey5-txt txt-md1">
              <span @click="pagGo(1)" class="blue-txt">1</span> /
              <span @click="pagGo(total_pages)" class="blue-txt"> {{total_pages}}</span>
            </div>
            <div @click="pagNext()" class="pd-l-10"><i class="fal fa-chevron-circle-right"></i></div>
          </div>
        </div>
        <div class="flex-center-raw grey3-txt"><span v-if="+current_page!==1 && +current_page!==+total_pages">({{current_page}})</span></div>


      </div>
    </div>

    <launchpad></launchpad>
  </div>
</template>

<script>
import Launchpad from "@/components/launchpad";
import InputSwitchWDesc from "@/components/STATIC/inputSwitchWDesc";
import vSelect from 'vue-select'
import {mapActions, mapState} from "vuex";
import Spinner from "@/components/STATIC/spinner";
import VueJsonToCsv from 'vue-json-to-csv'
import {canRequestUpdate, setCloudDataUpdatedToken, setCloudDataUpdateFail, space} from "@/support/support";

export default {
  components: {Spinner, InputSwitchWDesc, Launchpad,vSelect,VueJsonToCsv},
  data(){
    return {
      showUnread:false,
      showLeftSpinner:false,
      showRightSpinner:false,

      selectedCustomDate:false,
      selectedToday:false,
      selectedYesterday:false,

      searchFilter:false,
      dateFilter:false,
      branchFilter:false,
      employeeFilter:false,





      defaultForm:{
        searchKeyword:'',
        dateFrom:'',
        dateTo:'',
        branchSelectId:'',
        employeeSelectId:'',
        page:1,
      },
      submitForm:{},

      showFooterNDelete:false,
      showMoreInfo:false,
    }
  },
  mounted() {
    this.submitForm = this.defaultForm;
    if(this.messageCount<=0){
      this.showLeftSpinner = true;
      this.showRightSpinner = true;
      this._getAllDataFromCloud().then(()=>{
        this.showLeftSpinner = false;
        this.showRightSpinner = false;
      }).catch(()=>{
        this.showLeftSpinner = false;
        this.showRightSpinner = false;
      })
    }

  },
  computed:{
    ...mapState('_branch',{branchCLOUD:'CLOUD'}),
    ...mapState('_employee',{employeeCLOUD:'CLOUD'}),
    ...mapState('_inbox',['CLOUD']),
    branchData(){
      return this.branchCLOUD;
    },
    employeeData(){
      return this.employeeCLOUD;
    },
    messageCount(){
      return this.total_records;
    },
    unreadCount(){
      let unread = this.CLOUD.MESSAGES.filter(message=>{
        return +message.read ===0;
      })
      return unread.length;
    },
    exportMessages(){
      return this.CLOUD.MESSAGES;
    },
    messages(){
      if(this.showUnread){
        return this.CLOUD.MESSAGES.filter(message=>{
          return +message.read ===0;
        })
      }
      return this.CLOUD.MESSAGES;
    },
    total_records(){
      return this.CLOUD.TOTAL_RECORDS;
    },
    page_records(){
      return this.CLOUD.PER_PAGE_RECORDS;
    },
    current_page(){
      return this.CLOUD.CURRENT_PAGE;
    },
    total_pages(){
      return this.CLOUD.LAST_PAGE;
    },
    hasPages(){
      return +this.total_records>50;
    }
  },
  methods:{
    ...mapActions('_branch',{fetchBranchData:'_getAllDataFromCloud'}),
    ...mapActions('_employee',{fetchEmployeeData:'_getAllDataFromCloud'}),
    ...mapActions('_inbox',['_getAllDataFromCloud','_toggleRead','_removeMessageFromCloud']),
    toggleKeyword(){
      this.searchFilter = !this.searchFilter;
      if(!this.searchFilter){
        return this.submitForm.searchKeyword = '';
      }
      return true;
    },
    toggleDateFilter(){
      this.dateFilter = !this.dateFilter;
      if(this.dateFilter){
        return this.today();
      }
      return this.resetDateSelection();
    },
    toggleBranchFilter(){
      this.branchFilter = !this.branchFilter;
      /*
      * Fetch Branch Data if it does not exist
      */
      if((this.branchFilter && canRequestUpdate('_brch',+2)) || this.branchData.length<=0){
        this.fetchBranchData().then(()=>{
          return setCloudDataUpdatedToken('_brch');
        }).catch(()=>{
          return setCloudDataUpdateFail('_brch')
        });
      }

      if(!this.branchFilter){
        return this.submitForm.branchSelectId = '';
      }
      return true;
    },
    toggleEmployeeFilter(){
      this.employeeFilter = !this.employeeFilter;

      if((this.employeeFilter && canRequestUpdate('_emp',+2)) || this.employeeData.length<=0){
        this.fetchEmployeeData().then(()=>{
          return setCloudDataUpdatedToken('_emp');
        }).catch(()=>{
          return setCloudDataUpdateFail('_emp')
        });
      }

      if(!this.employeeFilter){
        return this.submitForm.employeeSelectId = '';
      }
      return true;
    },

    today(){
      this.resetDateSelection();
      this.selectedToday = true;
      this.submitForm.dateFrom = "02/01/2000";
      this.submitForm.dateTo="02/01/2000";
    },
    yesterday(){
      this.resetDateSelection();
      this.selectedYesterday = true;
      this.submitForm.dateFrom = "01/01/2000";
      this.submitForm.dateTo="01/01/2000";
    },
    custom(){
      this.resetDateSelection();
      this.selectedCustomDate = true;
    },
    branchSelected(e){
      this.submitForm.branchSelectId =e.id;

    },
    employeeSelected(e){
      this.submitForm.employeeSelectId =e.id;

    },
    showFilteredData(){
      this.showRightSpinner = true;
      this.showLeftSpinner =true;
      this._getAllDataFromCloud(this.submitForm).then(()=>{
          this.showRightSpinner = false;
          this.showLeftSpinner =false;
      }).catch(()=>{
          this.showRightSpinner = false;
          this.showLeftSpinner =false;
      });
    },
    resetForm(){
      this.submitForm = this.defaultForm;
    },
    resetDateSelection(){
      this.submitForm.dateFrom = '';
      this.submitForm.dateTo = '';
      this.selectedToday = false;
      this.selectedYesterday = false;
      this.selectedCustomDate = false;
    },

    tglFooterNDelete(message_id){
      if(this.showFooterNDelete === message_id){
        this.showFooterNDelete = false;
      }else{
        this.showFooterNDelete = message_id;
      }
    },
    deleteMessage(id){
      return this._removeMessageFromCloud(id);
    },
    toggleRead(id,read){
      let data = {
        id:id,
        read:read
      };
      return this._toggleRead(data);
    },
    moreInfo(id) {
      if(this.showMoreInfo===id){
        return this.showMoreInfo =false;
      }
      return this.showMoreInfo=id;
    },
    space(n){
      return space(n);
    },
    toggleShowUnread(){
      this.showUnread = !this.showUnread;
    },
    pagGo(page){
      this.submitForm.page = page;
      return this.showFilteredData();
    },
    pagNext(){
      let page = +this.submitForm.page+1;
      if(page <=this.total_pages){
        this.submitForm.page = page;
        return this.showFilteredData();
      }
    },
    pagPrev(){
      let page = +this.submitForm.page-1;
      if(page>0){
        this.submitForm.page = page;
        return this.showFilteredData();
      }

    },
  }
}
</script>

<style scoped>

</style>
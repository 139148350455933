<template>
<div>
  <div class="headline-txt">{{ $t('branchs') }}</div>
  <div class="section-wrp">
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('average_check')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
         <line-chart :chart-data="averageCheck"></line-chart>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('transactions_count')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <line-chart :chart-data="transactionsCountMonth"></line-chart>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>


import LineChart from "@/components/STATIC/lineChart";
import {chartColors, shuffle} from "@/support/helpers";
export default {
  name: "section-branch",
  components: {LineChart},
  props:{
    CLOUD:Object,
  },
  mounted() {

  },
  computed:{
    averageCheck(){
      if(Object.entries(this.CLOUD.branch.average_check).length<=0){
        return  false;
      }
      let datasets = [];
      let labels = [];
      Object.entries(this.CLOUD.branch.average_check).forEach(([key, value]) => {
        labels = value.labels;
        let color = shuffle(chartColors())[0];
        datasets.push({
          label:key,
          data: value.data,
          backgroundColor:color,
          borderColor: color,
        });
      });
      labels = labels.map(label=>{
        return this.$t('month_'+label);
      });
      return{
        labels,
        datasets
      }
    },
    transactionsCountMonth(){
      if(Object.entries(this.CLOUD.branch.transaction_count_month).length<=0){
        return  false;
      }
      let datasets = [];
      let labels = [];
      Object.entries(this.CLOUD.branch.transaction_count_month).forEach(([key, value]) => {
        labels = value.labels;
        let color = shuffle(chartColors())[0];
        datasets.push({
          label:key,
          data: value.data,
          backgroundColor:color,
          borderColor: color,
        });
      });
      labels = labels.map(label=>{
        return this.$t('month_'+label);
      });
      return{
        labels,
        datasets
      }
    }
  }
}
</script>

<style scoped>

</style>
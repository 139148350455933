/*
* @converts nested object data into one object to submit to API
* @ Assumed that object contains { {id:'name',val:'Vuex'},{id:'Email',val:'pro@gmail.com'},{...} }
* converter only Checks id and val
*/

import router from "@/router";

import config from "@/config";
import collector from "@/apis/collector";
import rootStore from '@/store/';
import state from '@/store/state';
import app from "@/apis/app";
/*
* @param searchTem - String
* @param searchPlace - Array
* @param searchColomns - Array with Objects inside [{name:'Jimmy',type:'String'}]
* @parame letterCount - Number , From How many letters in search term to start function
*/

export const searchFilter=(searchTerm,searchPlace,searchColomns,letterCount=0)=>{



    if(searchTerm!==false){
        if(searchTerm.length>letterCount || searchTerm=='0'){

            return searchPlace.filter(item=> {

                let result = false;

                searchColomns.forEach(column => {
                //NEW RELPACES WHITE SPACE , NAME SEARCH BECOMES DIFICULT
                searchTerm = searchTerm.replace(/\s/g, '');


                if(column.type ==='num' || column.type ==='number'){

                    if(item[column.name] <= Number(searchTerm)){

                        result = true;
                    }
                }else{

                    if((item[column.name]) && item[column.name].toString().toLowerCase().includes(searchTerm.toLowerCase())){
                        result = true;
                    }
                }

                });

                return result;

            });
        }else{
            return searchPlace;
        }

    }else{
        return searchPlace;
    }
}
export const convertData = (data)=> {
    const returnData = new Object();
    Object.entries(data).forEach(([key]) => {
        returnData[data[key].id] = data[key].val;
    });
    return returnData;
}
export const stripSpace=(variable)=>{
    return variable.replace(/\s/g, '');
}
/*
*
@INPUT{
"id": "100",
"email": "shalva@yahoo.com",
"companyId": "333333",
"companyName": "Inco Solutions",
"phone": "+995 555880088",
"address": "Rustaveli 1",
"companyRegDate": "2020-01-31"
}
* OUTPUT: [{id:'id',val:'100'},{id:'email',val:'shalva@yahoo.com'},{...}]
*/
export const convertForRegistrator=(data)=>{
    const returnData = [];

    Object.entries(data).forEach(([key,val]) => {
        returnData.push({id:key,val:val});
    });
    return returnData;
}
export const roundTo=(num,roundTo=2)=>{

    return +(Math.round(num + "e+"+roundTo)  + "e-"+roundTo);
}
export const deviceLog = () => {
    return navigator;
}
/*
* @returns boolean
*/
export const canRequestUpdate = (token=config.dataVersion,allowTime=config.dataUpdateIntervalInMinutes) => {


    let now = new Date();
    let _last = localStorage.getItem(token);


    let lastUpdate = new Date(_last);
    if(lastUpdate ===null || lastUpdate ===undefined){

        return true;
    }
    if(now.getFullYear() !== lastUpdate.getFullYear()){

        return true;
    }
    if(now.getMonth() !== lastUpdate.getMonth()){

        return true;
    }
    if(now.getDate() !== lastUpdate.getDate()){

        return true;
    }
    if(now.getHours() !== lastUpdate.getHours()){

        return true;
    }

    if(now.getMinutes() !== lastUpdate.getMinutes()){

        let differance =  now.getMinutes()-lastUpdate.getMinutes();

        let allowedDifferance = allowTime;
        if(differance<0){

            /* Something is not ok there so update */
            return true;
        }
        return (differance > allowedDifferance);
    }
}
export const setCloudDataUpdateFail = (token=config.dataVersion) => {
    return localStorage.removeItem(token);
}
export const setCloudDataUpdatedToken = (token=config.dataVersion) => {
    let now = new Date();
    return localStorage.setItem(token,now);
}
export const space =(str, after=4)=>{

        if (!str) {
            return false;
        }
        after = after || 4;
        let v = String(str).replace(/[^\dA-Z]/g, ''),
            reg = new RegExp(".{" + after + "}", "g");
        return v.replace(reg, function (a) {
            return a + ' ';
        });

}
export const autoColorMode=()=>{
    return localStorage.getItem('day-night-mode');
}
export const setColorMode=()=>{
    let dayNightMode = localStorage.getItem('day-night-mode');

    if(dayNightMode){

        if(window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches){
            //Dark Mode Here
            return document.documentElement.setAttribute('data-theme', 'dark');
        }
        return  window.matchMedia('(prefers-color-scheme:dark)').addEventListener('change',ev => {
            const newColorScheme = ev.matches ? "dark" : "light";
            document.documentElement.setAttribute('data-theme', newColorScheme);
        });
    }
    let currentTheme = localStorage.getItem('color-mode')
        ? 'dark'
        : 'light';
    if (currentTheme) {
        document.documentElement.setAttribute('data-theme', currentTheme);
    }
}


export const errorStatus401 =(error)=>{
    if(error.response.status ===401){
        localStorage.removeItem(config.tokenName);
        router.push({name:'login'});
    }else{
        return true;
    }
}

export const displayErrors=(input,errorsContainer)=>{
    if(errorsContainer === undefined ||  Object.entries(errorsContainer).length===0){
        return false;
    }
    let error = errorsContainer[input];
    return (error !== undefined) ? error[0] : false;
}
/*
COMPUTED:
 canSubmit(){
    let valuesToValidate = [
      {
        name:'label', // Value Name, the object  in input form
        input:this.form.label, // input form object
        rule:'min:char',
        message:'Error Message HERE'
      },
    ];
    return this.hasErrors(validator(valuesToValidate));
  },
METHODS:

    errorMessage(input){ //METHOD FOR INPUT
        return displayErrors(input,this.formErrors);
    },
    hasErrors(errors){ //METHOD FOR Can Submit
        this.formErrors = errors;
        return (Object.entries(this.formErrors).length ===0);
    },


*/
export const validator=(values)=>{

    let errors={};
    let i;
    for (i=0;i<values.length;i++){
        let result = validate(values[i].input,values[i].rule);
        if(!result){
            errors[values[i].name] = [values[i].message];
        }
        if(result===-1){
            errors[values[i].name] = [values[i].message];
        }
        if(result !==-1 && result){
            if(errors[values[i].name] !==undefined){
                delete errors[values[i].name];
            }
        }
    }
    return errors;
}


export const validate =(input,type)=>{
    let domain = getDomain();
    let domainData = getDomainData(domain);

    if(input ===null || input===undefined || input.length===0){
        return -1;
    }
    if(type==='zero'){
        return input===0;
    }
    if(type==='min:zero'){
        return (parseFloat(input)>=0);
    }
    if(type==='min:num'){
        return (parseFloat(input)>0);
    }

    if(type==='min:char'){
        if(parseInt(input.length)===0){
            return true;
        }
        return !(parseInt(input.length) > 0 && input.length < 2);
    }
    if(type==='length'){
        return parseInt(input.length) > 0;
    }
    if(type==='phone'){
        let regExp = new RegExp(domainData.phone_regex);
        return (parseInt(input.length)===0) ? true : regExp.test(input);
    }
    if(type==='reg'){
        let regExp = new RegExp(domainData.regid_regex);
        return (parseInt(input.length)===0) ? true : regExp.test(input);
    }
    if(type==='regid'){
        let regExp = new RegExp(domainData.regid_regex);
        return (parseInt(input.length)===0) ? true : regExp.test(input);
    }
    if(type==='pid'){
        let regExp = new RegExp(domainData.pid_regex);
        return (parseInt(input.length)===0) ? true : regExp.test(input);
    }
    if(type==='website'){
        let regExp = /^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/gm;
        return (parseInt(input.length) ===0) ? true : regExp.test(input);
    }
    if(type==='password'){
        let regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/gm; //Strong with one upper letter
        // let regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{6,}$/gm;
        return (parseInt(input.length) ===0) ? true : regExp.test(input);
    }
    if(type==='email'){
        let regExp = /([\w.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/igm;
        return (parseInt(input.length) ===0) ? true : regExp.test(input);
    }


}

export const colorPalette = (number_of_records)=>{
    let palette = [];
    let i = 1;
    for(i=1;i<=number_of_records;i++){
        palette.push('grcolor'+i);
    }
    return palette;

}
export const randomName = ()=>{


    return (Math.random().toString(36) + Date.now().toString(36)).substr(2);
}
export const setSubscriptionStatus = ()=>{
    if(isUserLoggedIn()){
        collector.get().then(response=>{
            if(response.status === 209){
               localStorage.setItem("_sub",0);
               if(router.currentRoute.path !=='/service'){
                   return router.push('/service');
               }

            }
            if(response.status === 200){
               localStorage.setItem("_sub",1);
            }
        });
        return true;
    }
    return logOut();
}

/*
* Determins If User is Logged In
*/
// export const isUserLoggedIn = ()=>{
//     return localStorage.getItem(config.tokenName);
// }
export const isUserLoggedIn = ()=>{

    let remember_token = localStorage.getItem(config.tokenName);
    if(remember_token){
        let deviceId = getDeviceId();
        let rememberedId = localStorage.getItem(encode('device_id'));
        if(deviceId !==rememberedId){
            return false;
        }
    }

    return !(!state.TOKEN && !remember_token);

}
export const logIn = (token,email,remember)=>{
    logOut();
    if(remember){
        localStorage.setItem(encode('device_id'), getDeviceId());
        localStorage.setItem(encode('remember_email'), encode(email));
        localStorage.setItem(config.tokenName, token);
        return rootStore.dispatch('setToken',token);
    }
    return rootStore.dispatch('setToken',token);

}
export const logOut = ()=>{

    localStorage.removeItem(encode('device_id'));
    localStorage.removeItem(encode('remember_email'));
    localStorage.removeItem(encode('settings'));
    localStorage.removeItem(config.tokenName);
    localStorage.removeItem(config.dataVersion);
    return rootStore.dispatch('setToken',false);
}
export const getDeviceId = ()=>{
    // let navigator_info = window.navigator;
    // let screen_info = window.screen;
    // let uid = navigator_info.mimeTypes.length;
    let uid = window.navigator.userAgent.replace(/\D+/g, '');
    // uid += navigator_info.userAgent.replace(/\D+/g, '');
    // uid += navigator_info.plugins.length;
    // uid += screen_info.height || '';
    // uid += screen_info.width || '';
    // uid += screen_info.pixelDepth || '';
    return encode(uid);
}
export const encode = (string)=>{
    return window.btoa(window.btoa(string));
} // encode a string
export const decode = (string)=>{
    return window.atob(window.atob(string));
} // decode the string
export const getToken = ()=>{
    let remember_token = localStorage.getItem(config.tokenName);
    if(state.TOKEN){
        return state.TOKEN;
    }
    if(remember_token){
        return remember_token;
    }
    return false;

}
/*
* Determins If User is Logged In
*/
// export const logOut = ()=>{
//     localStorage.removeItem("_sub");
//     return localStorage.removeItem(config.tokenName);
// }
export const currencyToSymbol = (currency)=>{
       if(currency==='eur' || currency==='Eur' || currency==='EUR'){
           return '€';
       }
        if(currency==='usd' || currency==='Usd' || currency==='USD'){
            return '$';
        }
        return currency;
}
export const returnIfExpected =(object,type)=>{
    return (typeof object === type)?object:false;
}

/*
*
*/
export const httpStatusActions =(response,options=false)=>{

    let status = 0;
    let data =null;



    if(response.status !==undefined){ //Success potentially Potential 200 Range
        status = response.status;
        data = response.data;
    }

    if (response.response) {

        status = response.response.status;
        data = response.response.data;

    } else if (response.request) {

        status = response.request.status;

    } else {

        return rootStore.dispatch('errorMessage','', { root: true });

    }

    if(status === 200) { //Success


        if(!options){
            rootStore.dispatch('notifySuccess',response, { root: true });
            return data;
        }
        return data;
    }
    if(status === 401) { //Non Logged User
        logOut();
        return router.push({name:'login'});
    }
    if(status === 209) { //Subscription Expired
        return router.push({name:'service'});
    }
    if(status === 422) { //Error

        rootStore.dispatch('hideLoading',{ root: true });
        rootStore.dispatch('notifyError',response, { root: true });
        return data;
    }
    // if(status ===0){ //Offline
    //
    //     rootStore.dispatch('hideLoading',{ root: true });
    //     rootStore.dispatch('warnMessage','operation_faild_becouse_of_connection', { root: true });
    //     return router.push({name:'home'});
    // }


}
export const getRandomInt=(max=null)=>{
    if(!max){
        return Math.floor(Math.random() * 10000000);
    }
    return Math.floor(Math.random() * max);
}

export const info=(message)=>{
    rootStore.dispatch('infoMessage',message,{ root: true });
}

export const resetTokenValid = (token)=>{
    return token.length ===64;
}

// export const emailValid = (email) =>{
//
//     let regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
//
//     if(email.length>0 && email.length<4){
//         return false;
//     }else{
//         if(email.length ===0){
//             return true;
//         }else{
//             return regExp.test(email);
//         }
//
//     }
// }
export const setLaunchpadState=(setState)=>{
    return localStorage.setItem(encode('launchpad-state'), setState);
}
export const getLaunchpadState=()=>{
    let lpstate = localStorage.getItem(encode('launchpad-state'));
    return lpstate==='true';
}

export const setLang =(lang)=>{
    localStorage.setItem('APP_LANG',lang.toLowerCase());
}
export const getLang =()=>{
    return (!localStorage.getItem('APP_LANG')) ? config.defaultLang :localStorage.getItem('APP_LANG');
}
export const getDomainData =(domain)=>{

    return rootStore.getters._getDomain(domain);
}
export const setDomain =(location)=>{
    if(location !==undefined){
        localStorage.setItem('APP_LOCATION',location.toLowerCase());
    }

}
export const resetDomain =()=>{
    localStorage.removeItem('APP_LOCATION');
    localStorage.removeItem('APP_LANG');
}
export const getDomain =()=>{
    let domain = localStorage.getItem('APP_LOCATION');
    if(!domain){
        logOut();
        resetDomain();
        return (router.currentRoute.name !=='setDomain') ? router.push({name: 'setDomain'}) : false;
    }
    return domain;

}

export const getBrowserLocales=(options = {}) =>{
    const defaultOptions = {
        languageCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,
    };

    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;

    if (!browserLocales) {
        return undefined;
    }

    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();

        return opt.languageCodeOnly
            ? trimmedLocale.split(/-|_/)[0]
            : trimmedLocale;
    });
}
export const checkAndNotifyUpdate=()=>{
    let settings = getAppSettings();

    if(parseFloat(settings.latest_app_version) > parseFloat(config.appVersion)){
        let updateData = {OS:config.OS,VERSION:settings.latest_app_version};
        return rootStore.dispatch('updateMessage',updateData, { root: true });
    }
    return false;
}
export const getAppSettings=()=>{

    let savedSettings = JSON.parse(localStorage.getItem(encode('app_settings')));
    let defaultSettings= {
        "latest_app_version": "0.06",
        "domain_enabled": ["Australia", "France",  "Germany", "Italy", "Poland", "Spain", "Ukraine", "United States", "United Kingdom"],
        "language_enabled": ["en", "ru", "de", "fr", "it", "uk", "es"],
        "color_count":"230"
    };
    return (savedSettings) ? savedSettings : defaultSettings;
}

export const fetchSettings=()=>{
    return new Promise((resolve, reject) => {
        app.get().then(response=>{
            localStorage.setItem(encode('app_settings'), JSON.stringify(response.data));
            resolve(true);
        }).catch(()=>{
            reject(false);
        });
    });

}
export const getColorCount=()=>{
    return getAppSettings().color_count;
}


export const getDollar=()=>{ //returns currency symbol
    let domainData = getDomainData(getDomain());
    return (domainData.currency === undefined) ? "$" : domainData.currency;
}

export const setLocale=(locale='S1')=>{
    return localStorage.setItem(encode('general_settings'), locale);
}
export const resetLocale=(locale='S2')=>{
    return localStorage.setItem(encode('general_settings'), locale);
}
export const getLocale=()=>{
    return localStorage.getItem(encode('general_settings'));
}

export const importScript=(url,attributes=false)=>{
    let script = document.createElement('script')
    if(attributes){
        script.setAttribute('defer','true');
    }
    if(attributes){
        script.setAttribute('async','true');
    }

    script.setAttribute('src', url)
    return document.head.appendChild(script);
}






















export default {
    namespaced: true,
    getLang,
    setLang,
    convertData,
    convertForRegistrator,
    roundTo,
    deviceLog,
    space,
    canRequestUpdate,
    setCloudDataUpdatedToken,
    searchFilter,
    setColorMode,
    errorStatus401,
    stripSpace,
    validate,
    colorPalette,
    randomName,
    displayErrors,
    validator,
    setSubscriptionStatus,
    isUserLoggedIn,
    logOut,
    logIn,
    getDeviceId,
    encode,
    decode,
    getToken,
    currencyToSymbol,
    returnIfExpected,
    httpStatusActions,
    resetTokenValid,
    // emailValid,
    getRandomInt,
    info,
    getLaunchpadState,
    setLaunchpadState,
    setLocale,
    getLocale,
    resetLocale,
    setCloudDataUpdateFail,
    importScript






}



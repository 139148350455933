export const SET_CLOUD_DATA = (state,form) => {
    state.CLOUD = Object.assign({}, form);
}

export const UPDATE_EDITED_VALUE = (state,{id,name,val}) => {
    /* Identify Record To Update */
    let theValue = state.CLOUD.find(obj=>obj.id==id);

    /* Store in Variable as Old */
    let theValue_OLD = theValue;
    /* Change Value of element in Old Value */
    theValue_OLD[name] = val;
    /* Create New Object with updated Value */
    let createNewObject = theValue_OLD;
    /* Inject in Object Updates */
    theValue = Object.assign({},theValue,createNewObject);

}
export const UPDATE_INPUT_VALUE = (state,{index,name,val}) => {
    // console.log('CAME UPDATE_INPUT_VALUE VASHAA')
    // console.log(index+","+name+","+val)
    /* Identify Record To Update */
    let theValue = state.INPUTS[index];
    /* Store in Variable as Old */
    let theValue_OLD = theValue;
    /* Change Value of element in Old Value */
    theValue_OLD[name] = val;
    /* Create New Object with updated Value */
    let createNewObject = theValue_OLD;
    /* Inject in Object Updates */
    theValue = Object.assign({},theValue,createNewObject);
}






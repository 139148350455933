<template>
  <div class="list-group-input-item">
    <div class="list-pre red-light-txt" v-if="canDestroy" v-on:click="destroy()"><i class="fas fa-minus-circle"></i></div>
    <div class="list-head ka txt-md1">
      <div class="input-group has-postxt ka in-list">
        <div class="input-desc ka txt-xs1 grey5-txt">{{$t('spend_amount')}}</div>
        <div class="input-pos grey7 txt-xxl pd-4 pd-t-8"><lari-symbol></lari-symbol></div>
        <div class="input-area">
          <input autocomplete="off" class="txt-xl number" type="number"   name="amount"
                 :value="getDynData.amount"
                 @input="updateValue('amount',$event.target.value)"
                 @dblclick="updateValue('amount','')"
          >
        </div>
      </div>
    </div>
    <div class="list-pos en-sb txt-lg">
      <div class="input-group has-postxt ka in-list">
        <div class="input-desc ka txt-xs1 grey5-txt t-left">{{$t('percent')}} </div>
        <div class="input-pos grey7 en-sb txt-xl pd-4 pd-t-7">%</div>
        <div class="input-area">
          <input autocomplete="off" class="txt-xl number" type="number"   name="discount"
                 @wheel="$event.target.blur()"
                 :value="getDynData.discount"
                 @input="updateValue('discount',$event.target.value)"
                 @dblclick="updateValue('discount','')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lariSymbol from "@/components/STATIC/lariSymbol";
import {mapState,mapActions} from 'vuex';
export default {
       props:{
         id:{
           required:true,
         },
         card_id:{
           required:true,
         }
       },
       components:{
              lariSymbol,
       },
       data(){
             return {
                rule:{
                  id:'',
                  card_id:'',
                  amount:'',
                  discount:'',
                }
             }
        },
      mounted() {
             this.rule.id = this.id;
             this.rule.card_id = this.card_id;
             this.rule.amount =this.getDynData.amount;
             this.rule.discount= this.getDynData.discount;
      },
      computed:{
             ...mapState('_discount',['CLOUD','FROM_DYNAMIC']),

               getDynData(){
                 return this.dynRulesData.find(item=>item.id===this.id);
               },
               dynRulesData(){
                 return this.FROM_DYNAMIC.is_dynamic;
               },
               canDestroy(){
                return ((this.dynRulesData.indexOf(this.getDynData)) !==0 && (this.dynRulesData.indexOf(this.getDynData)) !==1);
               },
                primaryDiscount(){
                  return this.dynRulesData[0].discount;
                },
             },

       methods:{
             ...mapActions('_discount',['_updateDynRule','_updateDynValue','_removeDynRule']),

             updateDynValue(){
               let UPDATE = { valueName:'discount',valueData:this.primaryDiscount };
               return this._updateDynValue(UPDATE);
             },
              updateValue(input,value){
                  this.rule[input]=value;
                  this._updateDynRule(this.rule);
                  this.updateDynValue();
              },
              destroy(){
                   this._removeDynRule(this.id);
               }


       }

}
</script>

<style scoped>

</style>
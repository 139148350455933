<template>
<div>
  <!-- List -->
  <div class="list-group">
    <div v-for="(customer,index) in customers" :key="index">
      <div class="list-group-item">
        <div class="list-pre  txt-2xl"><i :class="{'indigo-txt fal fa-mars':+customer.gender===1,'purple-txt fal fa-venus':+customer.gender===2,'grey3-txt fal fa-genderless':+customer.gender===0}"></i></div>
        <div class="list-head  txt-md1" :class="$t('font')">{{customer.fullName}} <span class="blue-txt cursor" @click="moreInfo(customer.card.id)"><i class="fal fa-info-circle" ></i></span></div>
        <div class="list-pos en-r txt-lg1 pof "><a :href="'tel:+'+customer.phone"><i class="blue-txt fal fa-phone"></i></a></div>
        <div class="list-head-sub grey3-txt en txt-md"><i class="fal fa-birthday-cake"></i> {{ customer.birthday}} </div>
        <div class="list-pos-sub grey3-txt en txt-lg1"><a v-if="customer.email !==null" :href="'mailto:'+customer.email"><i class="blue-txt fal fa-envelope"></i> </a></div>

        <div class="list-footer-united en txt-sm grey3-txt "  v-show="showMoreInfo ===customer.card.id">
          <customer-card-and-transactions  :card="customer.card" :user="customer.id" :transactions="customer.card.transactions"></customer-card-and-transactions>
        </div>
      </div>

    </div>
  </div>
  <div class="flex-center-raw wd-100p" v-if="hasPages">
    <div class="flex-center txt-lg1 blue-txt">
      <div @click="pagPrev()" class="pd-r-10 cursor"><i class="fal fa-chevron-circle-left"></i></div>
      <div class="grey5-txt txt-md1">
        <span @click="pagGo(1)" class="blue-txt cursor" :class="[+current_page===1 ? 'txt-lg1' : 'txt-md1']">1</span> /
        <span @click="pagGo(total_pages)" class="blue-txt cursor" :class="[+current_page===+total_pages ? 'txt-lg1' : 'txt-md1']"> {{total_pages}}</span>
      </div>
      <div @click="pagNext()" class="pd-l-10 cursor"><i class="fal fa-chevron-circle-right"></i></div>
    </div>
  </div>
  <div class="flex-center-raw grey3-txt" ><span v-if="+current_page!==1 && +current_page!==+total_pages">({{current_page}})</span></div>

</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import { space} from "@/support/support";
import CustomerCardAndTransactions from "@/components/component/customers/cardAndTransactions";
import {RightSpinner} from "@/support/loading";


export default {
  name: "CustomerRightList",
  components: {CustomerCardAndTransactions},
  data(){
    return {
      showMoreInfo:false,
    }
  },
  mounted() {

  },
  computed:{
    ...mapState('_customers',['CLOUD','SUBMIT_FORM']),
    submitForm(){
      return this.SUBMIT_FORM;
    },
    customers(){
      return this.CLOUD.CUSTOMERS;
    },
    total_records(){
      return this.CLOUD.TOTAL_RECORDS;
    },
    page_records(){
      return this.CLOUD.PER_PAGE_RECORDS;
    },
    current_page(){
      return this.CLOUD.CURRENT_PAGE;
    },
    total_pages(){
      return this.CLOUD.LAST_PAGE;
    },
    hasPages(){
      return +this.total_records>15;
    }
  },
  methods:{
    ...mapActions('_transactions',{fetchTransactionData:'_getAllDataFromCloud'}),
    ...mapActions('_customers',['_setSubmitForm','_findCustomer']),
    updateForm(param,value){
      let form = this.submitForm;
      form[param]=value;
      return this._setSubmitForm(form);
    },

    moreInfo(id) {
      if(this.showMoreInfo===id){
        return this.showMoreInfo =false;
      }
      return this.showMoreInfo=id;
    },
    space(n){
      return space(n);
    },
    pagGo(page){
      this.updateForm('page',page);
      return this.goToPage();
    },
    pagNext(){
      let page = +this.submitForm.page+1;
      if(page <=this.total_pages){
        this.updateForm('page',page);
        return this.goToPage();
      }
    },
    pagPrev(){
      let page = +this.submitForm.page-1;
      if(page>0){
        this.updateForm('page',page);
        return this.goToPage();
      }

    },
    goToPage(){
      RightSpinner();
      return this._findCustomer(this.submitForm).then(()=>{
        RightSpinner(false);
      }).catch(()=>{
        RightSpinner(false);
      });
    },
  },
}
</script>

<style scoped>
.list-group-item > .list-footer-united{
  align-items: center;
  border-top: var(--line);
  display: grid;
  grid-area: list-footer;
  grid-template-columns: 1fr;
  justify-content: center;
  padding: 10px 0 10px 6px;
  row-gap: 10px;
  height: 60vh;
  overflow: scroll;
}
</style>
import api from "@/apis/transactions";
import {httpStatusActions} from "@/support/support";

export const _getAllDataFromCloud = ({ commit },filter)=> {
    return new Promise((resolve, reject) => {
        api.getTransactions(filter).then(response => {
            httpStatusActions(response,true);
            commit('SET_CLOUD_DATA', response.data);
            resolve (response);
        }).catch(error=>{

            reject(httpStatusActions(error));
        });

    });
}
export const _getExportTransactions = ({ commit },filter)=> {
    return new Promise((resolve, reject) => {
        api.getTransactions(filter).then(response => {
            httpStatusActions(response,true);
            commit('SET_EXPORT_DATA', response.data);
            resolve (response);
        }).catch(error=>{

            reject(httpStatusActions(error));
        });

    });
}


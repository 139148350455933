export const SET_CLOUD_DATA = (state,{response}) => {
    let newData = response;
    state.CLOUD =[];
    state.CLOUD = Object.assign([], state.CLOUD,newData);
}

export const UPDATE_EDITED_VALUE = (state,form) => {

    return Object.assign(state.CLOUD.find(obj=>obj.id==form.id),form);

}
export const INSERT_NEW = (state,record) => {
    return state.CLOUD.push(record);
}
export const REMOVE_CLOUD_DATA = (state,id) => {
    state.CLOUD = state.CLOUD.filter(from => {
        return from.id != id;
    })
}


<template>
  <div class="group">
    <div class="card-color-group grey7">
      <div v-for="(logo,index) in logos" :key="index" class="txt-xl grey1-txt " :class="setClass(logo)" >
        <i :class="logo" class="pd-10 cursor"   @click="setLogo(logo)" ></i>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props:{
    editLogo:{
      type: String,
      required: false,
      default:'',
    }
  },
  data(){
    return {
        card_logo:false,
    }
  },
  mounted() {
    if(this.editLogo.length>0){
      this.card_logo = this.editLogo;
    }
  },
  watch:{
    editLogo(val){
      if(this.card_logo !== val){
        return this.card_logo = val;
      }
    }
  },
  computed:{
    ...mapState({SETTINGS:'SETTINGS'}),
     logos(){
      return this.SETTINGS.LOGOS;
     },
  },
  methods:{
    setClass(logo){
      let height = (logo ==="pof portfel txt-4xl pd-t-3") ? 'pd-t-10' : '';
      return this.card_logo === logo ? "yellow t-black-txt "+height : height;
    },
    setLogo(logo){
      return this.$emit('onSet',logo);
    },
  }
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./partnership_transactions_block.vue?vue&type=template&id=435262f9&scoped=true&"
import script from "./partnership_transactions_block.vue?vue&type=script&lang=js&"
export * from "./partnership_transactions_block.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "435262f9",
  null
  
)

export default component.exports
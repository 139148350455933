<template>
  <div class="input-group has-postxt" :class="wMargin">
    <div class="input-desc" :class="$t('font')">
      <label>
        <span>{{$t(label)}}</span>
      </label>
    </div>
    <div :class="{'grey5':!copied,'green':copied}" class="input-pos txt-xxl cursor" :id="elId" >
      <span @click="copy()" class="txt-md1 cursor"><i class="fal fa-copy"></i></span>
    </div>
    <div class="input-area" :class="setClass" >
      <input type="text" v-if="withoutLink"
             id="copyInput"
             name="link"
             style="color: var(--grey3)"
             autocomplete="false"
             :value="'https://wallet.portfel.app?'+direction+'='+hash"
             readonly>

    <input type="text" v-if="!withoutLink"
           id="copyInput"
           name="link"
           style="color: var(--grey3)"
           autocomplete="false"
           :value="hash"
           readonly>
    </div>
  </div>


</template>
<script>


import {randomName} from "@/support/support";

export default {

  components:{

  },
  props:{
    withoutLink:{
      default:true,
    },
    label:{
      required:true,
    },
    wMargin:{
      default:'w-margin',
    },
    setClass:{
      default:"",
    },
    hash:{
      default:"",
    },
    name:{
      default:"",
    },
    notDiscount:{
      default:false,
    },

  },
  data(){
    return {
      copyDone:false,
      elName:"",
    }
  },
  mounted() {
    this.elName = randomName();
  },
  computed:{
    copied(){
      return (this.copyDone && this.unit === 'cp');
    },
    elId(){
      return this.elName;
    },
    direction(){
      return (this.notDiscount) ? "gc" : "gd";
    }
  },
  methods:{
    copy() {
      let val = document.getElementById('copyInput').value;

      navigator.clipboard.writeText(val).then(()=>{
        document.getElementById(this.$data.elName).className = "input-pos txt-xxl green";
        setTimeout(()=>{
          document.getElementById(this.$data.elName).className = "input-pos txt-xxl grey5";
        },600);
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });

    },


  },
  markDone(){
    this.copyDone = true;
  }


}
</script>
<style scoped>

.input-pos >.c-icon-defaults {
  top: 0 !important;
}
textarea, input[type="text"], input[type="button"], input[type="submit"], input:not([type="radio"]):not([type="checkbox"]), input[type=text]:focus, input:focus, input:active {

  width: 100% !important;

}
</style>
<template>
  <div class=" input-group input-group-no-label">
    <div class="input-area search-grid" :class="$t('font')">
      <span class="fal fa-search grey3-txt pd-r-7 txt-md" style="max-width: 9%;"></span>
      <input :class="$t('font')" type="text" :placeholder="$t('search_str')" @input="$emit('search',searchFor)" v-model="searchFor" autocomplete="off" >
      <span v-if="searchFor" class="cursor fa fa-times-circle grey3-txt pd-r-2 txt-md" style="max-width: 9%;"  v-on:click="clearSearch()"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "uni-search",
  data(){
    return {
      searchFor:'',
    }
  },
  methods:{
    clearSearch(){
      this.searchFor='';
      this.$emit('search',this.searchFor);
    }
  }
}
</script>

<style scoped>

</style>
import rootStore from '@/store/';


/*
* number : 1-10
* State : True/False
*/
export const SlideSwitch=(switchNumber, State=true)=>{
        let options = {switch:switchNumber,state:State};
        return rootStore.dispatch('slideSwitch',options,{ root: true });
}
export const ResetSlideSwitch=()=>{
    return rootStore.dispatch('resetSlideSwitch',{ root: true });
}


export default {
    namespaced: true,
    SlideSwitch,
    ResetSlideSwitch,
}
import Api from "./Api";


const END_POINT = 'company/setup';

export default {
    getTransactions(filter) {
        return  Api().post(`${END_POINT}/transactions`,filter);
    },
}

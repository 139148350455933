<template>
  <div class="pd-t-10">
    <div class="list-group " v-for="(item,index) in data" :key="index+1">
      <div class="list-group-item list-group-item--addon" >
        <div class="list-pre red-light-txt txt-md1 t-left">
          <i class="fal grey3-txt pd-r-5 txt-lg" :class="{'fa-shopping-bag':item.type===1,'fa-coins':item.type===2}"></i>
        </div>
        <div class="list-head txt-md cursor" :class="$t('font')" @click="showMoreInfo(item.id)">
        <div class="flex-inline col-2 wd-100p j-c-space-between j-i-center a-i-center">
          <div class="flex-start">{{item.label}}</div>
          <div class="flex-end grey5-txt pd-r-20 txt-md1"><span class="en"><i @click="cashSpendInfo()" class="cursor grey6-txt txt-md pd-r-4 fal fa-question-circle"></i>{{item.sales_total}}<dollar></dollar> </span></div>
        </div>
        </div>
        <div class="list-pos en-r txt-lg cursor flex-inline j-i-center a-i-center" @click="showMoreInfo(item.id)">
          <span  class="blue-txt cursor "><i class="fal fa-info-circle"></i></span>
        </div>
        <div class="list-head-sub grey5-txt en txt-sm">
          <span class="en"><i class="fal fa-calendar-check"></i> {{item.start_date}}</span>
          <span class="en pd-l-10"><i class="fal fa-calendar-times"></i> {{item.end_date}}</span>
        </div>
        <div class="list-footer-raw pd-t-10"  v-if="+showFooter===+(item.id)">
          <div class="list-footer-grid-3 w-t-line pd-t-30" :class="+showFooter===+(item.id)?'pd-b-20':''">
            <balance :item="item"></balance>
            <rate :item="item"></rate>
            <div class="flex-inline wd-100p flex-end" ></div>
           <notifications-and-requests-finished :item="item"></notifications-and-requests-finished>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
* Note
* Emits ('awOpen') When Application Window Is open
*/
import Balance from "@/components/component/partnership/balance";
import Rate from "@/components/component/partnership/rate";
import {mapActions, mapGetters} from "vuex";
import Dollar from "@/components/STATIC/dollar";
import NotificationsAndRequestsFinished from "@/components/component/partnership/notificationsAndRequestsFinished";
import {awOpen} from "@/support/applicationWindow";



export default {
  props: {
    data: {
      default() {
        return []
      }
    },
  },
  name: "accept_block_finished",
  components: {NotificationsAndRequestsFinished,  Dollar, Rate, Balance},
  data(){
    return {
      showFooter:'',
      showDeleteBtn:'',
      showDeleteSpinner:false,
    }
  },
  computed:{
    ...mapGetters(['_applicationWindowData','_applicationWindowStatus']),



  },
  methods:{
    ...mapActions('_partnership',['_rejectPartnership']),
    cashSpendInfo(){
      return awOpen(3, {message: this.$t('cash_spend_desc_txt')});
    },
    showMoreInfo(id){
      if(this.showFooter===id){ //Second click on same info icon
        this.showDeleteBtn="";
       return  this.showFooter="";
      }
      this.showDeleteBtn=id;
      return this.showFooter=id;
    },
  }
}
</script>

<style scoped type="scss">

</style>
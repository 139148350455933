<template>
  <div :class="pad">
    <div class="switch-button-group" :class="{'w-text':label}">
      <input type="hidden" v-model="state" id="id">
      <div class="button mg-t-4" :class="{ off: !currentState }" @click="onClick"><i class="fas fa-circle pd-t-1"></i></div>
      <div v-if="label" class="text" @click="onClick" ><span :class="$t('font_b')" class="txt-lg">{{label}}</span>
        <span style="display: block;" :class="$t('font')">{{desc}} </span>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props:{
    setState:{
      default: false,
    },
    label:{
      default:false,
    },
    desc:{
      default:false,
    },
    pad:{
      default:"w-tb-pad-20"
    }
  },
  data(){
    return{
      state:null,
    }
  },
  computed:{
    currentState(){

      return (this.state===null) ? this.setState : this.state;
    }
  },
  methods:{
    onClick() {
      this.state = (!this.state) ? !this.setState : !this.state;
      this.$emit('stateChange',this.setState);
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <span v-if="showReload || spinnerState">
    <i v-if="spinnerState && !showReload" class="fal fa-spinner fa-spin " :class="setClass"></i>
    <i v-if="showReload" @click="reload()" class="rotate fad fa-redo txt-3xl blue-txt"></i>
  </span>
</template>

<script>

import {ShowLoading} from "@/support/loading";
import {mapGetters} from "vuex";

export default {
  props:{
    setClass:{
      default:'',
    },
    spinnerNumber:{
      default:2,
    }
  },
  data(){
    return {
      reloadSpinner:false,
    }

  },
  mounted(){
    this.reloadSpinner = false;

    setTimeout(()=>{
      if(this.spinnerState){
        this.reloadSpinner = true;
      }
    },6000)
  },
  computed:{
    ...mapGetters(['_loadingSpinnerStatus']),
    spinnerState(){
      return this._loadingSpinnerStatus(this.spinnerNumber);
    },
    showReload(){
      return this.spinnerState && this.reloadSpinner;
    }
  },
  methods:{
    reload(){
      ShowLoading();
      return window.location.reload(true);
    }
  }
}
</script>

<style scoped>
.rotate {
  animation: rotate-anime 300ms ease-in-out;
}
@keyframes rotate-anime {
  0%{
    transform: rotate(0.2turn);

  }
  1%{
    transform: scale(1.1);
  }
  99%{
    transform: scale(1.9);
  }
  100%{
    transform: rotate(359turn);
  }
}
</style>
export default {
    /* Data Is Filed From Cloud */
    CLOUD: {

            PROFILE: {
                merchant_email: "",
                merchant_reg_id: "",
                merchant_label: "",
                merchant_phone: "",
                merchant_address: "",
                merchant_email_verified:""
        },
            SETTINGS: {
                has_partners: 0,
                share_points: 0,
                points_enabled: 0,
                discounts_enabled: 0,
                has_payment_attached: 0,
                default_currency:'eur',
        },
            SUBSCRIPTIONS: {
                product_id: 0,
                order_id: 0,
                start: "00-00-0000",
                end: "00-00-0000",
                days_left: 0,
                merchant: ""
        },
            BALANCE: {
                discount_transactions: 0,
                collector_transactions: 0,
                discount_created: 0,
                discount_given: 0,
                discount_dynamic: 0,
                collector_given: 0,
                branches: 0,
                employees: 0,
                partners: 0,
                customers: 0,
                points_given: 0
        },
            ORDERS: [
            {
                "process_date": "0000-00-00 00:00:00",
                "amount": 0,
                "product_id": 0
            }
        ],
            PRODUCTS: [
            {
                "id": 1,
                "label": "",
                "price": 0,
                "limit_card": -1,
                "points_card": -1,
                "discount_card": -1,
                "discount_dynamic": -1,
                "discount_partners": -1,
                "sell_points_count": -1,
                "give_cards_count": -1,
                "customer_count": -1,
                "stats_count": -1,
                "valid_day_count": 90,
                "limit_branch": -1,
                "limit_employee": -1,
                "limit_trans_count": -1,
                "stripe_id": null,
                "price_stripe_id": null
            },

        ],
            APP_SETTINGS: {
            "latest_app_version": "0",
                "night_mode_starts": "20",
                "night_mode_ends": "7"
        }
        },



}
import Api from "./Api";

// const END_POINT_company = 'company';
const END_POINT = 'company/setup/discount';
// const END_POINT_employee = 'company';
// const END_POINT_card = 'company';

export default {

    get() {
        return  Api().get(`${END_POINT}/`);
    },
    createOrUpdate(data) {
        return  Api().post(`${END_POINT}/`,data);
    },
    delete(id) {
        return  Api().delete(`${END_POINT}/${id}`);
    },
    // update(data) {
    //
    //     return  Api().put(`${END_POINT}/card/${data.id}`,data);
    // },

        // get(filter) {
        //     if(filter.id ===undefined){
        //         return  Api().get(`${END_POINT}/`);
        //     }else{
        //         return  Api().get(`${END_POINT}/${filter.id}`);
        //     }
        // },
        // create(data) {
        //
        //     return  Api().post(`${END_POINT}/`,data);
        //
        // },
        // update(data) {
        //
        //     return  Api().put(`${END_POINT}/${data.id}`,data);
        // },
        // updateCard(data) {
        //
        //     return  Api().put(`${END_POINT}/card/${data.data.id}`,data.data);
        // },
        // delete(id) {
        //     return  Api().delete(`${END_POINT}/${id}`);
        // },
        // deleteRule(id) {
        //     return  Api().delete(`${END_POINT}/rule/${id}`);
        // },

    }
export default {
    /* Data Is Filed From Cloud */
    CLOUD: {

            "TRANSACTIONS": [],
            "EXPORT_TRANSACTIONS":[],
            "TOTAL_HEAD":0,
            "TOTAL_SUB":0,

    },
    EXPORT: {

        "TRANSACTIONS": [],
        "EXPORT_TRANSACTIONS":[],
        "TOTAL_HEAD":0,
        "TOTAL_SUB":0,

    }


}
<template>
  <div>
    <input-slot label="card_display_name" :req="true" :message="errorMessage('show_label')">
      <input type="text" name="card_display_name" autocomplete="false"

             :value="formData.show_label"
             @input="updateValue('show_label',$event.target.value)"
             @dblclick="updateValue('show_label','')"
      >
    </input-slot>
    <value-desc text="card_display_name_desc"></value-desc>

    <input-switch
        :label="$t('name_latin_letters')"
        v-on:stateChange="updateLabelEn()"
        :set-state="labelEn"
    ></input-switch>
    <value-desc text="name_latin_letters_desc"></value-desc>

    <div class="space-div--40"></div>
    <Fontaswome_logos :edit-logo="formData.logo_link" @onSet="setlogo"></Fontaswome_logos>
    <div class="heading-group--sub">
      <span class="txt-xl" :class="$t('font')">{{$t('card_design')}}</span>
      <span class="color-button cursor" v-on:click="showColors =!showColors"><i class="fal fa-paint-brush-alt"></i></span>
    </div>
    <value-desc :with-space="false" text="card_design_desc_txt"></value-desc>
    <div class="space-div--20"></div>
    <div class="card-color-group" v-if="showColors">
      <div :class="color"  v-for="(color,index) in colorPalette" :key="index"
           :value="formData.color"
           @click="updateValue('color',color)"
           class="t-center pd-t-2">
        <i  v-if="card_color===color" class="fas fa-check t-white-txt txt-xl"></i>
      </div>
    </div>
    <div class="card-wraper">
      <div class="wallet-card" :class="formData.color">
        <div class="card-skeleton" >
          <div class="company-logo en"><i :class="formData.logo_link"></i></div>
          <div class="company-name txt-lg1" :class="{'ka':!labelEn,'en-r':labelEn}">{{formData.show_label}}</div>
          <div class="card-type">%</div>
          <div class="pay-button yellow grey6-txt"><i class="fal fa-qrcode" style="font-size: var(--txt-4xl);"></i></div>
          <div class="account-number"><span class="acc">{{ addSpace('1000000000000000')}}</span></div>
          <div class="valid-date ka ">
            <span class="ka-b">{{$t('active')}}</span><br>
            <span class="en">mm/yy</span>
          </div>
          <card-logo></card-logo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import cardLogo from "@/components/STATIC/cardLogo";
import valueDesc from "@/components/STATIC/valueDesc";
import inputSlot from "@/components/STATIC/inputSlot";
import inputSwitch from "@/components/STATIC/inputSwitch";
import {colorPalette, displayErrors, space} from "@/support/support";
import {mapActions, mapState} from "vuex";
import Fontaswome_logos from "@/components/component/fontaswome_logos";
export default {
  components:{
    Fontaswome_logos,
    cardLogo,
    valueDesc,
    inputSlot,
    inputSwitch,
  },
  props: {
    fetchErrors: {
      default: () => {
        return {}
      },
    },
  },
  data(){
    return {
      showColors:false,
      card_color:'',
    }
  },
  mounted() {},
  computed:{
    ...mapState('_discount',['CLOUD','FORM_STANDARD']),

    formData(){
      return this.FORM_STANDARD;
    },
    labelEn(){
      return (Boolean(this.formData.label_en));
    },
    colorPalette(){
      return colorPalette(this.CLOUD.color_palette_count)
    }
  },

  methods:{
    ...mapActions('_discount',['_updateStandardValue']),

    updateValue(name,value){
      this.card_color = value;
      let UPDATE = { valueName:name,valueData:value };
      return this._updateStandardValue(UPDATE);
    },

    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
    },

    addSpace(value) { return (value.length > 0) ? space(value) : ''; },

    updateLabelEn(){
      this.updateValue('label_en',!this.formData.label_en);
    },
    setlogo(e){
      this.updateValue('logo_link',e);
    }
  }

}
</script>

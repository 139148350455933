import apiCall from "@/apis/customers";
import {httpStatusActions} from "@/support/support";


// import Support from '@/support/support';
export const _getAllDataFromCloud = ({ commit })=> {
    return new Promise((resolve, reject) => {
        apiCall.getCustomers().then(response => {
            httpStatusActions(response, true);
            commit('SET_CLOUD_DATA', {response: response.data});
            return resolve(response.data);
        }).catch(error => {
            return reject(httpStatusActions(error));
        });
    });

}
export const _exportCustomers = ({commit},filterData)=> {
    return new Promise((resolve, reject) => {
        apiCall.exportCustomers(filterData).then(response => {
            httpStatusActions(response, true);
            commit('SET_EXPORT_LINK', {response: response.data});
            return resolve(response.data);
        }).catch(error => {
            return reject(httpStatusActions(error));
        });
    });
}
export const _resetExportLink = ({commit})=> {
    commit('RESET_EXPORT_LINK');
}

export const _findCustomer = ({ commit },filterData)=> {
    return new Promise((resolve, reject) => {
        apiCall.findCustomer(filterData).then(response => {
            httpStatusActions(response, true);
            commit('SET_CLOUD_DATA', {response: response.data});
            return resolve(response.data);
        }).catch(error => {
            return reject(httpStatusActions(error));
        });
    });

}
export const _setSubmitForm=({commit},formData)=>{
    return new Promise((resolve) => {
        commit('SET_SUBMIT_FORM', formData);
        return resolve(true);
    });
}
export const _removeCustomer=({commit},data)=>{
    return new Promise((resolve) => {
        commit('REMOVE_CUSTOMER', data);
        return resolve(true);
    });
}

export const _toggleImport=({commit})=>{
    return commit('TOGGLE_IMPORT');
}



export default {

    PARTNERSHIP:{
        ACTIVE:[],
        YOU_ACCEPT_CARDS:[],
        THEY_ACCEPT_CARDS:[],
        REQUESTS:[],
        FINISHED:[],
    },
    TRANSACTIONS:{
        CURRENT_PAGE:'',
        LAST_PAGE: '',
        PER_PAGE_RECORDS: '',
        TOTAL_HEAD: '',
        TOTAL_RECORDS: '',
        TOTAL_SUB: '',
        TRANSACTIONS: [],
    },
    TRANSACTIONS_EXPORT:{
        CURRENT_PAGE:'',
        LAST_PAGE: '',
        PER_PAGE_RECORDS: '',
        TOTAL_HEAD: '',
        TOTAL_RECORDS: '',
        TOTAL_SUB: '',
        TRANSACTIONS: [],
    },

    TRANSACTION_FORM:{
        keyword:'',
        date_from:'',
        date_to:'',
        type_id:'',
        partnership_id:'',
        page:1,
    },
    FRESH_TRANSACTION_FORM:{
        keyword:'',
        date_from:'',
        date_to:'',
        type_id:'',
        partnership_id:'',
        page:1,
    },


}
<template>
  <div id="app" style="background-color: var(--white)">

    <div v-if="showInstallMessage" class="ios-install-message-container">
      <div class="ios-install-message" v-on:click="showInstallMessage = !showInstallMessage">
        <div class="install-icon"><i class="fal fa-plus-square"></i> </div>
        <div class="install-text" :class="$t('font')">
          {{$t('install_app_text_1')}}: {{$t('tap')}}: <svg data-name="ShareIocin" style="display: inline-block;height: 18px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.4 14.61">
          <path d="M.85,12.15v-6A1.53,1.53,0,0,1,2.58,4.45H4.26v.72H2.59a.92.92,0,0,0-1,1v5.91a.91.91,0,0,0,1,1H9.31a.92.92,0,0,0,1-1V6.2a.93.93,0,0,0-1-1H7.65V4.45H9.32a1.53,1.53,0,0,1,1.73,1.71v6a1.53,1.53,0,0,1-1.73,1.71H2.58A1.53,1.53,0,0,1,.85,12.15ZM5.6,9V2.69l0-.85-.52.54-1,1a.33.33,0,0,1-.25.1A.31.31,0,0,1,3.64,3L5.7,1A.33.33,0,0,1,6,.83.31.31,0,0,1,6.21,1L8.28,3a.37.37,0,0,1,.1.24.32.32,0,0,1-.33.32.33.33,0,0,1-.24-.1l-1-1-.52-.54,0,.85V9A.36.36,0,0,1,5.6,9Z"
                style="fill:var(--blue); max-height:20px;"/>
        </svg> {{$t('next')}} <span class="en">Add to Home Screen <i class="fal fa-plus-square"></i></span>
        </div>
      </div>
    </div>
    <div class="install-arrow" v-if="showInstallMessage"><i class="fas fa-sort-down"></i></div>
    <loading v-if="showLoadingWindow"></loading>



    <router-view></router-view>
    <notifications-list />

  </div>
</template>

<script>

import NotificationsList from "./components/STATIC/NotificationsList";


import {autoColorMode, getDomain, getLang, setColorMode} from "@/support/support";

import loading from "@/components/STATIC/loading";
import {closeSmartsApp} from "@/support/helpers";
import config from "@/config";

export default {

  name: 'App',
  components: {

    loading,
    NotificationsList,

  },
  data(){
    return {
      showInstallMessage:false,
      showRotateMessage:false,
    }
  },
  mounted() {
    //TODO REMOVE DEBUG
    /*
    * Smarts APP
    */
    if(config.enableSmartsUpp){
      closeSmartsApp();
      if(this._smartsupp!==undefined){
        this._smartsupp.hideWidget = true; // use on specific pages
        this._smartsupp.hideMobileWidget = true; // hide chat box on mobile devices
      }
      setTimeout(() => {
        closeSmartsApp();
      }, 600);

      setTimeout(() => {
        closeSmartsApp();
      }, 1000);

      setTimeout(() => {
        closeSmartsApp();
      }, 3000);
    }

    /*
    * Smarts End
    */
    // // Detects if device is on iOS
    // const isIos = () => {
    //   const userAgent = window.navigator.userAgent.toLowerCase();
    //   return /iphone|ipad|ipod/.test( userAgent );
    // }
    // // Detects if device is in standalone mode
    // const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    //
    // // Checks if should display install popup notification:
    // if (isIos() && !isInStandaloneMode()) {
    //   this.showInstallMessage=true;
    // }
    // checkAndNotifyUpdate();

    if(!getLang() || !getDomain()){
      if(this.$router.currentRoute.name!=="setDomain"){
        return this.$router.push({ name: "setDomain" });
      }
    }
    //Set Color Mode
    if (autoColorMode()) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches) {
        document.documentElement.setAttribute('data-theme', 'dark');
      }
      window.matchMedia('(prefers-color-scheme:dark)').addEventListener('change', ev => {
        const newColorScheme = ev.matches ? "dark" : "light";
        document.documentElement.setAttribute('data-theme', newColorScheme);
      });
    }else{
      setColorMode();
    }
  },
  computed:{
    showLoadingWindow(){
      return this.$store.state.LOADING_WINDOW;
    },


  },
  methods:{

    checkOrientationChange() {
      let screenOrientation = window.orientation;

      if(screenOrientation !==0){
        return this.showRotateMessage = true;
      }else {
        return this.showRotateMessage = false;

      }
    }

  },
}
</script>

<style>
@import "assets/css/style.css";
/*
* todo add to main css
*/
@media (min-width: 768px) {
  .hide-small {
    display: none;
  }
}
.input-area textarea, .input-area textarea:focus{
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  color: var(--blue);
  font-size: var(--txt-md);
  height: 100%;
  overflow: hidden;
  width: 100%;
  outline: none !important;
}
.grid-col-span1-2{
  grid-column: span 1/2;
}
.grid-col-span1-3{
  grid-column: span 1/3;
}
.grid-col-span1-4{
  grid-column: span 1/4;
}
.grid-col-span1-5{
  grid-column: span 1/5;
}
.grid-col-span1-6{
  grid-column: span 1/6;
}
.grid-col-span1-7{
  grid-column: span 1/7;
}
.grid-col-span2{
  grid-column: span 2;
}
.grid-col-span3{
  grid-column: span 3;
}
.grid-col-span4{
  grid-column: span 4;
}
.grid-col-span5{
  grid-column: span 5;
}
.grid-row-span2{
  grid-row: span 2;
}
.grid-row-span3{
  grid-row: span 3;
}
.grid-row-span4{
  grid-row: span 4;
}
.grid-row-span5{
  grid-row: span 5;
}
.grid-row-3{grid-template-areas:"grid-row1" "grid-row2" "grid-row3";}
.grid-col-gap1{
  grid-column-gap: 1px;
}
.grid-col-gap2{
  grid-column-gap: 2px;
}
.grid-col-gap3{
  grid-column-gap: 3px;
}
.grid-col-gap4{
  grid-column-gap: 4px;
}
.grid-row-gap1{
  grid-row-gap: 1px;
}
.grid-row-gap2{
  grid-row-gap: 2px;
}
.grid-row-gap3{
  grid-row-gap: 3px;
}
.grid-row-gap4{
  grid-row-gap: 4px;
}
.grid-col-xs-lg-auto-md{
  grid-template-columns: .05fr .35fr auto .25fr !important;
}
.grid-col-xs-auto-auto-md{
  grid-template-columns: 20px auto auto 70px !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.flex-column {
  display: flex !important;
  flex-direction: column !important;
}
.flex-wrap{
  flex-wrap: wrap !important;
}
.flex-no-wrap{
  flex-wrap: nowrap !important;
}
.column-gap1 {
  column-gap: 1px;
}
.column-gap2 {
  column-gap: 2px;
}
.column-gap3 {
  column-gap: 3px;
}
.column-gap4 {
  column-gap: 4px;
}
.column-gap5 {
  column-gap: 5px;
}
.row-gap1 {
  row-gap: 1px;
}
.row-gap2 {
  row-gap: 2px;
}
.row-gap3 {
  row-gap: 3px;
}
.row-gap4 {
  row-gap: 4px;
}
.row-gap5 {
  row-gap: 5px;
}
.list-footer-raw {
  grid-area: list-footer;
}
.list-footer-grid-3{
  align-items: center;
  display: grid;
  grid-template-columns: .33fr .33fr .33fr;
  justify-content: space-between;
  row-gap: 10px;
  justify-items: center;
}
.wd-170{
  width: 170px;
}
.shadow{
  box-shadow: 0 0 2px 0 rgba(10, 21, 2, 0.26);
}
.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>

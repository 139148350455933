<template>
  <div class="notification-frame" v-if="awShow && +id===+awId">
    <div class="flex-center-raw a-i-center j-i-center" style="height: 100vh;">
      <div class="radius white w-margin pd-t-30 pd-b-30 pd-l-30 pd-r-30 shadow" style="width: 50vw; height: auto;">
        <div class="flex-inline a-i-center">
          <div  @click="awClose()" class="cursor pd-r-10 pd-t-2">
            <i class="fas fa-times-circle grey3-txt txt-lg1 cursor"></i>
          </div>
          <div :class="$t('font_b')" v-if="!awStatus" class="txt-xl">{{title}}</div>
        </div>
        <div v-if="!awStatus" class="notification-enter">
          <slot name="content"></slot>
          <div class="w-margin pd-r-20 flex-center-raw wd-100p">
            <div class="txt-7xl grey3-txt container t-center mg-t-30" v-if="loadingSpinner">
              <SpinnerVuex :spinner-number="0"></SpinnerVuex>
            </div>
            <div v-if="!loadingSpinner">
              <slot name="submit"></slot>
            </div>
          </div>
        </div>
        <div v-if="awStatus"  style="height: 100%; width: 100%;" class="notification-sent flex j-i-center j-c-center a-i-center">
          <div class="t-center" style="width: auto;">
            <i class="fal fa-check-circle green-txt txt-7xl"></i>
            <p>{{successMsg}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {aw} from "@/support/applicationWindow";
import SpinnerVuex from "@/components/STATIC/spinnerVuex";

export default {
  name: "applicationWindow",
  components: {SpinnerVuex},
  props:{
    id:{
      default(){
        return 1;
      }
    },
    title:{
      default(){
        return '';
      }
    },
    successMsg:{
      default(){
        return '';
      }
    },
  },
  mounted() {

  },
  computed:{
    ...mapGetters(['_applicationWindowState','_applicationWindowStatus','_applicationWindowData','_applicationWindowId']),
    //SPINNERS
    ...mapGetters(['_loadingSpinnerStatus']),
    loadingSpinner(){
      return this._loadingSpinnerStatus(0);
    },
    awId(){
      return this._applicationWindowId;
    },
    awShow(){
      return this._applicationWindowState;
    },
    awStatus(){
      return this._applicationWindowStatus;
    },
    awData(){
      return this._applicationWindowData;
    },
  },
  methods:{
   awClose(){
     return aw(false);
   }
  },
}
</script>

<style scoped>

</style>
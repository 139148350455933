<template>
  <div id="onetime" class="w-t-line">
    <div class="space-div--20"></div>
    <div class="container col-2 pd-t-20 pd-b-20">
      <clear-btn v-on:click.native="freshForm()"></clear-btn>
      <save-btn :can-submit="canSubmit" :show-right-spinner="showRightSpinner" v-on:click.native="submitForm()"></save-btn>
    </div>
    <div class="space-div--20"></div>
    <input-copy v-if="isEdit && formData.public" :hash="formData.public" label="public_distribution_link"></input-copy>
    <value-desc v-if="isEdit" text="public_distrib_link_desc"></value-desc>


    <div class="space-div--20"></div>
    <input-slot label="model_name" :req="true" :message="errorMessage('label')">
      <input type="text" name="model_name" autocomplete="false"  :value="formData.label" @input="updateValue('label',$event.target.value)">
    </input-slot>
    <value-desc text="model_name_desc"></value-desc>

    <input-number label="card_discount_percent" :req="true" unit="%" :message="errorMessage('card_discount_percent')">
      <input type="number"   name="discount" autocomplete="false"
             :value="formData.discount"
             @input="updateValue('discount',$event.target.value)"
             @dblclick="updateValue('discount','')"
             @wheel="$event.target.blur()"
      >
    </input-number>
    <value-desc text="discount_percent_desc"></value-desc>

    <input-number label="givable_on_amount" :req="true" :message="errorMessage('givable_on_amount')">
      <input type="number"  name="discount" autocomplete="false"
             @wheel="$event.target.blur()"
             :value="formData.givable_on_amount"
             @input="updateValue('givable_on_amount',$event.target.value)"
             @dblclick="updateValue('givable_on_amount','')"
      >
    </input-number>
    <value-desc text="givable_on_amount_desc"></value-desc>

    <input-number label="minimum_purchase" :req="true" :message="errorMessage('minimum_purchase')">
      <input type="number" min="0"   name="discount" autocomplete="false"
             @wheel="$event.target.blur()"
             :value="formData.minimum_purchase"
             @input="updateValue('minimum_purchase',$event.target.value)"
             @dblclick="updateValue('minimum_purchase','')"

      >
    </input-number>
    <value-desc text="minimum_purchase_discount_desc"></value-desc>


    <div class="input-group w-margin ">
      <div class="input-desc" :class="$t('font')" v-if="!errorMessage('active_due')">{{$t('expiry_date')}}</div>
      <div class="input-desc red-light-txt" :class="$t('font')" v-if="errorMessage('active_due')">{{errorMessage('active_due')}}</div>
      <div class="input-area">
        <input type="date"  autocomplete="off"

               :value="formData.active_due"
               @input="updateValue('active_due',$event.target.value)"
               @dblclick="updateValue('active_due','')"
        ></div>
    </div>
    <value-desc text="active_due_date_desc"></value-desc>
    <onetime-discount-card :fetch-errors="fetchErrors"></onetime-discount-card>

  </div>
</template>

<script>
import {colorPalette, displayErrors, validator} from "@/support/support";
import inputSlot from "@/components/STATIC/inputSlot";
import valueDesc from "@/components/STATIC/valueDesc";

import InputNumber from "@/components/STATIC/inputNumber";
import {mapActions, mapState} from "vuex";
import OnetimeDiscountCard from "@/components/STATIC/onetimeDiscountCard";
import ClearBtn from "@/components/STATIC/clearBtn";
import SaveBtn from "@/components/STATIC/saveBtn";
import InputCopy from "@/components/STATIC/input-copy";
export default {
  props:{

    fetchErrors:{
      default:()=>{
        return {}
      },
    },
  },
  components:{
    InputCopy,
    SaveBtn,
    ClearBtn,
    OnetimeDiscountCard,
    InputNumber,
    inputSlot,
    valueDesc,
  },
  data(){
    return {
      showLeftSpinner:false,
      showRightSpinner:false,
      /* ERROR HANDLING */
      formErrors:{},
      /* END ERROR HANDLING */
    }
  },

  computed:{
    ...mapState('_discount',['CLOUD','FORM_ONETIME']),
    /*
    * Approved
    */
    isEdit(){
      return +this.FORM_ONETIME.id >0;
    },
    formData(){
      return this.FORM_ONETIME;
    },
    colorPalette(){
      return colorPalette(this.CLOUD.color_palette_count)
    },
    canSubmit(){
      let valuesToValidate = [
        {
          name:'label',
          input:this.formData.label,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'show_label',
          input:this.formData.show_label,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'active_due_balance',
          input:this.formData.active_due_balance,
          rule:'min:zero',
          message:this.$t('min_zero'),
        },
        {
          name:'givable_on_amount',
          input:this.formData.givable_on_amount,
          rule:'min:num',
          message:this.$t('min_num'),
        },
        {
          name:'minimum_purchase',
          input:this.formData.minimum_purchase,
          rule:'min:num',
          message:this.$t('min_num'),
        },
      ];
      return this.hasErrors(validator(valuesToValidate));
    },
  },
  methods:{
    ...mapActions('_discount',['_updateOnetimeValue','_freshOnetimeForm']),
    /*
    *
    */
    submitForm(){
      if(!this.canSubmit){
        return  false;
      }
      return this.$emit('submit',this.formData);
    },
    /*
    *
    */
    updateValue(name,value){
      let UPDATE = { valueName:name,valueData:value };
      return this._updateOnetimeValue(UPDATE);
    },
    freshForm(){
      this.$emit('fresh');
      return this._freshOnetimeForm();
    },
    /*
    *
    */
    /* Error Handling */
    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    hasErrors(errors){ //METHOD FOR Can Submit
      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
  }
}
</script>
<style scoped>

</style>
<template>

    <div class="grid-col-3 grid-col-gap3 w-b-line w-tb-pad-20 grid-col-xs-auto-auto-md">
      <div class="pd-t-3 orange-txt"><i class="fad fa-exclamation-triangle txt-md"></i></div>
      <div class="grid-row-2 pd-l-4 pd-r-4">
        <div class="en-b txt-xs1">
          {{$t('rate_change')}}
        </div>
        <div class="txt-xs grey1-txt">{{date}}</div>
      </div>
      <div class="txt-md flex-inline j-i-center j-c-center a-i-center grey3-txt" v-if="type==='sell'">1<dollar></dollar> = <b class="t-black-txt">{{from}}</b><i class="pof points"></i> <i class="fad fa-exchange-alt txt-xl mg-l-10 mg-r-10 grey1-txt"></i> 1<dollar></dollar> = <b class="t-black-txt">{{to}}</b><i class="pof points"></i></div>
      <div class="txt-md flex-inline j-i-center j-c-center a-i-center grey3-txt" v-if="type==='buy'">1<i class="pof points"></i> = <b class="t-black-txt">{{from}}</b><dollar></dollar> <i class="fad fa-exchange-alt txt-xl mg-l-10 mg-r-10 grey1-txt"></i> 1<i class="pof points"></i> = <b class="t-black-txt">{{to}}</b><dollar></dollar></div>
    </div>

</template>

<script>


import Dollar from "@/components/STATIC/dollar";
export default {
  name: "footer_rate_change",
  components: {Dollar},
  props:{
      id:{
        required:true,
        default(){
          return '';
        }
      },
      date:{
        required:true,
        default(){
          return '';
        }
      },
      from:{
        required:true,
        default(){
          return '';
        }
      },
      to:{
        required:true,
        default(){
          return '';
        }
      },
      type:{
        required:true,
        default(){
          return 'buy';
        }
      }
  }
}
</script>

<style scoped>

</style>
<template>
<div>
  <!-- Spinner -->

  <div class="group grid-col-1 a-i-center pd-t-20 pd-b-20 w-b-line" >
    <!-- Transaction Heading -->
    <div class="flex-start grey5-txt " :class="$t('font')">
      <div class="grid-row-2">
        <div><h1>{{$t('transactions')}}</h1></div>
        <div>{{$t('records')}} ({{showed_records}}/{{total_records}})</div>
      </div>
    </div>
  </div>
  <div class="flex-center-raw pd-t-30 pd-b-30 j-c-center j-i-center" v-if="SpinnerStatus">
    <spinner-vuex :spinner-number="3"  set-class="grey5-txt txt-3xl"></spinner-vuex>
  </div>

  <!-- List -->
  <div class="list-group pd-t-10" v-if="!SpinnerStatus">
    <div v-for="trans in getTransactions" :key="trans.id">
      <div class="list-group-item">
        <div class="list-pre  txt-md" :class="trans.color"><i :class="trans.icon"></i></div>
        <div class="list-head  txt-sm1" :class="$t('font')">{{trans.label}} <span class="blue-txt cursor" @click="moreInfo(trans.id)"><i class="fal fa-info-circle" ></i></span></div>
        <div class="list-pos en-r txt-lg1 pof">{{ trans.amount }}
          <i  v-if="trans.card_type===2" class="pof points"></i>
          <dollar  v-if="trans.card_type===1"></dollar>
        </div>
        <div class="list-head-sub grey3-txt en txt-sm"><i class="fal fa-calendar-alt"></i> {{ trans.date}} </div>
        <div class="list-pos-sub grey3-txt en txt-md">{{ trans.cash_spend }}<dollar set-class="pd-l-2"></dollar></div>

        <div class="list-footer en txt-sm grey3-txt"  v-show="showMoreInfo ===trans.id">
          <div :class="$t('font')"><i class="fal fa-store"></i> {{ trans.acceptor_label}}</div>
          <div v-if="trans.type<4" :class="$t('font')"><i class="fal fa-store"></i> {{ trans.metadata.branch_name}}</div>
          <div v-if="trans.type>4" :class="$t('font')"><i class="fas fa-people-arrows"></i> {{ trans.metadata.partner_employee_name}}</div>


          <div class="t-right"><i class="fal fa-calendar-alt"></i> {{ trans.date}}</div>
          <div v-if="trans.type<4" :class="$t('font')"><i class=" fal fa-user-minus"></i> {{ trans.metadata.customer_name}}</div>
          <div v-if="trans.type>4" :class="$t('font')"></div>
          <div v-if="trans.type<4"><i class="fal fa-credit-card-blank"></i> {{space(trans.card_account)}}</div>
          <div v-if="trans.type>4"><i class=" fal fa-people-arrows"></i> {{ trans.metadata.employee_name}}</div>
          <div class="ht-11 t-right"><dollar></dollar> {{ trans.check_amount }}</div>
        </div>
      </div>

    </div>
  </div>
  <div class="flex-center-raw wd-100p" v-if="hasPages">
    <div class="flex-center txt-lg1 blue-txt">
      <div @click="pagPrev()" class="pd-r-10 cursor"><i class="fal fa-chevron-circle-left"></i></div>
      <div class="grey5-txt txt-md1">
        <span @click="pagGo(1)" class="blue-txt cursor" :class="[+current_page===1 ? 'txt-lg1' : 'txt-md1']">1</span> /
        <span @click="pagGo(total_pages)" class="blue-txt cursor" :class="[+current_page===+total_pages ? 'txt-lg1' : 'txt-md1']"> {{total_pages}}</span>
      </div>
      <div @click="pagNext()" class="pd-l-10 cursor"><i class="fal fa-chevron-circle-right"></i></div>
    </div>
  </div>
  <div class="flex-center-raw grey3-txt"><span v-if="+current_page!==1 && +current_page!==+total_pages">({{current_page}})</span></div>


</div>
</template>

<script>
import Dollar from "@/components/STATIC/dollar";
import {mapActions, mapGetters, mapState} from "vuex";
import {space} from "@/support/support";
import SpinnerVuex from "@/components/STATIC/spinnerVuex";
import {HideSpinner, ShowSpinner} from "@/support/loading";
export default {
  name: "partnership_transactions_block",
  components: {SpinnerVuex, Dollar},
  data(){
    return {
      showMoreInfo:false,
    }
  },
  mounted() {
  },
  computed:{
    ...mapState('_partnership',['TRANSACTIONS','PARTNERSHIP','TRANSACTION_FORM']),
    //SPINNERS
    ...mapGetters(['_loadingSpinnerStatus']),

    SpinnerStatus(){
      return this._loadingSpinnerStatus(3);
    },
    //SPINNERS END
    form(){
      return this.TRANSACTION_FORM;
    },
    getTransactions(){
      return this.TRANSACTIONS.TRANSACTIONS;
    },
    //Pagination Start
    total_records(){
      return this.TRANSACTIONS.TOTAL_RECORDS;
    },
    page_records(){
      return this.TRANSACTIONS.PER_PAGE_RECORDS;
    },
    current_page(){
      return this.TRANSACTIONS.CURRENT_PAGE;
    },
    total_pages(){
      return this.TRANSACTIONS.LAST_PAGE;
    },
    showed_records(){
      let display = (this.page_records*this.current_page);
      if(this.current_page===+this.total_pages){
        if(display>this.total_records){
          return this.total_records;
        }
      }
      return display;
    },
    hasPages(){
      return +this.total_records>15;
    },
    //Pagination End

  },
  methods:{
    ...mapActions('_partnership',['_getTransactions','_getExportTransactions','showFilteredData','setTransactionForm']),
    moreInfo(id) {
      if(this.showMoreInfo===id){
        return this.showMoreInfo =false;
      }
      return this.showMoreInfo=id;
    },
    space(n){
      return space(n);
    },
    //Pagination Start
    pagGo(page){
      let form = this.form;
      form.page = page;
      this.setTransactionForm(form);
      ShowSpinner(3);
      return this.showFilteredData().then(()=>{ return HideSpinner(3) }).catch(()=>{ return HideSpinner(3) });
    },
    pagNext(){
      let page = +this.form.page+1;
      if(page <=this.total_pages){
        let form = this.form;
        form.page = page;
        this.setTransactionForm(form);
        ShowSpinner(3);
        return this.showFilteredData().then(()=>{ return HideSpinner(3) }).catch(()=>{ return HideSpinner(3) });
      }
    },
    pagPrev(){
      let page = +this.form.page-1;
      if(page>0){
        let form = this.form;
        form.page = page;
        this.setTransactionForm(form);
        ShowSpinner(3);
        return this.showFilteredData().then(()=>{ return HideSpinner(3) }).catch(()=>{ return HideSpinner(3) });
      }

    },
    //Pagination END

  }
}
</script>

<style scoped>

</style>
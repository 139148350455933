<template>
  <div class="group grid-col-1 a-i-center pd-b-20 w-b-line">


    <!-- Filter Heading -->
    <div  class="group grid-col-2 a-i-center" :class="{'w-b-line pd-b-20':filterState}">
      <div class="flex-start" :class="$t('font')">
        <input-switch-dynamic @stateChange="toggleFilter()" :set-state="filterState" :label="$t('filter_data')" :desc="$t('filter_transaction_data')" :pad="0"></input-switch-dynamic>
        <div v-if="filterHasData" class="flex-inline pd-l-10 mg-t-4 j-i-center grey5-txt txt-2xl"><i class="fal fa-sort-amount-down"></i></div>
      </div>
      <div class="flex-end">

        <div class="grid-row-2 t-center cursor" v-if="!exportSelect && !showExportSpinner && !exportHasLink">
          <div class="txt-4xl green-txt"><i class="fal fa-file-excel" @click="exportData()"></i></div>
          <div :class="$t('font')" class="txt-xs1">{{$t('export')}}</div>
        </div>
        <div class="grid-row-2 grid-col-2 g-c-gap-10 t-center cursor" v-if="exportSelect" @click="exportData()">
          <div class="txt-4xl blue-txt"><i class="fal fa-file-excel" @click="exportAll()"></i></div>
          <div class="txt-4xl green-txt"><i class="fal fa-file-excel" @click="exportPage()"></i></div>
          <div :class="$t('font')" class="txt-xs1">{{$t('database')}}</div>
          <div :class="$t('font')" class="txt-xs1">{{$t('page')}}</div>
        </div>
        <div class="grid-row-2 t-center cursor" v-if="showExportSpinner">
          <div class="txt-4xl green-txt"><spinner></spinner></div>
          <div :class="$t('font')" class="txt-xs1">{{$t('export')}}</div>
        </div>
        <vue-json-to-csv :json-data="exportResultData" csv-title="export" v-if="exportHasLink">
          <div class="grid-row-2 t-center cursor">
            <div class="txt-4xl blue-txt"><i class="fal fa-cloud-download"></i></div>
            <div :class="$t('font')" class="txt-xs1">{{$t('export')}}</div>
          </div>
        </vue-json-to-csv>
      </div>
    </div>

    <div class="group" v-if="filterState">
      <!-- Keyword -->
      <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
        <div class="flex-start">
          <input-switch-w-desc @stateChange="toggleKeyword()" :set-state="searchFilter" :label="$t('search_keyword')" :desc="$t('keyword_filter_txt')" :pad="0"></input-switch-w-desc>
        </div>
        <div class="flex-center wd-100p f-i-h" v-if="searchFilter">
          <div class="input-group wd-100p input-group-no-label w-tb-margin">
            <div class="input-area"><input type="text"  :placeholder="$t('keyword')" v-model="form.keyword"></div>
          </div>
        </div>
      </div>


      <!-- Date -->
      <div class="group grid-row-2  g-r-gap-20 w-tb-margin w-b-line">
        <div class="flex-start">
          <input-switch-w-desc @stateChange="toggleDateFilter()" :set-state="dateFilter" :label="$t('date')" :desc="$t('date_filter_txt')" :pad="0"></input-switch-w-desc>
        </div>
        <div class="flex-center wd-100p " v-if="dateFilter" style="padding-bottom: 30px !important;">
          <div class="grid-row-2 f-i-h g-r-gap-20 wd-100p">

            <div class="grid-col-3 a-i-center" style="height: 24px; padding-top: 30px;">
              <div class="selection-type-item selection-left cursor" :class="{'yellow':selectedToday}" v-on:click="today()">{{$t('today')}}</div>
              <div class="selection-type-item selection-center cursor" :class="{'yellow':selectedYesterday}" v-on:click="yesterday()">{{$t('yesterday')}}</div>
              <div class="selection-type-item selection-right cursor" :class="{'yellow':selectedCustomDate}" v-on:click="custom()">{{$t('custom_date')}}</div>
            </div>

            <div class="grid-col-2 g-c-gap-20 j-c-end" style="padding-bottom: 30px;" v-if="selectedCustomDate">
              <div class="flex-start">
                <div class="grid-row-2">
                  <div class="grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_from')}}</div>
                  <div><div class="input-area"><input v-model="form.date_from" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
                </div>

              </div>
              <div class="flex-end">
                <div class="grid-row-2">
                  <div class=" grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_to')}}</div>
                  <div><div class="input-area"><input v-model="form.date_to" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <!-- Transaction Type -->
      <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
        <div class="flex-start">
          <input-switch-w-desc @stateChange="toggleTypeFilter()" :set-state="typeFilter" :label="$t('transaction_type')" :desc="$t('type_filter_txt')" :pad="0"></input-switch-w-desc>
        </div>
        <div class="flex-center wd-100p f-i-h" v-if="typeFilter">
          <div class="input-group wd-100p input-group-no-label w-tb-margin">
            <div class="input-area">
              <v-select :multiple="true" :options="types" @input="typeSelected"></v-select>
            </div>
          </div>
        </div>
      </div>


      <!-- Partner -->
      <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
        <div class="flex-start">
          <input-switch-w-desc @stateChange="togglePartnerFilter()" :set-state="partnerFilter" :label="$t('partner')" :desc="$t('partner_filter_txt')" :pad="0"></input-switch-w-desc>
        </div>
        <div class="flex-center wd-100p f-i-h" v-if="partnerFilter">
          <div class="input-group wd-100p input-group-no-label w-tb-margin">
            <div class="input-area">
              <v-select :options="partnerData" label="select_label"  @input="partnershipSelected"></v-select>
            </div>
          </div>
        </div>
      </div>

      <!-- SUBMIT -->
      <div  class="group flex-center flex-center-self" style="margin-top: 60px !important; margin-bottom: 40px !important;">
        <div  class="yellow pd-20 txt-b cursor" style="border-radius: 30px !important;" v-if="!SpinnerStatus" v-on:click="getShowFilteredData()"><i class="fal fa-filter"></i> {{$t('show_filtered_data')}}</div>
        <div  class="txt-7xl grey3-txt container t-center" v-if="SpinnerStatus"><spinner></spinner></div>
      </div>

    </div>
  </div>
</template>

<script>
import InputSwitchDynamic from "@/components/STATIC/inputSwitchDynamic";
import InputSwitchWDesc from "@/components/STATIC/inputSwitchWDesc";
import vSelect from "vue-select";
import Spinner from "@/components/STATIC/spinner";
import {mapActions, mapGetters, mapState} from "vuex";
import {HideSpinner, ShowSpinner} from "@/support/loading";
import VueJsonToCsv from 'vue-json-to-csv'
export default {
  name: "partnership_transaction_filter",
  components: {Spinner, InputSwitchWDesc, InputSwitchDynamic,vSelect,VueJsonToCsv},
  data(){
      return {
        //Spinner
        showDownloadSpinner:false,


        //Filter
        form:{},
        freshForm:{
          keyword:'',
          date_from:'',
          date_to:'',
          type_id:'',
          partnership_id:'',
          limit:'',
        },

        selectedCustomDate:false,
        selectedToday:false,
        selectedYesterday:false,

        showFilter:false,
        searchFilter:false,
        dateFilter:false,
        typeFilter:false,
        partnerFilter:false,
        //FilterEnd

        //Export
        showExportSpinner:false,
        exportResultData:[],

        exportSelect:false,
        exportSelectAll:false,
        exportHasLink:false,

      }
  },
  mounted() {
    this.freshTransactionForm();
    this.resetExportIndicators();
  },
  computed:{
    ...mapState('_partnership',['PARTNERSHIP','EXPORT_LINK','TRANSACTIONS','TRANSACTIONS_EXPORT']),
    //SPINNERS
    ...mapGetters(['_loadingSpinnerStatus']),


    getTransactions(){
      return this.TRANSACTIONS.TRANSACTIONS;
    },
    getTransactionsExport(){
      return this.TRANSACTIONS_EXPORT.TRANSACTIONS;
    },
    SpinnerStatus(){
      return this._loadingSpinnerStatus(3);
    },
    //SPINNERS END
    partnerData(){
      return this.PARTNERSHIP.ACTIVE;
    },
    types(){
      return [
        { id:1, label:this.$t('buy_points') },
        { id:3, label:this.$t('accept_discount') },
        { id:8, label:this.$t('exchange_w_partner') },
      ];
    },
    filterState(){
      return this.showFilter;
    },
    filterHasData(){
      return this.dateFilter || this.searchFilter || this.typeFilter || this.partnerFilter;
    },

    total_records(){
      return this.TRANSACTIONS.TOTAL_RECORDS;
    }
  },
  methods:{
    ...mapActions('_partnership',['_getTransactions','_getExportTransactions','freshTransactionForm','setTransactionForm','showFilteredData']),
    //Filter
    toggleFilter(){
      this.showFilter = !this.showFilter;
    },
    toggleKeyword(){
      this.searchFilter = !this.searchFilter;
      if(!this.searchFilter){
        return this.form.keyword = '';
      }
      return true;
    },
    toggleDateFilter(){
      this.dateFilter = !this.dateFilter;
      if(this.dateFilter){
        return this.today();
      }
      return this.resetDateSelection();
    },
    toggleTypeFilter(){
      this.typeFilter = !this.typeFilter;
      if(!this.typeFilter){
        return this.form.type_id = '';
      }
      return true;
    },
    togglePartnerFilter(){
      this.partnerFilter = !this.partnerFilter;
      if(!this.partnerFilter){
        return this.form.partnership_id = '';
      }
      return true;
    },
    today(){
      this.resetDateSelection();
      this.selectedToday = true;
      this.form.date_from = "02/01/2000";
      this.form.date_to="02/01/2000";
    },
    yesterday(){
      this.resetDateSelection();
      this.selectedYesterday = true;
      this.form.date_from = "01/01/2000";
      this.form.date_to="01/01/2000";
    },
    custom(){
      this.resetDateSelection();
      this.selectedCustomDate = true;
    },
    typeSelected(e){
      let set = [];
      e.map(function(selected){
        return set.push(selected.id);
      })
      this.form.type_id =set;

    },
    partnershipSelected(e){
      this.form.partnership_id =e.id;

    },
    getShowFilteredData(){
      this.form.page = 1;
      this.toggleFilter();
      this.resetExportIndicators();
      this.setTransactionForm(this.form);
      ShowSpinner(3);
      return this.showFilteredData().then(()=>{ return HideSpinner(3); }).catch(()=>{ return HideSpinner(3); });
    },
    resetForm(){
      this.form = this.freshForm;
      this.freshTransactionForm();
    },
    resetDateSelection(){
      this.form.date_from = '';
      this.form.date_to = '';
      this.selectedToday = false;
      this.selectedYesterday = false;
      this.selectedCustomDate = false;
    },
    //End Filter

    //Export File
    generateExportFileData(transactions){
      let getThis = this;
      return new Promise((resolve) => {
        let exportFileData = [];
        transactions.map(function(item){
          let obj = {
            Date:item.date,
            OperationAuthor:item.acceptor_label,
            OperationType:item.label,
            CardType:(+item.card_type===1)? getThis.$t('discount'): getThis.$t('collector'),
            CardNumber:item.card_account,
            CheckAmount:item.check_amount,
            Amount:item.amount,
          };
          exportFileData.push(obj);
        });
        resolve(exportFileData);
      });

    },
    resetExportIndicators(){
      this.showExportSpinner=false;
      this.exportResultData=[];
      this.exportSelect=false;
      this.exportSelectAll=false;
      this.exportHasLink=false;
    },
    exportData(){
      this.resetExportIndicators();
      return this.exportSelect = true;
    },
    exportAll(){
      this.showExportSpinner = true;
      this.exportSelect =false;
      let filter = {limit:this.total_records};
      this._getExportTransactions(filter).then(()=>{
        this.generateExportFileData(this.getTransactionsExport).then(result=>{
          this.resetExportIndicators();
          this.exportHasLink=true;
          this.exportResultData = result;
        });
      }).catch(()=>{
        this.resetExportIndicators();
      });


    },
    exportPage(){
      this.showExportSpinner = true;
      this.exportSelect =false;
      this.generateExportFileData(this.getTransactions).then(result=>{
          this.resetExportIndicators();
          this.exportHasLink=true;
          this.exportResultData = result;
      });
    }
    //End Export File
  },


}
</script>

<style scoped>

</style>
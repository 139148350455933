<template>
<div v-if="!cardSuspended">
  <customer-card :card="card" :user="user" v-on:suspend="suspendCard()"></customer-card>

  <customer-transactions :transactions="transactions"></customer-transactions>
</div>
</template>

<script>
import CustomerCard from "@/components/component/customers/cardAndTransactions/card";
import CustomerTransactions from "@/components/component/customers/cardAndTransactions/transactions";
export default {
  name: "customerCardAndTransactions",
  components: {CustomerTransactions, CustomerCard},
  props:{
    transactions:{
      required: true,
    },
    card:{
      required: true,
    },
    user:{
      required: true,
    },
  },
  mounted() {
    this.suspend = false;
  },
  data(){
    return {
      suspend:false,
    }
  },
  computed:{
    cardSuspended(){
      return this.suspend;
    }
  },
  methods:{
    suspendCard(){
      this.$emit('suspend',this.user);
      return this.suspend = true;
    }
  }

}
</script>

<style scoped>

</style>
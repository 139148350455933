import Partners from "@/apis/partners";
import {httpStatusActions} from "@/support/support";

export const _getAllDataFromCloud = ({commit})=> {

    return new Promise((resolve, reject) => {
        Partners.get().then(response => {
            commit('SET_CLOUD_DATA', {response: response.data});
            httpStatusActions(response,true);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _getMemberDataFromCloud = ({commit})=> {

    return new Promise((resolve, reject) => {
        Partners.members().then(response => {
            commit('SET_CLOUD_MEMBERS_DATA', {response: response.data});
            httpStatusActions(response,true);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}







export const _createOrUpdate = ({commit},form)=> {

    return new Promise((resolve, reject) => {

            Partners.create(form).then(response => {
                commit('INSERT_NEW',response.data);
                httpStatusActions(response);
                resolve (response);
            }).catch(error=>{
                reject(httpStatusActions(error));
            });

    });
}
export const _delete = ({commit},id)=> {

    return new Promise((resolve, reject) => {
        Partners.delete(id).then(response => {
            httpStatusActions(response);
            commit('REMOVE_CLOUD_DATA', id);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}








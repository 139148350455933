<template>
  <div>

    <transition name="slide-fade">
    <div class="container main bg blur" v-if="visibleState">
      <div class="launchpad-grid">
        <div class="lhead">
          <div class="lhead-logo"><box-logo></box-logo></div>
          <div class="lhead-greeting t-white-txt txt-5xl" :class="$t('font_b')">{{$t('welcome')}}!</div>
          <div class="lhead-greeting-desc t-white-txt txt-lg1" :class="$t('font')">{{$t('you_are_logged_in_as')}} {{merchantName}} <div class="logout-btn cursor" v-on:click="logoutUser()"><span>{{$t('logout')}}<i class="pd-l-4 fal fa-sign-out"></i></span></div></div>
        </div>
        <div class="lbody" :class="$t('font')">
          <div class="licon-cont">
            <a :href="'https://'+docDomain+'.docs.portfel.app/'" class="no-decor-link" target="_blank"><div class="licon  cursor " :class="$t('font_b')"><i class="far fa-braille"></i></div></a>
            <a :href="'https://'+docDomain+'.docs.portfel.app/'" class="no-decor-link" target="_blank"><div class="licon-desc " :class="$t('font')">{{$t('start')}}</div></a>
          </div>
          <div class="licon-cont" v-on:click="routeTo('statistics')">
            <div class="licon  cursor"><i class="fal fa-analytics"></i></div>
            <div class="licon-desc">{{$t('statistics')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('transactions')">
            <div class="licon  cursor"><i class="fal fa-cabinet-filing"></i></div>
            <div class="licon-desc">{{$t('transactions')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('messages')">
            <div class="licon  cursor"><i class="fal fa-envelope"></i></div>
            <div class="licon-desc"><span class="inbox-unread-bage en mg-r-2" v-if="inboxUnreadCount>0">{{ inboxUnreadCount }}</span>{{$t('inbox')}}</div>

          </div>
          <div class="licon-cont" v-on:click="routeTo('collector')">
            <div class="licon  cursor"><i class="fal fa-coins"></i></div>
            <div class="licon-desc">{{$t('collector')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('discount')">
            <div class="licon  cursor"><i class="fal fa-gift-card"></i></div>
            <div class="licon-desc">{{$t('discount')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('branch')">
            <div class="licon  cursor"><i class="fal fa-store"></i></div>
            <div class="licon-desc">{{$t('branchs')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('employee')">
            <div class="licon  cursor"><i class="fal fa-user-tag"></i></div>
            <div class="licon-desc">{{$t('employees')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('partner')">
            <div class="licon  cursor"><i class="fal fa-handshake-alt"></i></div>
            <div class="licon-desc">{{$t('partners')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('partnership')">
            <div class="licon  cursor"><i class="fal fa-file-invoice"></i></div>
            <div class="licon-desc"><span class="inbox-unread-bage en mg-r-2" v-if="partnershipNotifyCount>0">{{ partnershipNotifyCount }}</span>{{$t('partnership')}} </div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('customers')">
            <div class="licon  cursor"><i class="fal fa-user-friends"></i></div>
            <div class="licon-desc">{{$t('customers')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('service')">
            <div class="licon  cursor"><i class="fal fa-box-full"></i></div>
            <div class="licon-desc">{{$t('service')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('profile')">
            <div class="licon  cursor"><i class="fal fa-user-cog"></i></div>
            <div class="licon-desc">{{$t('settings')}}</div>
          </div>
          <div class="licon-cont" v-on:click="routeTo('help')">
            <div class="licon  cursor"><i class="fal fa-question-circle"></i></div>
            <div class="licon-desc">{{$t('help')}}</div>
          </div>




        </div>
        <div class="lfooter t-center">&copy; Portfel Corporataion Limited, UK</div>
      </div>
    </div>
    </transition>
    <div v-if="!visibleState">
      <div class="extend-box yellow cursor" v-if="!visibleState" v-on:click="setLPState(true)">
        <i class="fab fa-buromobelexperte"></i>
      </div>
    </div>
    <div v-if="visibleState">
      <div class="extend-box yellow cursor" v-if="visibleState" v-on:click="setLPState(false)">
        <i class="fal fa-eye-slash"></i>
      </div>
    </div>
  </div>

</template>
<script>
import BoxLogo from "@/components/STATIC/box-logo";
import support, {
  logOut,
  setLang,
  getLang,
  canRequestUpdate,
  setCloudDataUpdatedToken,
  setCloudDataUpdateFail
} from "@/support/support";
import {mapActions, mapState,mapGetters} from "vuex";
import User from "@/apis/user";
import {closeSmartsApp, loading, openSmartsApp, warningMessage} from "@/support/helpers";
import config from "@/config";

export default {
  components: {BoxLogo},
  data(){
    return {
      lang:'en',
      visibleState:false,
    }
  },
  mounted() {
    if(!this.emailVerified){
      warningMessage(this.$t('please_verify_email'));
    }
    if(canRequestUpdate('_msg',+15)) {
      this.fetchMessages().then(() => {
        return setCloudDataUpdatedToken('_msg');
      }).catch(() => {
        return setCloudDataUpdateFail('_msg');
      });
    }
    if(canRequestUpdate('_ptns',+15)) {
      this.fetchPartnership().then(() => {
        return setCloudDataUpdatedToken('_ptns');
      }).catch(() => {
        return setCloudDataUpdateFail('_ptns');
      });
    }

    if(canRequestUpdate('_appd',+15) || this.merchantName.length<=0){
     this._getAllDataFromCloud().then(()=>{

       setCloudDataUpdatedToken('_appd');

     }).catch(()=>{
       return setCloudDataUpdateFail('_appd');
     });

    }

    if(this.getLPState){
      this.visibleState = true;
    }
    this.lang = getLang();

  },
  computed: {
    ...mapState('_general', ['CLOUD']),
    ...mapGetters('_inbox', ['unreadCount']),
    ...mapGetters('_partnership',['newRequests']),
    merchantName(){
      return this.CLOUD.PROFILE.merchant_label;
    },
    getLPState(){
      return support.getLaunchpadState();
    },
    inboxUnreadCount(){
      return this.unreadCount;
    },
    partnershipNotifyCount(){
      return this.newRequests;
    },
    docDomain(){
      if(getLang()==='ru'){
        return 'ru';
      }
      return 'en'; //else
    },
    emailVerified(){
      return (this.merchantName.length>0) ? this.CLOUD.PROFILE.merchant_email_verified : true;
    }
  },
  methods:{
    ...mapActions('_general',['_getAllDataFromCloud']),
    ...mapActions('_inbox',{fetchMessages:'_getAllDataFromCloud'}),
    ...mapActions('_partnership',{fetchPartnership:'_getPartnership'}),

    setLPState(setState){
      if(this.$route.name!=='help'){
        closeSmartsApp();
      }
      if(setState){
        if(config.enableSmartsUpp){
          window.smartsupp('chat:close');
        }

      }
      this.visibleState = setState;
      return support.setLaunchpadState(Boolean(setState));
    },
    routeTo(section){

      closeSmartsApp();

      if(this.$route.name==='help'){
        openSmartsApp();
      }
      if(section==='help'){
        openSmartsApp();
      }else{
        closeSmartsApp();
      }
      if(this.$route.name ===section){
        return this.setLPState(false);
      }
      this.setLPState(false);
      return this.$router.push({ name: section });
    },
    getLang(){
      return getLang();
    },
    setLang(lang){
      setLang(lang);
      return window.location.reload();
    },
    logoutUser() {
        loading();
        User.logout().then(() => {
          logOut();
          return this.$router.push({ name: "login" });
        });

    },

  }
}

</script>
<style scoped>
.bg {
  background-color:#331754;
  background-image: linear-gradient(-225deg, #EB0C8D 0%, #007CF8 100%);
}
.main {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  justify-content: center;
}
.launchpad-grid {
  justify-content: space-around;
  width: 90%;
  display: grid;
  grid-template-areas: "l-head l-head" "l-body l-body" "l-footer l-footer";
  grid-row-gap:50px ;
  max-height: 900px;
}
.lhead {
  grid-area: l-head;
  height: 150px;
  width: auto;
  display: grid;
  grid-template-areas: "logo greeting" "logo greeting-desc";
  grid-column-gap: 0;
  grid-row-gap: 0;
  max-width: 620px;
  margin-top: 30px;
  justify-self: center;
  align-items: center;
  justify-items: start;

}
.lhead-logo{
  grid-area: logo;
  align-items: center;
  padding-right: 18px;
  padding-top: 8px;
}
.lhead-logo > svg{
  height: 150px;
}
.lhead-greeting{
  padding-top: 30px;
  grid-area: greeting;
  justify-content: start;
  text-shadow: 0 1px 3px #5D5D5D;
}
.lhead-greeting-desc{
  grid-area: greeting-desc;
  height: 50px;
}
.lbody{
  grid-area: l-body;
  height: 100%;
  display: grid;
  grid-template-columns:repeat(auto-fill,minmax(110px,111px));
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  justify-items: center;
  justify-content: center;
}
.licon-cont{
  display: grid;
  grid-template-areas: "l-icon" "icon-desc";
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-items: center;
  justify-content: center;
}
.licon{
  grid-area: l-icon;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  color: var(--t-white);
  font-size: var(--txt-7xl);
  text-shadow: 0 3px 6px #5D5D5D;
  border-radius: 16px;
  border: 1px solid var(--t-white);
  box-shadow: 0 3px 6px #5D5D5D;
}
.licon-desc{
  grid-area: icon-desc;
  color: var(--t-white);
  font-size: var(--txt-md);
  font-weight: bold;
  text-shadow: 0 1px 3px #5D5D5D;
}
.logout-btn {
  display: inline-grid;
  border-radius: 12px;
  background-color: var(--yellow);
  color: var(--t-black);
  padding: 4px;
  font-size: var(--txt-md);
  width: 90px;
  height: 20px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: space-between;
}

.lfooter{
  grid-area: l-footer;
  width: 100%;
}
.language-selector >div {
  text-decoration: underline;
  font-size: var(--txt-xl);
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 10px;
  color: white;
}

.extend-box {
  font-size: 26px;
  text-align: center;
  justify-content: center;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 60px;
  width: 60px;
  bottom: 35px;
  right: 25px;
  border-radius: 100px;
  color: var(--t-black);
  z-index: 102;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);

}
.extend-box:hover{
  background-color: #c4af2c;
}
.slide-fade-enter-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
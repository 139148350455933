<template>

    <div class="grid-col-4 grid-col-gap3 w-b-line w-tb-pad-20 grid-col-xs-auto-auto-md">
      <div class="pd-t-3 indigo-txt"><i class="fad fa-people-arrows txt-md1"></i></div>
      <div class="grid-row-4 pd-l-4 pd-r-4">
        <div class="en-b txt-xs1">{{$t('exchange_request')}}</div>
        <div class="txt-xs grey1-txt pd-t-2"></div>
        <div class="grid-row-2">
          <div class="en txt-xs mg-t-5 grey3-txt">{{$t('deduct_balance')}}</div>
          <div class="txt-md">
            {{ deduct_balance }}
            <dollar v-if="+type===1"></dollar>
            <i class="pof points" v-if="+type===2"></i>
          </div>
        </div>
        <div class="grid-row-2">
          <div class="en txt-xs mg-t-5 grey3-txt">{{$t('request_amount')}}</div>
          <div class="txt-md">{{request_amount}}<dollar set-class="pd-l-4"></dollar>
          </div>
        </div>

      </div>
      <div class="txt-xs1 pd-l-20 pd-r-20 grey1-txt grid-row-2 j-c-end ">
        <div class="grey7 radius pd-t-10 pd-r-10 pd-l-10 pd-b-10" style="border-bottom-left-radius: 0 !important;">
          <div class="w-b-line wd-100p">
            <span class="txt-xs grey4-txt">{{date}}</span>
          </div>
          {{comment}}
        </div>
        <div class="grey7 mg-t-10 radius pd-t-10 pd-r-10 pd-l-10 pd-b-10 t-right" style="border-bottom-right-radius: 0 !important;" v-if="rejected">
          <div class="w-b-line wd-100p">
            <span class="txt-xs grey4-txt">{{rejectDate}}</span>
          </div>
          {{rejectComment}}
        </div>
      </div>
      <div v-if="!rejected">
        <input-button v-if="canCancel" :disabled="true" set-class="grey7 txt-xs1 mg-b-10 t-center" :label="$t('pending_request')"></input-button>
        <input-button v-if="canCancel" :show-spinner="acceptSpinner" set-class="yellow txt-xs1" @click.native="cancel()" :label="$t('cancel')"></input-button>
        <input-button v-if="!canCancel" set-class="red-light mg-b-10 txt-xs1" v-on:click.native="reject()" :label="$t('reject')"></input-button>
        <input-button v-if="!canCancel" :show-spinner="acceptSpinner" set-class="green txt-xs1" @click.native="accept()" :label="$t('accept')"></input-button>
      </div>
      <div v-if="rejected">
        <input-button :disabled="true" set-class="grey7 txt-xs1 t-center" :label="$t('request_rejected')"></input-button>
      </div>
    </div>

</template>

<script>
import InputButton from "@/components/STATIC/inputButton";
import Dollar from "@/components/STATIC/dollar";
import {awOpen} from "@/support/applicationWindow";
import {mapActions} from "vuex";



export default {
  name: "footer_exchange_request",
  components: {Dollar, InputButton},
  props:{
      request:{
        required:true,
        default(){
          return {};
        }
      },
  },
  data(){
    return {
      acceptSpinner:false,
    }
  },
  computed:{
    date(){
      return (this.request.date !== undefined) ? this.request.date : '00-00-0000';
    },
    rejectDate(){
      return (this.request.reject_date !== undefined) ? this.request.reject_date : '00-00-0000';
    },
    request_amount(){
      return (this.request.request_amount !== undefined) ? this.request.request_amount : 0.00;
    },
    deduct_balance(){
      return (this.request.deduct_balance !== undefined) ? this.request.deduct_balance : 0.00;
    },
    comment(){

      if(this.request.comment !== undefined){
        if(this.request.comment.length>0){
          return this.request.comment;
        }
      }
      return this.$t('no_comment');
    },
    rejectComment(){
      if(this.request.reject_comment !== undefined){
        if(this.request.reject_comment && this.request.reject_comment.length>0){
          return this.request.reject_comment;
        }
      }
      return this.$t('no_comment');
    },
    rejected(){
      return (this.request.rejected !== undefined) ? +this.request.rejected===1 : null;
    },
    canCancel(){
      return (this.request.can_cancel !== undefined) ? (this.request.can_cancel>0) : false;
    },
    type(){
      return (this.request.type !== undefined) ? this.request.type : 0;
    },
  },
  methods:{
    ...mapActions('_partnership',['_acceptRequest','_getPartnership','_cancelRequest']),
    reject(){
       awOpen(2,{id:this.request.id,});
    },
    accept(){
      if(this.acceptSpinner){ //Repeated Click
        return false;
      }
        let data = { id:this.request.id};
        this.acceptSpinner = true;
        return this._acceptRequest(data).then(()=>{
          this.acceptSpinner = false;
        }).catch(()=>{
          this.acceptSpinner = false;
        });
    },
    cancel(){
      if(this.acceptSpinner){ //Repeated Click
        return false;
      }
      let data = { id:this.request.id};
      this.acceptSpinner = true;
      return this._cancelRequest(data).then(()=>{
        this.acceptSpinner = false;
      }).catch(()=>{
        this.acceptSpinner = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
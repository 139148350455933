export default {
    /* Data Is Filed From Cloud */
    CLOUD:[],

    /* Static Hardcoded Data */
    STATIC:{
        /* Static Data For Page Generation */
        DATA:[],
    },



}
export const SET_CLOUD_DATA = (state,{response}) => {
    let newData = response;
    state.CLOUD =[];
    state.CLOUD = Object.assign([], state.CLOUD,newData);
}
export const SET_PARTNERSHIP_DATA = (state,{response}) => {
    let newData = response;
    state.PARTNERSHIP = {};
    state.PARTNERSHIP = Object.assign({}, state.PARTNERSHIP,newData);
}
export const SET_TRANSACTION_DATA = (state,{response}) => {
    let newData = response;
    state.TRANSACTIONS = {};
    state.TRANSACTIONS = Object.assign({}, state.TRANSACTIONS,newData);
}
export const SET_TRANSACTION_EXPORT_DATA = (state,{response}) => {
    let newData = response;
    state.TRANSACTIONS_EXPORT = {};
    state.TRANSACTIONS_EXPORT = Object.assign({}, state.TRANSACTIONS_EXPORT,newData);
}
export const SET_MARK_NOTIFICATIONS_READ = (state,data) => {
    let YOU_ACCEPT_CARDS = _MARK_READ_NOTIFICATIONS(state.PARTNERSHIP.YOU_ACCEPT_CARDS,data.id);
    let ACTIVE = _MARK_READ_NOTIFICATIONS(state.PARTNERSHIP.ACTIVE,data.id);

    state.PARTNERSHIP.ACTIVE = [];
    state.PARTNERSHIP.ACTIVE = Object.assign([], state.PARTNERSHIP.ACTIVE,ACTIVE);

    state.PARTNERSHIP.YOU_ACCEPT_CARDS = [];
    state.PARTNERSHIP.YOU_ACCEPT_CARDS = Object.assign([], state.PARTNERSHIP.YOU_ACCEPT_CARDS,YOU_ACCEPT_CARDS);
}
const _MARK_READ_NOTIFICATIONS =(dataSource,partnershipId)=>{
    let oldData = dataSource;
    let index = oldData.findIndex(item=>{
        return item.id ===partnershipId;
    });
    let partnership = oldData.filter(item=>{
        return item.id ===partnershipId;
    });
    if(partnership.length>0){
        partnership = partnership[0];
    }
    for(let i=0;i<partnership.notifications.length;i++){
        partnership.notifications[i].read=1;
    }
    oldData.splice(index,1,partnership);
    return oldData;
}

export const SET_EXPORT_LINK = (state,{response}) => {
    let newData = response;
    state.EXPORT_LINK = '';
    state.EXPORT_LINK = newData;
}
export const RESET_EXPORT_LINK = (state) => {
    state.EXPORT_LINK = '';
}
export const SET_TRANSACTION_FORM = (state,form) => {
    let newData = form;
    state.TRANSACTION_FORM = {};
    state.TRANSACTION_FORM = Object.assign({}, state.TRANSACTION_FORM,newData);
}
export const SET_FRESH_TRANSACTION_FORM = (state) => {
    let newData = state.FRESH_TRANSACTION_FORM;
    state.TRANSACTION_FORM = {};
    state.TRANSACTION_FORM = Object.assign({}, state.TRANSACTION_FORM,newData);
}

// export const REMOVE_CLOUD_DATA = (state,id) => {
//     state.CLOUD = state.CLOUD.filter(from => {
//         return +from.id !== +id;
//     })
// }

/*
*
*/


/*
*
*/

/*
*
*/


export const getBranchLabel = (state)=>(id) =>{
    let result =  state.CLOUD.find(item=>{
        return item.id === id;
    });
    if(result){
        return result.label;
    }else{
        return '';
    }
}
export const getBranchList = (state)=>(id=false) =>{
    if(!id){
        return state.CLOUD;
    }
    return state.CLOUD.filter(item=>{
        return item.id !== id;
    });
}
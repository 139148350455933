
export const SET_CLOUD_DATA = (state,data) => {
    let newData = data;
    state.CLOUD ={};
    state.CLOUD = Object.assign({}, state.CLOUD,newData);
}
export const SET_EXPORT_DATA = (state,data) => {
    let newData = data;
    state.EXPORT ={};
    state.EXPORT = Object.assign({}, state.EXPORT,newData);
}

<template>
  <div id="dynamic" class="w-t-line">
    <div class="space-div--20"></div>

    <div class="container col-2 pd-t-20 pd-b-20">
        <clear-btn @click.native="freshForm()"></clear-btn>
        <save-btn :can-submit="canSubmit" :show-right-spinner="showRightSpinner" v-on:click.native="submitForm()"></save-btn>
    </div>
    <div class="space-div--20"></div>
    <input-copy v-if="isEdit" :hash="formData.public" label="public_distribution_link"></input-copy>
    <value-desc v-if="isEdit" text="public_distrib_link_desc"></value-desc>

    <div class="space-div--20"></div>
    <input-slot label="model_name" :req="true" :message="errorMessage('label')">
      <input type="text"  name="model_name" autocomplete="false"  :value="formData.label" @input="updateValue('label',$event.target.value)">
    </input-slot>
    <value-desc text="model_name_desc"></value-desc>

    <div class="input-desc"><span class="mg-l-7" :class="$t('font')">{{$t('card_discount_percent')}}</span></div>
    <value-desc text="dyn_discount_percent_desc"></value-desc>

    <dynamic-item v-for="rule in dynRulesData" :key="rule.id" :id="rule.id" :card_id="rule.card_id"></dynamic-item>

    <div class="add-list-item-button" v-on:click="createNewRule()">
      <div class="icon"><i class="fal fa-plus-circle"></i></div>
      <div class="text" :class="$t('font_b')">{{$t('addRule')}}</div>
    </div>

    <input-number label="givable_on_amount" :req="true" :message="errorMessage('givable_on_amount')">
      <input type="number" @wheel="$event.target.blur()" name="discount" autocomplete="false" :value="formData.givable_on_amount" @input="updateValue('givable_on_amount',$event.target.value)" @dblclick="updateValue('givable_on_amount','')">
    </input-number>
    <value-desc text="givable_on_amount_desc"></value-desc>

    <input-number label="minimum_purchase" :req="true" :message="errorMessage('minimum_purchase')">
      <input type="number" @wheel="$event.target.blur()" name="discount" autocomplete="false" :value="formData.minimum_purchase" @input="updateValue('minimum_purchase',$event.target.value)" @dblclick="updateValue('minimum_purchase','')">
    </input-number>
    <value-desc text="minimum_purchase_discount_desc"></value-desc>

    <input-number label="active_due_balance" :req="true" :message="errorMessage('active_due_balance')">
      <input type="number"  @wheel="$event.target.blur()"  name="discount" autocomplete="false" :value="formData.active_due_balance" @input="updateValue('active_due_balance',$event.target.value)" @dblclick="updateValue('active_due_balance','')">
    </input-number>
    <value-desc text="active_due_balance_desc"></value-desc>

    <div class="input-group w-margin">
      <div class="input-desc" :class="$t('font')" v-if="!errorMessage('active_due')">{{$t('expiry_date')}}</div>
      <div class="input-desc red-light-txt" :class="$t('font')" v-if="errorMessage('active_due')">{{errorMessage('active_due')}}</div>
      <div class="input-area">
        <input type="date"  autocomplete="off" :value="formData.active_due" @input="updateValue('active_due',$event.target.value)" @dblclick="updateValue('active_due','')"></div>
    </div>
    <value-desc text="active_due_date_desc"></value-desc>

    <dyn-discount-card :fetch-errors="fetchErrors"></dyn-discount-card>
  </div>
</template>

<script>
import {colorPalette,validator, randomName, roundTo,displayErrors} from "@/support/support";
import inputSlot from "@/components/STATIC/inputSlot";
import valueDesc from "@/components/STATIC/valueDesc";
import InputNumber from "@/components/STATIC/inputNumber";
import dynamicItem from "@/components/discount/dynamicItem";
import {mapState,mapActions} from 'vuex';
import DynDiscountCard from "@/components/STATIC/dynDiscountCard";
import ClearBtn from "@/components/STATIC/clearBtn";
import SaveBtn from "@/components/STATIC/saveBtn";
import InputCopy from "@/components/STATIC/input-copy";
export default {
    props:{

      fetchErrors:{
        default:()=>{
          return {}
        },
      },
    },
    components:{
      InputCopy,
      SaveBtn,
      ClearBtn,
      DynDiscountCard,
      InputNumber,
      inputSlot,
      valueDesc,
      dynamicItem,
    },
    data(){
      return {
        showLeftSpinner:false,
        showRightSpinner:false,
        /* ERROR HANDLING */
        formErrors:{},
        /* END ERROR HANDLING */
      }
    },
    mounted(){
        this.updateValue('discount',this.primaryDiscount);
    },

    computed:{
      ...mapState('_discount',['CLOUD','FROM_DYNAMIC']),
      /*
      * Approved
      */
      isEdit(){
        return +this.FROM_DYNAMIC.id >0;
      },
      formData(){
        return this.FROM_DYNAMIC;
      },
      dynRulesData(){
        return this.FROM_DYNAMIC.is_dynamic;
      },
      primaryDiscount(){
        return this.dynRulesData[0].discount;
      },
      colorPalette(){
        return colorPalette(this.CLOUD.color_palette_count)
      },
      /*
      * Approved END
      */
      canSubmit(){
        let valuesToValidate = [
          {
            name:'label',
            input:this.formData.label,
            rule:'min:char',
            message:this.$t('min_char'),
          },
          {
            name:'show_label',
            input:this.formData.show_label,
            rule:'min:char',
            message:this.$t('min_char'),
          },
          {
            name:'active_due_balance',
            input:this.formData.active_due_balance,
            rule:'min:zero',
            message:this.$t('min_zero'),
          },
          {
            name:'givable_on_amount',
            input:this.formData.givable_on_amount,
            rule:'min:num',
            message:this.$t('min_num'),
          },
          {
            name:'minimum_purchase',
            input:this.formData.minimum_purchase,
            rule:'min:num',
            message:this.$t('min_num'),
          },
        ];
        return this.hasErrors(validator(valuesToValidate));
      },

    },
    methods:{
      ...mapActions('_discount',['_updateDynValue','_addDynRule','_updateDynRule','_removeDynRule','_freshDynForm']),
      submitForm(){
        if(!this.canSubmit){
          return  false;
        }
        return this.$emit('submit',this.formData);
      },
      /*
      * Approved
      */
      updateValue(name,value){
          let UPDATE = { valueName:name,valueData:value };
          return this._updateDynValue(UPDATE);
      },
      freshForm(){
        this.$emit('fresh');
        return this._freshDynForm();
      },
      /* Error Handling */
      errorMessage(input){ //METHOD FOR INPUT
        if(Object.keys(this.fetchErrors).length>0){
          let display = displayErrors(input,this.fetchErrors);
          if(display){
            return display;
          }
        }
        return displayErrors(input,this.formErrors);
      },
      hasErrors(errors){ //METHOD FOR Can Submit
        this.formErrors = errors;
        return (Object.entries(this.formErrors).length ===0);
      },
      /* END Error Handling */
      /*
      * Approved End
      */

      /* Dynamic  */
      getLastRule(){
        let index = this.dynRulesData.length;
        return this.dynRulesData[(index-1)];
      },


      createNewRule(){
        let lastRule = this.getLastRule();
        let discount =  roundTo(lastRule.discount*1.22,0);
        if(discount>=99){
          discount = 99;
        }
        let amount = lastRule.amount*2;
        let RULE = {
          id:randomName(),
          card_id:this.formData.id,
          amount:amount,
          discount:discount,
        };
        this._addDynRule(RULE);

      },
    }
}
</script>



import collector from "@/components/collector";
import discount from "@/components/discount";
import employee from "@/components/employee";
import branch from "@/components/branch";
import partner from "@/components/partner";
import customers from "@/components/customers";
// import customers from "@/components/customers";
// import home from "@/components/home";
import login from './components/login.vue';
import Register from './components/register.vue';
import Agreement from './components/agreement.vue';
import SelectPackage from './components/subscription/selectPackage';
import Service from './components/service'
import Help from './components/help'
import Forgot from './components/forgot.vue';
import ResetPassword from "@/components/resetPassword";
import ConfirmEmail from "@/components/confirmEmail";
import profile from "@/components/profile";
import setDomain from "@/components/set-domain"
import transactions from "@/components/transactions";
import messages from "@/components/messages";
import statistics from "@/components/statistics";
import partnership_transactions from "@/components/partnership";
import cardbuilder from "@/components/cardbuilder";


export default [

    {
        path: '/',
        component: statistics,
        name: 'home',
        meta: { authOnly: true }
    },
    {
        path: '/transactions',
        component: transactions,
        name: 'transactions',
        meta: { authOnly: true }

    },
    {
        path: '/partnership',
        component: partnership_transactions,
        name: 'partnership',
        meta: { authOnly: true }

    },
    {
        path: '/messages',
        component: messages,
        name: 'messages',
        meta: { authOnly: true }

    },
    {
        path: '/statistics',
        component: statistics,
        name: 'statistics',
        meta: { authOnly: true }

    },
    {
        path: '/collector',
        component: collector,
        name: 'collector',
        meta: { authOnly: true }
    },
    {
        path: '/discount',
        component: discount,
        name: 'discount',
        meta: { authOnly: true }
    },
    {
        path: '/branch',
        component: branch,
        name: 'branch',
        meta: { authOnly: true }
    },

    {
        path: '/employee',
        component: employee,
        name: 'employee',
        meta: { authOnly: true }

    },
    {
        path: '/partner',
        component: partner,
        name: 'partner',
        meta: { authOnly: true }

    },
    {
        path: '/customers',
        component: customers,
        name: 'customers',
        meta: { authOnly: true }

    },
    // {
    //     path: '/customers',
    //     component: customers,
    //     name: 'customers',
    //     meta: { authOnly: true }
    //
    // },
    {
        path: '/service',
        component: Service,
        name: 'service',
        meta: { authOnly: true }
    },
    {
        path: '/help',
        component: Help,
        name: 'help',
        meta: { authOnly: true }
    },
    {
        path: '/profile',
        component: profile,
        name: 'profile',
        meta: { authOnly: true }
    },
    {
        path: '/selectPackage',
        component: SelectPackage,
        name: 'selectPackage',
        meta: { authOnly: true }
    },




    {
        path: '/login',
        component: login,
        name: 'login',
        meta: { guestOnly: true }
    },

    /* Setup */
    {
        path: '/register',
        component: Register,
        name: 'register',
        meta: { guestOnly: true }
    },
    {
        path: '/agreement',
        component: Agreement,
        name: 'agreement',
        meta: { guestOnly: true }
    },
    {
        path: '/forgot',
        component: Forgot,
        name: 'forgot',
        meta: { guestOnly: true }
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        name: 'resetPassword',
        meta: { guestOnly: true }
    },
    {
        path: '/confirm-email',
        component: ConfirmEmail,
        name: 'confirmEmail',

    },

    {
        path: '/set-location',
        component: setDomain,
        name: 'setDomain',

    },

    //todo remove
    {
        path: '/cardbuilder',
        component: cardbuilder,
        name: 'cardbuilder',
        meta: { authOnly: true }

    },

];
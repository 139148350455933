import Api from "./Api";


const END_POINT = 'company/stats';

export default {
    get() {
        return  Api().get(`${END_POINT}/`);
    },
}

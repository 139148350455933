<template>
<div>
  <div class="headline-txt">{{ $t('points') }}</div>
  <div class="section-wrp">
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('overview')}} </div>
        <div class="period"></div>
      </div>
      <div class="body-wrp">
        <div class="body" style="width: 99%;">
          <div class="overview-wrp">
            <div class="overview-item">
              <div class="title txt-2xl">{{$t('card_given')}}</div>
              <div class="number txt-2xl" v-if="c_today" @click="c_today=!c_today"><span>{{collectorGivenToday}} <i class="fas fa-sort pd-r-4"></i></span></div>
              <div class="range" v-if="c_today">{{$t('today')}}</div>
              <div class="number txt-2xl" v-if="!c_today" @click="c_today=!c_today"><span>{{collectorGivenTotal}} <i class="fas fa-sort pd-r-4"></i></span></div>
              <div class="range" v-if="!c_today">{{$t('total')}}</div>
            </div>
            <div class="overview-item">
              <div class="title txt-2xl text-short-m">{{$t('in_circulation')}}</div>
              <div class="number txt-2xl"><span>{{pointsInCirculation}} <currency-symbol></currency-symbol></span></div>
              <div class="range">{{$t('total')}}</div>
            </div>
            <div class="overview-item">
              <div class="title txt-2xl text-short-m">{{$t('bought')}}</div>
              <div class="number txt-2xl" v-if="a_today" @click="a_today=!a_today"><span>{{pointsAcceptToday}} <currency-symbol></currency-symbol> / {{pointsAcceptTodayValue}} <dollar></dollar> <i class="fas fa-sort pd-r-4"></i></span></div>
              <div class="number txt-2xl" v-if="!a_today" @click="a_today=!a_today"><span>{{pointsAcceptTotal}} <currency-symbol></currency-symbol> <i class="fas fa-sort pd-r-4"></i></span></div>
              <div class="range" v-if="a_today">{{$t('today')}}</div>
              <div class="range" v-if="!a_today">{{$t('total')}}</div>
            </div>
            <div class="overview-item">
              <div class="title txt-2xl text-short-m">{{$t('given')}}</div>
              <div class="number txt-2xl" v-if="s_today" @click="s_today=!s_today"><span>{{pointsGivenToday}} <currency-symbol></currency-symbol> / {{pointsGivenTodayValue}} <dollar></dollar> <i class="fas fa-sort pd-r-4"></i></span></div>
              <div class="number txt-2xl" v-if="!s_today" @click="s_today=!s_today"><span>{{pointsGivenTotal}} <currency-symbol></currency-symbol> <i class="fas fa-sort pd-r-4"></i></span></div>
              <div class="range" v-if="s_today">{{$t('today')}}</div>
              <div class="range" v-if="!s_today">{{$t('total')}}</div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('transfered')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <bar-chart :chart-data="pointsTransferMonth"></bar-chart>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('distributed')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <bar-chart :chart-data="pointsGiveMonth"></bar-chart>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('accept_dynamics')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <bar-chart :chart-data="pointsAcceptMonth"></bar-chart>
        </div>
      </div>
    </div>


  </div>
</div>
</template>

<script>
import {chartColors} from "@/support/helpers";
import BarChart from "@/components/STATIC/barChart";
import Dollar from "@/components/STATIC/dollar";
import CurrencySymbol from "@/components/STATIC/currencySymbol";

export default {
  name: "section-points",
  components: {CurrencySymbol, Dollar, BarChart},
  props:{
    CLOUD:Object,
  },
  data(){
    return {
        c_today:true,
        a_today:true,
        s_today:true,
        v_today:true,
    }
  },
  computed:{
    /*
* Points
*/
    pointsAcceptMonth(){
      let labels = this.CLOUD.collection.accept_month.labels.map(label=>{
        return this.$t('month_'+label);
      });
      return {
        labels,
        datasets: [{
          data:this.CLOUD.collection.accept_month.data ,
          backgroundColor: chartColors(),
        }]
      }
    },
    pointsGiveMonth(){
      let labels = this.CLOUD.collection.give_month.labels.map(label=>{
        return this.$t('month_'+label);
      });
      return {
        labels,
        datasets: [{
          data:this.CLOUD.collection.give_month.data ,
          backgroundColor: chartColors(),
        }]
      }
    },
    pointsTransferMonth(){
      let labels = this.CLOUD.collection.transfer_month.labels.map(label=>{
        return this.$t('month_'+label);
      });
      return {
        labels,
        datasets: [{
          data:this.CLOUD.collection.transfer_month.data ,
          backgroundColor: chartColors(),
        }]
      }
    },
    collectorGivenToday(){
      if(+this.CLOUD.card.today_collector_count >0){
        return '+'+this.CLOUD.card.today_collector_count;
      }
      return this.CLOUD.card.today_collector_count;
    },
    collectorGivenTotal(){
      return this.CLOUD.collection.total_collector_given;
    },
    pointsInCirculation(){
      return this.CLOUD.collection.total_in_circulation;
    },
    pointsAcceptTotal(){
      return this.CLOUD.collection.total_bought;
    },
    pointsAcceptToday(){
      return this.CLOUD.collection.today_buy_points_count;
    },
    pointsAcceptTodayValue(){
      return this.CLOUD.collection.today_buy_points_cap;
    },
    pointsGivenTotal(){
      return this.CLOUD.collection.total_points_given;
    },
    pointsGivenToday(){
      return this.CLOUD.collection.today_sell_points_value;
    },
    pointsGivenTodayValue(){
      return this.CLOUD.collection.today_sell_points_cap;
    }
    /*
    * End Points
    */
  }
}
</script>

<style scoped>

</style>
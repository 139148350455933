<template>
  <div class="input-group w-margin" :class="{ 'has-pretxt': pre.length >0, 'has-postxt': pre.length ==0 }">
    <div class="input-desc" :class="$t('font')">
      <label :for="name">
        <span v-if="!message">{{$t(label)}}</span>
        <span v-if="message" class="red-light-txt" style="font-size: 11px;">{{message}}</span>
        <span v-if="req" class="red-light-txt">*</span>
      </label>
    </div>
    <div v-if="pre.length >0" class="input-pre  txt-2xl">
      <span class="pof pd-r-2">{{pre}}</span>
      <currency-symbol v-if="preUnit=='Q'"></currency-symbol>
      <lari-symbol v-if="preUnit=='L'"></lari-symbol>
      <span v-if="preUnit.length>0"> =</span>
    </div>
    <div :class="{'yellow':unit==='cp','grey7':unit!=='cp','green':copied}" class="input-pos txt-xxl" id="copyEl" >
      <lari-symbol v-if="unit==='L'"></lari-symbol>
      <span v-if="unit==='%'" class="txt-md1"><i class="far fa-percent"></i></span>
      <currency-symbol v-if="unit==='Q'"></currency-symbol>
      <span @click="copy()" v-if="unit==='cp'" class="txt-md1"><i class="fal fa-copy"></i></span>
    </div>
    <div class="input-area" :class="setClass" v-on:click="$emit('clicked')">
      <slot></slot>
    </div>
  </div>


</template>
<script>
import lariSymbol from "./lariSymbol";
import currencySymbol from "./currencySymbol";

    export default {
        components:{
            currencySymbol,
            lariSymbol,
        },
        props:{
          pre:{
            default:'',
          },
          preUnit:{
            default:'',
          },
          unit:{
            default:'L',
          },
          label:{
            required:true,
          },
          req:{
            default:false
          },
          wMargin:{
            default:'w-margin',
          },
          setClass:{
            default:"",
          },
          name:{
            default:"",
          },
          message:{
            default:false,
          },
        },
        data(){
          return {
            copyDone:false,
            elId:'',
          }
        },

      computed:{
          copied(){
            return (this.copyDone && this.unit === 'cp');
          }
        },
        methods:{
          copy() {
            let val = document.getElementById('copyInput').value;

            navigator.clipboard.writeText(val).then(function() {
              document.getElementById('copyEl').className = "input-pos txt-xxl green";
            }, function(err) {
              console.error('Async: Could not copy text: ', err);
            });

          },


        },
        markDone(){
          this.copyDone = true;
        }


    }
</script>
<style scoped>
.has-pretxt {

  grid-template-columns: minmax(60px, 75px) minmax(40px, auto) 55px !important;
}
    .icon-defaults, .icon-defaults >svg {
        top: 0 !important;
    }
    .c-icon-defaults, .c-icon-defaults >svg {
        width: 22px !important;
        top: 0.23em !important;
    }
    .input-pos >.c-icon-defaults {
        top: 0 !important;
    }
textarea, input[type="text"], input[type="button"], input[type="submit"], input:not([type="radio"]):not([type="checkbox"]), input[type=text]:focus, input:focus, input:active {

  width: 100% !important;

}
</style>
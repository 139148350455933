import Collector from "@/apis/collector";
import {httpStatusActions} from "@/support/support";

export const _getAllDataFromCloud = ({commit})=> {

    return new Promise((resolve, reject) => {
        Collector.get().then(response => {
            httpStatusActions(response,true);
            commit('SET_CLOUD_DATA', response.data);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _updateCloud = ({commit},form)=> {

    return new Promise((resolve, reject) => {

        Collector.update(form).then(response => {
            httpStatusActions(response);
            commit('SET_CLOUD_DATA', form);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });



    });
}



<template>
  <div class="pd-t-10">
    <div class="list-group " v-for="(item,index) in data" :key="index+1">
      <div class="list-group-item list-group-item--addon" >
        <div class="list-pre red-light-txt txt-md1 t-left">
          <i class="fal grey3-txt pd-r-5 txt-lg" :class="{'fa-shopping-bag':item.type===1,'fa-coins':item.type===2}"></i>
        </div>
        <div class="list-head txt-md cursor" :class="$t('font')">
          <div class="flex-inline col-2 wd-100p j-c-space-between j-i-center a-i-center">
            <div class="flex-start row-2 wd-100p flex-wrap j-c-start g-r-gap-7" >
                <div><span>{{item.label}}</span></div>
                <div class="grey5-txt en txt-xs1 flex-inline column-gap4 flex-wrap j-c-start a-i-center wd-100p">
                  <div class="en"><i class="fal fa-calendar-check pd-r-2"></i>{{item.start_date}}</div>
                  <div class="en"><i class="fal fa-calendar-times pd-r-2"></i>{{item.end_date}}</div>
                </div>

            </div>
            <div class="flex-inline flex-start wd-100p">
              <div class="txt-2xl">
                <i class="fal fa-heart-rate"></i>
              </div>
              <div class="grid-row-2 j-i-start">
                <div class="txt-xs1">{{ $t('rate') }}</div>
                <div class="txt-m">
                  <span class="pd-r-1">
                    <span v-if="item.type===2" class="pof">1<i class="pd-l-2 pof points"></i> =</span>
                    {{item.rate}}
                  </span>
                  <span v-if="item.type===1">%</span>
                  <span v-if="item.type===2"><dollar></dollar></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-pos en-r txt-lg cursor flex-inline j-i-center a-i-center">
          <div class="flex-inline wd-100p flex-end" >
            <div>
              <input-button v-if="+acceptSpinner!==+item.id" :show-spinner="+rejectSpinner===+item.id" set-class="red-light mg-b-10 txt-xs1" icon="fal fa-times-circle" v-on:click.native="reject(item.id)" :label="$t('reject')"></input-button>
              <input-button v-if="+rejectSpinner!==+item.id" :show-spinner="+acceptSpinner===+item.id" set-class="green txt-xs1" icon="fal fa-check-circle"  @click.native="accept(item.id)" :label="$t('accept')"></input-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
* Note
*/


import InputButton from "@/components/STATIC/inputButton";
import Dollar from "@/components/STATIC/dollar";
import {mapActions} from "vuex";



export default {
  props: {
    data: {
      default() {
        return []
      }
    },
  },
  name: "accept_block_requests",
  components: {Dollar, InputButton},
  data(){
    return {
      showFooter:'',
      rejectSpinner:false,
      acceptSpinner:false,
    }
  },
  computed:{



  },
  methods:{
    ...mapActions('_partnership',['_acceptPartnership','_rejectPartnership']),
    accept(id) {
      this.acceptSpinner=id;
      return this._acceptPartnership({id:id}).then(()=>{
        this.acceptSpinner=false;
      })
    },
    reject(id){
      this.rejectSpinner=id;
      return this._rejectPartnership(id).then(()=>{
        this.rejectSpinner=false;
      });
    },
  }
}
</script>

<style scoped type="scss">

</style>
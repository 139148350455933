<template>
  <!-- Heading -->
  <div class="group grid-col-2 a-i-center pd-b-10 w-b-line">
    <div class="flex-start grey5-txt " :class="$t('font')">
      <div class="grid-row-2">
        <div><h1>{{$t('total')}}</h1></div>
        <div>{{$t('records')}} ({{page_records}}/{{total_records}})</div>
      </div>
    </div>
    <div class="flex-end">
      <div class="flex-center-self t-right">
        <div class="grid-row-2 t-center cursor" v-if="!showImportIcon" @click="toggleImportWindow()">
          <div class="txt-4xl indigo-txt"><i class="fal fa-file-excel"></i></div>
          <div :class="$t('font')" class="txt-xs1">{{$t('import')}}</div>
        </div>
        <div class="grid-row-2 t-center cursor" v-if="showImportIcon" @click="toggleImportWindow()">
          <div class="txt-4xl indigo-txt"><i class="fal fa-eye-slash"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "customerRightTop",
  data(){
    return {
      importIcon:true,
    }
  },
  mounted() {

  },
  computed:{
    ...mapState('_customers',['CLOUD']),
    ...mapGetters('_customers',['importState']),
    showImportIcon(){
      return this.importState;
    },
    total_head(){
      return this.CLOUD.TOTAL_HEAD;
    },
    total_sub(){
      return this.CLOUD.TOTAL_SUB;
    },
    total_records(){
      return this.CLOUD.TOTAL_RECORDS;
    },
    page_records(){
      return this.CLOUD.PER_PAGE_RECORDS;
    },
  },
  methods:{
    ...mapActions('_customers',['_toggleImport']),
    toggleImportWindow(){
      return this._toggleImport();
    }
  }
}
</script>

<style scoped>

</style>
import Api from "./Api";

// const END_POINT_company = 'company';
const END_POINT = 'company/setup/appdata';

// const END_POINT_employee = 'company';
// const END_POINT_card = 'company';

export default {
    get() {
        return  Api().get(`${END_POINT}/`);
    },
    pay(data) {
        return  Api().put(`${END_POINT}/pay`,data);
    },
    toggleSettings(data) {
        return  Api().put(`${END_POINT}/toggleSettings`,data);
    },
    updateProfile(data) {
        return  Api().put(`${END_POINT}/profile`,data);
    },
    getTransactions(filter) {
        return  Api().post(`${END_POINT}/transactions`,filter);
    },
}

import rootStore from '@/store/';

export const userNotifyWindow=(state=true)=>{
    if(!state){
        return rootStore.dispatch('notifyUserWindowClose',{ root: true });
    }
    return rootStore.dispatch('notifyUserWindowOpen',{ root: true });
}
export const userNotificationSent=()=>{
    return rootStore.dispatch('notifyUserWindowSent',{ root: true });
}
export const setNotifyUserId=(data)=>{
    return rootStore.dispatch('setNotifyUserId',data,{ root: true });
}


export default {
    namespaced: true,
    userNotificationSent,
    userNotifyWindow,
}
<template>
  <div class="agreement">
    <ul>მობილური აპლიკაციის მოხმარების შეთანხმება და  კონფიდენციალურობის პოლიტიკა

      <li>1. შპს "პორთფელი" („პორთფელი“, "ჩვენ") ცნობილია, რამდენად მნიშვნელოვანია ჩვენი კლიენტებისთვის კონფიდენციალურობა და გვსურს ნათელი იყოს, როგორ ვაგროვებთ, ვიყენებთ და გავცემთ და თქვენს ინფორმაციას.</li>

      <li>2. კონფიდენციალურობის პოლიტიკა ვრცელდება Portfel ის ყველა აპლიკაციაზე (ერთობლივად, ჩვენს "მომსახურებაზე"), მიუხედავად იმისა, ამ აპლიკაციისთვის კომპიუტერს იყენებთ, მობილური ტელეფონს, ტაბლეტს თუ მოწყობილობას.</li>

      <li>3. თქვენი და თქვენი მოწყობილობის მობილური ბანკინგის აპლიკაციაში დასარეგისტრირებლად ჩვენ ვიყენებთ თქვენი მოწყობილობის შესახებ ინფორმაციას, როგორიცაა: ოპერაციული სისტემის ვერსია, მოწყობილობის მოდელი, IMEI ნომერი და მოწყობილობის სხვა უნიკალური იდენტიფიკატორები.</li>

      <li>4. მობილური ბანკინგის აპლიკაციის მომსახურების გაუმჯობესებისათვის ბანკის მიერ შესაძლოა დამუშავდეს სხვა დამატებითი პერსონალური მონაცემები (არასავალდებულოდ), რომ გამოვიყენოთ შემდეგი სახის ინფორმაცია თქვენი მოწყობილობიდან: SMS აქტივობები, მიმდინარე ლოკაცია, კონტაქტები, ფოტოკამერა, მეხსიერების მოცულობა, მიკროფონი და ა.შ., რაც ექვემდებარება კლიენტის მხრიდან დამატებით თანხმობას. თანხმობები მოპოვებულ იქნება ამავე აპლიკაციის საშუალებით/ლოგებით.</li>

      <li>5. კლიენტი უფლებამოსილია უარი განუცხადოს ბანკს მოწყობილობიდან გარკვეული ტიპის ინფორმაციის გაცემაზე (SMS აქტივობები, მიმდინარე ლოკაცია, კონტაქტები, ფოტოკამერა, განხორციელებული ზარები, მეხსიერების მოცულობა და ა.შ.), მაგრამ ამან შესაძლოა გავლენა იქონიოს აპლიკაციის მეშვეობით კლიენტის მიერ გარკვეული სერვისების გამოყენებაზე.</li>

      <li>6. იმისათვის, რომ ავტომატურად შევავსოთ აპლიკაციაში შესაბამისი ველი საქართველოს ბანკის მხრიდან გამოგზავნილი ერთჯერადი კოდით, ჩვენ გვჭირდება ნებართვა თქვენი მობილური მოწყობილობის SMS სერვისზე. ერთჯერადი კოდი შესაძლოა დაგვჭირდეს როგორც რეგისტრაციისას ასევე აპლიკაციის გარკვეული სერვისებით სარგებლობისას. ჩვენ არ ვაგროვებთ ინფორმაციას SMS-ების ისტორიის შესახებ.</li>

      <li>7. ჩვენ ასევე ვაგროვებთ ინფორმაციას, რომელსაც ვიღებთ პირადად თქვენგან. მაგალითად. მომხმარებლის სარეგისტრაციო მონაცემებს, რომელიც საჭიროა თქვენი ან/და თქვენი მოწყობილობის მობილური ბანკინგის აპლიკაციაში დასარეგისტრირებლად, შემდგომში თქვენი საქმიანობის განსახორციელებლად.</li>

      <li>8. ჩვენ ვიყენებთ იმ ინფორმაციას (თქვენს შესახებ არსებულ სხვა ინფორმაციასთან ერთად), რომელსაც ჩვენ ვაგროვებთ თქვენს მიერ მოთხოვნილი სერვისების დასაკმაყოფილებლად. ამის მეშვეობით, ვიგებთ როგორ იყენებთ სხვადასხვა სერვისებს, რაც გვეხმარება ჩვენი გამოცდილების გაუმჯობესებაში.</li>

      <li>9. ჩვენ შეიძლება გავუზიაროთ ინფორმაცია:</li>

      <li>შვილობილ კომპანიებს - Inco.Solutions ჯგუფში შემავალ სხვა ორგანიზაციებს
        მომსახურების პროვაიდერებს - კომპანიებს, რომლებიც ეწევიან მომსახურებას პორთფელის აპლიკაციის საშუალებით.
        სამართალდამცავი ორგანოებს, საჭიროების შემთხვევაში მისი მომხმარებლების დასაცავად.</li>


      <li>10. რეკომენდაციები:</li>

      <li>არასოდეს გაუზიაროთ თქვენი მომხმარებლის სახელი და პაროლი ვინმეს.</li>
      <li>წაშალეთ მობილური ბანკინგის აპლიკაცია, თქვენი მობილური ტელეფონის სხვისთვის გადაცემის შემთხვევაში.</li>
      <li>თქვენი მომხმარებლის სარეგისტრაციო მონაცემების არასანქცირებული გამოყენების შესახებ ეჭვის არსებობის შემთხვევაში დაუყოვნებლივ დაგვიკავშირდით.</li>


      <li>კონკრეტული კითხვების შემთხვევაში, გთხოვთ, დაგვიკავშირდეთ:</li>
    </ul>
    <div class="cyrcle-button cyrcle-button-sm yellow  home-button" style="color:black !important;" v-on:click="$router.push({name:'register'})">
      <i class="far fa-chevron-left"></i>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.agreement {
  font-size:12px;
  color:black;
  background: white;
}
</style>
export const addNotification = ({ commit }, notification) => {
    commit('PUSH_NOTIFICATION', notification);
}

export const removeNotification = ({ commit }, notification) => {
    commit('REMOVE_NOTIFICATION', notification);
}
export const showLoading = ({ commit }) => {
    commit('SHOW_LOADING');
}
export const hideLoading = ({ commit }) => {
    commit('HIDE_LOADING');
}

export const loadingSpinner = ({ commit }, options) => {
    commit('LOADING_SPINNER',options);
}
export const resetLoadingSpinner = ({ commit }, options) => {
    commit('RESET_LOADING_SPINNER',options);
}
export const slideSwitch = ({ commit }, options) => {
    commit('SLIDE_SWITCH',options);
}
export const resetSlideSwitch = ({ commit }) => {
    commit('RESET_SLIDE_SWITCH');
}
export const notifyUserWindowOpen = ({ commit }) => {
    commit('NOTIFY_USER_WINDOW_OPEN');
}
export const notifyUserWindowClose = ({ commit }) => {
    commit('NOTIFY_USER_WINDOW_CLOSE');
}
export const notifyUserWindowSent = ({ commit }) => {
    commit('NOTIFY_USER_WINDOW_SENT');
}
export const setNotifyUserId = ({ commit },data) => {
    commit('SET_NOTIFY_USERID',data);
}

export const notifySuccess = ({ commit }, response) => {

    let notificationMessage = '';
    let rd = response.data;
    let rdm = response.data.message;

    if(rd!==undefined){
        if(rdm !==undefined && rdm.length !== undefined && rdm.length>=2){
            notificationMessage = rdm;
        }
    }

    let notification = {
        type: 'success',
        message: notificationMessage
    }
    commit('PUSH_NOTIFICATION', notification);
}
export const notifyError = ({ commit }, error) => {

    let notificationMessage = '';

    let rd = error.response.data;
    let rdm = error.response.data.message;

    if(rd!==undefined){
        if(rdm !==undefined && rdm.length !== undefined && rdm.length>=2){
            notificationMessage = rdm;
        }
    }


    let notification = {
        type: 'error',
        message: notificationMessage
    }
    commit('PUSH_NOTIFICATION', notification);
}
/*
*
*/
export const successMessage = ({ commit }, messageText)=>{
    let notification = {
        type: 'success',
        message: messageText
    }
    commit('PUSH_NOTIFICATION', notification);
}
/*
*
*/
export const errorMessage = ({ commit }, messageText)=>{
    let notification = {
        type: 'error',
        message: messageText
    }
    commit('PUSH_NOTIFICATION', notification);
}
export const warnMessage = ({ commit }, messageText)=>{
    let notification = {
        type: 'warning',
        message: messageText
    }
    commit('PUSH_NOTIFICATION', notification);
}
export const infoMessage = ({ commit }, messageText)=>{
    let notification = {
        type: 'info',
        message: messageText
    }
    commit('PUSH_NOTIFICATION', notification);
}
export const updateMessage = ({ commit },updateData)=>{
    let notification = {
        type: 'update',
        data:updateData
    }
    commit('PUSH_NOTIFICATION', notification);
}
export const setToken = ({ commit },token) => {
    commit('SET_TOKEN',token);
}

export const applicationWindowOpen = ({ commit }) => {
    commit('APPLICATION_WINDOW_OPEN');
 }
export const applicationWindowClose = ({ commit }) => {
    commit('APPLICATION_WINDOW_CLOSE');
}
export const applicationWindowStatus = ({ commit }) => {
    commit('APPLICATION_WINDOW_STATUS');
}
export const applicationWindowSetData = ({ commit },data) => {
    commit('APPLICATION_WINDOW_SET_DATA',data);
}
export const applicationWindowSetId = ({ commit },id) => {
    commit('APPLICATION_WINDOW_SET_ID',id);
}
<template>
  <div>
    <div class="heading-group bg">
      <h6 class="txt-3xl mg-t-20 pd-l-8 txt-uppercase" :class="$t('font_b')">{{$t('service')}}</h6>
    </div>
    <div class="split-view">
      <div class="view-list pd-20" :class="$t('font')">
        <div class="space-div--20"></div>
        <h4 class="pd-5 grey5-txt">{{$t('service_pakcage_info')}}</h4>
        <div class="w-b-line mg-5 txt-lg1"></div>
        <div class="space-div--20"></div>
        <div class="grid-col-2 radius grey7 w-df-pad g-r-gap-10 pd-l-20">

          <div class="en-b">{{$t('package')}}</div>
          <div>{{ productName }}</div>

          <div class="en-b">{{$t('price')}}</div>
          <div>
            <span class="txt-lg1 pof pd-r-4" v-if="!isTrail">{{ price }} <lari-symbol></lari-symbol></span>
            <span class="txt-lg1 pof pd-r-4" v-if="isTrail">{{ $t('free') }}</span>
          </div>

          <div class="en-b">{{$t('start_date')}}</div>
          <div>{{ startDate }}</div>

          <div class="en-b">{{$t('next_billing')}}</div>
          <div>{{ billingDate }}</div>

          <div v-if="isTrail" class="en-b">{{$t('trail_days_left')}}</div>
          <div v-if="isTrail">{{ trailDaysLeft }}</div>
          <div v-if="!isTrail" class="en-b">{{$t('days_left')}}</div>
          <div v-if="!isTrail">{{ trailDaysLeft }}</div>

          <div class="en-b">{{$t('status')}}</div>
          <div>
            <i :class="{'green-txt txt-md1':!canceledService}" class="fas fa-check-circle" v-show="!canceledService"></i>
            <i :class="{'red-txt txt-md1':canceledService}" class="fas fa-pause-circle" v-show="canceledService"></i>
            <span class="pd-2 pd-t-4 pd-r-10 grey3-txt">{{ subscriptionStatusText }}</span>
          </div>

          <div class="en-b">{{$t('payment_method')}}</div>
          <div>
             <span class="pd-t-4 pd-r-10 grey3-txt" :class="{'red-txt':!hasPaymentMethod}"  >
              <i class="fab fa-cc-visa" :class="{'red-txt':!hasPaymentMethod,'indigo-txt':hasPaymentMethod}"></i>
              {{ hasPaymentMethodText }} <a class="pd-l-5" v-if="!hasPaymentMethod" :href="stripeDashboardLink" target="_blank">{{$t('add_card')}}</a>
            </span>
          </div>

          <div class="en-b" v-if="stripeDashboardLink">{{$t('manage_invoices')}}</div>
          <div>
            <i class="fas fa-receipt blue-txt"></i> <a :href="stripeDashboardLink" target="_blank">{{$t('invoice_dashboard')}}</a>
          </div>

        </div>


        <div class="space-div--40"></div>
        <div class="flex-inline j-i-center a-i-center col-4 height-auto wd-100p" :class="$t('font')" style="border-radius: 12px;overflow: hidden;">
          <div v-on:click="$router.push('selectPackage')" class="flex-inline j-c-center a-i-center yellow t-center wd-100p">
            <input-button icon="fal fa-box-full" :label="$t('change_package')" set-class=" cursor txt-md height-auto"></input-button>
          </div>
          <div v-on:click="retryPayment()" class="blue t-center wd-100p flex-inline j-c-center a-i-center">
            <input-button :show-spinner="paymentSpinner" icon="fal fa-receipt" :label="$t('pay_invoices')" set-class="t-white-txt cursor txt-md height-auto"></input-button>
          </div>
<!--          <div v-on:click="getAddPaymentCardToken()" class="indigo t-center wd-100p ">-->
<!--            <input-button :show-spinner="addCardSpinner" icon="fab fa-cc-visa" :label="$t('add_card')" set-class="t-white-txt cursor txt-md height-auto"></input-button>-->
<!--          </div>-->


          <div v-if="isTrail" class="red t-center wd-100p flex-inline j-c-center a-i-center" v-on:click="endTrail()">
            <input-button :show-spinner="trailEndSpinner" icon="fal fa-times-circle" :label="$t('end_trail')" set-class=" cursor txt-md height-auto t-white-txt"></input-button>
          </div>
          <div v-if="!canceledService && !isTrail" class="grey5 t-center wd-100p flex-inline j-c-center a-i-center" v-on:click="cancelService()">
            <input-button :show-spinner="cancelSpinner" icon="fal fa-pause-circle" :label="$t('cancel_service')" set-class=" cursor txt-md height-auto"></input-button>
          </div>
          <div v-if="canceledService && !isTrail" class="green t-center wd-100p flex-inline j-c-center a-i-center" v-on:click="resumeService()">
            <input-button :show-spinner="cancelSpinner"  icon="fal fa-pause-circle" :label="$t('resume_service')" set-class="cursor txt-md height-auto t-white-txt"></input-button>
          </div>
        </div>
        <payment-card-form v-if="addCardToken" :client-secret="addCardToken" v-on:onSuccess="hideAddCard()"></payment-card-form>

        <div class="space-div--40"></div>
        <div class="space-div--40"></div>
        <div class="space-div--40"></div>
        <div class="design_txt grid-col-2" v-if="currentPackage.stats_count>0">
          <div><i class="fal fa-info-circle blue-txt txt-2xl mg-r-10"></i></div>
          <div>{{$t('you_have_selected_ultimate_package_txt')}} <a  class="blue-txt" href="mailto:design@portfel.co.uk?subject=Custom card design">design@portfel.co.uk</a></div>
        </div>
        <div class="space-div--40" v-if="currentPackage.stats_count>0"></div>
        <div class="space-div--40" v-if="currentPackage.stats_count>0"></div>
        <div class="space-div--40" v-if="currentPackage.stats_count>0"></div>

        <h6 class="grey5-txt">{{$t('agreements_polices')}}</h6>
        <div class="w-b-line pd-t-10"></div>
        <p><a class="blue-txt" :class="$t('font')" href="https://portfel.co.uk/manager-privacy-policy?ref=service-page-manager">{{ $t('privacy_policy') }}</a></p>
        <p><a class="blue-txt" :class="$t('font')" href="https://portfel.co.uk/manager-service-agreement?ref=service-page-manager">{{ $t('service_agreement') }}</a></p>
        <p><a class="blue-txt" :class="$t('font')" href="https://portfel.co.uk/manager-refund-policy?ref=service-page-manager">{{ $t('refund_policy') }}</a></p>
        <p><a class="blue-txt" :class="$t('font')" href="https://portfel.co.uk/manager-cookie-policy?ref=service-page-manager">{{ $t('cookie_policy') }}</a></p>
      </div>
      <div class="view-action pd-20" :class="$t('font')">
        <div class="space-div--20"></div>
        <h6 class="pd-5 grey5-txt">{{$t('limits_used')}}</h6>
        <div class="w-b-line pd-t-10"></div>
        <div class="space-div--20"></div>
        <package-info v-if="currentPackage"
                      :product="currentPackage"
                      :balance="balance"
                      :show-select-button='false'
        ></package-info>
      </div>
    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>
import launchpad from "@/components/launchpad";
import {mapActions, mapState} from 'vuex';
import PaymentCardForm from "@/components/subscription/Payments/Stripe/Static/PaymentCardForm";
import payment from "@/apis/payment";
import InputButton from "@/components/STATIC/inputButton";
import PackageInfo from "@/components/subscription/Static/PackageInfo";
import support from "@/support/support";
import lariSymbol from "@/components/STATIC/lariSymbol";
import {errorMessage, warningMessage} from "@/support/helpers";

export default {
  components:{

    InputButton,
    PaymentCardForm,
    PackageInfo,
    launchpad,
    lariSymbol
  },
  data(){
    return {
      addCardToken:false,
      showSpinner:true,
      addCardSpinner:false,
      cancelSpinner:false,
      stripeDashboardLink:false,
      selectedTutorial:1,
      paymentSpinner:false,
      trailEndSpinner:false,
      trailEndMessage:false,
    }
  },
  mounted(){
    this._getAllDataFromCloud();
    support.setSubscriptionStatus();
    this.getStripeDashboardLink();
    this.$store.dispatch('hideLoading');



  },
  computed:{
    ...mapState('_general',['CLOUD']),

    hasPaymentMethodAttached(){
      return this.CLOUD.SETTINGS.has_payment_attached;
    },
    merchantName(){
      return this.CLOUD.PROFILE.merchant_label;
    },
    productName(){
      let product = this.finProduct(this.CLOUD.SUBSCRIPTIONS.product_id);
      if(product){
        return product.label;
      }
      return false;
    },
    startDate(){
      return this.CLOUD.SUBSCRIPTIONS.start;
    },
    billingDate(){
      return this.CLOUD.SUBSCRIPTIONS.end;
    },
    trailDaysLeft(){
      return this.CLOUD.SUBSCRIPTIONS.days_left;
    },
    isTrail(){
      return this.CLOUD.SUBSCRIPTIONS.trail;
    },
    subscriptionStatus() {
      return this.CLOUD.SUBSCRIPTIONS.status;
    },
    subscriptionStatusText(){
      return this.$t(this.subscriptionStatus);
    },
    hasPaymentMethod(){
      return this.CLOUD.SETTINGS.has_payment_attached;
    },
    hasPaymentMethodText(){
      if(!this.hasPaymentMethod){
        return this.$t('no');
      }
      return this.$t('yes');

    },
    currentPackage(){
      let product = this.finProduct(this.CLOUD.SUBSCRIPTIONS.product_id);
      return support.returnIfExpected(product,'object');
    },
    balance(){
      return this.CLOUD.BALANCE;
    },
    canceledService(){
      return (this.subscriptionStatus !== 'active' && this.subscriptionStatus !== 'trialing');
    },
    price(){
      return this.currentPackage.price;
    }
  },
  methods:{
    ...mapActions('_general',['_getAllDataFromCloud']),

    getAddPaymentCardToken(){
      this.addCardSpinner = true;
      payment.addPaymentCardToken().then(result=>{
        this.addCardToken = result.data.clientSecret;
      }).catch(error=>{
        this.$store.dispatch('addNotification',{type:'error',message:error.message});
      });
    },
    hideAddCard(){
      return window.location.reload(true);
    },
    finProduct(id){

      if(this.CLOUD.PRODUCTS !==undefined){
        let __PRODUCT =  this.CLOUD.PRODUCTS.filter(product=>{
          return product.id ==id;
        });

        if(__PRODUCT[0] !==undefined){
          return this.setProduct = __PRODUCT[0];
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    endTrail(){

      if(!this.trailEndMessage){
        this.trailEndMessage = true;
        return warningMessage(this.$t('trail_will_end_attach_card_txt'));
      }
      if(!this.hasPaymentMethod){
        return errorMessage(this.$t('add_payment_card_to_end_trial_txt'));
      }
      this.trailEndSpinner = true;
      payment.cancelService().then(()=>{
        payment.resumeService().then(()=>{
          return  window.location.reload(true);
        }).catch(error=>{
          this.$store.dispatch('addNotification',{type:'error',message:error.message});
        });
      }).catch((error)=>{
          errorMessage(error.message);
      });

    },
    cancelService(){
      this.cancelSpinner = true;
      payment.cancelService().then(()=>{
        return  window.location.reload(true);
      }).catch(error=>{
        this.$store.dispatch('addNotification',{type:'error',message:error.message});
      });
    },
    resumeService(){
      this.cancelSpinner = true;
      payment.resumeService().then(()=>{
        return  window.location.reload(true);
      }).catch(error=>{
        this.$store.dispatch('addNotification',{type:'error',message:error.message});
      });
    },
    retryPayment(){
      this.paymentSpinner = true;
      payment.retryPayment().then(()=>{
        return  window.location.reload(true);
      }).catch(error=>{
        this.$store.dispatch('warnMessage',error.response.data.message);
        this.paymentSpinner = false;
      });
    },
    getStripeDashboardLink(){
      let backUrl = {url:process.env.VUE_APP_URL};
      payment.getSubscriptionSessionUrl(backUrl).then(result=>{
        this.stripeDashboardLink = result.data.url;
      }).catch(error=>{
        this.stripeDashboardLink = false;
        this.$store.dispatch('addNotification',{type:'error',message:error.message});
      });
    }

  }
}
</script>

<style scoped>
.package_info {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: calc(18px + 1vw);
}
/*.tutorial-container{*/
/*  display: flex;*/
/*  align-content: center;*/
/*  flex-wrap: wrap;*/
/*}*/

/*.tutorial-item{*/
/*  margin-top: 4px;*/
/*  justify-content: center;*/
/*  width: 100%;*/
/*  display: block;*/
/*  height: 40px;*/
/*  overflow: hidden;*/
/*}*/
/*.tut-selected{*/
/*  height: 400px;*/
/*  overflow: hidden;*/
/*}*/
/*.tut-selected>div:first-child{*/
/*  background-color: var(--yellow);*/
/*  color: var(--t-black);*/
/*}*/
/*.tutorial-title {*/
/*  padding-left: 18px;*/
/*  padding-top: 10px;*/
/*  height: 40px;*/
/*  background-color: var(--indigo);*/
/*  font-size: var(--txt-lg);*/
/*  color: var(--t-white);*/
/*  display: grid;*/
/*  grid-template-areas: "v-title v-icon";*/
/*  grid-template-columns: 0.9fr 0.1fr;*/
/*}*/
/*.vtitle{*/
/*  grid-area: v-title;*/
/*}*/
/*.vicon{*/
/*  grid-area: v-icon;*/
/*  text-align: right;*/
/*  padding-right: 18px;*/
/*}*/
/*.tutorial-video {*/
/*  background-color: var(--indigo);*/
/*  height: 100%;*/
/*  width: 100%;*/
/*}*/
/*.slide-fade-enter-active {*/
/*  transition: all .4s cubic-bezier(.1, 0.3, 0.5, 0.8);*/
/*}*/
/*.slide-fade-leave-active {*/
/*  transition: all .4s cubic-bezier(.1, 0.3, 0.5, 0.8);*/
/*}*/
/*.slide-fade-enter, .slide-fade-leave-to*/
/*  !* .slide-fade-leave-active below version 2.1.8 *! {*/
/*  transform: translateY(10px);*/
/*  opacity: 0;*/
/*}*/
.end-trail {
  display: inline-flex;
  padding: 1px 10px;
  background-color: var(--red-light);
  border-radius: var(--radius);
  color: white;
  font-weight: 500;
  box-shadow: 0 3px 6px rgba(0,0,0,.06);
}
.design_txt {
  background-color: var(--grey7);
  padding: 20px 30px;
  border-radius: var(--radius);
}
</style>
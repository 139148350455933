<template>
<div id="stripe-card-payment" class="grey7">
  <div v-if="!token" class="pd-20  t-center"><i class="fal fa-exclamation-triangle red-light-txt txt-3xl"></i><p :class="$t('font')" class="grey3-txt txt-md"> {{$t('some_error_refresh')}}</p></div>
  <form id="payment-form" v-show="token">

    <div class="input-group w-margin">
      <div class="input-area card-input-style" ><input type="text" :class="$t('font')" class="txt-md" :placeholder="$t('name_on_card')" v-model="nameOnCard"></div>
    </div>

    <div id="card-element" class="pd-l-10 pd-r-10">
      <!-- Elements will create input elements here -->
    </div>

    <!-- We'll put the error messages in this element -->
    <div id="card-element-errors" role="alert" class="en red-light-txt t-center txt-md">{{displayError}}</div>

    <div class="container div t-center pd-t-20 ">
      <div :id="'send-container'" v-if="processCustomerCard">
        <button :class="$t('font')" v-on:click.prevent="processCardPayment()" class="btn blue height-auto width-auto pd-10">{{$t('buy')}}</button>
      </div>
      <div :id="'send-container'" v-if="attachCustomerCard">
        <button :class="$t('font')" v-on:click.prevent="attachCardToCustomer()" class="btn blue height-auto height-auto width-auto pd-10"><spinner v-if="showSpinner"></spinner> {{$t('add_card')}}</button>
      </div>
      <div class="space-div--40"></div>
    </div>

  </form>
</div>
</template>

<script>
import payment from "@/apis/payment";
import Spinner from "@/components/STATIC/spinner";

export default {
  props:{
    processCustomerCard:{
      default:false,
    },
    attachCustomerCard:{
      default:true,
    },
    clientSecret:{
      type:String,
      required:true,
    }
  },
  components:{
    Spinner

  },
  data(){
    return {
      showSpinner:'',
      stripe:'',
      elements:'',
      card:'',
      token:'', // Client Secret
      nameOnCard:'',
      sendAndChargeNewCardAction:false,
      style:{
        base: {
          border: '1px solid #D8D8D8',
          borderRadius: '4px',
          borderBottom: '1px solid var(--grey3)',
          color: "#000",
        },
        invalid:{}
      },
      errors:'',
    }
  },
  mounted() {
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', { style: this.style });
    this.card.mount('#card-element');
    this.token = this.clientSecret;
  },
  watch:{
    clientSecret:function (newVal,oldVal){
      if(newVal!==oldVal){
        this.token = '';
        return this.token = newVal;
      }
    }
  },
  computed:{
    cardPayloadData(){
      return { payment_method: { card: this.card, billing_details: { name: this.nameOnCard,},}};
    },
    displayError(){
      return this.errors;
    }

  },
  methods:{
       processCardPayment(){

         this.stripe.confirmCardPayment(this.token,this.cardPayloadData).then(result=>{
              if(result.error){
                return this.onError(result.error);
              }
              return (result.paymentIntent.status === 'succeeded') ? this.onSuccess(result) : false;

         });
       },

       attachCardToCustomer(){
         if(this.nameOnCard.length<=0){
           return this.errors = this.$t('name_on_card');
         }
         this.showSpinner = true;
         this.stripe.confirmCardSetup(this.token,this.cardPayloadData).then(result=>{
           this.showSpinner = false;
           if(result.error){
             this.showSpinner = false;
             return this.onError(result.error);
           }
           return (result.setupIntent.status === 'succeeded') ? this.onSuccess(result.setupIntent) : false;

         });

       },

       onSuccess(success){
          this.sendAndChargeNewCard(success.payment_method);
       },

       onError(error){
          this.$emit('onError',error);
          this.errors = error.message;

       },

       sendAndChargeNewCard(paymentCardId){
         payment.paymentCardAddSuccess({paymentCardId:paymentCardId}).then(()=>{
                this.$store.dispatch('addNotification',{type:'success'});
                this.$emit('onSuccess',paymentCardId);
         }).catch(error=>{
           this.$store.dispatch('addNotification',{type:'error',message:error.message});

         });
       }
  }
}
</script>

<style scoped>
.card-input-style {
  height: 21px !important;
  border-bottom: 1px solid var(--grey3) !important;
  border-radius: 0 !important;
}
</style>

<template>
   <div class="register-form-wrap">
    <div class="container h-overflow" style="width: 80vw; max-width:500px; ">
      <form>
        <div class="heading-group w-button w-b-line pd-b-30">
          <h6 :class="$t('font_b')">{{$t('user_registration')}}</h6>
          <div class="head-button txt-5xl"></div>
        </div>
        <div :class="$t('font')" style="min-width: 320px; top:300px;">
          <div class="space-div--10"></div>

          <div class="group pd-10 radius mg-t-20" :class="{'red-border':!typeValid}">
            <div class="flex-center j-i-center">
              <div @click="form.type=1" class="en flex-pd pd-6 pd-r-14 pd-l-14 txt-2xl radius  a-i-center j-i-center" :class="{'selected':form.type===1,'grey0-txt':form.type===2 }"><i class="fad fa-briefcase pd-r-4"></i> <span class="txt-xl">{{$t('company')}}</span></div>
              <div @click="form.type=2" class="en flex-pd pd-6 pd-r-14 pd-l-14 txt-2xl radius  a-i-center j-i-center" :class="{'selected':form.type===2,'grey0-txt':form.type===1 }"><i class="fad fa-user-visor pd-r-4"></i> <span class="txt-xl">{{$t('influencer')}}</span></div>
            </div>
          </div>
          <div class="space-div--10"></div>

          <div class="input-group w-margin">
            <div class="input-desc" :class="$t('font')">{{$t('email')}} <span class="red-light-txt">*</span>
              <span class="red-light-txt" v-if="errors.email">{{ errors.email[0] }}</span>
            </div>
            <div class="input-area" :class="{'red-border':!emailValid,'green-border':emailValid}" >
              <input type="text" autocomplete="email" v-model="form.email" ></div>
          </div>


          <div class="input-group mg-t-10 w-margin">
            <div class="input-desc" :class="$t('font')">{{$t('password')}} <span class="red-light-txt">*</span>
              <span class="red-light-txt" v-if="errors.password">{{ errors.password[0] }}</span>
              <span class="red-light-txt" v-if="!passwordValid">{{$t('weak_password')}}</span>

            </div>
            <div class="input-area" :class="{'red-border':!passwordValid,'green-border':passwordValid}" >
              <input type="password" autocomplete="new-password" v-model="form.password" ></div>
          </div>


          <div class="input-group mg-t-10 w-margin">
            <div class="input-desc" :class="$t('font')">{{$t('password_confirmation')}}<span class="red-light-txt">*</span>
              <span class="red-light-txt" v-if="errors.password_confirmation">{{ errors.password_confirmation[0] }}</span>
            </div>
            <div class="input-area" :class="{'red-border':!passwordConfirmationValid,'green-border':passwordConfirmationValid}">
              <input type="password" autocomplete="new-password" v-model="form.password_confirmation" ></div>
          </div>
          <div class="space-div--40 w-b-line"></div>

          <div class="input-group w-margin">
            <div class="input-desc " :class="$t('font')">{{$t('first_name')}} <span class="red-light-txt">*</span>
              <span class="red-light-txt" v-if="errors.first_name">{{ errors.first_name[0] }}</span>
            </div>
            <div class="input-area" :class="{'red-border':!firstNameValid,'green-border':passwordValid}">
              <input type="text" autocomplete="given-name" v-model="form.first_name" ></div>
          </div>


          <div class="input-group w-margin">
            <div class="input-desc" :class="$t('font')">{{$t('last_name')}} <span class="red-light-txt">*</span>
              <span class="red-light-txt" v-if="errors.last_name">{{ errors.last_name[0] }}</span>
            </div>
            <div class="input-area" :class="{'red-border':!lastNameValid,'green-border':lastNameValid}">
              <input type="text" autocomplete="family-name" v-model="form.last_name" ></div>
          </div>



          <div class="group w-margin" v-if="country">
             <span class="phone-input-desc" :class="$t('font')">{{$t('phone')}}<span v-if="!phoneVerified" class="red-light-txt pd-l-2">*</span> <i v-if="phoneVerified" class="fal fa-check green-txt txt-md1"></i>
               <span class="red-light-txt pd-l-4" v-if="errors.phone">{{ errors.phone[0] }}</span>
             </span>
            <vue-tel-input :disabled='phoneVerified' :styleClasses="getStyle" v-model="phone" @validate="isValid" :defaultCountry="getCountry" :inputOptions="inputOptions" :dropdownOptions="dropdownOptions"></vue-tel-input>
            <div class="register-confirmButton" v-if="phoneValid && !phoneVerified" @click="validatePhoneNumber()"><spinner v-if="showCheckNumberSpinner"></spinner><span v-if="!showCheckNumberSpinner">{{$t('verify')}}</span></div>
          </div>




          <div class="space-div--40 w-b-line"></div>

          <div class="input-group w-margin" v-if="+form.type>0">
            <div class="input-desc" :class="$t('font')">
              <span v-if="+form.type===1">{{$t('company_name')}}</span>
              <span v-if="+form.type===2">{{$t('brand_name')}}</span>
              <span class="red-light-txt">*</span>
              <span class="red-light-txt" v-if="errors.companyName">{{ errors.companyName[0] }}</span>
            </div>
            <div class="input-area" :class="{'red-border':!companyNameValid,'green-border':companyNameValid}">
              <input type="text" autocomplete="family-name" v-model="form.companyName" ></div>
          </div>


          <div class="input-group w-margin" v-if="+form.type===1">
            <div class="input-desc" :class="$t('font')">{{$t('company_id')}} <span class="red-light-txt">*</span>
              <span class="red-light-txt" v-if="errors.reg_id">{{ errors.reg_id[0] }}</span>
            </div>
            <div class="input-area" :class="{'red-border':!regIdValid,'green-border':regIdValid}">
              <input type="text" autocomplete="personal-id" v-model="form.reg_id" ></div>
          </div>


          <div class="input-group w-margin">
            <div class="input-desc" :class="$t('font')">{{$t('website')}} <span class="red-light-txt">*</span>
              <span class="red-light-txt" v-if="errors.address">{{ errors.address[0] }}</span>
            </div>
            <div class="input-area" :class="{'red-border':!addressValid,'green-border':addressValid}">
              <input type="text" autocomplete="family-name" v-model="form.address" ></div>
          </div>

          <div class="space-div--40 w-b-line" ></div>


          <div class="group">
            <div class="user-agreement">
              <div>
                <i class="fal fa-circle checkbox txt-lg1" v-if="!agree" v-on:click="agree=!agree"></i>
                <i class="fal fa-check-circle checkbox green-txt txt-lg1" v-if="agree" v-on:click="agree=!agree"></i>
                <a :href="'https://portfel.co.uk/manager-privacy-policy?lang='+lang" target="_blank" class="txt-xs1 blue-txt txt-underline">{{$t('agreement_privacy')}}</a>
              </div>
              <div  class="confirmation_text">
                {{$t('agreement_confirmed')}}
              </div>
            </div>
          </div>

          <div class="group">
            <div class="user-agreement">
              <div>
                <i class="fal fa-circle checkbox txt-lg1" v-if="!agree_eula" v-on:click="agree_eula=!agree_eula"></i>
                <i class="fal fa-check-circle checkbox green-txt txt-lg1" v-if="agree_eula" v-on:click="agree_eula=!agree_eula"></i>
                <a :href="'https://portfel.co.uk/manager-eula?lang='+lang" target="_blank" class="txt-xs1 blue-txt txt-underline">{{$t('agreement_eula')}}</a>
              </div>
              <div  class="confirmation_text">
                {{$t('agreement_confirmed')}}
              </div>
            </div>
          </div>

          <div class="flex-center mg-t-10 mg-b-10">
            <div class="mg-b-10">
              <button class="btn btn-md t-black-txt mg-t-30" :class="{'yellow':canSubmit,'grey7':!canSubmit}" @click.prevent="register" >
                <span :class="$t('font_b')">{{$t('register')}}</span>
              </button>
            </div>
          </div>


        </div>
      </form>
      <div class="cyrcle-button cyrcle-button-sm yellow  home-button" style="color:black !important;" v-on:click="$router.push({name:'login'})">
        <i class="far fa-chevron-left"></i>
      </div>
    </div>
   </div>

</template>
<script>

// import support from "../../../support/support";
import User from "../apis/user";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import userApi from "@/apis/user";
import Spinner from "@/components/STATIC/spinner";
import {loading} from "@/support/helpers";
import support, {getLang, validate,getDomain} from "@/support/support";




export default {
  components:{
    Spinner,
    VueTelInput

  },
  data(){
    return {
      lang:'',
      agree:false,
      agree_eula:false,
      country:'',
      phoneIsValid:false,
      phoneVerified:false,
      phone:'',
      verificationCodeSent:false,

      showCheckNumberSpinner:false,
      codeVerifyForm:{
        phone:'',
        code:'',
      },
      inputOptions:{
        placeholder:this.$t('enter_mobile_phone'),
        showDialCode:true,
      },
      dropdownOptions:{
        width:'80vw',
        showFlags:true,
        showSearchBox:false,
      },
      form:{
        email:'',
        password:'',
        first_name:'',
        last_name:'',
        personal_id:'',
        phone:'',
        password_confirmation:'',
        companyName:'',
        reg_id:'',
        address:'',
        type:'',
        country:'',

        // device_log:support.deviceLog(),
      },
      errors: []

    }
  },
  mounted() {

    this.getIpInfo();
    this.lang = getLang();
    support.setColorMode();


  },
  computed:{
    getStyle(){
      if(this.phoneValid && !this.phoneVerified){
        return 'register-buttonSpace';
      }
      return '';
    },
    getCountry(){
      return this.country;
    },
    phoneValid(){
      return this.phoneIsValid;
    },
    codeValid(){
      return (this.codeVerifyForm.code.length ===6);
    },
    emailValid(){
      return validate(this.form.email,'email')
    },
    passwordValid(){
      return validate(this.form.password,'password')
    },
    passwordConfirmationValid(){
      if(this.form.password !== this.form.password_confirmation){
        return false;
      }else{
        return true;
      }
    },
    firstNameValid(){
      return validate(this.form.first_name,'min:char');
    },
    lastNameValid(){
      return validate(this.form.last_name,'min:char');
    },
    companyNameValid(){
      return validate(this.form.companyName,'min:char');
    },
    addressValid(){
      return validate(this.form.address,'website');
    },
    regIdValid(){
      return validate(this.form.reg_id,'min:char');
    },
    typeValid(){
      return (+this.form.type>0);
    },
    required(){
      //Array of Fileds
      let fileds =
          [
            'email',
            'password',
            'password_confirmation',
            'first_name',
            'last_name',
            'phone',
            'reg_id',
            'companyName',
            'address',
            'type',
          ];
      if(+this.form.type===2){
        fileds =
            [
              'email',
              'password',
              'password_confirmation',
              'first_name',
              'last_name',
              'phone',
              'companyName',
              'address',
              'type',
            ];
      }
      /* Mark Faild as Faild */
      let result = fileds.map(filed=>{
        if(this.form[filed].length <=0){
          return 'fail';
        }else {
          return true;
        }
      });
      /* If contains Faild attr */
      let found = result.find(element => element == 'fail');
      /* Check Result, if does not contain fail means all pass */
      if(found ===undefined){
        return true;
      }else{
        return false;
      }

    },
    canSubmit() {
      if(!this.phoneValid){
        return false;
      }
      if(!this.phoneVerified){
        return false;
      }
      return (+this.form.type===1)
             ?(this.agree && this.agree_eula && this.addressValid && this.regIdValid && this.companyNameValid  && this.required && this.emailValid && this.passwordValid && this.firstNameValid && this.lastNameValid)
             :(this.agree && this.agree_eula && this.addressValid && this.required && this.companyNameValid && this.emailValid && this.passwordValid && this.firstNameValid && this.lastNameValid);
    }
  },

  methods:{
    isValid(e){
      this.phoneIsValid = e.valid;
      this.form.phone = e.number;
      if(e.countryCode !==undefined){
        this.country = e.countryCode;
        this.form.country =e.countryCode;
      }
    },
    getIpInfo(){
      let self = this;
      fetch('https://ipinfo.io/json?token=3dca986f555bc1', { headers: { 'Accept': 'application/json' }})
          .then((resp) => resp.json())
          .catch(() => {
            loading(false);
            self.country = getDomain();
          })
          .then((resp) => {
            loading(false);
            self.country=resp.country;
          });
    },
    validatePhoneNumber(){
      this.showCheckNumberSpinner = true;
      this.errors = [];
      let phone = this.phone;
      userApi.validateNumber({phone})
          .then(()=>{
              this.form.phone = phone;
              this.phoneIsValid = true;
              this.phoneVerified = true;
              return this.showCheckNumberSpinner = false;
          })
          .catch((error)=>{
            this.errors = error.response.data.errors;
            return this.showCheckNumberSpinner = false;
          });
    },
    register() {
      if(!this.canSubmit){
        return false;
      }
      loading();
      User.register(this.form)
          .then(() => {
            this.$store.dispatch('hideLoading');
            this.$root.$store.dispatch('successMessage',this.$t('registration_success'));
            this.$router.push({ name: "login" });
          })
          .catch(error => {
            this.$store.dispatch('hideLoading');
            this.$store.dispatch('warnMessage',this.$t('please_check_fields_and_try_again'));
            this.errors = error.response.data.errors;
          });
    },


  },



}
</script>
<style scoped>
.notification-group {
  padding: 0;
  margin: 0;
}
.logo,.w-logo{
  overflow: hidden;
  z-index: 1;
  position:relative;
}

.ka, .ka-b {
  padding-top: 4px;
}
.selection-type-selector {
  display: grid;
  grid-template-columns: 50px 50px 50px;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  vertical-align: center;
  align-items: center;
}
.selection-type-item {
  font-size: var(--txt-md1);
  display: inline-flex;
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: var(--grey7);
  padding: 6px 0px 3px 0px;
}
.selection-center{
  border-radius: 0px 0px 0px 0px;
}
.selection-left{
  border-radius: 10px 0px 0px 10px;
}
.selection-right{
  border-radius: 0px 10px 10px 0px;
}
.selected {
  background-color: var(--yellow);
  color: var(--t-black);
}
.red-border{
  border:1px solid var(--red);
}
.user-agreement {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
  position: relative;
  width: 90vw;
  text-align: left;
  justify-content: left;
  padding-left: 20px;
}
.checkbox {

  margin-right: 15px;
  font-size: 18px;
  margin-bottom: 7px;
  color:red;
}
.confirmation_text {
  display: block;
  text-wrap: normal;
  font-size: 13px;
  width: 90%;
  color: var(--grey4);
  text-align: left;
}

.green-border{
    border:none;
}
.cyrcle-button {
  position: fixed;
  bottom: 10px;
  left: 10px;
}
.register-form-wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding-top: env(safe-area-inset-top,0);
  position: relative;
  width: 100vw;
}

</style>
import Inbox from "@/apis/inbox";


import {httpStatusActions} from "@/support/support";

export const _getAllDataFromCloud = ({commit },filter)=> {
    return new Promise((resolve, reject) => {
        Inbox.get(filter).then(response => {
            httpStatusActions(response,true);
            commit('SET_CLOUD_DATA', {response: response.data});
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _toggleRead = ({ commit },data) => {
    return new Promise((resolve, reject) => {
        commit('TOGGLE_READ', {id:data.id,name:'read',val:data.read});
        Inbox.update(data).then(response => {
            resolve (response);
        }).catch(error => {
            reject(httpStatusActions(error));
        });
    });

}
export const _removeMessageFromCloud = ({ commit },id)=> {
    return new Promise((resolve, reject) => {
        Inbox.delete(id).then(response => {

            commit('REMOVE_MESSAGE', {id: id});
            resolve (response);

        }).catch(error => {
            reject(httpStatusActions(error));
        });
    });


}

export const _getDomains = (state) => {
    return state.SETTINGS.COUNTRY_DATA;
}
export const _getDomain = (state) =>(getDomain)=> {
    let domains = _getDomains(state);

    if(typeof getDomain === 'string'){
        return domains.find(domain => domain.tld === getDomain.toLowerCase());
    }
}
export const _getTld = (state) =>(country)=> {
    let domains = _getDomains(state);

    if(typeof country === 'string'){
        return domains.find(domain => domain.country === country);
    }
}
export const _getLanguages = (state) => {
    return state.SETTINGS.LANGUAGE_DATA;
}
export const _getUserNotificationMessage = (state) => {
    return state.NOTIFY_USER_MESSAGE.DATA;
}
export const _userNotificationWindow = (state) => {
    return state.NOTIFY_USER_MESSAGE;
}

export const _applicationWindowState = (state) => {
    return state.APPLICATION_WINDOW.SHOW;
}
export const _applicationWindowId = (state) => {
    return state.APPLICATION_WINDOW.ID;
}
export const _applicationWindowStatus = (state) => {
    return state.APPLICATION_WINDOW.STATUS;
}
export const _applicationWindowData = (state) => {
    return state.APPLICATION_WINDOW.DATA;
}
export const _getLang = (state) =>(lang)=> {
    let domains = _getLanguages(state);
    if(lang){
        return domains.find(language => language.code === lang.toLowerCase());
    }

}

export const _loadingSpinnerStatus = (state)=>(spinner)=> {
    return state.LOADING_SPINNER[spinner];
}
export const _slideSwitchStatus = (state)=>(number)=> {
    return state.SLIDE_SWITCH[number];
}
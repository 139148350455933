export const newRequests = (state)  =>{
        let returnNumber =0;
        returnNumber = returnNumber+state.PARTNERSHIP.REQUESTS.length;

        let ACTIVE = state.PARTNERSHIP.ACTIVE;
        let ACTIVE_COUNT = ACTIVE.filter(item=>{
            let req = item.requests.filter(req=>{
                return !req.rejected || req.rejected===0;
            });
            let notify = item.notifications.filter(not=>{
                return !not.read;
            });
            return req.length>0 || notify.length>0;
        });

        return returnNumber+ACTIVE_COUNT.length;

}
import Appdata from "@/apis/appdata";
import {httpStatusActions} from "@/support/support";
export const _getAllDataFromCloud = ({ commit })=> {
    return new Promise((resolve, reject) => {
        Appdata.get().then(response => {
            httpStatusActions(response,true);
            commit('SET_CLOUD_DATA', response.data);
            resolve (response);
        }).catch(error=>{

            reject(httpStatusActions(error));
        });

    });
}
export const _toggleSettings = ({commit },data)=> {
    return new Promise((resolve, reject) => {
        Appdata.toggleSettings(data).then(response => {
            commit('TOGGLE_SETTINGS', data);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _updateProfile = ({commit },data)=> {
    return new Promise((resolve, reject) => {
        Appdata.updateProfile(data).then(response => {
            commit('UPDATE_PROFILE', data);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _updateValue = ({commit},UPDATE)=>{
    return commit('UPDATE_VALUE',UPDATE);
}
export const  _clearCache = ({ commit })=> {
    return  commit('CLEAR_CACHE');
}

<template>
  <div class="stats-bg" >
    <div class="stats  bubble-bg" v-if="pageLoaded">
      <section-overview :c-l-o-u-d="CLOUD"></section-overview>
      <section-customer :c-l-o-u-d="CLOUD"></section-customer>
      <section-cashier :c-l-o-u-d="CLOUD"></section-cashier>
      <section-branch :c-l-o-u-d="CLOUD"></section-branch>
      <section-points :c-l-o-u-d="CLOUD"></section-points>
      <section-discount :c-l-o-u-d="CLOUD"></section-discount>
      <div class="flex-center white-txt">{{$t('stats_update_timeout')}}</div>
    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>
import Launchpad from "@/components/launchpad";
// import PieChart from "@/components/STATIC/pieChart";


import {mapActions, mapState} from "vuex";
import {loading} from "@/support/helpers";
import SectionOverview from "@/components/stats/section-overview";
import SectionCustomer from "@/components/stats/section-customer";
import SectionPoints from "@/components/stats/section-points";
import SectionDiscount from "@/components/stats/section-discount";
import SectionBranch from "@/components/stats/section-branch";
import SectionCashier from "@/components/stats/section-cashier";

export default {
  components: {
    SectionCashier,
    SectionBranch, SectionDiscount, SectionPoints, SectionCustomer, SectionOverview, Launchpad},
  data(){
    return {
        pageLoaded:false,
        customers:{
          today:true, //False Total
        },
        love:{
          today:true, //False Total
        },
        trans:{
          today:true, //False Total
        },
        collector:{
          today:true, //False Total
        }
    }
  },
  mounted() {
      loading(true);
      this._getAllDataFromCloud().then(()=>{
        loading(false);
        //todo commented before i finish statistics
        this.pageLoaded = true;
      }).catch(()=>{
        this.pageLoaded = true;
        loading(false);
      })
  },
  computed:{
    ...mapState('_stats',['CLOUD']),





  },
  methods:{
    ...mapActions('_stats',['_getAllDataFromCloud'])
  }
}
</script>

<style>
.section-wrp {
  /*display: grid;*/
  /*grid-template-columns: repeat(auto-fit,minmax(max(320px,40vw),1fr));*/
  /*grid-column-gap: 1vw;*/
  /*grid-row-gap: max(30px,2vw);*/
  /*justify-items: center;*/
  /*margin: 1vw 10px 100px;*/

  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(max(320px,40vw),1fr));
  grid-column-gap: 3vw;
  grid-row-gap: max(50px,2vw);
  justify-items: center;
  margin: 1vw 30px 100px;

}
.section-item {
  position: relative;
  display: grid;
  grid-template-rows: 50px auto;
  grid-template-areas: "title" "body";
  border-radius: var(--radius);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  width: 100%;
  /*height: calc(320px + 1vw);*/
}
.section-item .title {
  grid-area: title;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  height: max(40px,2vw);
  font-size: var(--txt-2xl);
  background-color: rgba(223, 230, 255, .30);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  color: var(--t-white);
  font-weight: 500;
}
.section-item .title .name {
  font-size: min(calc(14px + 1vw),22px);
}
.section-item .title .period {
  color:rgba(255,255,255, .60);
  font-size: min(calc(14px + 1vw),22px);
}
.section-item .body-wrp {
  grid-area: body;
  position: relative;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 100%;
  background-color: var(--t-white);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  width: 100%;
}
.section-item .body {
  height: auto;
  width: 90%;
}
.headline-txt {
  font-size: var(--txt-6xl);
  color: var(--t-white);
  text-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  font-weight: bold;
  margin: 2vh 1vh;
}
.bubble-bg {
  background-image: url(https://assets.portfel.app/img/portfel.co.uk/bubles.svg?v=1.4);
  background-repeat: repeat;
}
.overview-wrp {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(220px,1fr));
  grid-column-gap: 1vw;
  grid-row-gap: 8px;
  justify-items: center;
  margin: 1vw 10px 30px;
}
.overview-item {
  position: relative;
  display: grid;
  grid-template-rows: 50px auto 40px;
  grid-template-areas: "title" "number" "range";
  background-color: var(--t-white);
  border-radius: var(--radius);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  width: 90%;
  padding: calc(3px + 1vw) calc(5px + 1vw);
  height: calc(170px + 1vw);

}
.overview-item .title {
  font-family: var(--en);
  font-weight: 550;
  font-size: var(--txt-3xl);
  color: var(--indigo);
  background-color: white !important;
}
.overview-item .number {
  display: inline-flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  font-size: var(--txt-6xl);
  font-weight: 600;
  text-align: center;
  width: 100%;
  color: var(--indigo);
}
.overview-item .number i{
  color: var(--grey4);
  font-size: var(--txt-2xl);
}
.overview-item .range {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: var(--txt-3xl);
  font-weight: 700;
  color: var(--grey5);
  align-items: center;
}
</style>
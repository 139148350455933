import Stats from "@/apis/stats";


import {httpStatusActions} from "@/support/support";

export const _getAllDataFromCloud = ({commit })=> {
    return new Promise((resolve, reject) => {
        Stats.get().then(response => {
            httpStatusActions(response,true);
            commit('SET_CLOUD_DATA', response.data);
            resolve (response);

        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}

export const SET_CLOUD_DATA = (state,{response}) => {
    let newData = response;
    state.CLOUD = {};
    state.CLOUD = Object.assign({}, state.CLOUD,newData);
}
export const SET_CARDS = (state,{response}) => {
    let newData = response;
    state.CLOUD.CARDS =[];
    state.CLOUD.CARDS = Object.assign([], state.CLOUD.CARDS,newData);
}
export const RESET_EXPORT_LINK = (state) => {
    state.EXPORT.EXPORT_LINK = '';
}
export const SET_EXPORT_LINK = (state,{response}) => {
    state.EXPORT.EXPORT_LINK = '';
    state.EXPORT.EXPORT_LINK =response;
}

export const TOGGLE_IMPORT=(state)=>{
    state.OPTIONS.SHOW_IMPORT = !state.OPTIONS.SHOW_IMPORT;
}
export const SET_SUBMIT_FORM=(state,submitForm)=>{
    let newData = submitForm;
    state.SUBMIT_FORM = {};
    state.SUBMIT_FORM = Object.assign({}, state.SUBMIT_FORM,newData);
}
export const REMOVE_CUSTOMER=(state,data)=>{

    let id = data;
    let CLOUD = state.CLOUD;

    let cData = CLOUD.CUSTOMERS.filter(c=>{
        return +c.card.id !== +id;
    });
    state.CLOUD.CUSTOMERS = [];
    state.CLOUD.CUSTOMERS = Object.assign([], cData);
    state.CLOUD.TOTAL_RECORDS = +state.CLOUD.TOTAL_RECORDS-1;

}







<template>
  <canvas :id="chartName"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import {randomName} from "@/support/support";

export default {
  props:{
    chartData:{
      default(){
        return {
          labels: ['Red', 'Blue','Yellow','Violet'],
              datasets: [{
            label: '',
            data: [100, 178,189,121],
            backgroundColor: [

              '#eb0c8d',
              '#007cf8',
              '#04cafb',
              '#af52de'
            ],
          }]
        }
      }
    }
  },
  data(){
    return {
      chartName:randomName(),
    }
  },
  mounted() {
    let delayed;
    let ctx = document.getElementById(this.chartName);
    new Chart(ctx, {
      type: 'bar',
      data: this.chartData,
      options: {
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        animation: {
          onComplete: () => {
            delayed = true;
          },
          delay: (context) => {
            let delay = 0;
            if (context.type === 'data' && context.mode === 'default' && !delayed) {
              delay = context.dataIndex * 300 + context.datasetIndex * 100;
            }
            return delay;
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        },
        responsive: true
      }
    });
  },

}
</script>

<style scoped>

</style>
<template>
  <div :class="pad">
    <div class="switch-button-group" :class="{'w-text':label}">
      <div class="button mg-t-4" :class="{ off: !currentState }" @click="onClick"><i class="fas fa-circle pd-t-1"></i></div>
      <div v-if="label" class="text" @click="onClick" ><span :class="$t('font_b')" class="txt-lg">{{label}}</span>
        <span style="display: block;" :class="$t('font')">{{desc}} </span>
      </div>
    </div>
  </div>
</template>

<script>


import {SlideSwitch} from "@/support/slide_switch";
import {mapGetters} from "vuex";

export default {
  props:{
    defaultState:{
      default:false,
    },
    switchNumber:{
      default: 0,
    },
    label:{
      default:false,
    },
    desc:{
      default:false,
    },
    pad:{
      default:"w-tb-pad-20"
    }
  },
  mounted() {
    SlideSwitch(this.switchNumber,!this.defaultState);
  },
  computed:{
    ...mapGetters(['_slideSwitchStatus']),
    currentState(){
      return this._slideSwitchStatus(this.switchNumber);
    }
  },
  methods:{
    onClick() {
      SlideSwitch(this.switchNumber,!this.currentState);
      this.$emit('on-state-change',!this.currentState);
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <span>
    <i v-if="spinner" class="fal fa-spinner fa-spin " :class="setClass"></i>
    <i v-if="!spinner" @click="reload()" class="rotate fad fa-redo"></i>
  </span>
</template>

<script>

import {loading} from "@/support/helpers";

export default {
  props:{
    setClass:{
      default:'',
    }
  },
  data(){
    return {
      spinner:true,
    }
  },
  mounted(){
    setTimeout(()=>{
        return this.spinner = false;
    },10000)
  },
  methods:{
    reload(){
      loading();
      return window.location.reload(true);
    }
  }
}
</script>

<style scoped>
    .rotate {
      animation: rotate-anime 300ms ease-in-out;
    }
    @keyframes rotate-anime {
      0%{
        transform: rotate(0.2turn);

      }
      1%{
        transform: scale(1.1);
      }
      99%{
        transform: scale(1.9);
      }
      100%{
        transform: rotate(359turn);
      }
    }
</style>
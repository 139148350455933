<template>
  <canvas :id="chartName"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import {randomName} from "@/support/support";

export default {
  props:{
    chartData:{
      default(){
        return {
          labels: ['Jan','Feb','March','Apr'],
          datasets: [
           {
            label: ['Red'],
            data: [11, 18,-23,178],
            backgroundColor:'#eb0c8d',
            borderColor: '#eb0c8d',
            },
            {
            label: ['Red'],
            data: [100, 178,-24,121],
            backgroundColor:'#007cf8',
            borderColor: '#007cf8',
           },

          ]
        }
      }
    }
  },
  data(){
    return {
        chartName:randomName(),
    }
  },
  mounted() {

    let ctx = document.getElementById(this.chartName);

    let delayed;
    new Chart(ctx, {
          type: 'line',
          data: this.chartData,
          options: {
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                fullSize: true
              },
            },
            animation: {
              onComplete: () => {
                delayed = true;
              },
              delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                  delay = context.dataIndex * 300 + context.datasetIndex * 100;
                }
                return delay;
              },
            },
            responsive:true
          }
        }
    );
  },

}
</script>

<style scoped>

</style>
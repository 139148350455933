<template>
  <div class="login-form-wrap">
    <form>
      <div id="login-container" style="max-width: 500px;" :class="$t('font')">
          <div class="logo grid-col-1 a-i-center">
            <div class="flex-center pof txt-7xl"><i class="pof portfel-text-horizontal"></i></div>
          </div>

          <div  v-on:click="$router.push({name:'login'})" class="save_settings_btn t-center txt-md yellow" ><i class="far fa-chevron-circle-left pd-r-4"></i>{{$t('login')}}</div>

        </div>
    </form>

  </div>
</template>
<script>

import User from "@/apis/user";
import {setDomain, setLang} from "@/support/support";
import {errorMessage, loading, successMessage, warningMessage} from "@/support/helpers";



export default {
  components:{



  },
  data(){
    return {
      confirmed:false, // State Before any action
      resent:false, // Resend of confirmation email
      form:{
        email:'',
      },


    }
  },
  mounted() {
    loading();
    let domain = this.$route.query.domain;
    let lang = this.$route.query.lang;
    let email = this.$route.query.email;
    if(!domain || !lang || !email){

      warningMessage('error_detected_try_later');
      return this.$router.push({name:'profile'});
    }
    setDomain(domain);
    setLang(lang);
    this.form.email = this.$route.query.email;

    this.confirmEmail();

  },


  methods:{


    confirmEmail() {
      User.confirmEmail(this.form).then(() => {
            loading(false);
            successMessage('email_confirmed');
            this.$router.push({name:'login'});
          })
          .catch(()=>{
            loading(false);
            errorMessage('error_detected_try_later');
            this.$router.push({name:'login'});
          });
    }

  }

}
</script>
<style scoped>
body {
  background-color:#331754;
  background-image: linear-gradient(-225deg, #EB0C8D 0%, #007CF8 100%);
}
.company-login-bg {
  background-color:#331754;
  background-image: linear-gradient(-225deg, #EB0C8D 0%, #007CF8 100%);
}

.login-form{
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-top: 0;
  padding-top: env(safe-area-inset-top, 0);
}
.notification-group {
  padding: 0;
  margin: 0;
}
#logo{
  z-index: 0;
}
.logo,.w-logo{
  overflow: hidden;
  z-index: 1;
  position:relative;
}
.selection-type-selector-login {
  display: grid;
  grid-template-columns: 50px 50px 50px;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  vertical-align: center;
  align-items: center;
}
.selection-type-item-login {
  text-decoration: underline;
  font-size: var(--txt-md1);
  display: inline-flex;
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;

  padding: 6px 0px 3px 0px;
  color: white;
}
.selection-center-login{
  border-radius: 0px 0px 0px 0px;
}
.selection-left-login{
  border-radius: 10px 0px 0px 10px;
}
.selection-right-login{
  border-radius: 0px 10px 10px 0px;
}
.selected-login {

  color: var(--blue);
}

.reg-container{
  -webkit-backdrop-filter: blur(40px);
  -moz-filter: blur(40px);
  -o-filter: blur(40px);
  -ms-filter: blur(40px);
  backdrop-filter: blur(40px);
  -webkit-box-shadow: -12px 20px 25px -13px rgba(0,0,0,0.75);
  -moz-box-shadow: -12px 20px 25px -13px rgba(0,0,0,0.75);
  border-radius: 20px;
  box-shadow: -12px 20px 25px -13px rgba(0,0,0,0.75);
  padding: 20px 20px 20px 20px;

}
.forgotcss {
  width: 150px;
  text-align: right;
  float: right;
  padding-top: 5rem;
  padding-right: 10px;
}
</style>
<template>
  <div>

      <div class="input-group w-margin has-pos" >
        <div class="input-desc " :class="$t('font')">{{$t('address')}} <span class="red-light-txt">*</span>
        </div>
        <div class="input-area">
          <GmapAutocomplete v-if="!enteredAddress" :value="enteredAddress" @place_changed='setPlace' :options="{fields: ['geometry', 'formatted_address', 'address_components']}"/>
          <span v-if="enteredAddress" class="cursor fa fa-times-circle grey3-txt pd-r-2 txt-md" style="max-width: 9%;"  v-on:click="reset()"></span>
          <input v-if="enteredAddress" :value="enteredAddress" disabled/>
        </div>
        <div class="input-pos yellow cursor" @click='addMarker' v-if="!locationAdded" ><span><i class="fal fa-map-marker-plus" ></i></span></div>
        <div class="input-pos green" v-if="locationAdded"><i class="fal fa-map-marker-check"></i></div>
      </div>
      <div class="flex-center-raw pd-10 grey7 radius mg-10">

          <GmapMap :center='center' :zoom='12' style='width:100%;  height: 400px;'>
          <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position"  :clickable="true" :draggable="true" @dragend="updateLocation" @click="center=m.position"/></GmapMap>

      </div>
  </div>
</template>

<script>

import {errorMessage, loading, rmSpace} from "@/support/helpers";
import config from "@/config";
import axios from "axios";
//
export default {
  name: 'GoogleMap',
  props:{
    city:{
      type: String,
      required: false
    },
    country:{
      type: String,
      required: false
    },
    region:{
      type: String,
      required: false
    },
    street:{
      type: String,
      required: false
    },
    map:{
      type: String,
      required: false
    },
    number:{
      type: String,
      required: false
    },
  },
  data() {
    return {
      center: { lat:51.5149056, lng:-0.1235464 },
      currentPlace: null,
      locationAdded:false,
      markers: [],
      places: [],
      enteredAddress:'',
      response:{
        city:false,
        country:false,
        address:false,
        latLong:false,
        district:false,
        state:false,
        number:false,
      }
    }
  },
  mounted() {
    this.mountMapData();
  },
  watch: {
    map: function () {
      this.mountMapData();
    },
  },
  computed:{
    getMap(){
      return this.map;
    }
  },
  methods: {
    mountMapData(){
      this.reset();
      if(!this.getMap){
        this.geolocate();
      }else{

        let location = rmSpace(this.getMap).split(',');
        let lat = +location[0];
        let lng = +location[1];
        this.center ={ lat, lng };
        this.markers =[{ position:{lat, lng} }];
        this.locationAdded = true;
        this.reverseGeocode();
      }
    },
    reset(){

      this.locationAdded = false;
      this.markers = [];
      this.currentPlace = null;
      this.markers=[];
      this.places=[];
      this.enteredAddress='';
      this.locationAdded=false;
      this.response ={
            city:false,
            country:false,
            address:false,
            latLong:false,
            district:false,
            state:false,
            number:false,
      };


    },
    updateLocation(location){
      this.markers =[{
        position:{
          lat:location.latLng.lat(),
          lng:location.latLng.lng()
        }
      }];
      this.reverseGeocode();
    },
    reverseGeocode(){
      let latlng = this.markers[0].position.lat+","+this.markers[0].position.lng;
      axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+latlng+'&key='+config.googleMapsToken).then((resp)=>{
          this.currentPlace = resp.data.results[0];
          this.saveMarkedLocation();
      }).catch(()=>{
        this.center= { lat:51.5149056, lng:-0.1235464 };
        this.reset();
        errorMessage(this.$t('address'));
      });
    },
    getIpInfo(){
      let self = this;
      fetch('https://ipinfo.io/json?token='+config.ipInfoToken, { headers: { 'Accept': 'application/json' }})
          .then((resp) => resp.json())
          .catch(() => {
            loading(false);
          })
          .then((resp) => {
            self.response.country=resp.country;
            let loc = resp.loc.split(",");
            this.center =  {
              lat:+loc[0],
              lng:+loc[1]
            }
            loading(false);
          });
    },
    setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },
    saveMarkedLocation(){
      let place = this.currentPlace;
      let street = this.getPlaceData(place, "route", "long_name");
      let street_number = this.getPlaceData(place, "street_number", "long_name");
      let district = this.getPlaceData(place, "sublocality", "long_name") || this.getPlaceData(place, "sublocality_level_1", "long_name");
      let city = this.getPlaceData(place, "locality", "long_name") || this.getPlaceData(place, "administrative_area_level_2", "long_name");
      let state = this.getPlaceData(place, "administrative_area_level_1", "short_name");
      let country = this.getPlaceData(place, "country", "long_name");

      this.enteredAddress = this.currentPlace.formatted_address;
      this.response.address = street;
      this.response.city = city;
      this.response.country = country;
      this.response.state = state;
      this.response.district = district;
      this.response.number = street_number;

      let lat, lng;

      if(typeof this.currentPlace.geometry.location.lat==="number"){
          lat = this.currentPlace.geometry.location.lat;
          lng = this.currentPlace.geometry.location.lng;
      }else{
          lat = this.currentPlace.geometry.location.lat();
          lng = this.currentPlace.geometry.location.lng();
      }
      this.response.latLong = lat+","+lng;


      this.places.push(this.currentPlace);
      this.center = {
        lat,
        lng,
      };

      this.locationAdded = true;
      this.$emit('result',this.response);
    },
    addMarker() {
      if(this.currentPlace){
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        return this.saveMarkedLocation();
      }else{
        this.markers.push({ position: this.center });
        this.reverseGeocode();

      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      },()=>{
        this.getIpInfo();
      });
    },
    getPlaceData(place, infoType, format){
      let data,
          addressComponents = [...place.address_components];

      addressComponents.forEach(componentItem => {
        if (componentItem.types[0] === infoType) {
          data = componentItem[format];
          addressComponents.length = 0;
        }
      });

      return data;
    },





  },
};
</script>
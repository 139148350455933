<template>
  <div class="input-group" :class="wMargin">
    <div class="input-desc" :class="$t('font')">
      <label :for="name">
        <span v-if="!message"><i  v-if="icon" :class="icon" class="fal pd-r-3"></i>{{$t(label)}}</span>
        <span v-if="message" class="red-light-txt">{{message}}</span>
        <span v-if="req" class="red-light-txt">*</span>
      </label>
    </div>
    <div class="input-area" :class="setClass" v-on:click="$emit('clicked')">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    props:{
      label:{
        required:true,
      },
      req:{
        default:false
      },
      wMargin:{
        default:'w-margin',
      },
      setClass:{
        default:"",
      },
      name:{
        default:"",
      },
      icon:{
        default:false,
      },
      message:{
        default:false,
      },

    }
}
</script>

<style scoped>

</style>
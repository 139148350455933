<template>
  <div>
    <user-notification-window></user-notification-window>
    <application-window :title="$t('suspend')">
      <template #content>
        <div class="w-df-pad">{{$t('suspend_card_warning')}}</div>
      </template>
      <template #submit>
        <input-button v-on:click.native="suspendCard()"  :label="$t('yes')" set-class="yellow en-b txt-md" icon="fal fa-check-circle"></input-button>
      </template>
    </application-window>
    <div class="heading-group bg">
      <h6 :class="$t('font_b')" class=" txt-3xl mg-t-20 pd-l-8 txt-uppercase">{{$t('customers')}}</h6>
    </div>

    <div class="split-view">

      <div class="view-list">
        <div class="txt-7xl grey3-txt container t-center mg-t-30">
          <SpinnerVuex :spinner-number="0"></SpinnerVuex>
        </div>
        <div v-if="!leftSpinner">
          <customer-left-filter></customer-left-filter>
        </div>
      </div>
      <div class="view-action">
        <div class="txt-7xl grey3-txt container t-center mg-t-30">
          <SpinnerVuex :spinner-number="1"></SpinnerVuex>
        </div>
        <div v-if="!rightSpinner">
          <customer-right-top></customer-right-top>
          <customer-right-import v-if="showImportWindow"></customer-right-import>
          <customer-right-list></customer-right-list>
        </div>
      </div>
    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>


import Launchpad from "@/components/launchpad";
import {mapActions, mapGetters} from "vuex";
import {ResetSlideSwitch} from "@/support/slide_switch";
import {HideSpinners, ResetLoadingSpinner} from "@/support/loading";
import CustomerLeftFilter from "@/components/component/customers/leftFilter";
import SpinnerVuex from "@/components/STATIC/spinnerVuex";
import CustomerRightTop from "@/components/component/customers/rightTop";
import CustomerRightImport from "@/components/component/customers/rightImport";
import CustomerRightList from "@/components/component/customers/rightList";
import UserNotificationWindow from "@/components/component/customers/userNotificationWindow";
import ApplicationWindow from "@/components/component/applicationWindow";
import customers from "@/apis/customers";
import {successMessage} from "@/support/helpers";
import {httpStatusActions} from "@/support/support";
import InputButton from "@/components/STATIC/inputButton";
import {aw} from "@/support/applicationWindow";



export default {
  components:{
    InputButton,
    ApplicationWindow,
    UserNotificationWindow,
    CustomerRightList,
    CustomerRightImport,
    CustomerRightTop,
    SpinnerVuex,
    CustomerLeftFilter,
    Launchpad,


  },
  data(){
    return {
    }
  },
  mounted(){
    ResetLoadingSpinner();
    ResetSlideSwitch();
    // ShowSpinners();
    this._getAllDataFromCloud().then(()=>{
      HideSpinners();
    }).catch(()=>{
      HideSpinners();
    });
  },

  computed:{
    ...mapGetters('_customers',['importState']),
    ...mapGetters(['_applicationWindowData']),
    suspendCardData(){
      return this._applicationWindowData;
    },
    //SPINNERS
    ...mapGetters(['_loadingSpinnerStatus','_slideSwitchStatus']),
    showImportWindow(){
      return this.importState;
    },
    leftSpinner(){
      return this._loadingSpinnerStatus(0);
    },
    rightSpinner(){
      return this._loadingSpinnerStatus(1);
    },
    //SPINNERS END

    keywordFilterSwitch(){
      return this._slideSwitchStatus(1);
    }



  },

  methods:{
    ...mapActions('_customers',['_getAllDataFromCloud','_removeCustomer']),
    suspendCard(){

      customers.suspendCard({card_id:this.suspendCardData.id}).then(()=>{
        this._removeCustomer(this.suspendCardData.id).then(()=>{
            aw(false);
            return successMessage();
        });
      }).catch(error=>{
        return httpStatusActions(error);
      });
    }
  }
}
</script>


<template>
<div class="login-form-wrap">
<form>
  <div id="login-container">


    <div class="logo grid-col-1 a-i-center">
      <div class="flex-center pof txt-7xl"><i class="pof portfel-text-horizontal"></i></div>
    </div>
    <div class="register blur pd-10 grid-col-1 a-i-center">
      <div class="flex-center ">
        <div class="txt-sm1 t-center" :class="$t('font')">
          <p class="t-white-txt"><span class="txt-md">{{$t('register_text')}}</span><br> <router-link :to="{name:'register'}" style="text-decoration: none; padding-top: 5px;"><span class="txt-md1 t-white-txt" :class="$t('font')" style="text-transform:uppercase;font-weight: 800; text-decoration: underline;"> {{$t('user_registration')}}</span></router-link></p>
        </div>
      </div>
    </div>
    <div class="login-form grid-col-1 a-i-center">

      <div class="group">
        <div class="input-group">
          <div class="input-desc t-white-txt" :class="$t('font')">{{$t('email')}}<span class="red-txt txt-md">*</span>
            <span class="t-white-txt pd-l-2" v-if="getErrors.email">{{ getErrors.email[0] }}</span>
          </div>
          <div class="input-area"><input type="text"  autocomplete="email"  v-model="form.email">
            <span v-if="form.email" class="fa fa-times-circle grey3-txt pd-r-2 txt-md" style="max-width: 9%;"  v-on:click="form.email=''"></span>
          </div>
        </div>
      </div>

      <div class="group">
        <div class="input-group">
          <div class="input-desc t-white-txt" :class="$t('font')">{{$t('password')}}<span class="red-txt txt-md">*</span>
          </div>
          <div class="input-area"><input type="password"  autocomplete="current-password"  v-model="form.password">
            <span v-if="form.password" class="fa fa-times-circle grey3-txt pd-r-2 txt-md" style="max-width: 9%;"  v-on:click="form.password=''"></span>
          </div>
        </div>
      </div>




    </div>
    <div class="remember-forgot grid-col-1 a-i-center">
      <div class="grid-col-2">
        <div class="g-c-1">
          <div class="group">
            <div class="switch-button-group w-text">
              <input type="hidden" :value="remember_me">
              <div class="button " :class="{ off: !remember_me }" @click="remember_me = !remember_me"><i class="fas fa-circle pd-t-1"></i></div>
              <div class="text" @click="remember_me = !remember_me"><span :class="$t('font_b')">{{$t('remember_me')}}</span> <br> <span :class="$t('font')">{{$t('on_off')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="g-c-2">
          <div class=" txt-sm txt-xs t-white-txt t-right cursor" style="text-decoration: underline;" :class="$t('font')"><span @click="forgotPassword">{{$t('forgot')}}</span></div>
        </div>
      </div>

    </div>
    <div class="login-button flex-center">
      <button class=" btn btn-md yellow t-black-txt mg-10 cursor" :class="$t('font_b')" @click.prevent="login"> {{$t('login')}}</button>
    </div>


  </div>
</form>
<div class="select-loc-wrap">
  <div class="select-loc">
    <span class="txt-xs1 t-center txt-uppercase " style="color: rgba(255,255,255,0.30);"><i class="fal fa-map-marked-alt txt-lg"></i>{{location.tld}}</span>
  </div>
</div>
<div class="select-lang-wrap" v-on:click="$router.push({name:'setDomain'})">
  <div class="select-lang">
    <span class="txt-xs1 t-center txt-uppercase"><i class="fal fa-globe txt-lg"></i>{{lang}}</span>
  </div>
</div>


</div>
</template>
<script>

// import support from "../../../support/support";
import User from "../apis/user";


import support, {
  getDomain,
  getLang,
  logIn,
  logOut,
  setColorMode,
  setLaunchpadState,
  setLocale
} from "@/support/support";
import {errorMessage} from "@/support/helpers";



export default {
  components:{



  },
  data(){
    return {
      lang:'',
      location:'',
      form:{
        email:'',
        password:'',
        first_name:'',
        last_name:'',
        personal_id:'',
        phone:'',
        password_confirmation:'',
        scope:1,
        //device_log:support.deviceLog(),
      },
      errors: [],
      state: this.val,
      remember_me:true,

    }
  },
  mounted() {


    setLocale();
    logOut();
    this.state =true;
    this.$store.dispatch('hideLoading');

    this.lang = this.getLang;
    this.location = this.getLocation(getDomain());

    setColorMode();
    let email = localStorage.getItem(support.encode('remember_email'));
    if(email){
      this.form.email = support.decode(email);
      this.state =true;
    }



  },
  computed:{
    getLang(){
      return getLang();
    },
    getErrors(){
      return this.errors;
    },
  },
  watch:{
    val() {
      this.toggle(this.val)
    },
  },
  methods:{
    getLocation(loc){
      return this.$root.$store.getters._getDomain(loc);
    },
    forgotPassword(){
      return this.$router.push({name:'forgot'});
    },
    onClick() {
      this.toggle(!this.state)
      this.emit()
    },
    toggle(state) {
      this.state = state
    },
    emit() {
      this.$emit('input', this.state)
    },
    login() {
      this.$store.dispatch('showLoading');
      User.login(this.form)
          .then(response => {
            logIn(response.data,this.form.email,this.remember_me);
            setLaunchpadState(true);
            this.$router.push({ name: "service" });

          })
          .catch(error => {

            if (error.response.status === 422) {
              let message = (error.response.data.errors.email !== undefined) ? error.response.data.errors.email[0] : error.response.data.message;
              errorMessage(message);
              this.errors = error.response.data.errors;
            }
            this.$store.dispatch('hideLoading');
          });
    }

  }

}
</script>
<style scoped>

</style>
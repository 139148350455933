<template>
  <div class="view-from-save-btn-cont cursor" v-if="!showRightSpinner">
    <div class="save-btn-container">
      <span :class="{'blue-txt':canSubmit,'grey7-txt':!canSubmit}"><i class="fal fa-cloud-upload"></i></span>
      <span class="block txt-xs" :class="$t('font')">{{$t('add_save')}}</span>
    </div>
  </div>
</template>

<script>
export default {
    props:{
      canSubmit:{
        default:false,
      },
      showRightSpinner:{
        default:false,
      },
    }
}
</script>

<style scoped>
.view-from-save-btn-cont {
  display: flex;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  padding-right: 1.2rem;
  font-size: var(--txt-3xl);
}
.save-btn-container{
  text-align: center;
  max-width: 200px;
}
</style>
export const SET_CLOUD_DATA = (state,{response}) => {
    let newData = response;
    state.CLOUD = {};
    state.CLOUD = Object.assign({}, state.CLOUD,newData);
}
export const TOGGLE_READ = (state,{id,val}) => {

    /* Identify Record To Update */
    let theValue = state.CLOUD.MESSAGES.find(obj=>obj.id===+id);

    /* Store in Variable as Old */
    let theValue_OLD = theValue;
    /* Change Value of element in Old Value */
    theValue_OLD.read = val;
    /* Create New Object with updated Value */
    let createNewObject = theValue_OLD;
    /* Inject in Object Updates */
    theValue = Object.assign({},theValue,createNewObject);

}
export const REMOVE_MESSAGE = (state,{id}) => {
    state.CLOUD.MESSAGES = state.CLOUD.MESSAGES.filter(from => {
        return +from.id !== id;
    })
}
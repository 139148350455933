<template>
  <div class="list-group-item list-group-item--addon cursor"  v-on:click="packageInfo(product.id)">
    <div class="list-pre txt-xl cursor t-left flex-start a-i-center" :class="{'green-txt':showSelectIcon,'grey3-txt':!showSelectIcon}"><i class="far fa-chevron-right"></i></div>
    <div class="list-head txt-xl en">{{product.label}} <i  v-if="isCurrentPlan" class="fal fa-check-circle green-txt txt-md"></i></div>
    <div class="list-pos en-r txt-xl" ><span class="blue-txt"><i class="fal fa-info-circle"></i></span></div>
  </div>
</template>

<script>
export default {
  props:{
    isCurrentPlan:{
      default:false,
    },
    product:{
        required:Object
    },
  },
  data(){
    return {
      infoRequested:null,
    }
  },
  mounted() {
    return this.infoRequested = null;
  },
  watch:{
    product:function (newVal,oldVal){
      if(newVal.id!==oldVal.id){
        return this.infoRequested = '';
      }
    }
  },
  computed:{
    showSelectIcon(){
      return this.infoRequested === this.product.id;
    }
  },
  methods:{
    changePackage(productId){
      return this.$emit('changePackage',productId);
    },
    packageInfo(productId){
      this.infoRequested = productId;
      return this.$emit('packageInfo',productId);
    }
  }
}
</script>

<style scoped>

</style>
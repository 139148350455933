import Discount from "@/apis/discount";
import {httpStatusActions} from "@/support/support";
/*
*
*/
export const _getAllDataFromCloud = ({commit})=> {

    return new Promise((resolve, reject) => {
        Discount.get().then(response => {
            httpStatusActions(response,true);
            commit('SET_CLOUD_DATA',response.data);
            resolve (response);
        }).catch(error=>{
            return reject(httpStatusActions(error));
        });

    });
}
/*
*
*/
export const _createOrUpdate = ({commit},form)=> {
    let isUpdate = (parseInt(form.id)>0);

    return new Promise((resolve, reject) => {
        Discount.createOrUpdate(form).then(response => {
                httpStatusActions(response);
                if(isUpdate){
                    commit('UPDATE_EDITED_VALUE', form);
                    commit('FRESH_STANDARD_FORM');
                    commit('FRESH_ONETIME_FORM');
                    commit('FRESH_DYN_FORM');
                }else{
                    let commitData = {
                        form:form,
                        id:response.data
                    }
                    commit('INSERT_NEW',commitData);
                    commit('FRESH_STANDARD_FORM');
                    commit('FRESH_ONETIME_FORM');
                    commit('FRESH_DYN_FORM');
                }
                return resolve (response);
        }).catch(error=>{
            return reject(httpStatusActions(error));

        });
    });
}
/*
*
*/
export const _delete = ({commit},id)=> {

    return new Promise((resolve, reject) => {
        Discount.delete(id).then(response => {
            httpStatusActions(response,true);
            commit('REMOVE_CLOUD_DATA', id);
            resolve (response);
        }).catch(error=>{
            return reject(httpStatusActions(error));
        });

    });
}





// export const _setRules = ({commit},rulesData)=>{
//     return commit('SET_RULES',rulesData);
// }
// // export const _resetRules = ({commit})=>{
// //     return commit('RESET_RULES');
// // }
// export const _createRule = ({commit},rule)=>{
//      return commit('CREATE_RULE',rule);
// }
// export const _updateDynamicRule = ({commit},data)=>{
//     return commit('UPDATE_RULE',data);
// }
// export const _destroyRule = ({commit},id)=>{
//     return commit('DESTROY_RULE',id);
// }




export const _setEditDynamic = ({commit},card_id)=>{
    return commit('SET_DYNAMIC_EDIT',card_id);
}
export const _setEditOnetime = ({commit},card_id)=>{
    return commit('SET_ONETIME_EDIT',card_id);
}
export const _setEditStandard = ({commit},card_id)=>{
    return commit('SET_STANDARD_EDIT',card_id);
}
export const _updateDynValue = ({commit},UPDATE)=>{
    return commit('UPDATE_DYN_VALUE',UPDATE);
}
export const _updateOnetimeValue = ({commit},UPDATE)=>{
    return commit('UPDATE_ONETIME_VALUE',UPDATE);
}
export const _updateStandardValue = ({commit},UPDATE)=>{
    return commit('UPDATE_STANDARD_VALUE',UPDATE);
}
export const _addDynRule = ({commit},rule)=>{
    return commit('ADD_DYN_RULE',rule);
}
export const _updateDynRule = ({commit},rule)=>{
    return commit('UPDATE_DYN_RULE',rule);
}
export const _removeDynRule = ({commit},id)=>{
    return commit('REMOVE_DYN_RULE',id);
}
export const _freshDynForm = ({commit})=>{
    return commit('FRESH_DYN_FORM');
}
export const _freshStandardForm = ({commit})=>{

    return commit('FRESH_STANDARD_FORM');
}
export const _freshOnetimeForm = ({commit})=>{
    return commit('FRESH_ONETIME_FORM');
}

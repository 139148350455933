import support from "@/support/support";
export const SET_CLOUD_DATA = (state,data) => {
    let newData = data;
    state.CLOUD ={};
    state.CLOUD = Object.assign({}, state.CLOUD,newData);
}
export const CLEAR_CACHE = (state) => {
    /* Inject Update */
    state.CLOUD = {
        active:0,
        buy_rate:0,
        collector_enabled:0,
        collector_min_purchase:0,
        collector_spend_minimum:0,
        discount_enabled:0,
        first_name:"0",
        id:0,
        last_name:"0",
        max_give_points:0,
        partners_enabled:0,
        sell_rate:0,
    };
    //Local Storage Update
    localStorage.removeItem(support.encode('settings'));
}
export const TOGGLE_SETTINGS = (state,data) => {
    let currentState = state.CLOUD.SETTINGS ;
    if(data.toggle==='points'){
        currentState.points_enabled = data.state;
    }
    if(data.toggle==='partners'){
        currentState.has_partners = data.state;
    }
    if(data.toggle==='discount'){
        currentState.discounts_enabled = data.state;
    }
    state.CLOUD.SETTINGS  = Object.assign({},currentState);
}
export const UPDATE_PROFILE = (state,data) => {
    let newData = data;
    state.CLOUD.PROFILE ={};
    state.CLOUD.PROFILE = Object.assign({}, state.CLOUD.PROFILE,newData);
}
/*
*  UPDATE = { valueName:'email',valueData:'dummyemail@example.org' }
*/
export const UPDATE_VALUE = (state,UPDATE) => {
    let currentState = state.CLOUD.PROFILE ;
    currentState[UPDATE.valueName] = UPDATE.valueData;
    state.CLOUD.PROFILE  = Object.assign({},currentState);
}
<template>
  <div class="view-from-clear-btn-cont">
    <div class="clear-btn-container cursor">
      <i class="fal fa-redo blue-txt"></i>
      <span class="block txt-xs" :class="$t('font')">{{$t('clear')}}</span>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.view-from-clear-btn-cont {
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  padding-left: 1.2rem;
  font-size: var(--txt-3xl);
}
.clear-btn-container{
  text-align: center;
  max-width: 200px;
}
</style>
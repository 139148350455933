import {getLang} from "@/support/support";
import rootStore from '@/store/';
import config from "@/config";
import {ShowLoading} from "@/support/loading";

export const closeSmartsApp=()=>{
    if(config.enableSmartsUpp){
        window.smartsupp('language',getLang());
        window.smartsupp('chat:hide');
        window.smartsupp('chat:close');

        let el = document.getElementById('chat-application-iframe');
        let elx = document.getElementById('chat-application');
        if(elx){
            elx.style.visibility = "hidden";
            elx.style.zIndex = "0";
        }
        if(el){
            el.style.visibility = "hidden";
            el.style.zIndex = "0";
        }
    }

}
export const openSmartsApp=()=>{
   if(config.enableSmartsUpp){
       window.smartsupp('language',getLang());
       window.smartsupp('chat:show');
       window.smartsupp('chat:open');
       let el = document.getElementById('chat-application-iframe');
       let elx = document.getElementById('chat-application');
       if(elx){
           elx.style.visibility = "visible";
           elx.style.zIndex = "201";
       }
       if(el){
           el.style.visibility = "visible";
           el.style.zIndex = "201";
       }
   }

}
export const loading=(LoadingState=true)=>{
    return ShowLoading(LoadingState);
}
export const infoMessage=(message)=>{
    rootStore.dispatch('infoMessage',message,{ root: true });
}
export const successMessage=(message)=>{
    rootStore.dispatch('successMessage',message,{ root: true });
}
export const warningMessage=(message)=>{
    rootStore.dispatch('warnMessage',message,{ root: true });
}
export const errorMessage=(message)=>{
    rootStore.dispatch('errorMessage',message,{ root: true });
}
export const isLightCard=(card)=>{
    return (parseInt(card.like) === -1);
}
export const updateMessage=(os)=>{
    rootStore.dispatch('updateMessage',os,{ root: true });
}
export const shuffle=(array) =>{
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}
export const rmSpace=(obj)=>{
    return obj.replace(/\s+/g, '');
}

/*
* Object Has Data
*/
export const hasData=(obj)=>{
    if (typeof obj ==='object'){
        if(obj.length !==undefined){ //Array
            return obj.length >0;
        }
        return Object.keys(obj).length>0; //Object

    }
    return obj.length>0; //Other
}
export const chartColors=()=>{

    return [
        '#f72585ff',
        '#b5179eff',
        '#7209b7ff',
        '#560badff',
        '#480ca8ff',
        '#3a0ca3ff',
        '#3f37c9ff',
        '#4361eeff',
        '#4895efff',
        '#4cc9f0ff',
        '#6a040fff',
        '#9d0208ff',
        '#d00000ff',
        '#dc2f02ff',
        '#e85d04ff',
        '#f48c06ff',
        '#faa307ff',
        '#ffba08ff',
        '#d9ed92ff',
        '#b5e48cff',
        '#99d98cff',
        '#76c893ff',
        '#52b69aff',
        '#34a0a4ff',
        '#168aadff',
        '#1a759fff',
        '#1e6091ff',
        '#184e77ff',
        '#af52de',
        '#4a93b5',
        '#4155be',
        '#ffe231',
        '#fa0587',
        '#b500ff',
        '#0058ff',
        '#72B722',
        '#04cafb'];
}
export const getDates=()=>{

    function currentDateTime() {
        const current = new Date();
        const month = (+(current.getMonth()+1)<10)?'0'+(current.getMonth()+1):(current.getMonth()+1);
        const day = (+(current.getDate())<10)?'0'+(current.getDate()):(current.getDate());
        const date = current.getFullYear()+'-'+month+'-'+day;
        const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        return date + ' ' + time;
    }
    function currentDate() {
        const current = new Date();
        const month = (+(current.getMonth()+1)<10)?'0'+(current.getMonth()+1):(current.getMonth()+1);
        const day = (+(current.getDate())<10)?'0'+(current.getDate()):(current.getDate());
        return current.getFullYear()+'-'+month+'-'+day;

    }

    return {
        today:currentDate(),
        todayDateTime:currentDateTime(),
    }

}

export default {
    namespaced: true,
    closeSmartsApp,
    openSmartsApp,
    chartColors,
    infoMessage,
    successMessage,
    warningMessage,
    errorMessage,
    isLightCard,
    updateMessage,
    shuffle,
    hasData,
    getDates


}
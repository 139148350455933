<template>
  <div>
    <div class="heading-group bg">
      <h6 :class="$t('font_b')" class=" txt-3xl mg-t-20 pd-l-8 txt-uppercase">{{$t('org_branches')}}</h6>
    </div>

    <div class="split-view">

      <div class="view-list">
        <div  v-if="showLeftSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div class="w-margin">
          <uni-search v-on:search="search" v-if="!showLeftSpinner"></uni-search>
        </div>
        <div class="space-div--40"></div>
        <div class="list-group" v-if="!showLeftSpinner">

          <div class="list-group-item list-group-item--addon" v-for="(branch,index) in branches" :key="index">
            <div class="list-pre red-light-txt txt-md1 t-left pd-r-9 cursor" v-on:click="deleteItem(branch.id)" v-if="showDeleteBtn===branch.id"><i class="fas fa-minus-circle"></i></div>
            <div class="list-head txt-md1" :class="$t('font')" v-on:click="setForEdit(branch.id)">{{branch.label}}</div>
            <div class="list-pos en-r txt-xl cursor" v-on:click="setForEdit(branch.id)"><span class="blue-txt"><i class="fal fa-info-circle"></i></span></div>
            <div class="list-head-sub grey4-txt en txt-sm"><i class="fal fa-map-marker-check"></i> {{branch.city}}</div>
          </div>

        </div>

      </div>
      <div class="view-action">
        <div class="container col-2 w-b-line pd-t-20 pd-b-20">
          <clear-btn v-on:click.native="resetToDefaults()"></clear-btn>
          <save-btn :can-submit="canSubmit" :show-right-spinner="showRightSpinner" v-on:click.native="saveForm()"></save-btn>
        </div>

        <div  v-if="showRightSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div class="form" v-if="!showRightSpinner">

          <div class="txt-sm1 pd-l-16 pd-t-10">{{$t('working_hours')}}</div>
          <input-switch
              :label="$t('open_always')"
              v-on:stateChange="form.open_always = !form.open_always"
              :set-state="form.open_always"
              pad="mg-t-1 pd-l-4"
          ></input-switch>
          <div class="grid-col-2" v-if="!form.open_always">
            <input-slot label="open_from">
              <input id="open-from" type="time" name="open_from" min="00:00" max="24:00" v-model="form.open_from">
              <span class="validity"></span>
            </input-slot>
            <input-slot label="open_due">
              <input id="open-due" type="time" name="open_due" min="00:00" max="24:00" v-model="form.open_due">
              <span class="validity"></span>
            </input-slot>
          </div>


          <input-slot label="branch_name" :req="true" :message="errorMessage('label')">
              <input type="text" name="label" autocomplete="false" v-model="form.label">
          </input-slot>
          <div class="group pd-10 mg-t-20" v-if="getCountry">
             <span class="phone-input-desc" :class="$t('font')">{{$t('phone')}}<span class="red-light-txt pd-l-2">*</span>
               <span class="red-light-txt pd-l-4" v-if="errors.phone">{{ errors.phone[0] }}</span>
             </span>
            <vue-tel-input  v-model="phone" @validate="isValid" :defaultCountry="getCountry" :inputOptions="inputOptions" :dropdownOptions="dropdownOptions"></vue-tel-input>
          </div>

         <google-maps  @result="setMapResult" :map="thisForm.map" :street="thisForm.street"  :region="thisForm.region" :city="thisForm.city" :country="thisForm.country" :number="thisForm.number"></google-maps>


        </div>


      </div>
    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import spinner from "@/components/STATIC/spinner";
import inputSlot from "@/components/STATIC/inputSlot";
import support, {displayErrors, searchFilter, validator,getDomain} from "@/support/support";
import Launchpad from "@/components/launchpad";
import saveBtn from "@/components/STATIC/saveBtn";
import ClearBtn from "@/components/STATIC/clearBtn";
import inputSwitch from "@/components/STATIC/inputSwitch";
import {warningMessage,loading} from "@/support/helpers";
import uniSearch from "@/components/component/uni-search";
import GoogleMaps from "@/components/component/googleMaps";

import 'vue-tel-input/dist/vue-tel-input.css';
import { VueTelInput } from 'vue-tel-input';
import config from "@/config";

export default {
  components:{
    GoogleMaps,
    ClearBtn,
    saveBtn,
    Launchpad,
    uniSearch,
    inputSwitch,
    spinner,
    inputSlot,
    VueTelInput,
  },
  data(){
    return {
        showLeftSpinner:false,
        showRightSpinner:false,
        showDeleteBtn:false,
        formErrors:{},
        fetchErrors:{},
        searchFor:'',
        inputOptions:{
          placeholder:this.$t('phone'),
          showDialCode:true,
        },
        dropdownOptions:{
          width:'80vw',
          showFlags:true,
          showSearchBox:false,
        },
        phoneIsValid:false,
        errors: [],
        country:'', //Phone
        phone:'', //TMP PHONE
        form:{
          id:"NEW",
          additional:"",
          city:"",
          label:"Branch-"+support.getRandomInt(90),
          map:"",
          number:"",
          phone:"",
          region:"",
          street:"",
          country:"",
          open_always:true
        }
    }
  },
  mounted(){
      this.country = '';
      this.resetToDefaults();
      this.getIpInfo();

      this.showLeftSpinner = true;
      this._getAllDataFromCloud().then(()=>{
        this.showLeftSpinner = false;
      }).catch(()=>{
        this.showLeftSpinner = false;
      });

  },
  computed:{
    ...mapState('_branch',['CLOUD']),
    ...mapGetters({getTld:'_getTld'}),

    //Balance
    ...mapState('_general',{GENCLOUD:'CLOUD'}),
    getCountry(){
      return this.country;
    },
    thisForm(){
      return this.form;
    },
    currentPackage(){
      let product = this.finProduct(this.GENCLOUD.SUBSCRIPTIONS.product_id);
      return support.returnIfExpected(product,'object');
    },
    balance(){
      return this.GENCLOUD.BALANCE;
    },
    //End Balance

    branches(){
      let getData = (this.CLOUD.length>0) ? this.CLOUD : [];
      let searchColumns =[
        {name:'label',type:'str'},
        {name:'city',type:'str'},
        {name:'address',type:'str'},
        {name:'phone',type:'str'},

      ];
      return searchFilter(this.searchFor,getData,searchColumns,1);
    },
    canSubmit(){
      if(!this.phoneIsValid){
        return false;
      }
      let valuesToValidate = [
        {
          name:'label',
          input:this.form.label,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'city',
          input:this.form.city,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'map',
          input:this.form.map,
          rule:'min:char',
          message:this.$t('min_char'),
        },

      ];
      return this.hasErrors(validator(valuesToValidate));
    },

  },
  methods:{
    ...mapActions('_branch',['_getAllDataFromCloud','_createOrUpdate','_delete']),
    /* Error Handling */
    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    hasErrors(errors){ //METHOD FOR Can Submit

      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
    /* END Error Handling */
    resetToDefaults(){
       this.showLeftSpinner = false;
       this.showRightSpinner = false;
       this.showDeleteBtn = false;
       this.fetchErrors = {};
       this.phoneIsValid=true;
       this.phone="";
       this.errors =[];
       this.form= {
             id:"NEW",
             additional:"",
             city:"",
             label:"Branch-"+support.getRandomInt(90),
             map:"",
             number:"",
             phone:"",
             region:"",
             street:"",
             country:"",
             open_always:false

       };

       },
    getItemById(id){
      return this.CLOUD.find(item => {
        return item.id === id;
      });
    },
    setForEdit(id){
       if(this.showDeleteBtn === id){
         return this.resetToDefaults();
       }
      this.showDeleteBtn = id;

      this.form = Object.assign({},this.getItemById(id));
      return this.phone = "+"+this.form.phone;
    },
    deleteItem(id){
      this.showLeftSpinner = true;
      this._delete(id).then(()=>{
        this.showLeftSpinner = false;
        this.resetToDefaults();
      }).catch(()=>{ this.showLeftSpinner = false; })
    },
    saveForm(){
       //Balance
      if(this.form.id ==="NEW" && this.CLOUD.length>=+this.currentPackage.limit_branch && +this.currentPackage.limit_branch !==-1){
        warningMessage(this.$t('you_have_reached_package_limit'));
        return false;
      }
      //End Balance

      if(!this.canSubmit){
        return false;
      }
      this.showRightSpinner = true;
      this.form.open_always = Number(this.form.open_always);
      this._createOrUpdate(this.form).then(()=>{
        this.showRightSpinner = false;
        this.resetToDefaults();
      }).catch((e)=>{

        this.showRightSpinner = false;
        this.fetchErrors =e.errors;
        this.errors = e.errors;
        if(typeof e.errors.map !== "undefined"){
          warningMessage(this.$t('address'));
        }
      })

    },
    //Balance
    finProduct(id){

      if(this.GENCLOUD.PRODUCTS !==undefined){
        let __PRODUCT =  this.GENCLOUD.PRODUCTS.filter(product=>{
          return product.id ==id;
        });

        if(__PRODUCT[0] !==undefined){
          return this.setProduct = __PRODUCT[0];
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    //End Balance
    search(searchFor){
      this.searchFor = searchFor;
    },
    setMapResult(result){
        this.form.additional = result.state;
        this.form.city = result.city;
        this.form.map = result.latLong;
        this.form.number = result.number;
        this.form.region = result.district;
        this.form.street = result.address;
        this.form.country = this.getTld(result.country).tld;
    },
    isValid(e){

      this.phoneIsValid = e.valid;
      if(this.phoneIsValid){
        this.form.phone = e.number.replace('+',"");
      }
      if(e.countryCode !==undefined){
        this.country = e.countryCode;
      }
    },
    getIpInfo(){
      let self = this;
      fetch('https://ipinfo.io/json?token='+config.ipInfoToken, { headers: { 'Accept': 'application/json' }})
          .then((resp) => resp.json())
          .catch(() => {
            loading(false);
            self.country = getDomain();
          })
          .then((resp) => {
            loading(false);
            self.country=resp.country;
          });
    },


  }
}
</script>

<style scoped>


  /*.view-from-clear-btn {*/
  /*  display: block;*/
  /*  width: 100%;*/
  /*  text-align: left;*/
  /*  justify-items: left;*/
  /*  padding-left: 1.7rem;*/
  /*  margin-top: 3px;*/
  /*  font-size: var(--txt-3xl);*/

  /*}*/
  /*.block{*/
  /*  display: block;*/
  /*}*/
  .list-group-item--addon {
    grid-template-columns: auto 1fr auto !important;
  }
  .has-postxt {
    -moz-column-gap: 1px;
    column-gap: 1px;
    grid-template-areas:
        "it it "
        "co ep"!important;
    grid-template-columns: minmax(10px,245px) auto!important;
  }
</style>
import Api from "./Api";

// const END_POINT_company = 'company';
const END_POINT = 'company/setup/branch';
// const END_POINT_employee = 'company';
// const END_POINT_card = 'company';

export default {

        get() {
          return  Api().get(`${END_POINT}/`);
        },
        create(data) {
            return  Api().post(`${END_POINT}/`,data);
        },
        update(data) {

            return  Api().put(`${END_POINT}/${data.id}`,data);
        },
        delete(id) {
            return  Api().delete(`${END_POINT}/${id}`);
        },

    }
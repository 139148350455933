export default {

    CLOUD:[],
    FROM_DYNAMIC:{
            id:0,
            active_due:"2060-08-19",
            active_due_balance:'0',
            color:"grcolor6",
            discount:5,
            givable_on_amount:'1',
            is_dynamic:[
                {
                    id:'Default-1',
                    card_id:0,
                    amount:1000,
                    discount:5,
                },
                {
                    id:'Default-2',
                    card_id:0,
                    amount:2000,
                    discount:10,
                }
            ],
            label:'Dynamic -5%',
            label_en:false,
            minimum_purchase:'1',
            onetime:false,
            show_label:"",
            logo_link:'pof portfel txt-4xl pd-t-3',
    },
    FRESH_DYNAMIC:{
        id:0,
        active_due:"2060-08-19",
        active_due_balance:'0',
        color:"grcolor"+Math.floor(Math.random() * 400),
        discount:5,
        givable_on_amount:'1',
        is_dynamic:[
            {
                id:'Default-1',
                card_id:0,
                amount:1000,
                discount:5,
            },
            {
                id:'Default-2',
                card_id:0,
                amount:2000,
                discount:10,
            }
        ],
        label:'Dynamic -5%',
        label_en:false,
        minimum_purchase:'1',
        onetime:false,
        show_label:"",
        logo_link:'pof portfel txt-4xl pd-t-3',
    },
    FORM_ONETIME:{
        id:0,
        active_due:"2060-08-19",
        active_due_balance:'0',
        color:"grcolor19",
        discount:5,
        givable_on_amount:'1',
        is_dynamic:false,
        label:'Onetime -90%',
        label_en:false,
        minimum_purchase:'1',
        onetime:true,
        show_label:"",
        logo_link:'pof portfel txt-4xl pd-t-3',
    },
    FRESH_ONETIME:{
        id:0,
        active_due:"2060-08-19",
        active_due_balance:'0',
        color:"grcolor"+Math.floor(Math.random() * 400),
        discount:5,
        givable_on_amount:'1',
        is_dynamic:false,
        label:'Onetime -90%',
        label_en:false,
        minimum_purchase:'1',
        onetime:true,
        show_label:"",
        logo_link:'pof portfel txt-4xl pd-t-3',
    },
    FORM_STANDARD:{
        id:0,
        active_due:"2060-08-19",
        active_due_balance:'0',
        color:"grcolor6",
        discount:5,
        givable_on_amount:'1',
        is_dynamic:false,
        label:'Standard -15%',
        label_en:false,
        minimum_purchase:'1',
        onetime:false,
        show_label:"",
        logo_link:'pof portfel txt-4xl pd-t-3',
    },
    FRESH_STANDARD:{
        id:0,
        active_due:"2060-08-19",
        active_due_balance:'0',
        color:"grcolor"+Math.floor(Math.random() * 400),
        discount:5,
        givable_on_amount:'1',
        is_dynamic:false,
        label:'Standard -15%',
        label_en:false,
        minimum_purchase:'1',
        onetime:false,
        show_label:"",
        logo_link:'pof portfel txt-4xl pd-t-3',
    },












    // TEMPLATE:{
    //     RESET: [
    //         {
    //             id:'Default-1',
    //             card_id:'NEW',
    //             amount:1000,
    //             discount:5,
    //         },
    //         {
    //             id:'Default-2',
    //             card_id:'NEW',
    //             amount:2000,
    //             discount:10,
    //         }
    //     ],
    //     RULES: [
    //         {
    //             id:'Default-1',
    //             card_id:'NEW',
    //             amount:1000,
    //             discount:5,
    //         },
    //         {
    //             id:'Default-2',
    //             card_id:'NEW',
    //             amount:2000,
    //             discount:10,
    //         }
    //     ],
    //     DYNAMIC: {
    //         id:'NEW',
    //         active_due:"",
    //         active_due_balance:'',
    //         color:"grcolor6",
    //         discount:5,
    //         givable_on_amount:'',
    //         is_dynamic:[
    //             {
    //                 id:'Default-1',
    //                 card_id:'NEW',
    //                 amount:1000,
    //                 discount:5,
    //             },
    //             {
    //                 id:'Default-2',
    //                 card_id:'NEW',
    //                 amount:2000,
    //                 discount:10,
    //             }
    //         ],
    //         label:'',
    //         label_en:false,
    //         minimum_purchase:'',
    //         onetime:'',
    //         show_label:"",
    //     }
    // }
}
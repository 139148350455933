import Api from "./Api";


export default {

    register(form) {

        return Api().post("/company/register", form);
    },
    login(form) {
    
        return Api().post("/company/login", form);
    },

     logout() {

        return Api().post("/company/logout");
    },

    auth() {
        return Api().get("/user");

    },
    feedback(form){
        return Api().post("/company/feedback",form);
    },
    /*
    * Update 2021
    */
    sendResetLink(form) {
        return Api().post("/company/reset-password-link", form);
    },
    resetPassword(form) {
        return Api().post("/company/reset-password", form);
    },
    confirmEmail(form) {
        return Api().post("/company/confirm-email", form);
    },
    resendEmailConfirmation(form) {
        return Api().post("/company/resend-email-confirmation", form);
    },
    validateNumber(form) {
        /*
        * Number
        */
        return Api().post("/company/validate-phone", form);
    },
};

import Api from "./Api";


const END_POINT = 'company/setup/partnership';

export default {

    get() {
       return  Api().get(`${END_POINT}/`);
    },
    getTransactions(filter) {
        return  Api().post(`${END_POINT}/transactions`,filter);
    },
    getPartnershipData() {
        return  Api().post(`${END_POINT}/partners`);
    },
    markNotificationsRead(data){
        return  Api().post(`${END_POINT}/notifications/markRead`,data);
    },
    exchangeRequest(data){
        return  Api().post(`${END_POINT}/exchange/request`,data);
    },
    acceptRequest(data){
        return  Api().post(`${END_POINT}/exchange/accept`,data);
    },
    rejectRequest(data){
        return  Api().post(`${END_POINT}/exchange/reject`,data);
    },
    cancelRequest(data){
        return  Api().post(`${END_POINT}/exchange/cancel`,data);
    },
    acceptPartnership(data){
        return  Api().post(`${END_POINT}/accept`,data);
    }
}
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import state from './state';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import _branch from './modules/company/branch';
import _employee from './modules/company/employee';
import _collector from './modules/company/collector';
import _discount from './modules/company/discount';
import _partner from './modules/company/partner';
import _customers from './modules/company/customers';
import _general from './modules/company/general'
import _transactions from './modules/company/transactions'
import _inbox from './modules/company/inbox'
import _stats from './modules/company/stats'
import _partnership from './modules/company/partnership'




export default new Vuex.Store({
    strict: true,
    state,
    getters,
    mutations,
    actions,
    modules: {

        _branch,
        _employee,
        _collector,
        _discount,
        _partner,
        _customers,
        _general,
        _transactions,
        _inbox,
        _stats,
        _partnership

    }
});
import Api from "./Api";

// const END_POINT_company = 'company';
const END_POINT = 'company/payment';
// const END_POINT_employee = 'company';
// const END_POINT_card = 'company';

export default {
    // verifyPayPal(data) {
    //     return  Api().put(`${END_POINT}/paypal`,data);
    // },

    // processPaymentCardToken(data) {
    //     return  Api().post(`${END_POINT}/stripe/processPaymentCardToken`,data);
    // },
    addPaymentCardToken() {
        return  Api().get(`${END_POINT}/stripe/addPaymentCardToken`);
    },
    paymentCardAddSuccess(data) {
        return  Api().post(`${END_POINT}/stripe/paymentCardAddSuccess`,data);
    },
    cancelService(){
        return  Api().get(`${END_POINT}/cancelService`);
    },

    resumeService(){
        return  Api().get(`${END_POINT}/resumeService`);
    },
    changePackage(productId) {
        return  Api().post(`${END_POINT}/changePackage`,productId);
    },
    retryPayment() {
        return  Api().post(`${END_POINT}/retryPayment`);
    },



    getSubscriptionSessionUrl(backUrl) {
        return  Api().post(`${END_POINT}/stripe/getSubscriptionSessionUrl`,backUrl);
    },
    // verifyStripePayment(data){
    //     return  Api().post(`${END_POINT}/stripe/verifyStripePayment`,data);
    // }
}

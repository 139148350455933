import Api from "./Api";
const END_POINT = 'company/setup/customer';


export default {
    getCustomers() {
        return  Api().get(`${END_POINT}/customers`);
    },
    sendNotification(data) {
        return  Api().post(`${END_POINT}/notify`,data);
    },
    exportCustomers(filterData){
        return  Api().post(`${END_POINT}/export`,filterData);
    },
    findCustomer(filterData){
        return  Api().post(`${END_POINT}/find`,filterData);
    },
    importCustomers(data) {
        return  Api().post(`${END_POINT}/import`,data);
    },
    suspendCard(data) {
        return  Api().post(`${END_POINT}/suspend`,data);
    },

}
<template>
  <div>
    <div class="heading-group bg">
      <h6 :class="$t('font_b')" class="txt-3xl mg-t-20 pd-l-8 txt-uppercase">{{$t('partners')}}</h6>

    </div>

    <div class="split-view">

      <div class="view-list">
        <div  v-if="showLeftSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div class="list-group" v-if="!showLeftSpinner">
          <input-switch
              :label="$t('partners_enabled')"
              v-on:stateChange="togglePartners()"
              :set-state="partnersEnabled"
              pad="pad-t-20"
          ></input-switch>
          <div class="value-description" style="justify-content: left;">
            <span>{{$t('partners_enabled_desc')}}</span>
          </div>
          <div class="w-b-line"></div>
          <div class="space-div--10"></div>

          <div class="list-group-item list-group-item--addon" v-for="(item,index) in CLOUD_DATA" :key="index">
            <div class="list-pre red-light-txt txt-md1 t-left pd-r-9 cursor" v-on:click="deleteItem(item.id)" v-if="showDeleteBtn===item.id"><i class="fas fa-minus-circle"></i></div>
            <div class="list-head txt-md1 j-i-center" :class="$t('font')" v-on:click="setForEdit(item.id)">
              <span class="pd-t-2">{{item.partner_label}}</span>
              <span class="mg-l-10 radius pd-2 pd-r-4 pd-l-4 grey5 txt-xs" v-if="+item.accepted<=0">{{$t('pending_request')}}</span>
            </div>
            <div class="list-pos en-r txt-xl" v-on:click="setForEdit(item.id)"><span class="blue-txt cursor"><i class="fal fa-info-circle"></i></span></div>
            <div class="list-head-sub grey4-txt en txt-sm">
              <span v-if="+item.type===2"> <i class="fal fa-coins"></i> </span>
              <span v-if="+item.type===2"> 1<i class="pof currency-l" style="font-weight: bold"></i> = {{ item.rate }} <dollar></dollar></span>
              <span v-if="+item.type===1"><i class="fal fa-credit-card-blank"></i></span>
              <span v-if="+item.type===1"> -{{ item.rate }} <i class="far fa-percent"></i></span>
              <span class="en pd-l-10"><i class="fal fa-calendar-check"></i> {{item.start_date}}</span>
              <span class="en pd-l-10"><i class="fal fa-calendar-times"></i> {{item.end_date}}</span>
              <span class="en pd-l-10" v-if="item.notify_changes>0"><i class="fal fa-bell"></i></span>
            </div>
          </div>

        </div>


      </div>

      <div class="view-action">
        <div  v-if="showRightSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div v-if="!showRightSpinner">

          <div class="container col-2 w-b-line pd-t-20 pd-b-20">
            <clear-btn v-on:click.native="freshForm()"></clear-btn>
            <save-btn :can-submit="canSubmit" :show-right-spinner="showRightSpinner" v-on:click.native="saveForm()"></save-btn>
          </div>


          <div class="space-div--40"></div>

          <div class="container">
            <div class="selection-type-selector--col2 w-margin">
              <div class="selection-type-item selection-left cursor" :class="{'selected':type===1}" v-on:click="setType(1)"><span :class="$t('font')">{{$t('discount')}}</span></div>
              <div class="selection-type-item selection-right cursor" :class="{'selected':type===2}" v-on:click="setType(2)"><span :class="$t('font')">{{$t('collector')}}</span></div>
            </div>
            <div class="space-div--40"></div>
            <div class="grid-col-2 wd-100p" >

              <input-slot label="start_date" :req="true" :message="errorMessage('start_date')" icon="fa-calendar-check">
                <input id="start_date" type="date" name="start_date" autocomplete="false"
                       :value="FORM.start_date"
                       @input="updateValue('start_date',$event.target.value)"
                       @dblclick="updateValue('start_date','')"
                >
                <span class="validity"></span>
              </input-slot>
              <input-slot label="end_date" :req="true" :message="errorMessage('end_date')" icon="fa-calendar-times">
                <input id="end_date" type="date" name="end_date" autocomplete="false"
                       :value="FORM.end_date"
                       @input="updateValue('end_date',$event.target.value)"
                       @dblclick="updateValue('end_date','')"
                >
                <span class="validity"></span>
              </input-slot>
            </div>
            <value-desc text="start_and_end_date_desc"></value-desc>
          </div>

          <input-slot :label="!editExisting?'set_partner':'partner'" :req="true" :message="errorMessage('partner_id')">
            <div v-if="editExisting" class="pd-l-6 txt-md flex-inline j-c-space-between" :class="$t('font')">
              <div class="flex-start">{{FORM.partner_label}}</div>
            </div>
            <v-select v-if="!editExisting" :options="MEMBERS_DATA" @input="setSelectItem" :value="FORM.partner_label">
              <div slot="no-options">{{$t('company_not_found')}}</div>
            </v-select>
          </input-slot>
          <value-desc text="set_partner_desc" v-if="!editExisting"></value-desc>



          <div v-if="type===2"> <!-- Collector -->
            <div class="space-div--20"></div>
            <input-number  label="buy_rate_txt" :req="true" pre="1" pre-unit="Q" unit="L" :message="errorMessage('rate')">
              <input type="number" step="0.01" name="buy_rate_txt" autocomplete="false"
                     :value="FORM.rate"
                     @input="updateValue('rate',$event.target.value)"
                     @dblclick="updateValue('rate','')"
              >
            </input-number>
            <value-desc text="partner_collector_desc"></value-desc>
          </div> <!-- Collector END -->

          <div v-if="type===1"> <!-- Discount -->
            <div class="space-div--20"></div>
            <input-number label="card_discount_percent" :req="true" unit="%" :message="errorMessage('rate')">
              <input type="number" min="0" max="99.99" step="0.01" name="discount" autocomplete="false"
                     :value="FORM.rate"
                     @input="updateValue('rate',$event.target.value)"
                     @dblclick="updateValue('rate','')"
              >
            </input-number>
            <value-desc text="partner_discount_desc"></value-desc>
          </div> <!-- Discount END -->

          <input-switch
              :label="$t('notify_rate_changes')"
              v-on:stateChange="togglePartnerChanges()"
              :set-state="FORM.notify_changes"
              pad="pad-t-20"
              v-if="type===2"
          ></input-switch>
          <div class="value-description" style="justify-content: left;" v-if="type===2">
            <span>{{$t('notify_partner_rate_changes_desc')}}</span>
          </div>
          <div class="space-div--40"></div>

        </div>
      </div>


    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>

import spinner from "@/components/STATIC/spinner";
import {mapActions, mapGetters, mapState} from "vuex";
import inputSlot from "@/components/STATIC/inputSlot";
import vSelect from 'vue-select'
import support, {displayErrors} from "@/support/support";
import inputNumber from "@/components/STATIC/inputNumber";
import valueDesc from "@/components/STATIC/valueDesc";
import Launchpad from "@/components/launchpad";
import InputSwitch from "@/components/STATIC/inputSwitch";
import ClearBtn from "@/components/STATIC/clearBtn";
import SaveBtn from "@/components/STATIC/saveBtn";
import Dollar from "@/components/STATIC/dollar";
import {getDates, warningMessage} from "@/support/helpers";
// import inputSwitch from "@/components/STATIC/inputSwitch";


export default {
  components:{
    Dollar,
    SaveBtn,
    ClearBtn,
    InputSwitch,
    Launchpad,
    vSelect,
    spinner,
    inputSlot,
    inputNumber,
    valueDesc,
    // inputSwitch,


  },
  data(){
    return {

      fetchErrors:{},
      showLeftSpinner:false,
      showRightSpinner:false,
      showDeleteBtn:false,
      FORM:{
        id:0,
        partner_id: "",
        partner_label :"",
        rate :1,
        type :1,
        start_date:getDates().today,
        end_date:'',
        notify_changes:0,
      },
      FRESH_FORM:{
        id:0,
        partner_id: "",
        partner_label :"",
        rate :1,
        type :1,
        start_date:getDates().today,
        end_date:'',
        notify_changes:0,
      },

    }
  },
  mounted(){
    this.showLeftSpinner = true;
    this.showRightSpinner = true;
    this._getAllDataFromCloud().then(()=>{
      this.showLeftSpinner = false;
    }).catch(()=>{
      this.showLeftSpinner = false;
    });
    this._getMemberDataFromCloud().then(()=>{
      this.showRightSpinner = false;
    }).catch(()=>{
      this.showRightSpinner = false;
    });
  },
  computed:{
    ...mapState('_partner',['CLOUD','MEMBER_DB']),
    ...mapState('_general',{GCLOUD:'CLOUD'}),
    ...mapGetters('_partner',['getPartner']),

    //Balance
    currentPackage(){
      let product = this.finProduct(this.GCLOUD.SUBSCRIPTIONS.product_id);
      return support.returnIfExpected(product,'object');
    },
    balance(){
      return this.GCLOUD.BALANCE;
    },
    //End Balance

    /*
    * Approved Start
    */
    editExisting(){
      return this.FORM.id >0;
    },
    type(){ //PartnershipType
      return this.FORM.type;
    },
    CLOUD_DATA(){ return this.CLOUD ?? []; },
    MEMBERS_DATA(){ return this.MEMBER_DB ?? []; },


    canSubmit(){
      return (
        this.FORM.partner_id>0
        && this.FORM.rate>0
        && this.FORM.start_date.length>0
        && this.FORM.end_date.length>0
        && this.FORM.type>0
      );
    },
    partnersEnabled(){
      return this.GCLOUD.SETTINGS.has_partners;
    }
    /*
    * Approved End
    */

  },
  methods:{
    ...mapActions('_partner',['_getAllDataFromCloud','_getMemberDataFromCloud','_delete','_createOrUpdate','_freshForm','_updateFormValue','_setEdit']),
    ...mapActions('_general',['_toggleSettings']),


    togglePartners(){
      let form = {
        toggle:'partners',
        state: !this.partnersEnabled,
      }
      return this._toggleSettings(form);
    },
    togglePartnerChanges(){
        return this.FORM.notify_changes = !this.FORM.notify_changes;
    },

    /*
    * Approved Start
    */
    /* Error Handling */
    errorMessage(input){
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    hasErrors(errors){ //METHOD FOR Can Submit
      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
    /* END Error Handling */


    setType(type){
      let partnerId = '';
      let partnerLabel = '';
      if(this.editExisting){
         partnerLabel = this.FORM.partner_label;
         partnerId = this.FORM.partner_id;
      }
      this.resetToDefaults();
      this.FORM.partner_label = partnerLabel;
      this.FORM.partner_id = partnerId;
      return this.FORM.type = type;
    },
    resetToDefaults(){
      this.showDeleteBtn = false;
      this.fetchErrors = {};
      this.FORM = {};
      this.FORM = Object.assign({},this.FRESH_FORM);
    },

    setForEdit(id){
      if(this.showDeleteBtn === id){ //Second Click
          return this.resetToDefaults();
      }
      this.showDeleteBtn = id;

    },
    freshForm(){
      this.resetToDefaults();
    },
    saveForm(){

      if(!this.canSubmit){
        return false;
      }


      //Balance
      if(this.FORM.id <=0 && +this.CLOUD.length>=+this.currentPackage.discount_partners && +this.currentPackage.discount_partners !==-1){
        warningMessage(this.$t('you_have_reached_package_limit'));
        return false;
      }
      //End Balance

      this.showRightSpinner = true;


      this._createOrUpdate(this.FORM).then(()=>{
          this.showRightSpinner = false;
          this.freshForm();
      }).catch((error)=>{
        this.showRightSpinner = false;
        this.fetchErrors =error.errors;
      });

    },
    deleteItem(id){
      this.showLeftSpinner = true;
      this._delete(id).then(()=>{
        this.freshForm();
        this.showLeftSpinner = false;
      }).catch(()=>{
        this.showLeftSpinner = false;
      })
    },
    setSelectItem(value){

      let UPDATE_ID = {
        name:'partner_id',
        value:(value && value.id !==undefined) ? value.id : "",
      };
      let UPDATE_LABEL = {
        name:'partner_label',
        value:(value && value.id !==undefined) ? value.label : "",
      };
      this.updateValue(UPDATE_ID.name,UPDATE_ID.value);
      this.updateValue(UPDATE_LABEL.name,UPDATE_LABEL.value);
    },
    updateValue(name,value){
      return this.FORM[name] = value;
    },

    //Balance

    finProduct(id){

      if(this.GCLOUD.PRODUCTS !==undefined){
        let __PRODUCT =  this.GCLOUD.PRODUCTS.filter(product=>{
          return +product.id ===+id;
        });

        if(__PRODUCT[0] !==undefined){
          return this.setProduct = __PRODUCT[0];
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    //End Balance
/*
* Approved End
*/
  }
}
</script>

<style  scoped>
@import "../assets/css/cardsPage.css";
.info-box {
  display: grid;
  grid-template-areas: "box-content box-info-icon" "box-content box-action-button";
  grid-template-columns: auto 55px;
  grid-template-rows: auto 55px;
  padding: 15px 15px 15px 15px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 5px;
  border-radius: 12px;
  -webkit-box-shadow: 0 2px  8px rgba(0,0,0,0.08);
  -moz-box-shadow: 0 2px  8px rgba(0,0,0,0.08);
  box-shadow: 0 2px  8px rgba(0,0,0,0.08);


  justify-self: center;
}
.info-box-content {
  grid-area: box-content;
  display: flex;
  justify-content: left;
  text-align: left;
  font-size: 15px;

}
.info-box-content >ul{
  text-decoration: none;
  list-style: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  line-height: 25px;
}
.info-box-button{
  grid-area: box-action-button;
  display: flex;

  justify-content: center;
  justify-items: center;
  width: 100%;
  height: 100%;

}
</style>
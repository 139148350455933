<template>
  <div class="notification-frame" v-if="userNotificationWindow">
    <div class="flex-center-raw a-i-center j-i-center" style="height: 100vh;">
      <div class="radius white w-margin pd-t-30 pd-b-30 pd-l-30 pd-r-30" style="width: 50vw; height: auto; border: 1px solid var(--blue) !important;">
        <div class="flex-inline a-i-center">
          <div  @click="closeUserNotificationWindow()" class="cursor pd-r-10">
            <i class="fal fa-times-circle red-light-txt txt-lg1 cursor"></i>
          </div>
          <div :class="$t('font_b')">{{$t('send_notify_msg_user_txt')}}</div>
        </div>
        <div v-if="!userNotificationSent" class="notification-enter">
          <input-slot label="title" :req="true" :message="errorMessage('title')">
            <input type="text" name="heading" autocomplete="false" v-model="form.title">
          </input-slot>
          <div class="input-group w-margin" style="height: 120px !important;">
            <div class="input-desc" :class="$t('font')" v-html="labelOrError('message',errorMessage('message'))"></div>
            <div class="input-area" style="height: 100px;">
              <textarea rows="4" cols="50" style="height: auto !important;" v-model="form.message"></textarea>
            </div>
          </div>
          <div class="w-margin pd-r-20 flex-center-raw wd-100p">
            <div class="txt-7xl grey3-txt container t-center mg-t-30" v-if="loadingSpinner">
              <SpinnerVuex :spinner-number="0"></SpinnerVuex>
            </div>
            <div v-if="!loadingSpinner"><button class="btn btn-md blue cursor" @click="sendUserNotification()">{{ $t('send') }}</button></div>
          </div>
        </div>
        <div v-if="userNotificationSent"  style="height: 100%; width: 100%;" class="notification-sent flex j-i-center j-c-center a-i-center">
          <div class="t-center" style="width: auto;">
            <i class="fal fa-check-circle green-txt txt-7xl"></i>
            <p>{{$t('notification_sent')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSlot from "@/components/STATIC/inputSlot";
import {userNotifyWindow,userNotificationSent} from "@/support/userNotifications";
import {mapGetters} from "vuex";
import customers from "@/apis/customers";
import {displayErrors, httpStatusActions, validator} from "@/support/support";
import {ShowSpinner} from "@/support/loading";
import SpinnerVuex from "@/components/STATIC/spinnerVuex";
export default {
  name: "userNotificationWindow",
  components: {
    SpinnerVuex,
    InputSlot,
  },
  data(){
    return {
      form:{
        card_id:'',
        title:'',
        message:'',
      },
      formErrors: {},
      fetchErrors:{},
    }
  },
  mounted() {
    this.resetForm();
  },
  computed:{
    ...mapGetters(['_userNotificationWindow','_getUserNotificationMessage']),
    //SPINNERS
    ...mapGetters(['_loadingSpinnerStatus']),
    loadingSpinner(){
      return this._loadingSpinnerStatus(0);
    },
    userNotificationWindow(){
      return this._userNotificationWindow.ENABLED;
    },
    userNotificationSent(){
      return this._userNotificationWindow.SENT;
    },
    canSubmit(){
      let valuesToValidate = [
        {
          name:'title',
          input:this.form.title,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'message',
          input:this.form.message,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        ];
      return this.hasErrors(validator(valuesToValidate));

    }
  },
  methods:{
    resetForm(){
      this.form = {
        card_id:'',
        title:'',
        message:'',
      };
      this.formErrors= {};
      this.fetchErrors={};
    },
    closeUserNotificationWindow(){
      this.resetForm();
      return userNotifyWindow(false);
    },
    sendUserNotification(){
      if(!this.canSubmit){
        return false;
      }
      this.form.card_id = this._getUserNotificationMessage.card_id;
      ShowSpinner(0);
      customers.sendNotification(this.form).then(()=>{
         this.resetForm();
          ShowSpinner(0,false);
         return userNotificationSent();
      }).catch((e)=>{
        let error = httpStatusActions(e);
        ShowSpinner(0,false);
        return this.fetchErrors = error.errors;
      });

    },
    /* Error Handling */
    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    hasErrors(errors){ //METHOD FOR Can Submit
      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
    labelOrError(label,errorMsg){
      if(errorMsg){
        return '<span class="red-light-txt">* '+errorMsg+'</span>';
      }
      return this.$t(label);
    },
    /* END Error Handling */
  }
}
</script>

<style scoped>

</style>
<template>
  <div :class="(!disabled)?'cursor':''" >
      <div :class="setClass" class="flex-row column-gap2 j-i-center j-c-center a-i-center pd-6 radius" style="max-width: 90px;" @click="onClick()">
        <div v-if="!Spinner && icon" :class="$t('font')" class=""><i :class="icon"></i></div>
        <div v-if="!Spinner" :class="$t('font')" class="t-center">{{label}}</div>
        <div :class="$t('font')"><spinner v-if="Spinner"></spinner></div>
      </div>
  </div>

</template>

<script>
import spinner from "@/components/STATIC/spinner";
export default {
  components: {spinner},
  props:{
    disabled:{
      default:false,
    },
    showSpinner:{
      default:false,
    },
    icon:{
      default:false,
    },
    label:{
      default: '',
    },
    setClass:{
      default:'btn-sm txt-md'
    },
  },
  data(){
     return {
       enableSpinner:false,
     }
  },
  watch:{
    showSpinner:function(oldVal,newVal){
        if(oldVal!==newVal){
          this.enableSpinner = this.showSpinner;
        }
    }
  },
  computed:{
    Spinner(){
      return this.enableSpinner;
    }
  },
  methods:{
    onClick(){
      return this.$emit('onClick');
    }
  }
}
</script>

<style scoped>

</style>
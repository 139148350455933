<template>
  <div>
  <div class="value-description">
    <span>{{$t(text)}}</span>
  </div>
  <div v-if="withSpace" :class="'space-div--'+space"></div>
  </div>
</template>

<script>
export default {
    props:{
      withSpace:{
        default:true,
      },
      space:{
        default:40,
      },
      text:{
        default:'no_text_defined',
      }
    }
}
</script>

<style scoped>

</style>
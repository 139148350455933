<template>
<div>
  <application-window :title="$t('exchange_request')" :success-msg="$t('request_sent')">
    <template #content>
      <div class="flex-center-raw j-c-end wd-100p">
        <span class="mg-r-30  txt-md1" :class="incorrectDeductAmount?'red-light-txt':'grey3-txt'">
          {{$t('balance')}}: {{awBalance}}
          <dollar v-if="+awCardType===1"></dollar>
          <i class="pof points" v-if="+awCardType===2"></i>
        </span></div>
      <div class="w-margin">
        <input-number :unit="(+awCardType===1)?'L':'Q'" :req="true" label="deduct_balance" :message="errorMessage('deduct_balance')">
          <input type="number" v-model="requestForm.deduct_balance" @wheel="$event.target.blur()"  name="amount" autocomplete="false">
        </input-number>
        <input-number unit="L" :req="false" label="request_amount" :message="errorMessage('request_amount')">
          <input type="number" v-model="requestForm.request_amount" @wheel="$event.target.blur()"  name="amount" autocomplete="false">
        </input-number>
        <div class="input-group w-margin" style="height: 120px !important;">
          <div class="input-desc" :class="$t('font')" v-html="labelOrError('message',errorMessage('comment'))"></div>
          <div class="input-area" style="height: 100px;">
            <textarea rows="4" cols="50" style="height: auto !important;" v-model="requestForm.comment"></textarea>
          </div>
        </div>
      </div>
    </template>
    <template #submit>
      <input-button :label="$t('send')" icon="fad fa-people-arrows" set-class="txt-md pd-10 yellow" :show-spinner="exchangeRequestSpinner" @click.native="submitExchangeRequest()"></input-button>
    </template>
  </application-window>
  <application-window :id="2" :title="$t('reject')" :success-msg="$t('operation_success')">
    <template #content>
      <div class="w-margin">
        <div class="input-group w-margin" style="height: 120px !important;">
          <div class="input-desc" :class="$t('font')" v-html="labelOrError('message',errorMessage('comment'))"></div>
          <div class="input-area" style="height: 100px;">
            <textarea rows="4" cols="50" style="height: auto !important;" v-model="requestForm.comment"></textarea>
          </div>
        </div>
      </div>
    </template>
    <template #submit>
      <input-button :label="$t('reject')" set-class="txt-md red" :show-spinner="rejectButtonSpinner" @click.native="rejectRequest()"></input-button>
    </template>
  </application-window>
  <application-window id="3" :title="$t('info')">
    <template #content>
      <p class="txt-xs1">{{infoMessage}}</p>
    </template>
  </application-window>
  <div class="heading-group bg">
    <h6 :class="$t('font_b')" class=" txt-3xl mg-t-20 pd-l-8 txt-uppercase">{{$t('partnership_data')}}</h6>
  </div>
    <div class="split-view">


      <div class="view-list">
        <div class="flex-center-raw j-i-center a-i-center wd-100p grey5-txt a-i-center txt-2xl txt-b t-center w-margin" v-if="!theyHasData && !youHasData && !requestsHasData"><span>{{$t('no_partners_data')}}</span></div>
        <!-- Wants to Accept -->
        <div  class="group grid-col-2 a-i-center pd-b-20 w-b-line" v-if="requestsHasData">
          <div class="flex-start grey5-txt a-i-center txt-2xl txt-b txt-capital" :class="$t('font')">{{$t('wants_to_accept')}}</div>
          <div class="w-margin">
            <uni-search v-on:search="searchRequests"></uni-search>
          </div>
        </div>
        <!-- Wants to Accept List -->
        <Accept_block_requests :data="requests" v-on:awOpen="resetRequestForm"></Accept_block_requests>


        <!-- They Accept -->
        <div  class="group grid-col-2 a-i-center pd-b-20 w-b-line" v-if="theyHasData">
          <div class="flex-start grey5-txt a-i-center txt-2xl txt-b txt-capital" :class="$t('font')">{{$t('accept_your_cards')}}</div>
          <div class="w-margin">
            <uni-search v-on:search="searchThey"></uni-search>
          </div>
        </div>
        <!-- They Accept List -->
        <Accept_block :data="theyAcceptCards" v-on:awOpen="resetRequestForm"></Accept_block>
        <!-- You accept -->
        <div  class="group grid-col-2 a-i-center pd-b-20 w-b-line" v-if="youHasData">
          <div class="flex-start grey5-txt a-i-center txt-2xl txt-b txt-capital" :class="$t('font')">{{$t('you_accept_cards')}}</div>
          <div class="w-margin">
            <uni-search v-on:search="searchYou"></uni-search>
          </div>
        </div>
        <!-- Finished Partnership List -->
        <Accept_block :data="youAcceptCards" v-on:awOpen="resetRequestForm"></Accept_block>

        <!-- Finished Partnership -->
        <div  class="group grid-col-2 a-i-center pd-b-20 w-b-line" v-if="finishedHasData">
          <div class="flex-start grey5-txt a-i-center txt-2xl txt-b txt-capital" :class="$t('font')">{{$t('canceled_or_finished_partnerships')}}</div>
          <div class="w-margin">
            <uni-search v-on:search="searchFinished"></uni-search>
          </div>
        </div>
        <!-- Finished Partnership List -->
        <Accept_block_finished :data="finished"></Accept_block_finished>
      </div>

      <div class="view-action">

        <!-- RIGHT -->
        <div  v-if="showRightSpinner" class="txt-7xl grey3-txt container t-center mg-t-30"><spinner></spinner></div>
        <Partnership_transaction_filter v-if="!showRightSpinner"></Partnership_transaction_filter>
        <Partnership_transactions_block v-if="!showRightSpinner"></Partnership_transactions_block>

      </div>


    </div>

  <launchpad></launchpad>
</div>
</template>

<script>
import Launchpad from "@/components/launchpad";

import {mapActions, mapGetters, mapState} from "vuex";
import {
  displayErrors,
  searchFilter,
   validator
} from "@/support/support";


import Spinner from "@/components/STATIC/spinner";
import {hasData,} from "@/support/helpers";
import Dollar from "@/components/STATIC/dollar";


import {aw} from "@/support/applicationWindow";

import ApplicationWindow from "@/components/component/applicationWindow";
import InputNumber from "@/components/STATIC/inputNumber";
import InputButton from "@/components/STATIC/inputButton";
import Accept_block from "@/components/component/partnership/accept_block";
import uniSearch from "@/components/component/uni-search";
import Partnership_transactions_block from "@/components/component/partnership/partnership_transactions_block";
import Partnership_transaction_filter from "@/components/component/partnership/partnership_transaction_filter";
import Accept_block_requests from "@/components/component/partnership/accept_block_requests";
import Accept_block_finished from "@/components/component/partnership/accept_block_finished";



export default {

  components: {
    Accept_block_finished,
    Accept_block_requests,
    Partnership_transaction_filter,
    Partnership_transactions_block,
    Accept_block,
    InputButton,
    InputNumber,
    ApplicationWindow,


    Dollar,
    Spinner,

    Launchpad,
    uniSearch,

  },
  data(){
    return {

      showLeftSpinner:false,
      showRightSpinner:false,
      rejectButtonSpinner:false,
      showDownloadSpinner:false,
      exchangeRequestSpinner:false,

      searchStrThey:'',
      searchStrYou:'',
      searchStrRequests:'',
      searchStrFinished:'',



      requestForm:{
        id:'', //Partnership ID
        deduct_balance:'',
        request_amount:'',
        comment:'',
      },
      formErrors: {},
      fetchErrors:{},

    }
  },
  mounted() {
     this.resetRequestForm();

     this.showLeftSpinner = true;
     this.showRightSpinner = true;

    // if(canRequestUpdate('_part',+2) || this.partnerData.length<=0){
    //
    // }
    this._getPartnership().then(()=>{
      this.showLeftSpinner = false;
      // setCloudDataUpdatedToken('_part');
    }).catch(()=>{
      this.showLeftSpinner = false;
      // setCloudDataUpdateFail('_part');
    });

     this._getTransactions(this.submitForm).then(()=>{
           this.showRightSpinner = false;
     });




  },
  computed:{
      ...mapGetters(['_applicationWindowData']),
      ...mapState('_partnership',['TRANSACTIONS','PARTNERSHIP']),

      awData(){
        return this._applicationWindowData;
      },
      infoMessage(){
        return this.awData.message;
      },
      awBalance(){ // Balance in Application Window
        if(this.awData.balance !==undefined){
          return this.awData.balance;
        }
        return 0;
      },
      awCardType(){ // CardType in Application Window
        if(this.awData.card_type !==undefined){
          return this.awData.card_type;
        }
        return 0;
      },

      partnershipData(){
        return this.PARTNERSHIP;
      },
      theyHasData(){
        return hasData(this.partnershipData.THEY_ACCEPT_CARDS);
      },
      requestsHasData(){
        return hasData(this.partnershipData.REQUESTS);
      },
      youHasData(){
        return hasData(this.partnershipData.YOU_ACCEPT_CARDS);
      },
      finishedHasData(){
        return hasData(this.partnershipData.FINISHED);
      },
      requests(){

        let data = this.partnershipData.REQUESTS;
        let getData = (hasData(data)) ? data : [];
        let searchColumns =[
          {name:'label',type:'str'},
          {name:'start_date',type:'str'},
          {name:'reject_comment',type:'str'},
          {name:'comment',type:'str'},
        ];
        return searchFilter(this.searchStrRequests,getData,searchColumns,1);
      },
      finished(){

        let data = this.partnershipData.FINISHED;
        let getData = (hasData(data)) ? data : [];
        let searchColumns =[
          {name:'label',type:'str'},
          {name:'start_date',type:'str'},
        ];
        return searchFilter(this.searchStrFinished,getData,searchColumns,1);
      },
      theyAcceptCards(){

        let data = this.partnershipData.THEY_ACCEPT_CARDS;
        let getData = (hasData(data)) ? data : [];
        let searchColumns =[
          {name:'label',type:'str'},
          {name:'start_date',type:'str'},
          {name:'reject_comment',type:'str'},
          {name:'comment',type:'str'},
        ];
        return searchFilter(this.searchStrThey,getData,searchColumns,1);
      },
      youAcceptCards(){

        let data = this.partnershipData.YOU_ACCEPT_CARDS;
        let getData = (hasData(data)) ? data : [];
        let searchColumns =[
          {name:'label',type:'str'},
          {name:'start_date',type:'str'},
          {name:'reject_comment',type:'str'},
          {name:'comment',type:'str'},
        ];
        return searchFilter(this.searchStrYou,getData,searchColumns,1);
      },
      filterState(){
        return this.showFilter;
      },

      incorrectDeductAmount(){
        return (+this.requestForm.deduct_balance > +this.awBalance);
      },
      canSubmitRequest(){
        let valuesToValidate = [
          {
            name:'amount',
            input:this.requestForm.deduct_balance,
            rule:'min:num',
            message:this.$t('min_num'),
          },
        ];
        if(this.hasNoErrors(validator(valuesToValidate))){
            return (+this.requestForm.deduct_balance <=+this.awBalance);
        }
        return false;

      }
  },
  methods:{
    ...mapActions('_partnership',['_getPartnership','_getTransactions','_rejectRequest','_acceptRequest','_exchangeRequest']),

    searchRequests(searchFor){
      this.searchStrRequests = searchFor;
    },
    searchThey(searchFor){
      this.searchStrThey = searchFor;
    },
    searchYou(searchFor){
      this.searchStrYou = searchFor;
    },
    searchFinished(searchFor){
      this.searchStrFinished = searchFor;
    },
    resetRequestForm(){
      this.requestForm = {
        id:'', //Partnership ID
        deduct_balance:'',
        request_amount:'',
        comment:'',
      };
      this.formErrors= {};
      this.fetchErrors={};
    },
    /* Error Handling */
    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    hasNoErrors(errors){ //METHOD FOR Can Submit
      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
    labelOrError(label,errorMsg){
      if(errorMsg){
        return '<span class="red-light-txt">* '+errorMsg+'</span>';
      }
      return this.$t(label);
    },
    /* END Error Handling */
    submitExchangeRequest(){
      if(!this.canSubmitRequest){
        return false;
      }

      this.exchangeRequestSpinner =true;
      this.showLeftSpinner = true;
      this.requestForm.id = this.awData.id;

      return this._exchangeRequest(this.requestForm).then(()=>{
        this.showLeftSpinner = false;
        this.resetRequestForm(); //Reset
        this.exchangeRequestSpinner =false;
        aw(false);
      }).catch(()=>{
        aw(false);
      });
    },
    rejectRequest(){
      if(this.rejectButtonSpinner){ //Repeated Click
        return false;
      }
      let data = {
        id:this.awData.id,
        comment:this.requestForm.comment,
      };
      this.rejectButtonSpinner = true;
      this.showLeftSpinner = true;
      return this._rejectRequest(data).then(()=>{
            aw(false);
            this.showLeftSpinner = false;
            this.rejectButtonSpinner =false;
            this.requestForm.comment = '';
      }).catch(()=>{
          aw(false);
      });

    },
  }
}
</script>

<style scoped>


</style>
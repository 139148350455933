<template>
  <i class="pof points"></i>
</template>
<script>
export default {
  props:{
    setClass:{
      default:'currency-symbol',
    },
    setStyle:{
      default:'',
    },
    wNumber:{
      default:false,
    }
  }
}
</script>
<style >
.w-number {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "icon number" ;
}
.number {
  grid-area: number;
  min-width: 10px;
  width: 10px;
  color: #1C1C1E;
}
.icon{
  grid-area:icon;
  width: 15px;
  display: inline;
  margin-right: 5px;
  margin-left: 2px;
  position: relative;
  top: 0.26em;
  fill:var(--label-primary);
  stroke:var(--label-primary);
  stroke-miterlimit:10;
  stroke-width:5.2px;
}
.currency-symbol{
  fill:var(--label-primary);
  stroke:var(--label-primary);
  stroke-miterlimit:10;
  stroke-width:5.2px;


}
.c-icon-defaults,.c-icon-defaults >svg{
  width: 15px;
  display: inline;
  margin-right: 5px;
  margin-left: 2px;
  position: relative;
  top: 0.26em;

}
</style>
<template>
<div>
  <div class="headline-txt">{{ $t('customers') }}</div>
  <div class="section-wrp">
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('age_segment')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <pie-chart :chart-data="ageData"></pie-chart>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('gender_segment')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <pie-chart :chart-data="genderData"></pie-chart>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('unique_vs_repeated')}} </div>
        <div class="period">{{$t('year')}} <i class="fal fa-sort pd-l-5"></i></div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <line-chart :chart-data="uniqueVsRepeatedDay"></line-chart>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('visit_times')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <dounut-chart :chart-data="customerVisitHours"></dounut-chart>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('top_visitors')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">

          <pie-chart :chart-data="topVisitors"></pie-chart>
        </div>
      </div>
    </div>
    <div class="section-item">
      <div class="title">
        <div class="name"><i class="fal fa-info-circle pd-r-5"></i> {{$t('top_spenders')}} </div>
        <div class="period">{{$t('year')}}</div>
      </div>
      <div class="body-wrp">
        <div class="body">
          <dounut-chart :chart-data="topSpenders"></dounut-chart>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import PieChart from "@/components/STATIC/pieChart";
import LineChart from "@/components/STATIC/lineChart";
import DounutChart from "@/components/STATIC/dounutChart";
import {chartColors, shuffle} from "@/support/helpers";
export default {
  name: "section-customer",
  components: {DounutChart, LineChart, PieChart},
  props:{
    CLOUD:Object,
  },
  computed:{
    genderData(){
      let labels = [this.$t('male'),this.$t('female')];
      return {
        labels,
        datasets: [{
          data: [this.CLOUD.customers.male,this.CLOUD.customers.female],
          backgroundColor: chartColors(),
        }]
      }
    },
    ageData(){
      let labels = this.CLOUD.customers.ageSegment.labels;
      return {
        labels,
        datasets: [{
          data:this.CLOUD.customers.ageSegment.data ,
          backgroundColor: chartColors(),
        }]
      }
    },
    topSpenders(){
      let labels = this.CLOUD.customers.topSpenders.labels;
      return {
        labels,
        datasets: [{
          data:this.CLOUD.customers.topSpenders.data ,
          backgroundColor: chartColors(),
        }]
      }
    },


    topVisitors(){
      let labels = this.CLOUD.customers.topVisitors.labels;
      return {
        labels,
        datasets: [{
          data:this.CLOUD.customers.topVisitors.data,
          backgroundColor: chartColors(),
        }]
      }
    },
    uniqueVsRepeatedDay(){
      let color1 = shuffle(chartColors())[0];
      let color2 = shuffle(chartColors())[0];
      return{
        labels: this.CLOUD.customers.visitsDay.labels,
        datasets: [
          {
            label: [this.$t('customer_unique_visits')],
            data: this.CLOUD.customers.uniqueVisitsDay.data,
            backgroundColor:color1,
            borderColor: color1,
          },
          {
            label: [this.$t('customer_visits')],
            data: this.CLOUD.customers.visitsDay.data,
            backgroundColor:color2,
            borderColor: color2,
          },
        ]
      }
    },
    customerVisitHours(){
      let labels = this.CLOUD.customers.visitTimes.labels;
      return {
        labels,
        datasets: [{
          data:this.CLOUD.customers.visitTimes.data ,
          backgroundColor: chartColors(),
        }]
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>

    <div class="flex-inline flex-start wd-100p" >
      <div class="txt-3xl mg-r-4">
        <i class="fal fa-wallet"></i>
      </div>
      <div class="grid-row-3">
        <div class="txt-xs1">{{ $t('balance') }}<i @click="balanceInfo()" class="cursor txt-xs blue-txt pd-l-4 fal fa-question-circle"></i></div>
        <div class="txt-m en-b">
          <span class="pd-r-1">{{item.balance}}</span>
          <i  v-if="item.type===2" class="pof points"></i>
          <dollar v-if="item.type===1"></dollar>

        </div>
      </div>
    </div>
</template>

<script>
import Dollar from "@/components/STATIC/dollar";
import {awOpen} from "@/support/applicationWindow";


export default {
  components: {Dollar},
  props: {
    item: {
      default() {
        return {}
      }
    },
  },
  name: "balance",

  methods:{
    balanceInfo(){
      return awOpen(3, {message: this.$t('balance_desc_txt')});
    },
  }
}
</script>

<style scoped>

</style>
import Api from "./Api";

const END_POINT = 'company/inbox';


export default {

    get(filter) {
        return  Api().post(`${END_POINT}/`,filter);
    },
    update(data) {
        return  Api().put(`${END_POINT}/read/${data.id}`,data);
    },
    delete(id) {
        return  Api().delete(`${END_POINT}/delete/${id}`);
    },


}
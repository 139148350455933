<template>
<div>
  <div class="headline-txt">{{ $t('overview') }}</div>
  <div class="overview-wrp">

    <div class="overview-item">
      <div class="title">{{$t('customers')}}</div>
      <div class="number" v-if="customers.today" @click="customers.today = !customers.today"><span>{{ customersToday }}</span><i class="fas fa-sort pd-r-4"></i></div>
      <div class="number"  v-if="!customers.today" @click="customers.today = !customers.today"><span>{{ totalCustomers }}</span><i class="fas fa-sort pd-r-4"></i></div>
      <div class="range" v-if="customers.today">{{$t('today')}}</div>
      <div class="range" v-if="!customers.today">{{$t('total')}}</div>
    </div>
    <div class="overview-item">
      <div class="title">{{$t('crr')}}</div>
      <div class="number"><span>{{ crr }}</span></div>
      <div class="range">{{$t('month')}}</div>
    </div>
    <div class="overview-item">
      <div class="title text-short-m">{{$t('transactions')}}</div>
      <div class="number" v-if="trans.today" @click="trans.today = !trans.today"><span>{{transToday}}</span><i class="fas fa-sort pd-r-4"></i></div>
      <div class="number" v-if="!trans.today" @click="trans.today = !trans.today"><span>{{transTotal}}</span><i class="fas fa-sort pd-r-4"></i></div>
      <div class="range" v-if="trans.today">{{$t('today')}}</div>
      <div class="range" v-if="!trans.today">{{$t('total')}}</div>
    </div>
    <div class="overview-item">
      <div class="title">{{$t('likes')}}</div>
      <div class="number" v-if="love.today" @click="love.today = !love.today"><span>{{loveToday}}</span><i class="fas fa-sort pd-r-4"></i></div>
      <div class="number" v-if="!love.today" @click="love.today = !love.today"><span>{{loveTotal}}</span><i class="fas fa-sort pd-r-4"></i></div>
      <div class="range" v-if="love.today">{{$t('today')}}</div>
      <div class="range" v-if="!love.today">{{$t('total')}}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "section-overview",
  props:{
    CLOUD:Object,
  },
  data() {
    return {
      pageLoaded: false,
      customers: {
        today: true, //False Total
      },
      love: {
        today: true, //False Total
      },
      trans: {
        today: true, //False Total
      },
      collector: {
        today: true, //False Total
      }
    }
  },
  computed:{
    /*
    * Customers
    */
    totalCustomers(){
      return this.CLOUD.customers.total;
    },
    customersToday(){
      if(+this.CLOUD.customers.newToday >0){
        return '+'+this.CLOUD.customers.newToday;
      }
      return this.CLOUD.customers.newToday;
    },
    crr(){
      return this.CLOUD.customers.crr;
    },
    /*
    * Love
    */
    loveToday(){
      if(+this.CLOUD.love.today >0){
        return '+'+this.CLOUD.love.today;
      }
      return this.CLOUD.love.today
    },
    loveTotal(){
      return this.CLOUD.love.total
    },
    /*
    * End Love
    */

    /*
    * Transactions
    */
    transToday(){
      if(+this.CLOUD.transaction.today_total_count >0){
        return '+'+this.CLOUD.transaction.today_total_count;
      }
      return this.CLOUD.transaction.today_total_count
    },
    transTotal(){
      return this.CLOUD.transaction.total
    },
    /*
    * End Transactions
    */
  }
}
</script>

<style scoped>

</style>
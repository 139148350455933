/*
*
*/
export const SET_CLOUD_DATA = (state,response) => {
    let newData = response;
    state.CLOUD ={};
    state.CLOUD = Object.assign({}, state.CLOUD,newData);
}
/*
*
*/
export const INSERT_NEW = (state,record) => {
    record.form.id = record.id;
    return state.CLOUD.cards.push(record.form);
}
/*
*
*/
export const UPDATE_EDITED_VALUE = (state,form) => {
    return Object.assign(state.CLOUD.cards.find(obj=>parseInt(obj.id)===parseInt(form.id)),form);
}
/*
*
*/
export const REMOVE_CLOUD_DATA = (state,id) => {
    state.CLOUD.cards = state.CLOUD.cards.filter(from => {
        return parseInt(from.id) !== parseInt(id);
    })
}

/*
*  id = CARD Id
*/
export const SET_DYNAMIC_EDIT = (state,id)=>{
    let CARD = state.CLOUD.cards.filter(obj=>{
        return obj.id === id;
    })
    state.FROM_DYNAMIC = Object.assign({},CARD[0]);
}
/*
* RULE = {
                    id:'XJ6373',
                    card_id:'0',
                    amount:1000,
                    discount:5,
         }
*/
export const ADD_DYN_RULE = (state,RULE)=>{
    //Get Current Condition
    let currentState = state.FROM_DYNAMIC.is_dynamic;
    //Push Update
    currentState.push(RULE);
    state.FROM_DYNAMIC.is_dynamic = [];
    return state.FROM_DYNAMIC.is_dynamic = Object.assign([],currentState);
}
/*
* RULE = {
                    id:'XJ6373',
                    card_id:'0',
                    amount:1000,
                    discount:5,
         }
*/
export const UPDATE_DYN_RULE = (state,RULE)=>{
    //Get Current Condition
    let currentState = state.FROM_DYNAMIC.is_dynamic;
    //Remove Update Value From Current Condition
    let objIndex = currentState.findIndex((obj => obj.id === RULE.id));
    state.FROM_DYNAMIC.is_dynamic[objIndex] = {};
    state.FROM_DYNAMIC.is_dynamic[objIndex] = Object.assign({},RULE);
    // //Push Update
    // newState.push(RULE);
    // state.FROM_DYNAMIC.is_dynamic = [];
    // return state.FROM_DYNAMIC.is_dynamic = Object.assign([],newState);
}
/*
*
*/
export const REMOVE_DYN_RULE = (state,id)=>{
    //Get Current Condition
    let currentState = state.FROM_DYNAMIC.is_dynamic;
    //Remove Update Value From Current Condition
    let newState = currentState.filter(obj=>{
        return obj.id !==id;
    });
    state.FROM_DYNAMIC.is_dynamic = [];
    return state.FROM_DYNAMIC.is_dynamic = Object.assign([],newState);
}
/*
* UPDATE = { valueName:'discount',valueData:99 }
*/
export const UPDATE_DYN_VALUE = (state,UPDATE)=>{
       let currentState = state.FROM_DYNAMIC;
       currentState[UPDATE.valueName] = UPDATE.valueData;
       state.FROM_DYNAMIC = Object.assign({},currentState);
}
/*
*
*/
export const FRESH_DYN_FORM = (state)=>{
    state.FROM_DYNAMIC = {};
    return state.FROM_DYNAMIC = Object.assign({}, state.FRESH_DYNAMIC);
}
/*
*
*/
export const FRESH_STANDARD_FORM = (state)=>{

    state.FORM_STANDARD = {};

   state.FORM_STANDARD = Object.assign({}, state.FRESH_STANDARD);

}
/*
*
*/
export const FRESH_ONETIME_FORM = (state)=>{
    state.FORM_ONETIME = {};
    return state.FORM_ONETIME = Object.assign({}, state.FRESH_ONETIME);
}
/*
*
*/
export const UPDATE_ONETIME_VALUE = (state,UPDATE)=>{
    let currentState = state.FORM_ONETIME;
    currentState[UPDATE.valueName] = UPDATE.valueData;
    state.FORM_ONETIME = Object.assign({},currentState);
}
/*
*
*/
export const UPDATE_STANDARD_VALUE = (state,UPDATE)=>{
    let currentState = state.FORM_STANDARD;
    currentState[UPDATE.valueName] = UPDATE.valueData;
    state.FORM_STANDARD = Object.assign({},currentState);
}
/*
*
*/
export const SET_ONETIME_EDIT = (state,id)=>{
    let CARD = state.CLOUD.cards.filter(obj=>{
        return obj.id === id;
    })
    state.FORM_ONETIME = Object.assign({},CARD[0]);
}
/*
*
*/
export const SET_STANDARD_EDIT = (state,id)=>{
    let CARD = state.CLOUD.cards.filter(obj=>{
        return obj.id === id;
    })
    state.FORM_STANDARD = Object.assign({},CARD[0]);
}
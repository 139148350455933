<template>
  <div class="login-form-wrap" v-if="pageLoaded">
    <form>
      <div id="login-container" style="max-width: 500px;" :class="$t('font')">


        <div class="logo grid-col-1 a-i-center">
          <div class="flex-center pof txt-7xl"><i class="pof portfel-text-horizontal"></i></div>
        </div>
        <div class="register pd-20 grid-col-1 a-i-center" :class="$t('font')"><span class="txt-lg t-center">{{$t('welcome_greeting')}}</span></div>
        <div class="login-form grid-col-1 a-i-center">

          <div class="group pd-t-10">
            <div class="input-group txt-lg1">
              <div class="input-desc t-white-txt" :class="$t('font')">{{$t('select_country')}}<span class="red-txt txt-md">*</span></div>
              <div class="input-area pd-t-1 en"><v-select class="vs_style" label="country" v-model="country" :options="domainList"></v-select></div>
            </div>
          </div>


          <div class="group pd-t-10">
            <div class="input-group txt-lg1">
              <div class="input-desc t-white-txt" :class="$t('font')">{{$t('select_app_language')}}<span class="red-txt txt-md">*</span>
              </div>
              <div class="input-area en"><v-select class="vs_style" label="native" v-model="language" :options="languageList" ></v-select></div>
            </div>
          </div>





          <div class="remember-forgot grid-col-1 a-i-center pd-t-30"></div>

          <div class="login-button flex-center" :class="$t('font_b')">
            <div v-show="settingsSet" v-on:click="$router.push({name:'login'})" class="save_settings_btn t-center txt-md indigo t-white-txt" ><i class="far fa-chevron-circle-left pd-r-4"></i>{{$t('login')}}</div>
            <div @click.prevent="saveSettings()"  :class="{'yellow t-black-txt':!settingsSaved, 'green t-white-txt':settingsSaved}" class="save_settings_btn t-center txt-md"><i class="fal fa-save pd-r-4"></i>{{$t('save_settings')}}</div>
          </div>



        </div>
      </div>

    </form>
  </div>
</template>
<script>



import vSelect from 'vue-select'
import {
  getLang,
  getDomain,
  fetchSettings,
  setDomain,
  setLang,
  resetDomain,
  logOut,
  setColorMode,
  getBrowserLocales,
  getAppSettings, getLocale, setLocale,

} from "@/support/support";
import {loading, successMessage} from "@/support/helpers";



export default {
  components:{
    vSelect


  },
  data(){
    return {
      pageLoaded:false,
      language:false,
      country:'',
      settingsSaved:false,
      appSettings:{},
    }
  },
  mounted() {

    loading();


    if(!getLocale()){
       setLocale();
    }
    if(getLocale()==="S2"){
      this.$router.push({name:'login'});
    }


    fetchSettings().then(()=>{
      this.appSettings = getAppSettings();
      this.pageLoaded = true;
      loading(false);
    }).catch(()=>{
      this.appSettings = getAppSettings();
      this.pageLoaded = true;
      loading(false);
    });



    this.settingsSaved=false;
    if(!this.settingsSet){

      let deviceLang = getBrowserLocales({languageCodeOnly:true})[0];
      this.language=this.getLanguage(deviceLang);
      resetDomain();
      setLang(deviceLang);
    }else{
      this.language=this.getLanguage(getLang());
      this.country=this.getLocation(getDomain());
    }

    setColorMode();



  },
  computed:{

    domainList(){
      return this.$root.$store.getters._getDomains.filter(domain=>{
        return this.allowDomains(domain);
      });
    },

    languageList(){
      return this.$root.$store.getters._getLanguages.filter(lang=>{

        return this.allowLanguages(lang);

      });
    },

    canSubmit(){
      return true;
    },
    settingsSet(){
      return (getLang() && getDomain());
    },

  },

  methods:{
    getLanguage(lang){
      return this.$root.$store.getters._getLang(lang);
    },
    getLocation(loc){
      return this.$root.$store.getters._getDomain(loc);
    },
    saveSettings(){
      if(getDomain()!==this.country.tld){
        logOut();
      }
      successMessage(this.$t('settings_saved'));
      setLang(this.language.code);
      setDomain(this.country.tld);
      this.settingsSaved = true;
      setLocale('S2');
      setTimeout(function(){
        window.location.reload();
      }, 800);

    },
    allowLanguages(option){
      if(this.appSettings.language_enabled!==undefined){
        let result = this.appSettings.language_enabled.filter(lang=> lang ===option.code);
        return (result.length>0);
      }


    },
    allowDomains(option){
      if(this.appSettings.language_enabled!==undefined) {
        let result = this.appSettings.domain_enabled.filter(domain => domain === option.country);
        return (result.length > 0);
      }
    }

  }

}
</script>
<template>
<div>
    <div class="heading-group bg">
      <h6 :class="$t('font_b')" class=" txt-3xl mg-t-20 pd-l-8 txt-uppercase">{{$t('transactions')}}</h6>
    </div>
    <div class="split-view">

      <!-- Filters -->
      <div class="view-list">

       <!-- Heading -->
       <div  class="group grid-col-2 a-i-center pd-b-20 w-b-line">
         <div class="flex-start grey5-txt " :class="$t('font')"><h1>{{$t('filter_data')}}</h1></div>
         <div class="flex-end">


           <div class="grid-row-2 t-center cursor" v-if="!exportSelect && !showExportSpinner && !exportHasLink">
             <div class="txt-4xl green-txt"><i class="fal fa-file-excel" @click="exportData()"></i></div>
             <div :class="$t('font')" class="txt-xs1">{{$t('export')}}</div>
           </div>
           <div class="grid-row-2 grid-col-2 g-c-gap-10 t-center cursor" v-if="exportSelect" @click="exportData()">
             <div class="txt-4xl blue-txt"><i class="fal fa-file-excel" @click="exportAll()"></i></div>
             <div class="txt-4xl green-txt"><i class="fal fa-file-excel" @click="exportPage()"></i></div>
             <div :class="$t('font')" class="txt-xs1">{{$t('database')}}</div>
             <div :class="$t('font')" class="txt-xs1">{{$t('page')}}</div>
           </div>
           <div class="grid-row-2 t-center cursor" v-if="showExportSpinner">
             <div class="txt-4xl green-txt"><spinner></spinner></div>
             <div :class="$t('font')" class="txt-xs1">{{$t('export')}}</div>
           </div>
           <vue-json-to-csv :json-data="exportResultData" csv-title="export" v-if="exportHasLink">
             <div class="grid-row-2 t-center cursor">
               <div class="txt-4xl blue-txt"><i class="fal fa-cloud-download"></i></div>
               <div :class="$t('font')" class="txt-xs1">{{$t('export')}}</div>
             </div>
           </vue-json-to-csv>
         </div>
       </div>


        <!-- Keyword -->
        <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
          <div class="flex-start">
               <input-switch-w-desc @stateChange="toggleKeyword()" :set-state="searchFilter" :label="$t('search_keyword')" :desc="$t('keyword_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p f-i-h" v-if="searchFilter">
            <div class="input-group wd-100p input-group-no-label w-tb-margin">
              <div class="input-area"><input type="text"  :placeholder="$t('keyword')" v-model="submitForm.searchKeyword"></div>
            </div>
          </div>
        </div>


        <!-- Date -->
        <div class="group grid-row-2  g-r-gap-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="toggleDateFilter()" :set-state="dateFilter" :label="$t('date')" :desc="$t('date_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p " v-if="dateFilter" style="padding-bottom: 30px !important;">
              <div class="grid-row-2 f-i-h g-r-gap-20 wd-100p">

                  <div class="grid-col-3 a-i-center" style="height: 24px; padding-top: 30px;">
                    <div class="selection-type-item selection-left" :class="{'yellow':selectedToday}" v-on:click="today()">{{$t('today')}}</div>
                    <div class="selection-type-item selection-center" :class="{'yellow':selectedYesterday}" v-on:click="yesterday()">{{$t('yesterday')}}</div>
                    <div class="selection-type-item selection-right" :class="{'yellow':selectedCustomDate}" v-on:click="custom()">{{$t('custom_date')}}</div>
                  </div>

                  <div class="grid-col-2 g-c-gap-20 j-c-end" style="padding-bottom: 30px;" v-if="selectedCustomDate">
                    <div class="flex-start">
                      <div class="grid-row-2">
                        <div class="grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_from')}}</div>
                        <div><div class="input-area"><input v-model="submitForm.dateFrom" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
                      </div>

                    </div>
                    <div class="flex-end">
                      <div class="grid-row-2">
                        <div class=" grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_to')}}</div>
                        <div><div class="input-area"><input v-model="submitForm.dateTo" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
                      </div>
                    </div>
                  </div>

              </div>
          </div>
        </div>


        <!-- Branch -->
        <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="toggleBranchFilter()" :set-state="branchFilter" :label="$t('branch')" :desc="$t('branch_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p f-i-h" v-if="branchFilter">
            <div class="input-group wd-100p input-group-no-label w-tb-margin">
              <div class="input-area">
                <v-select :multiple="true" :options="branchData" @input="branchSelected"></v-select>
              </div>
            </div>
          </div>
        </div>


        <!-- Employee -->
        <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="toggleEmployeeFilter()" :set-state="employeeFilter" :label="$t('employee')" :desc="$t('employee_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p f-i-h" v-if="employeeFilter">
            <div class="input-group wd-100p input-group-no-label w-tb-margin">
              <div class="input-area">
                <v-select :multiple="true" :options="employeeData" label="email" @input="employeeSelected"></v-select>
              </div>
            </div>
          </div>
        </div>


        <!-- Transaction Type -->
        <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="toggleTypeFilter()" :set-state="typeFilter" :label="$t('transaction_type')" :desc="$t('type_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p f-i-h" v-if="typeFilter">
            <div class="input-group wd-100p input-group-no-label w-tb-margin">
              <div class="input-area">
                <v-select :multiple="true" :options="transactionTypeData" @input="transactionTypeSelected"></v-select>
              </div>
            </div>
          </div>
        </div>


        <!-- Partner -->
        <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
          <div class="flex-start">
            <input-switch-w-desc @stateChange="togglePartnerFilter()" :set-state="partnerFilter" :label="$t('partner')" :desc="$t('partner_filter_txt')" :pad="0"></input-switch-w-desc>
          </div>
          <div class="flex-center wd-100p f-i-h" v-if="partnerFilter">
            <div class="input-group wd-100p input-group-no-label w-tb-margin">
              <div class="input-area">
                <v-select :options="partnerData" label="partner_label"  @input="partnerSelected"></v-select>
              </div>
            </div>
          </div>
        </div>



        <div  class="group flex-center flex-center-self" style="margin-top: 60px !important; margin-bottom: 40px !important;">
              <div v-if="!showLeftSpinner" class="yellow pd-20 txt-b cursor" style="border-radius: 30px !important;" v-on:click="getShowFilteredData()"><i class="fal fa-filter"></i> {{$t('show_filtered_data')}}</div>
              <div  v-if="showLeftSpinner" class="txt-7xl grey3-txt container t-center"><spinner></spinner></div>
        </div>

      </div>

      <!-- Result -->
      <div class="view-action">
        <!-- Heading -->
        <div class="group grid-col-2 a-i-center pd-b-20 w-b-line">
          <div class="flex-start grey5-txt " :class="$t('font')">
            <div class="grid-row-2">
              <div><h1>{{$t('total')}}</h1></div>
              <div>{{$t('records')}} ({{showed_records}}/{{total_records}})</div>
            </div>
          </div>
          <div class="flex-end">
            <div class="grid-row-2 t-right">
              <div class="pof txt-4xl pd-b-6">{{total_head}} <i class="pof points"></i></div>
              <div class="pof txt-md1 grey5-txt">{{total_sub}} <dollar></dollar></div>
            </div>
          </div>
        </div>

        <!-- Spinner -->
        <div  v-if="showRightSpinner" class="txt-7xl grey3-txt container t-center mg-t-30"><spinner></spinner></div>

        <!-- List -->
        <div v-if="!showRightSpinner" class="list-group">
            <div v-for="trans in transactions" :key="trans.id">
              <div class="list-group-item">
                <div class="list-pre  txt-md" :class="trans.color"><i :class="trans.icon"></i></div>
                <div class="list-head  txt-sm1" :class="$t('font')">{{trans.label}} <span class="blue-txt cursor" @click="moreInfo(trans.id)"><i class="fal fa-info-circle" ></i></span></div>
                <div class="list-pos en-r txt-lg1 pof">{{ trans.value }}<i  v-if="trans.type===1 || trans.type ===2" class="pof points"></i>

                </div>
                <div class="list-head-sub grey3-txt en txt-sm"><i class="fal fa-calendar-alt"></i> {{ trans.date}} </div>
                <div class="list-pos-sub grey3-txt en txt-md">{{ trans.profit }}<dollar></dollar></div>

                <div class="list-footer en txt-sm grey3-txt"  v-show="showMoreInfo ===trans.id">
                  <div :class="$t('font')"><i class="fal " :class="{'fa-user-plus':trans.type>4,'fa-map-marker-alt':trans.type<=4}"></i> {{ trans.branch_name}}</div>
                  <div :class="$t('font')"><i class="fal " :class="{'fa-info-circle':trans.type>4,'fa-user-check':trans.type<=4}"></i> {{ trans.actor_name}}</div>
                  <div class="t-right"><i class="fal fa-calendar-alt"></i> {{ trans.date}}</div>
                  <div :class="$t('font')"><i class="fal " :class="{'fa-user-minus':trans.type>4,'fa-user':trans.type<=4}"></i> {{ trans.customer_name}}</div>
                  <div><i class="fal fa-info-circle"></i> {{space(trans.account_number)}}</div>
                  <div class="ht-11 t-right"><dollar></dollar> {{ trans.trans_check_amount }}</div>
                </div>
              </div>

            </div>
          </div>

        <div class="flex-center-raw wd-100p" v-if="hasPages && !showRightSpinner">
          <div class="flex-center txt-lg1 blue-txt">
            <div @click="pagPrev()" class="pd-r-10"><i class="fal fa-chevron-circle-left"></i></div>
            <div class="grey5-txt txt-md1">
              <span @click="pagGo(1)" class="blue-txt cursor" :class="[+current_page===1 ? 'txt-lg1' : 'txt-md1']">1</span> /
              <span @click="pagGo(total_pages)" class="blue-txt cursor" :class="[+current_page===+total_pages ? 'txt-lg1' : 'txt-md1']"> {{total_pages}}</span>
            </div>
            <div @click="pagNext()" class="pd-l-10 cursor"><i class="fal fa-chevron-circle-right"></i></div>
          </div>
        </div>
        <div class="flex-center-raw grey3-txt"><span class="txt-lg" v-if="+current_page!==1 && +current_page!==+total_pages">({{current_page}})</span></div>



      </div>


    </div>

  <launchpad></launchpad>
</div>
</template>

<script>
import Launchpad from "@/components/launchpad";
import InputSwitchWDesc from "@/components/STATIC/inputSwitchWDesc";
import vSelect from 'vue-select'
import {mapActions, mapState} from "vuex";
import {canRequestUpdate, setCloudDataUpdatedToken, setCloudDataUpdateFail, space} from "@/support/support";

import Spinner from "@/components/STATIC/spinner";
import Dollar from "@/components/STATIC/dollar";
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  components: {Dollar, Spinner, InputSwitchWDesc, Launchpad,vSelect,VueJsonToCsv},
  data(){
    return {

      showLeftSpinner:false,
      showRightSpinner:false,

      selectedCustomDate:false,
      selectedToday:false,
      selectedYesterday:false,

      searchFilter:false,
      dateFilter:false,
      branchFilter:false,
      employeeFilter:false,
      typeFilter:false,
      partnerFilter:false,



      showMoreInfo:false,


      defaultForm:{
        searchKeyword:'',
        dateFrom:'',
        dateTo:'',
        branchSelectId:'',
        employeeSelectId:'',
        transactionSelectTypeId:'',
        partnerSelectId:'',
        page:1,
      },
      submitForm:{},
      //Export
      //Export
      showExportSpinner:false,
      exportResultData:[],

      exportSelect:false,
      exportSelectAll:false,
      exportHasLink:false,

    }
  },
  mounted() {
     this.submitForm = this.defaultForm;
     this.showLeftSpinner = true;
     this.showRightSpinner = true;
     this.exportLink = false;
     this.fetchTransactionData(this.submitForm).then(()=>{
       this.showLeftSpinner = false;
       this.showRightSpinner= false;
     });

  },
  computed:{
      ...mapState('_transactions',['CLOUD','EXPORT']),
      ...mapState('_branch',{branchCLOUD:'CLOUD'}),
      ...mapState('_employee',{employeeCLOUD:'CLOUD'}),
      ...mapState('_partner',{partnerCLOUD:'CLOUD'}),
      branchData(){
        return this.branchCLOUD;
      },
      employeeData(){
        return this.employeeCLOUD;
      },
      transactionTypeData(){

        return [
            { id:1, label:this.$t('buy_points') },
            { id:2, label:this.$t('sell_points') },
            { id:3, label:this.$t('accept_discount') },
            { id:4, label:this.$t('grant_discount') },
            { id:5, label:this.$t('transfer_points') },
        ];
      },
      partnerData(){
        return this.partnerCLOUD;
      },
      transactions(){
        return this.CLOUD.TRANSACTIONS;
      },

      total_head(){
        return this.CLOUD.TOTAL_HEAD;
      },
      total_sub(){
        return this.CLOUD.TOTAL_SUB;
      },
      total_records(){
        return this.CLOUD.TOTAL_RECORDS;
      },
      page_records(){
        return this.CLOUD.PER_PAGE_RECORDS;
      },
      showed_records(){
        let display = (this.page_records*this.current_page);
        if(this.current_page===+this.total_pages){
          if(display>this.total_records){
            return this.total_records;
          }
        }
        return display;
      },
      current_page(){
        return this.CLOUD.CURRENT_PAGE;
      },
      total_pages(){
        return this.CLOUD.LAST_PAGE;
      },
      hasPages(){
        return +this.total_records>15;
      },
      //export
    getTransactionsExport(){
      return this.EXPORT.TRANSACTIONS;
    },
    //End export
  },
  methods:{
    ...mapActions('_transactions',{fetchTransactionData:'_getAllDataFromCloud',_getExportTransactions:'_getExportTransactions'}),
    ...mapActions('_branch',{fetchBranchData:'_getAllDataFromCloud'}),
    ...mapActions('_employee',{fetchEmployeeData:'_getAllDataFromCloud'}),
    ...mapActions('_partner',{fetchPartnerData:'_getAllDataFromCloud'}),

    moreInfo(id) {
      if(this.showMoreInfo===id){
        return this.showMoreInfo =false;
      }
      return this.showMoreInfo=id;
    },
    space(n){
      return space(n);
    },

    toggleKeyword(){
      this.searchFilter = !this.searchFilter;
      if(!this.searchFilter){
        return this.submitForm.searchKeyword = '';
      }
      return true;
    },
    toggleDateFilter(){
      this.dateFilter = !this.dateFilter;
      if(this.dateFilter){
        return this.today();
      }
      return this.resetDateSelection();
    },
    toggleBranchFilter(){

       this.branchFilter = !this.branchFilter;
        if(this.branchFilter) {
          if(canRequestUpdate('_brch',2) || this.branchData.length<=0){
            this.fetchBranchData().then(()=>{
              setCloudDataUpdatedToken('_brch');
            }).catch(()=>{
              setCloudDataUpdateFail('_brch');
            });
          }

        }
       if(!this.branchFilter){
         return this.submitForm.branchSelectId = '';
       }
       return true;
    },
    toggleEmployeeFilter(){

      this.employeeFilter = !this.employeeFilter;
      if(this.employeeFilter){
        if(canRequestUpdate('_emp',2) || this.employeeData.length<=0){
          this.fetchEmployeeData().then(()=>{
            setCloudDataUpdatedToken('_emp');
          }).catch(()=>{
            setCloudDataUpdateFail('_emp');
          });
        }
      }

      if(!this.employeeFilter){
        return this.submitForm.employeeSelectId = '';
      }
      return true;
    },
    toggleTypeFilter(){
      this.typeFilter = !this.typeFilter;
      if(!this.typeFilter){
        return this.submitForm.transactionSelectTypeId = '';
      }
      return true;
    },
    togglePartnerFilter(){
      this.partnerFilter = !this.partnerFilter;
      if(this.partnerFilter){
        if(canRequestUpdate('_part',2) || this.partnerData.length<=0){
          this.fetchPartnerData().then(()=>{
            setCloudDataUpdatedToken('_part');
          }).catch(()=>{
            setCloudDataUpdateFail('_part');
          });
        }

      }
      if(!this.partnerFilter){
        return this.submitForm.partnerSelectId = '';
      }
      return true;
    },
    today(){
      this.resetDateSelection();
      this.selectedToday = true;
      this.submitForm.dateFrom = "02/01/2000";
      this.submitForm.dateTo="02/01/2000";
    },
    yesterday(){
      this.resetDateSelection();
      this.selectedYesterday = true;
      this.submitForm.dateFrom = "01/01/2000";
      this.submitForm.dateTo="01/01/2000";
    },
    custom(){
      this.resetDateSelection();
      this.selectedCustomDate = true;
    },
    branchSelected(e){
      let set = [];
      e.map(function(selected){
        return set.push(selected.id);
      });
      this.submitForm.branchSelectId =set;

    },
    employeeSelected(e){
      let set = [];
      e.map(function(selected){
        return set.push(selected.id);
      });
      this.submitForm.employeeSelectId =set;

    },
    transactionTypeSelected(e){
      let set = [];
      e.map(function(selected){
        return set.push(selected.id);
      });
      this.submitForm.transactionSelectTypeId =set;

    },
    partnerSelected(e){
      this.submitForm.partnerSelectId =e.partner_id;

    },
    getShowFilteredData(){
      this.submitForm.page = 1;
      return this.showFilteredData();
    },
    showFilteredData(){
      this.resetExportIndicators();
      this.showMoreInfo = false;
      this.showLeftSpinner = true;
      this.showRightSpinner = true;
      this.fetchTransactionData(this.submitForm).then(()=>{
        this.showLeftSpinner = false;
        this.showRightSpinner = false;
      }).catch(()=>{
        this.showLeftSpinner = false;
        this.showRightSpinner = false;
      });
    },
    resetForm(){
      this.submitForm = this.defaultForm;
    },
    resetDateSelection(){
        this.submitForm.dateFrom = '';
        this.submitForm.dateTo = '';
        this.selectedToday = false;
        this.selectedYesterday = false;
        this.selectedCustomDate = false;
    },
    pagGo(page){
      this.submitForm.page = page;
      return this.showFilteredData();
    },
    pagNext(){
      let page = +this.submitForm.page+1;
      if(page <=this.total_pages){
        this.submitForm.page = page;
        return this.showFilteredData();
      }
    },
    pagPrev(){
      let page = +this.submitForm.page-1;
      if(page>0){
        this.submitForm.page = page;
        return this.showFilteredData();
      }

    },


    //Export File
    generateExportFileData(transactions){

      return new Promise((resolve) => {
        let exportFileData = [];
        transactions.map(function(item){
          let obj = {
            OperationType:item.label,
            Date:item.date,
            OperationCashier:item.actor_name,
            OperationBranch:item.branch_name,
            CustomerName:item.customer_name,
            CardNumber:item.account_number,
            CheckAmount:item.trans_check_amount,
            Amount:item.value,
          };
          exportFileData.push(obj);
        });
        resolve(exportFileData);
      });

    },
    resetExportIndicators(){
      this.showExportSpinner=false;
      this.exportResultData=[];
      this.exportSelect=false;
      this.exportSelectAll=false;
      this.exportHasLink=false;
    },
    exportData(){
      this.resetExportIndicators();
      return this.exportSelect = true;
    },
    exportAll(){
      this.showExportSpinner = true;
      this.exportSelect =false;
      let filter = {limit:this.total_records};
      this._getExportTransactions(filter).then(()=>{
        this.generateExportFileData(this.getTransactionsExport).then(result=>{
          this.resetExportIndicators();
          this.exportHasLink=true;
          this.exportResultData = result;
        });
      }).catch(()=>{
        this.resetExportIndicators();
      });


    },
    exportPage(){
      this.showExportSpinner = true;
      this.exportSelect =false;
      this.generateExportFileData(this.transactions).then(result=>{
        this.resetExportIndicators();
        this.exportHasLink=true;
        this.exportResultData = result;
      });
    }
    //End Export File






  }
}
</script>

<style scoped>

</style>
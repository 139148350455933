<template>
<div>

  <form>
  <div class="heading-group bg">
    <h6 class="txt-3xl mg-t-20 pd-l-8" :class="$t('font_b')">{{$t('settings').toUpperCase()}}</h6>
  </div>
  <div class="split-view">
    <div  v-if="showLeftSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
      <spinner></spinner>
    </div>
    <div class="view-list pd-20" :class="$t('font')" v-if="!showLeftSpinner">


      <div class="grid-col-2  pd-t-20 pd-b-20 w-b-line">
        <div class="flex-self-start" ><h6 class="grey5-txt">{{$t('edit_profile')}}</h6></div>
        <save-btn :can-submit="canSubmit" :show-right-spinner="showLeftSpinner" v-on:click.native="updateProfile()"></save-btn>
      </div>


      <div class="input-group w-margin " :class="{'has-pos':!emailVerified}">
        <div class="input-desc" :class="$t('font')"> {{$t('email')}}<span class="red-light-txt">*</span></div>
        <div class="input-area"><input type="text" autocomplete="username"  disabled :value="merchant_email"></div>
        <div class="input-pos  txt-md" :class="{'red':!checkEmail,'orange':checkEmail}" v-if="!emailVerified" v-on:click="resendEmailConfirmation"><i class="fas fa-envelope"></i></div>

      </div>

      <div class="input-group mg-t-10 w-margin">
        <div class="input-desc" :class="$t('font')">{{$t('old_password')}} <span class="red-light-txt">*</span>
          <span class="red-light-txt" v-if="errors.old_password">{{ errors.old_password}}</span>
        </div>
        <div class="input-area" :class="{'red-border':redBorder }">
          <input type="password" autocomplete="current-password" v-model="merchant_old_password"></div>
      </div>

      <div class="space-div--20"></div>

      <div class="group w-margin">
        <div class="switch-button-group w-text" >
          <input type="hidden" :value="changePassword">
          <div class="button " :class="{ off: !changePassword }" @click="changePassword = !changePassword"><i class="fas fa-circle"></i></div>
          <div class="text" @click="changePassword = !changePassword"><span :class="$t('font_b')">{{$t('change_password')}}</span> <br> <span :class="$t('font')">{{$t('on_off')}} </span>
          </div>
        </div>
      </div>

      <div v-if="changePassword" class="space-div--40 w-t-line"></div>
      <div v-if="changePassword" class="input-group mg-t-10 w-margin">
        <div class="input-desc" :class="$t('font')">{{$t('new_password')}}
          <span class="red-light-txt" v-if="errors.new_password">{{ errors.merchant_new_password[0] }}</span>
        </div>
        <div class="input-area"><input type="password" autocomplete="new-password" v-model="merchant_new_password"></div>
      </div>
      <div v-if="changePassword" class="input-group mg-t-10 w-margin">
        <div class="input-desc" :class="$t('font')">{{$t('password_confirmation')}}
          <span class="red-light-txt" v-if="errors.merchant_new_password_confirmation">{{ errors.merchant_new_password_confirmation[0] }}</span>
        </div>
        <div class="input-area" :class="{'red-border':!passwordConfirmationValid}"><input type="password" autocomplete="new-password" v-model="merchant_new_password_confirmation"></div>
      </div>
      <div v-if="changePassword" class="space-div--40 w-b-line"></div>
      <div class="space-div--20"></div>


      <div class="input-group w-margin">
        <div class="input-desc" :class="$t('font')">
          <span v-if="type===1">{{$t('company_name')}}</span>
          <span v-if="type===2">{{$t('brand_name')}}</span>
          <span class="red-light-txt"> *</span>
          <span class="red-light-txt" v-if="errors.label">{{ errors.label[0] }}</span>
        </div>
        <div class="input-area" :class="{'red-border':!labelValid}">
          <input type="text" autocomplete="given-name" v-model="merchant_label"></div>
      </div>

      <div class="input-group w-margin" v-if="type===1">
        <div class="input-desc" :class="$t('font')">{{$t('company_id')}}<span class="red-light-txt">*</span><span class="red-light-txt" v-if="errors.reg_id">{{ errors.reg_id[0] }}</span></div>
        <div class="input-area" :class="{'red-border':!regIdValid}">
          <input type="text" autocomplete="personal-id" v-model="merchant_reg_id"></div>
      </div>

      <div class="group w-margin" v-if="country">
             <span class="phone-input-desc" :class="$t('font')">{{$t('phone')}}<span v-if="!phoneVerified" class="red-light-txt pd-l-2">*</span> <i v-if="phoneVerified" class="fal fa-check green-txt txt-md1"></i>
               <span class="red-light-txt pd-l-4" v-if="errors.phone">{{ errors.phone[0] }}</span>
             </span>
        <vue-tel-input @input="changeMonitor" :styleClasses="getStyle" v-model="phone" @validate="isValid" :defaultCountry="getCountry" :inputOptions="inputOptions" :dropdownOptions="dropdownOptions"></vue-tel-input>
        <div class="register-confirmButton" v-if="phoneValid && !phoneVerified" @click="validatePhoneNumber()"><spinner v-if="showCheckNumberSpinner"></spinner><span v-if="!showCheckNumberSpinner">{{$t('verify')}}</span></div>
      </div>

      <div class="input-group w-margin">
        <div class="input-desc" :class="$t('font')">{{$t('website')}} <span class="red-light-txt" v-if="errors.address">{{ errors.address[0] }}</span></div>
        <div class="input-area" :class="{'red-border':!addressValid}" >
          <input type="text" autocomplete="family-name" v-model="merchant_address"></div>
      </div>




    </div>


    <div class="view-action pd-20" :class="$t('font')">
      <div class="grid-col-2  pd-t-20 pd-b-30 w-b-line">
        <div class="flex-self-start" ><h6 class="grey5-txt">{{$t('inegration')}}</h6></div>
      </div>
      <div class="en pd-t-30 pd-b-30">
        <div class="pd-10"><b>API Docs:</b> <a class="txt-no-decor blue-txt" href="https://en.api-docs.portfel.app/" target="_blank"> {{$t('documentation_page')}} <i class="pd-l-4 fal fa-book-open"></i></a></div>
        <div class="pd-10"><b>Endpoint URL:</b> https://{{getDomain}}.portfel.app/api/v1/</div>

      </div>

      <div class="group pd-b-30 pd-t-20" >
        <div class="grid-row-2 g-r-gap-20">
          <div class="container col-2  pd-t-20 pd-b-20 w-b-line">
            <div class="flex-self-start" ><h6 class="grey5-txt">{{$t('settings')}}</h6></div>
          </div>

          <div class="input-group pd-t-10 pd-l-10">
            <div class="input-desc" :class="$t('font_b')">{{$t('select_app_language')}}</div>
            <div class="input-area en"><v-select class="vs_style" label="native" v-model="language" :options="languageList" @input="updateLanguage"></v-select></div>
          </div>
        </div>
      </div>


    </div>
  </div>
  <launchpad></launchpad>
  </form>
</div>
</template>

<script>
import launchpad from "@/components/launchpad";
import {mapActions, mapState} from 'vuex';
import user from "@/apis/user";
import support, {validate, checkAndNotifyUpdate, fetchSettings, getLang, getDomain} from "@/support/support";
import {loading} from "@/support/helpers";
import SaveBtn from "@/components/STATIC/saveBtn";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import userApi from "@/apis/user";
import Spinner from "@/components/STATIC/spinner";


import vSelect from 'vue-select'


export default {
    components:{

      Spinner,
      vSelect,
      SaveBtn,
      launchpad,
      VueTelInput,
        },
        data(){
          return {
            changePassword:false,
            language:false,
            checkEmail:false,
            showLeftSpinner:false,
            emailVerificationForm:{
              email: '',
            },
            redBorder:false,
            errors:[],
            expendSettings:false,
            expendProfile:false,
            expendInterface:false,
            merchant_email:'',
            merchant_old_password:'',
            merchant_new_password:'',
            merchant_new_password_confirmation:'',
            merchant_label:'',
            merchant_reg_id:'',
            merchant_phone:'',
            merchant_address:'',
            merchant_email_verified:'',
            merchant_type:'',

            country:'',
            phoneIsValid:true,
            phone:'',
            verificationCodeSent:false,
            phoneVerified:true,
            showCheckNumberSpinner:false,
            codeVerifyForm:{
              phone:'',
              code:'',
            },
            inputOptions:{
              placeholder:this.$t('enter_mobile_phone'),
              showDialCode:true,
            },
            dropdownOptions:{
              width:'80vw',
              showFlags:true,
              showSearchBox:false,
            },
          }
        },
        mounted(){
          this.showLeftSpinner = true;
          loading(false);
          fetchSettings().then(()=>{
            loading(false);
            this.showLeftSpinner = false;
            checkAndNotifyUpdate();
          }).catch(()=>{
            this.showLeftSpinner = false;
            loading(false);
          });

          this.language=this.getLanguage(getLang());
          this._getAllDataFromCloud().then(()=>{
           this.merchant_email=this.CLOUD.PROFILE.merchant_email;
           this.merchant_label=this.CLOUD.PROFILE.merchant_label;
           this.merchant_reg_id=this.CLOUD.PROFILE.merchant_reg_id;
           this.merchant_phone=this.CLOUD.PROFILE.merchant_phone;
           this.merchant_address=this.CLOUD.PROFILE.merchant_address;
           this.emailVerificationForm.email = this.CLOUD.PROFILE.merchant_email;
           this.merchant_type = this.CLOUD.PROFILE.merchant_type;
           this.phone = this.merchant_phone;
         }).catch(()=>{
            console.log('...');
         });
          this.getIpInfo();

        },
        computed:{
          ...mapState('_general',['CLOUD']),
          getStyle(){
            if(this.phoneValid && !this.phoneVerified){
              return 'register-buttonSpace';
            }
            return '';
          },
          getCountry(){
            return this.country;
          },
          phoneValid(){
            return this.phoneIsValid;
          },
          codeValid(){
            return (this.codeVerifyForm.code.length ===6);
          },
          emailVerified(){
            return this.CLOUD.PROFILE.merchant_email_verified;
          },
          languageList(){
            return this.$root.$store.getters._getLanguages.filter(lang=>{
              return this.allowLanguages(lang);
            });
          },
          latest(){
            if(this.CLOUD.APP_SETTINGS !==undefined){
              return this.CLOUD.APP_SETTINGS.latest_app_version;
            }else{
              return this.appVersion;
            }
          },
          passwordConfirmationValid(){
            return this.merchant_new_password === this.merchant_new_password_confirmation;
          },
          labelValid(){
            return validate(this.merchant_label,'min:char');
          },
          oldPasswordValid(){
            return validate(this.merchant_old_password,'password');
          },
          regIdValid() {
            return validate(this.merchant_reg_id,'min:char');
          },
          addressValid() {
            return validate(this.merchant_address,'website');
          },

          canSubmit(){
            return (this.oldPasswordValid && this.labelValid && this.passwordConfirmationValid && this.phoneValid && this.regIdValid);
          },
          getDomain(){
            return getDomain();
          },
          type(){
            return +this.merchant_type;
          }
        },
        methods: {
          ...mapActions('_general', ['_getAllDataFromCloud','_updateProfile','_updateValue']),

          isValid(e){
            this.phoneIsValid = e.valid;
            if(e.countryCode !==undefined){
              this.country = e.countryCode;
            }
          },
          getIpInfo(){
            let self = this;
            fetch('https://ipinfo.io/json?token=3dca986f555bc1', { headers: { 'Accept': 'application/json' }})
                .then((resp) => resp.json())
                .catch(() => {
                  loading(false);
                  self.country = getDomain();
                })
                .then((resp) => {
                  loading(false);
                  self.country=resp.country;
                });
          },
          validatePhoneNumber(){
            this.showCheckNumberSpinner = true;
            this.errors = [];
            let phone = this.phone;
            userApi.validateNumber({phone})
                .then(()=>{
                  this.merchant_phone = phone;
                  this.phone = phone;
                  this.phoneIsValid = true;
                  this.phoneVerified = true;
                  return this.showCheckNumberSpinner = false;
                })
                .catch((error)=>{
                  this.errors = error.response.data.errors;
                  return this.showCheckNumberSpinner = false;
                });
          },
          changeMonitor(){ //Show Hide Verify Button

            let formPhone = this.merchant_phone.replace(/\s/g, '');
            formPhone = formPhone.split('+').pop();

            let phone = this.phone.replace(/\s/g, '');
            phone = phone.split('+').pop();

            if(formPhone !== phone){
              this.errors =[];
              this.verificationCodeSent = false;
              return this.phoneVerified = false;
            }
            //Not Changed
            return this.phoneVerified = true;


          },
          resendEmailConfirmation() {
            this.$store.dispatch('showLoading');
            user.resendEmailConfirmation(this.emailVerificationForm)
                .then(() => {
                  this.$store.dispatch('hideLoading');
                  this.checkEmail = true;
                  this.$store.dispatch('successMessage',this.$t('check_email'),{ root: true });
                })
                .catch(error => {

                  this.$store.dispatch('hideLoading');
                  support.httpStatusActions(error);

                });
          },
          updateProfile() {
            if(this.merchant_old_password.length<=0 || !validate(this.merchant_old_password,'password')){
              this.redBorder = true;
              return false;
            }
            if(!this.canSubmit) {
                return false;
            }
            this.errors = [];
            this.showLeftSpinner = true;
              let form = {
                email: this.merchant_email,
                email_verified: this.merchant_email_verified,
                old_password: this.merchant_old_password,
                password: this.merchant_new_password,
                password_confirmation: this.merchant_new_password_confirmation,
                label: this.merchant_label,
                reg_id: this.merchant_reg_id.toString(),
                phone: this.merchant_phone,
                address: this.merchant_address,
                country:this.country,
                type:this.merchant_type,

              };
              this._updateProfile(form)
                  .then(()=>{
                    this.$store.dispatch('successMessage',this.$t('profile_updated'))
                    this.showLeftSpinner=false;
                  })
                  .catch(error => {
                    this.showLeftSpinner=false;
                    this.errors = error.errors;
                  });

          },
          getLanguage(lang){
            return this.$root.$store.getters._getLang(lang);
          },
          allowLanguages(option){
            return option.code==='en'
                || option.code==='uk'
                || option.code==='ru';
          },
          updateLanguage(){
            if(this.language !==null){
              localStorage.setItem('APP_LANG',this.language.code);
              return window.location.reload();
            }
          }


        }

}
</script>

<style scoped>
.red-border{
  border:1px solid var(--red);
}
</style>
<template>
        <div class="loading-wrap">


          <div class="loading-window">
              <div class="logo-container"><i class="pof portfel"></i></div>
              <div class="loading-dots">
                <span class="loader__dot"><i class="fas fa-circle"></i></span>
                <span class="loader__dot"><i class=" fas fa-circle"></i></span>
                <span class="loader__dot"><i class="fas fa-circle"></i></span>
              </div>
              <div class="message" :class="$t('font_b')">
                    <span class="msg-0 msg">{{$t('please_wait')}}</span>
                    <span class="msg-1 msg"><i class="fas fa-hourglass-half"></i> {{$t('taking_too_much_time')}}</span>
                    <span class="msg-2 msg"><i class="fal fa-wifi green-txt"></i> {{$t('check_connection')}} </span>
                    <span class="msg-3 msg"><i class="fas fa-exclamation-triangle"></i> {{$t('delay_message')}}</span>
              </div>


          </div>
        </div>
</template>
<script>
    export default {

    }
</script>
<style scoped>
    .loading-wrap {
      background-color:#331754;
      background-image: linear-gradient(-225deg, #EB0C8D 0%, #007CF8 100%);
      position: fixed;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }
    .loading-window {
      width: 0;
      height: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      -webkit-backdrop-filter: blur(5px);
      -moz-filter: blur(5px);
      -o-filter: blur(5px);
      -ms-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      animation: showLoading .1s ease-in-out forwards;
      z-index: 1001;
    }
    @keyframes showLoading {
      0% {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      100% {
        border-radius: 0;
        width: 100vw;
        height: 100%;
      }
    }


    .loader__dot {
      font-size: 18px;
      text-shadow: 2px 2px 4px rgba(0,0,0,.2);
    }

    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-items: center;
      font-size: 18px;
      color:var(--t-white);
      text-align: center;
      text-shadow: 1px 1px 2px rgba(0,0,0,.1);

    }
    .msg {
      width: 250px;
      font-size: 11px;
    }
    .msg-0 {
      opacity: 0;
      animation-duration: 3s;
      animation-delay: 1.5s;
      animation-name: showMessages;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
    .msg-1 {
      opacity: 0;
      animation-duration: 4s;
      animation-delay: 4.5s;
      animation-name: showMessages;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
    .msg-2 {
      opacity: 0;
      animation-duration: 5s;
      animation-delay: 8.5s;
      animation-name: showMessages;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
    .msg-3 {
      opacity: 0;
      animation-duration: 60s;
      animation-delay: 13.8s;
      animation-name: showMessages;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
    @keyframes showMessages {
      1% {
        opacity: 1;
      }
      99%{
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    .message:nth-child(1) { animation-delay: 1s }
    .message:nth-child(2) { animation-delay: 3s }
    .message:nth-child(3) { animation-delay: 10s }

    .loading-dots {
      font-size: 23px;
      color:var(--t-white);
    }

    @keyframes blink {
      50% {
        opacity: 1
      }
    }


    .loader__dot {
      opacity: 0;
      animation: 1.3s blink infinite
    }
    .loader__dot:nth-child(1) { animation-delay: .1s }
    .loader__dot:nth-child(2) { animation-delay: .4s }
    .loader__dot:nth-child(3) { animation-delay: .8s }

    .logo-container{
      font-size: 80px;
      color: rgba(255,255,255,1.2);
      /*text-shadow: 2px 2px 4px rgba(0,0,0,.2);*/

    }


</style>
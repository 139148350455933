<template>
  <div class="wd-100p grid-col-span3 flex-column w-t-line pd-b-30 mg-t-30" v-if="hasData">
    <div v-for="(request,index) in requests" :key="(index+1)*33">
      <footer_exchange_request :request="request"></footer_exchange_request>
    </div>
    <div v-for="(notification,index) in exchangeRateChangeNotifications" :key="index*39" >
      <footer_rate_change :id="notification.id" :from="notification.data.from" :to="notification.data.to" :date="notification.date" :type="notification.data.type"></footer_rate_change>
    </div>

  </div>
</template>

<script>
import footer_rate_change from "@/components/component/partnership/footer_rate_change";
import footer_exchange_request from "@/components/component/partnership/footer_exchange_request";


export default {
  name: "notificationsAndRequestsFinished",
  components: {footer_rate_change, footer_exchange_request},
  props: {
    item: {
      required:true,
      default() {
        return []
      }
    },
  },
  computed:{
    notifications(){
      return (this.item.notifications!==undefined)?this.item.notifications:[];
    },
    requests(){
      return (this.item.requests!==undefined)?this.item.requests:[];
    },
    hasData(){
      return (this.notifications.length>0 || this.requests.length>0);
    },
    exchangeRateChangeNotifications(){
      return this.item.notifications.filter(notification=>{
        return notification.type===1;
      });
    },


  },
  methods:{

  }
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import './registerServiceWorker'
import Vue2TouchEvents from 'vue2-touch-events'
import i18n from './i18n'
import support from "@/support/support";
import helpers from "@/support/helpers";
import * as VueGoogleMaps from 'vue2-google-maps'
import config from "@/config";

//#Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
//#Sentry END
// Vue.use(VueI18n);
// const lang = {
//   en,
//   ka,
//   ru,
// }
// const i18n = new VueI18n({
//   locale: support.getLang(), // set locale
//   messages:lang,
//   fallbackLocale: 'en',
// });
//#Sentry
Sentry.init({
  Vue,
  dsn: "https://9f4531f2abd646aa997dc8490c009a81@o1291216.ingest.sentry.io/6512665",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "manager.portfel.app", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
//#Sentry END



Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsToken,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
    installComponents: true,
  }
  });
Vue.use(Vue2TouchEvents,{
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 400
});
Vue.config.productionTip = false;



new Vue({
  i18n,
  router,
  store,
  support,
  helpers,
  VueGoogleMaps,
  render: h => h(App),
}).$mount('#app')

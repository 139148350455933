<template>
<div>
  <div v-for="trans in transactions" :key="trans.id">
    <div class="list-group-item">
      <div class="list-pre  txt-md" :class="trans.color"><i :class="trans.icon"></i></div>
      <div class="list-head txt-sm1" :class="$t('font')">{{trans.label}} <span class="blue-txt"><i class="fal fa-info-circle" @click="moreInfo(trans.id)" ></i></span></div>
      <div class="list-pos en-r txt-lg1">
        {{ trans.value }}
        <currency-symbol v-if="parseInt(trans.type)===1 || parseInt(trans.type) ===2" ></currency-symbol>
      </div>
      <div class="list-head-sub grey5-txt en txt-sm"><i class="fal fa-calendar-alt"></i> {{ trans.date}} </div>
      <div class="list-pos-sub grey5-txt en txt-md">{{ trans.profit }}<lari-symbol set-class="icon-defaults-c"></lari-symbol></div>
      <div class="list-footer en txt-sm grey4-txt"  v-show="showMoreInfo ===trans.id">
        <div class="ka"><i class="fal fa-map-marker-alt"></i> {{ trans.branch_name}}</div>
        <div class="ka"><i class="fal fa-user-check"></i> {{ trans.actor_name}}</div>
        <div class="t-right"><i class="fal fa-calendar-alt"></i> {{ trans.date}}</div>
        <div class="ka"><i class="fal fa-user"></i> {{ trans.employee_name}}</div>
        <div><i class="fal fa-info-circle"></i> {{space(trans.account_number)}}</div>
        <div class="ht-11 t-right"><svg style="height: 18px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11.987"><path d="M38.821,17.907V13.381a3.516,3.516,0,0,0-.85-.1h-.192v4.622h-.7V13.381c-1.262.315-2.428,1.4-2.428,4.115,0,2.62,1.358,4.732,3.648,4.993h4.65v.7H33.842v-.7h1.92A4.8,4.8,0,0,1,32.8,17.907a5.11,5.11,0,0,1,4.279-5.157V11.2h.7v1.454c.178-.014.384-.014.576-.014.165,0,.315,0,.466.014V11.2h.7v1.522A4.7,4.7,0,0,1,43.8,17.674v.233H41.907a4.631,4.631,0,0,0-2.387-4.279v4.279Z" transform="translate(-32.8 -11.2)"></path></svg> {{ trans.trans_check_amount }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import CurrencySymbol from "@/components/STATIC/currencySymbol";
import LariSymbol from "@/components/STATIC/lariSymbol";
import {space} from "@/support/support";
export default {
  name: "customerTransactions",
  components: {LariSymbol, CurrencySymbol},
  props:{
    transactions:{
      required: true,
    },
  },
  data(){
        return {
          showMoreInfo:false,
        }
  },
  methods:{
    space(text){
      return space(text);
    },
    moreInfo(id) {
      if(this.showMoreInfo===id){
        return this.showMoreInfo =false;
      }
      return this.showMoreInfo=id;
    },
  }
}
</script>

<style scoped>

</style>
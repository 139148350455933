import Employee from "@/apis/employee";
import {httpStatusActions} from "@/support/support";

export const _getAllDataFromCloud = ({commit})=> {

    return new Promise((resolve, reject) => {
        Employee.get().then(response => {
            httpStatusActions(response,true);
            commit('SET_CLOUD_DATA', {response: response.data});
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _createOrUpdate = ({commit},form)=> {

    return new Promise((resolve, reject) => {
        if(form.id==='NEW'){
            Employee.create(form).then(response => {
                httpStatusActions(response);
                commit('INSERT_NEW',response.data);
                resolve (response);
            }).catch(error=>{
                reject(httpStatusActions(error));
            });
        }else{
            Employee.update(form).then(response => {
                httpStatusActions(response);
                commit('UPDATE_EDITED_VALUE', form);
                resolve (response);
            }).catch(error=>{
                reject(httpStatusActions(error));
            });
        }


    });
}
export const _delete = ({commit},id)=> {

    return new Promise((resolve, reject) => {
        Employee.delete(id).then(response => {
            httpStatusActions(response,true);
            commit('REMOVE_CLOUD_DATA', id);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}


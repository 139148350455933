<template>
  <canvas :id="chartName"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import {randomName} from "@/support/support";

export default {
  props:{
    chartData:{
      default(){
        return {
          labels: ['Red', 'Blue','Yellow','Violet'],
              datasets: [{
            label: '',
            data: [100, 178,189,121],
            backgroundColor: [

              '#eb0c8d',
              '#007cf8',
              '#ffe231',
              '#af52de',
              '#45cb34',
              '#4a93b5',
              '#4155be',
              '#f4a30b',
              '#fa0587',
              '#b500ff',
              '#0058ff',
              '#04cafb',
            ],
          }]
        }
      }
    }
  },
  data(){
    return {
      chartName:randomName(),
    }
  },
  mounted() {

    let ctx = document.getElementById(this.chartName);
    let delayed;
    new Chart(ctx, {
      type: 'doughnut',
      data: this.chartData,
          options: {

            plugins:{
              title:{
                display:false,
              },
              legend: {
                display: true,
                position: 'right',
                align:'center',
                fullSize:true
              },
            },
            animation: {
              onComplete: () => {
                delayed = true;
              },
              delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                  delay = context.dataIndex * 300 + context.datasetIndex * 100;
                }
                return delay;
              },
            },
            responsive:true
          }
    }
    );
  },

}
</script>

<style scoped>

</style>
<template>
  <div v-if="pageLoaded">
    <div class="heading-group bg">
      <h6 class="txt-3xl mg-t-20 pd-l-8" :class="$t('font_b')">{{$t('help').toUpperCase()}}</h6>
    </div>
    <div class="split-view">
      <div class="view-list pd-20" :class="$t('font')">
        <div class="group pd-b-30 pd-t-20 w-b-line">
          <h6 class="grey5-txt">{{$t('contact_headquarters')}}</h6>
        </div>
        <div class="txt-md1 pd-b-20 w-margin">
          <p class="grey5-txt">{{$t('quality_is_important_support_txt')}}</p>
          <p class="indigo-txt"><i class="fad fa-mail-bulk"></i> quality@portfel.co.uk</p>
        </div>
        <div class="input-group w-margin">
          <div class="input-desc" :class="$t('font')" v-html="labelOrError('email',errorMessage('email'))"></div>
          <div class="input-area">
            <input type="text" autocomplete="email" v-model="submitForm.email" ></div>
        </div>
        <div class="input-group w-margin">
          <div class="input-desc" :class="$t('font')" v-html="labelOrError('full_name',errorMessage('full_name'))"></div>
          <div class="input-area">
            <input type="text" autocomplete="email" v-model="submitForm.full_name" ></div>
        </div>
        <div class="input-group w-margin">
          <div class="input-desc" :class="$t('font')" v-html="labelOrError('phone',errorMessage('phone'))"></div>
          <div class="input-area">
            <input type="text" autocomplete="email" v-model="submitForm.phone" ></div>
        </div>
        <div class=" w-margin">
          <div class="input-desc" :class="$t('font')" v-html="labelOrError('describe_problem',errorMessage('text'))"></div>
          <div class="textarea mg-t-30 no_outline">
            <textarea rows="6" style="height: 100px !important;" class="no_outline pd-6 black-txt" v-model="submitForm.text"></textarea>
          </div>
        </div>
        <div class="flex-center mg-t-10 mg-b-10">
          <div class="mg-b-10">
            <button class="btn btn-md t-black-txt mg-t-30 yellow"  @click.prevent="submitHQFrom" >
              <span :class="$t('font_b')">{{$t('submit')}}</span>
            </button>
          </div>
        </div>

      </div>
      <div class="view-action pd-20" :class="$t('font')">
        <div class="group pd-b-30 pd-t-20 w-b-line">
          <h6 class="grey5-txt">{{$t('contact_support')}}</h6>
        </div>
        <div class="grid-col-1 pd-t-10">
          <div class="grid-sm-lg g-c-gap-20 a-i-center">
            <div class="txt-6xl grey5-txt"><i class="fal fa-user-headset"></i></div>
            <div class="txt-lg1 grey5-txt">{{ $t('before_contacting_support') }} <a :href="'https://'+docDomain+'.docs.portfel.app/'" target="_blank" class="txt-no-decor cursor blue-txt">{{$t('how_to_docs')}}</a></div>
          </div>

        </div>
        <div class="group grid-col-1 txt-lg1 pd-20 radius grey7 mg-t-30" style="max-width: 450px">
          <p :class="$t('font')"><span class="txt-b">{{$t('your_support_id')}}:</span> <span class="txt-uppercase"> {{ supportData.support_id }}</span></p>
          <p :class="$t('font')"><span class="txt-b">{{$t('company')}}:</span> {{ supportData.company }}</p>
          <p :class="$t('font')"><span class="txt-b">{{$t('address')}}:</span> {{ supportData.address }}</p>
          <p :class="$t('font')"><span class="txt-b">{{$t('support_languages')}}:</span> {{ supportData.language }}</p>
          <p :class="$t('font')"><span class="txt-b">{{$t('phone')}}:</span> <a class="txt-no-decor" :href="'tel:'+supportData.phone"> {{ supportData.phone }} </a></p>
          <p :class="$t('font')"><span class="txt-b">{{$t('email')}}:</span> <a class="txt-no-decor" :href="'mailto:'+supportData.email">{{ supportData.email }}</a></p>
          <p :class="$t('font')" class="t-center">
            <a  class="txt-no-decor blue-txt txt-4xl mg-20" :href="'skype:'+supportData.skype"><i class="fab fa-skype"></i><span class="en-b grey5-txt"></span></a>
            <a  class="txt-no-decor green-txt mg-l-10 txt-4xl mg-20" :href="'whatsapp:'+supportData.whatsapp"><i class="fab fa-whatsapp"></i><span class="en-b grey5-txt"> </span></a>
            <a  class="txt-no-decor blue-txt mg-l-10 txt-4xl mg-20" :href="'telegram:'+supportData.telegram"><i class="fab fa-telegram"></i><span class="en-b grey5-txt"></span></a>
          </p>
        </div>


      </div>
    </div>
    <launchpad></launchpad>
  </div>
</template>


<script>

import launchpad from "@/components/launchpad";
import {mapActions, mapState} from 'vuex';
import user from "@/apis/user";

import {getLang, getDomain, validate, displayErrors} from "@/support/support";
import {errorMessage, loading, successMessage, warningMessage} from "@/support/helpers";
export default {
  components:{
    launchpad,
  },
  data(){
    return {
      fetchErrors: {},
      pageLoaded:false,
      addCardToken:false,
      showSpinner:true,
      addCardSpinner:false,
      cancelSpinner:false,
      stripeDashboardLink:false,
      selectedTutorial:false,
      submitForm:{
        email:'',
        full_name:'',
        phone:'',
        text:'',
      },
      resetSubmit:{
        email:'',
        full_name:'',
        phone:'',
        text:'',
      }
    }
  },
  mounted(){
    loading();
    this.submitForm = this.resetSubmit;
    this._getAllDataFromCloud().then(()=>{
      loading(false);
      this.pageLoaded = true;
      window.smartsupp('group', process.env.VUE_APP_COUNTRY);
      window.smartsupp('name', this.CLOUD.PROFILE.merchant_label);
      window.smartsupp('email', this.CLOUD.PROFILE.merchant_email);
      window.smartsupp('phone', this.CLOUD.PROFILE.merchant_phone);
      window.smartsupp('variables', {
        SubscriptionDaysLeft:this.CLOUD.SUBSCRIPTIONS.days_left,
        Service:this.CLOUD.PRODUCTS.label,
        HasAttachedCard:Boolean(this.CLOUD.SETTINGS.has_payment_attached),
        PointsEnabled:Boolean(this.CLOUD.SETTINGS.points_enabled),
        DiscountsEnabled:Boolean(this.CLOUD.SETTINGS.discounts_enabled),
        HasPartners:Boolean(this.CLOUD.SETTINGS.has_partners),
      });
      window.smartsupp('language',getLang());
      window.smartsupp('chat:show');
      window.smartsupp('chat:open');
      let el = document.getElementById('chat-application-iframe');
      if(el){
        el.style.visibility = "visible";
      }
    }).catch(()=>{
        loading(false);
        this.pageLoaded = true;
        return false;
    });


  },
  computed:{
    ...mapState('_general',['CLOUD','TUTORIALS']),

    supportData(){
      return this.CLOUD.APP_SETTINGS.SUPPORT[getDomain()];
    },
    docDomain(){
      if(getLang()==='ru'){
        return 'ru';
      }
      return 'en'; //else
    },
  },
  methods: {
    ...mapActions('_general', ['_getAllDataFromCloud']),

    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    labelOrError(label,errorMsg){
      if(errorMsg){
        return '<span class="red-light-txt">* '+errorMsg+'</span>';
      }
      return this.$t(label)+'<span class="red-light-txt">*</span>';
    },
    submitHQFrom(){
      loading();
      let emailValidation = validate(this.submitForm.email,'email');
      let fullNameValidation = validate(this.submitForm.full_name,'min:char');
      let phoneValidation = validate(this.submitForm.phone,'phone');
      let textValidation = validate(this.submitForm.text,'min:char');

      let emailValid = (emailValidation ===-1) ? false :  emailValidation;
      let fullNameValid = ( fullNameValidation===-1) ? false :  fullNameValidation;
      let phoneValid = ( phoneValidation===-1) ? false :  phoneValidation;
      let textValid = ( textValidation===-1) ? false :  textValidation;

      if(!emailValid || !fullNameValid || !phoneValid || !textValid){
          return warningMessage('required_values');
      }
      user.feedback(this.submitForm).then(()=>{
        this.submitForm ={
              email:'',
              full_name:'',
              phone:'',
              text:''
        };
        loading(false);
        return successMessage('letter_submit_success');

      }).catch(error=>{
        loading(false);
        this.fetchErrors = error.response.data.errors;
        return errorMessage('error_detected_try_later');
      })

    }
  }
}
</script>


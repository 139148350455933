export default {
    /* Data Is Filed From Cloud */
    CLOUD: {
        CURRENT_PAGE: 0,
        LAST_PAGE: 0,
        MESSAGES: [],
        PER_PAGE_RECORDS: 50,
        TOTAL_RECORDS: 0,
    },



}
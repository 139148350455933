<template>

  <div class="login-form-wrap">
    <form>
      <input style="visibility: hidden;" type="text" autocomplete="username" v-model="form.email">
      <div id="login-container">


        <div class="logo grid-col-1 a-i-center">
          <div class="flex-center pof txt-7xl"><i class="pof portfel-text-horizontal"></i></div>
        </div>
        <div class="register blur pd-10 grid-col-1 a-i-center">
          <div class="flex-center ">
            <div class="txt-sm1 t-center" :class="$t('font')">
              <p class="t-white-txt"><span class="txt-md">{{$t('password_update')}}</span></p>
            </div>
          </div>
        </div>
        <div class="login-form grid-col-1 a-i-center">

          <div class="group">
            <div class="input-group">
              <div class="input-desc t-white-txt" :class="$t('font')">{{$t('new_password')}}
                <span class="red-txt txt-md">*</span>
                <span class="red-light-txt" v-if="errors.password">{{ errors.password[0] }}</span>
              </div>
              <div class="input-area" :class="{'red-border':!passwordValid,'green-border':passwordValid}">
                <input type="password"  autocomplete="new-password"  v-model="form.password">
                <span v-if="form.password" class="fa fa-times-circle grey3-txt pd-r-2 txt-md" style="max-width: 9%;"  v-on:click="form.password=''"></span>
              </div>
            </div>
          </div>

          <div class="group">
            <div class="input-group">
              <div class="input-desc t-white-txt" :class="$t('font')">{{$t('password_confirmation')}}
                <span class="red-txt txt-md">*</span>
                <span class="red-light-txt" v-if="errors.password_confirmation">{{ errors.password_confirmation[0] }}</span>
              </div>
              <div class="input-area"  :class="{'red-border':!passwordConfirmationValid,'green-border':passwordConfirmationValid}">
                <input type="password"  autocomplete="new-password"  v-model="form.password_confirmation">
                <span v-if="form.password_confirmation" class="fa fa-times-circle grey3-txt pd-r-2 txt-md" style="max-width: 9%;"  v-on:click="form.password_confirmation=''"></span>
              </div>
            </div>
          </div>




        </div>
        <div class="remember-forgot grid-col-1 a-i-center"></div>
        <div class="login-button flex-center" :class="$t('font_b')">
          <button class=" btn btn-md yellow t-black-txt mg-10" :disabled="!canSubmit" :class="{'yellow':canSubmit,'grey7':!canSubmit}" @click.prevent="reset"> {{$t('update_password')}}</button>
        </div>


      </div>
    </form>
    <div class="select-loc-wrap">
      <div class="select-loc " v-on:click="$router.push({name:'login'})">
        <div class="cyrcle-button cyrcle-button-sm yellow  home-button" style="color:black !important;" >
          <i class="far fa-chevron-left"></i>
        </div>
      </div>
    </div>
    <div class="select-lang-wrap">
      <div class="select-lang">
        <span class="txt-xs1 t-center txt-uppercase"><i class="fal fa-globe txt-lg"></i></span>
      </div>
    </div>



  </div>
</template>







<script>


    import User from "@/apis/user";

    import {resetTokenValid, setDomain, setLang, validate} from "@/support/support";
    import {errorMessage, loading, successMessage, warningMessage} from "@/support/helpers";



    export default {
        components:{


        },
        data(){
            return {
                pageLoaded:false,
                agree:false,
                form:{
                    email:'',
                    token:'',
                    password:'',
                    password_confirmation:'',

                    // device_log:support.deviceLog(),
                },
                errors: []

            }
        },
        mounted() {


          loading();
          let domain = this.$route.query.domain;
          let lang = this.$route.query.lang;
          let email = this.$route.query.email;
          let token = this.$route.query.token;
          if(!domain || !lang || !email || !token || !resetTokenValid(token)){

            warningMessage('error_detected_try_later');
            return this.$router.push({name:'login'});
          }
          setDomain(domain);
          setLang(lang);
          this.form.email = this.$route.query.email;
          this.form.token = this.$route.query.token;

          this.pageLoaded = true;
          return loading(false);
        },
        computed:{

            passwordValid(){
                return (this.form.password.length > 0 && validate(this.form.password, 'password'));
            },
            passwordConfirmationValid(){
                return this.form.password === this.form.password_confirmation;
            },

            required(){
                //Array of Fileds
                let fileds =
                    [
                    'password',
                    'password_confirmation',
                    ];
                /* Mark Faild as Faild */
                let result = fileds.map(filed=>{
                    return (this.form[filed].length <=0) ? 'fail' : true;
                });
                /* If contains Faild attr */
                let found = result.find(element => element === 'fail');
                /* Check Result, if does not contain fail means all pass */
                return found === undefined;

            },
            canSubmit(){
                if(!this.required){
                    return false;
                }
                if(!this.passwordConfirmationValid){
                  return false;
                }
                return this.passwordValid;

            }
        },

        methods:{

            reset() {
              if(!this.canSubmit){
                return false;
              }
                User.resetPassword(this.form).then(() => {

                        successMessage('password_updated');
                        this.$router.push({ name: "login" });

                    }).catch(() => {

                          errorMessage('error__detected_try_later');
                          this.$router.push({ name: "login" });

                    });
            },


        },



    }
</script>
<style scoped>


</style>
<template>
  <div>
    <div class="heading-group bg">
      <h6 :class="$t('font_b')" class="txt-3xl mg-t-20 pd-l-8 txt-uppercase">{{$t('org_employees')}}</h6>
    </div>

    <div class="split-view">

      <div class="view-list">
        <div  v-if="showLeftSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div class="w-margin">
          <uni-search v-on:search="search" v-if="!showLeftSpinner"></uni-search>
        </div>

        <div class="space-div--40"></div>
        <div class="list-group" v-if="!showLeftSpinner">

          <div class="list-group-item list-group-item--addon" v-for="(employee,index) in employees" :key="index">
            <div class="list-pre red-light-txt txt-md1 t-left pd-r-9 cursor" v-on:click="deleteItem(employee.id)" v-if="showDeleteBtn===employee.id"><i class="fas fa-minus-circle"></i></div>
            <div class="list-head txt-md1" :class="$t('font')" v-on:click="setForEdit(employee.id)">{{employee.first_name}} {{employee.last_name}}</div>
            <div class="list-pos en-r txt-xl cursor" v-on:click="setForEdit(employee.id)"><span class="blue-txt cursor"><i class="fal fa-info-circle"></i></span></div>
            <div class="list-head-sub grey4-txt en txt-sm"><i class="fal fa-map-marker-check"></i> {{ getBranchLabel(employee.branch_id) }}</div>
          </div>

        </div>

      </div>
      <div class="view-action">

        <div class="container col-2 w-b-line pd-t-20 pd-b-20" v-if="!showRightSpinner">
          <clear-btn v-on:click.native="resetForm()"></clear-btn>
          <save-btn :can-submit="canSubmit" :show-right-spinner="showRightSpinner" v-on:click.native="saveForm()"></save-btn>
        </div>

        <div  v-if="showRightSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>

        <div class="form" v-if="!showRightSpinner">
          <input-slot label="first_name" :req="true" :message="errorMessage('first_name')">
            <input type="text" name="first_name" autocomplete="false" v-model="form.first_name">
          </input-slot>

          <input-slot label="last_name" :req="true" :message="errorMessage('last_name')">
            <input type="text" name="last_name" autocomplete="false" v-model="form.last_name">
          </input-slot>

          <input-slot label="personal_id" :message="errorMessage('personal_id')">
            <input type="text" name="personal_id" autocomplete="false" v-model="form.personal_id">
          </input-slot>

          <input-slot label="email" :req="true" :message="errorMessage('email')">
            <input type="text"  name="email" autocomplete="false" v-model="form.email">
          </input-slot>

          <input-slot label="password" :req="true" :message="errorMessage('password')">
            <input type="text"  name="password" autocomplete="false" v-model="form.password">
          </input-slot>

          <input-slot label="set_branch" :req="true" v-on:clicked="enableEditBranch()" :message="errorMessage('branch_id')">
            <div v-if="!editBranch" >
              <div :class="$t('font')"  style="font-size: 18px; padding-top: 6px;" v-on:click="enableEditBranch()">{{getBranchLabel(this.form.branch_id)}}</div>
            </div>
            <v-select  v-if="editBranch" :options="branchData" @input="setSelectBranch"></v-select>
          </input-slot>

          <input-switch :label="$t('allow_cards')" v-on:stateChange="updateCanCards()" :set-state="form.can_cards"></input-switch>

          <input-switch :label="$t('allow_points')" v-on:stateChange="updateCanPoints()" :set-state="form.can_points"></input-switch>

          <input-switch :label="$t('allow_partners')" v-on:stateChange="updateCanPartners()" :set-state="form.can_partners"></input-switch>


          <input-switch :label="$t('is_active')" v-on:stateChange="updateActive()" :set-state="form.active"></input-switch>




        </div>


      </div>
    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import inputSwitch from "@/components/STATIC/inputSwitch";
import vSelect from 'vue-select'

import spinner from "@/components/STATIC/spinner";
import inputSlot from "@/components/STATIC/inputSlot";
import support, {displayErrors, getRandomInt, randomName, searchFilter, validator} from "@/support/support";
import Launchpad from "@/components/launchpad";
import ClearBtn from "@/components/STATIC/clearBtn";
import SaveBtn from "@/components/STATIC/saveBtn";
import {warningMessage} from "@/support/helpers";
import uniSearch from "@/components/component/uni-search";

export default {
  components:{
    SaveBtn,
    ClearBtn,
    Launchpad,
    vSelect,
    uniSearch,
    spinner,
    inputSlot,
    inputSwitch,
  },
  data(){
    return {
      showLeftSpinner:false,
      showRightSpinner:false,
      showDeleteBtn:false,
      editBranch:false,
      password_message:false,
      email_was_taken:'',
      formErrors:{},
      fetchErrors:{},
      searchFor:'',
      form:{
        id:"NEW",
        active: 1,
        branch_id: '',
        can_cards: false,
        can_points: false,
        can_partners: false,
        email: getRandomInt(99999)+"@cashier.portfel.app",
        first_name: "  ",
        last_name: "  ",
        password: "P"+randomName(),
        personal_id: "",
        point_limit: "",
        user_id: "",
      }
    }
  },
  mounted(){
    this.resetToDefaults();
    this.showLeftSpinner = true;
    /*
    * Get Employee Data
    */
    this._getAllDataFromCloud().then(()=>{
      /*
      * Check IF There is Branch Data if not Call it
      */
      if(this.BRANCH_CLOUD.length<=0){
         this._getBranchCloudData().then(()=>{
           this.showLeftSpinner = false;
         });
      }else{
        this.showLeftSpinner = false;
      }
    }).catch(()=>{
      this.showLeftSpinner = false;
    });
  },
  computed:{
    ...mapState('_employee',['CLOUD']),
    ...mapState('_branch',{BRANCH_CLOUD:'CLOUD'}),

    //Balance
    ...mapState('_general',{GENCLOUD:'CLOUD'}),
    currentPackage(){
      let product = this.finProduct(this.GENCLOUD.SUBSCRIPTIONS.product_id);
      return support.returnIfExpected(product,'object');
    },
    balance(){
      return this.GENCLOUD.BALANCE;

    },
    //End Balance


    employees(){

      let getData = (this.CLOUD.length>0) ? this.CLOUD : [];
      let searchColumns =[
        {name:'first_name',type:'str'},
        {name:'last_name',type:'str'},
        {name:'email',type:'str'},

      ];
      return searchFilter(this.searchFor,getData,searchColumns,1);
    },
    canSubmit(){
      let valuesToValidate = [
        {
          name:'first_name',
          input:this.form.first_name,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'last_name',
          input:this.form.last_name,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'branch_id',
          input:this.form.branch_id,
          rule:'min:num',
          message:this.$t('select_branch'),
        },
        {
          name:'email',
          input:this.form.email,
          rule:'email',
          message:'***@***.*',
        },

      ];
      if(this.form.id==='NEW'){
        valuesToValidate.push(
            {
              name:'password',
              input:this.form.password,
              rule:'password',
              message:this.$t('weak_password'),
            }
        );
      }
      return this.hasErrors(validator(valuesToValidate));
    },

    branchData(){
      return (this.BRANCH_CLOUD.length>0) ? this.BRANCH_CLOUD : [];
    },
    errors(){
      return this.formErrors;
    }

  },
  methods:{
    ...mapActions('_employee',['_getAllDataFromCloud','_createOrUpdate','_delete']),
    ...mapActions('_branch',{_getBranchCloudData:'_getAllDataFromCloud'}),
    /* Error Handling */
    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    hasErrors(errors){ //METHOD FOR Can Submit
      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
    /* END Error Handling */

    getBranch(id){
      return this.BRANCH_CLOUD.find(item=>{
        return item.id === id;
      }) ?? [];
    },
    getBranchLabel(id){
      let result =this.getBranch(id);
      return (result !==undefined) ? result.label : '';
    },

    resetToDefaults(){
      this.showLeftSpinner = false;
      this.showRightSpinner = false;
      this.showDeleteBtn = false;
      this.editBranch=false;
      this.resetForm();


    },
    resetForm(){
      this.formErrors= {};
      this.fetchErrors= {};
      this.showDeleteBtn=false;
      return this.form= {
        id:"NEW",
        active: 1,
        branch_id: 1,
        can_cards: false,
        can_points: false,
        can_partners: false,
        email: getRandomInt(99999)+"@cashier.portfel.app",
        first_name: "  ",
        last_name: "  ",
        password: "P"+randomName(),
        personal_id: "",
        point_limit: "",
        user_id: "",
      };

    },
    getItemById(id){
      return this.CLOUD.find(item => {
        return item.id === id;
      }) ;
    },
    setForEdit(id){
      if(this.showDeleteBtn === id){
        return this.resetToDefaults();
      }
      this.showDeleteBtn = id;
      return this.form = Object.assign({},this.getItemById(id));
    },
    deleteItem(id){
      this.showLeftSpinner = true;
      this._delete(id).then(()=>{
        this.showLeftSpinner = false;
        this.resetToDefaults();
      }).catch(()=>{ this.showLeftSpinner = false; })
    },
    /*
    *
    */
    saveForm(){


      //Balance
      if(this.form.id ==="NEW" &&  this.CLOUD.length>=+this.currentPackage.limit_employee && +this.currentPackage.limit_employee !==-1){
        warningMessage(this.$t('you_have_reached_package_limit'));
        return false;
      }
      //End Balance

      if(!this.canSubmit){
        return false;
      }
      this.showRightSpinner = true;
      this._createOrUpdate(this.form).then(()=>{
        this.showRightSpinner = false;
        this.resetToDefaults();
      }).catch(error=>{
        this.fetchErrors = error.errors;
        this.showRightSpinner = false;
      })

    },
    /*
    *
    */
    updateCanPoints(){
      this.form.can_points = !this.form.can_points;
    },
    /*
    *
    */
    updateCanCards(){
      this.form.can_cards = !this.form.can_cards;
    },
    updateCanPartners(){
      this.form.can_partners = !this.form.can_partners;
    },
    updateActive(){
      this.form.active = !this.form.active;
    },
    /*
    *
    */
    enableEditBranch(){

      if(parseInt(this.form.branch_id)>0){
        let branch = this.getBranch(this.form.branch_id);
        if(branch.length>0){
          this.editBranch = true;
          return this.from.branch_id = branch;
        }else{
          return this.editBranch = true;
        }
      }else{
        return this.editBranch = true;
      }
    },
    /*
    * 
    */
    setSelectBranch(value){
          if(!value){
            return this.form.branch_id = '';
          }
          return  this.form.branch_id = value.id;
    },
    //Balance

    finProduct(id){

      if(this.GENCLOUD.PRODUCTS !==undefined){
        let __PRODUCT =  this.GENCLOUD.PRODUCTS.filter(product=>{
          return +product.id ===+id;
        });

        if(__PRODUCT[0] !==undefined){
          return this.setProduct = __PRODUCT[0];
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    //End Balance

    search(searchFor){
      this.searchFor = searchFor;
    },
  }
}
</script>

<style scoped>

.view-from-save-btn {
  display: block;
  width: 100%;
  text-align: right;
  justify-items: right;
  padding-right: 1.2rem;
  font-size: var(--txt-3xl);
}
.view-from-clear-btn {
  display: block;
  width: 100%;
  text-align: left;
  justify-items: left;
  padding-left: 1.7rem;
  margin-top: 3px;
  font-size: var(--txt-3xl);

}
.block{
  display: block;
}
.list-group-item--addon {
  grid-template-columns: auto 1fr auto !important;
}
.vs__actions {
  height: 30px;
}
.vs__selected-options {
  color:var(--blue) !important;
  height: 40px !important;
}
.style-chooser, .vs--single, .vs__selected {
  font-size: var(--txt-lg1) !important;

}
.vs__clear {
  fill: var(--black);
}

.vs--single .vs__selected {
  color: var(--blue);
}


.vs__selected-options {
  font-size: 14px;
  color: var(--blue);
}
.employee-branch {
  display: flex;
  background-color: var(--grey7);
  font-size: var(--txt-xl);
  text-align: center;
  height: 28px;
  padding: 10px 13px 10px 10px;
  border-radius: 12px;
}
#vs1__listbox{
  background-color: var(--white);
}
.green-border{
  border-radius: 12px;
  border: 1px solid var(--green);
}
.error-container {
  color:var(--red-light);
  margin-top: 15px;
  height: 30px;
  font-size: 11px;

}
</style>
import Partnership from "@/apis/partnership";
import Partner from "@/apis/partners";
import {httpStatusActions} from "@/support/support";

export const _getPartnership = ({commit})=> {

    return new Promise((resolve, reject) => {
        Partnership.getPartnershipData().then(response => {
            commit('SET_PARTNERSHIP_DATA', {response: response.data});
            httpStatusActions(response,true);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _getTransactions = ({commit},filter)=> {

    return new Promise((resolve, reject) => {
        Partnership.getTransactions(filter).then(response => {
            commit('SET_TRANSACTION_DATA', {response: response.data});
            httpStatusActions(response,true);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _getExportTransactions = ({commit},filter)=> {

    return new Promise((resolve, reject) => {
        Partnership.getTransactions(filter).then(response => {
            commit('SET_TRANSACTION_EXPORT_DATA', {response: response.data});
            httpStatusActions(response,true);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _markNotificationsRead = ({commit},data)=> {

    return new Promise((resolve, reject) => {
        Partnership.markNotificationsRead(data).then(response => {
            commit('SET_MARK_NOTIFICATIONS_READ',data);
            httpStatusActions(response,true);
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}


export const _exchangeRequest = ({commit},data)=> {
    return new Promise((resolve, reject) => {
        Partnership.exchangeRequest(data).then(response => {
            _getPartnership({commit}).then(()=>{
                httpStatusActions(response);
                resolve (response);
            });
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _acceptRequest = ({commit},data)=> {
    return new Promise((resolve, reject) => {
        Partnership.acceptRequest(data).then(response => {

            _getPartnership({commit}).then(()=>{
                _getTransactions({commit},{}).then(()=>{
                    httpStatusActions(response);
                    resolve (response);
                });
            });

        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _rejectRequest = ({commit},data)=> {
    return new Promise((resolve, reject) => {
        Partnership.rejectRequest(data).then(response => {
            _getPartnership({commit}).then(()=>{
                httpStatusActions(response);
                resolve (response);
            });
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _cancelRequest = ({commit},data)=> {
    return new Promise((resolve, reject) => {
        Partnership.cancelRequest(data).then(response => {
            _getPartnership({commit}).then(()=>{
                httpStatusActions(response);
                resolve (response);
            });
            resolve (response);
        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
//Set form data from component
export const showFilteredData = ({state,commit})=> {
    return new Promise((resolve,reject) => {
        return _getTransactions({commit}, state.TRANSACTION_FORM).then(response=>{
            resolve (response);
        }).catch(error=>{
            reject(error);
        });
    });
}
export const _acceptPartnership = ({commit},data)=> {
    return new Promise((resolve, reject) => {
        Partnership.acceptPartnership(data).then(response => {
            _getPartnership({commit}).then(()=>{
                    httpStatusActions(response);
                    resolve (response);
            });

        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const _rejectPartnership = ({commit},data)=> {
    return new Promise((resolve, reject) => {
        Partner.delete(data).then(response => {
            _getPartnership({commit}).then(()=>{
                httpStatusActions(response);
                resolve (response);
            });

        }).catch(error=>{
            reject(httpStatusActions(error));
        });

    });
}
export const freshTransactionForm = ({commit})=> {
      return commit('SET_FRESH_TRANSACTION_FORM');
}
export const setTransactionForm = ({commit},form)=> {
    return commit('SET_TRANSACTION_FORM',form);
}



export const SET_CLOUD_DATA = (state,{response}) => {
    let newData = response;
    state.CLOUD =[];
    state.CLOUD = Object.assign([], state.CLOUD,newData);
}




export const SET_CLOUD_MEMBERS_DATA = (state,{response}) => {
    let newData = response;
    state.MEMBER_DB =[];
    state.MEMBER_DB = Object.assign([], state.MEMBER_DB,newData);
}

export const UPDATE_EDITED_VALUE = (state,form) => {
    return Object.assign(state.CLOUD.find(obj=>parseInt(obj.id)===parseInt(form.id)),form);
}
export const INSERT_NEW = (state,record) => {
    let find = state.CLOUD.find(obj=>parseInt(obj.id)===parseInt(record.id));

    if(find!==undefined){
       REMOVE_CLOUD_DATA(state,find.id)
    }
    return state.CLOUD.push(record);
}
export const REMOVE_CLOUD_DATA = (state,id) => {
    state.CLOUD = state.CLOUD.filter(from => {
        return +from.id !== +id;
    })
}

/*
*
*/


/*
*
*/

/*
*
*/


<template>
  <div class="pd-t-10">
    <div class="list-group " v-for="(item,index) in data" :key="index+1">
      <div class="list-group-item list-group-item--addon" >
        <div class="list-pre red-light-txt txt-md1 t-left">
          <i v-if="showDeleteBtn!==item.id" class="fal grey3-txt pd-r-5 txt-lg" :class="{'fa-shopping-bag':item.type===1,'fa-coins':item.type===2}"></i>
          <div class="list-pre red-light-txt txt-md1 t-left pd-r-9 cursor" v-on:click="deleteItem(item.id)" v-if="showDeleteBtn===item.id"><i class="fas fa-minus-circle" v-if="showDeleteSpinner!==item.id"></i><spinner v-if="showDeleteSpinner===item.id"></spinner></div>
        </div>
        <div class="list-head txt-md cursor" :class="$t('font')" @click="showMoreInfo(item.id)">
        <div class="flex-inline col-2 wd-100p j-c-space-between j-i-center a-i-center">
          <div class="flex-start">{{item.label}}</div>
          <div class="flex-end grey5-txt pd-r-20 txt-md1"><span class="en"><i @click="cashSpendInfo()" class="cursor grey6-txt txt-md pd-r-4 fal fa-question-circle"></i>{{item.sales_total}}<dollar></dollar> </span></div>
        </div>
        </div>
        <div class="list-pos en-r txt-lg cursor flex-inline j-i-center a-i-center" @click="showMoreInfo(item)">
          <span v-if="hasUnreadNotifications(item)" class="red-txt"><i class="fal fa-bell"></i></span>
          <span v-if="!hasUnreadNotifications(item)" class="blue-txt cursor "><i class="fal fa-info-circle"></i></span>
        </div>
        <div class="list-head-sub grey5-txt en txt-sm">
          <span class="en"><i class="fal fa-calendar-check" :class="{'blink grey4-txt':item.started===0}"></i> {{item.start_date}}</span>
          <span class="en pd-l-10"><i class="fal fa-calendar-times" :class="{'blink red-light-txt':item.ends_today===1}"></i> {{item.end_date}}</span>
        </div>
        <div class="list-footer-raw pd-t-10"  v-if="+showFooter===+(item.id)">
          <div class="list-footer-grid-3 w-t-line pd-t-30" :class="+showFooter===+(item.id)?'pd-b-20':''">
            <balance :item="item"></balance>
            <rate :item="item"></rate>
            <div class="flex-inline wd-100p flex-end" @click="awOpen(item)" >
              <input-button :set-class="(hasPendingRequest(item))?'grey7 txt-xs1':'yellow txt-sm pd-8'" :disabled="hasPendingRequest(item)" :label="$t('exchange_request')" icon="fad fa-people-arrows txt-xl"></input-button>
            </div>
           <notifications-and-requests :item="item"></notifications-and-requests>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
* Note
* Emits ('awOpen') When Application Window Is open
*/
import Balance from "@/components/component/partnership/balance";
import Rate from "@/components/component/partnership/rate";
import InputButton from "@/components/STATIC/inputButton";
import {aw, awOpen, awSetData} from "@/support/applicationWindow";
import {mapActions, mapGetters} from "vuex";
import NotificationsAndRequests from "@/components/component/partnership/notificationsAndRequests";
import Dollar from "@/components/STATIC/dollar";
import Spinner from "@/components/STATIC/spinner";



export default {
  props: {
    data: {
      default() {
        return []
      }
    },
  },
  name: "accept_block",
  components: {Spinner, Dollar, NotificationsAndRequests, InputButton, Rate, Balance},
  data(){
    return {
      showFooter:'',
      showDeleteBtn:'',
      showDeleteSpinner:false,
    }
  },
  computed:{
    ...mapGetters(['_applicationWindowData','_applicationWindowStatus']),

    awDataId(){
      return (this._applicationWindowData.id !==undefined)?this._applicationWindowData.id:'';
    },
    awStatus() {
      return this._applicationWindowStatus;
    },

  },
  methods:{
    ...mapActions('_partnership',['_rejectPartnership','_markNotificationsRead']),
    cashSpendInfo(){
      return awOpen(3, {message: this.$t('cash_spend_desc_txt')});
    },
    showMoreInfo(item){
      let id = item.id;
      if(this.showFooter===id){ //Second click on same info icon
        this.showDeleteBtn="";
       return  this.showFooter="";
      }

      if(this.hasUnreadNotifications(item)){
        let data = {id};
        this._markNotificationsRead(data);
      }

      this.showDeleteBtn=id;
      return this.showFooter=id;
    },
    deleteItem(id){
      this.showDeleteSpinner = id;
      this._rejectPartnership(id).then(()=>{
        this.showDeleteBtn="";
        this.showDeleteSpinner=false;
      });
    },
    awOpen(item){
      if(this.hasPendingRequest(item)){
        return false;
      }
      let data = {
        id:item.id,
        balance:item.balance,
        card_type:item.type
      }
      aw();
      awSetData(data);
      return this.$emit('awOpen');

    },
    hasPendingRequest(item){
      if(item.requests!==undefined){
        let result = item.requests.filter(request=>{
          return request.rejected===0 || !request.rejected;
        });
        return (result.length>0);
      }
      return false;

    },
    hasBellData(item){
      if(item.notifications !==undefined){
        return (item.notifications.length>0 || item.requests.length>0);
      }
      return false;
    },
    hasUnreadNotifications(item){
      let not =[];
      let req =[];
      if(item.notifications!==undefined){
        not= item.notifications.filter(notification=>{
          return !notification.read;
        });
      }
      if(item.requests!==undefined){
        req= item.requests.filter(request=>{
          return !request.rejected || request.rejected===0;
        });
      }

      return (not.length>0 || req.length>0);
    },
  }
}
</script>

<style scoped type="scss">

</style>
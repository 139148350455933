<template>
<div>

  <!-- Heading -->
  <div  class="group grid-col-2 a-i-center pd-b-20 w-b-line">
    <div class="flex-start grey5-txt " :class="$t('font')"><h1>{{$t('filter_data')}}</h1></div>
    <div class="flex-end">
      <div class="grid-row-2 t-center cursor" @click="getCustomerExportLink()" v-if="!EXPORT_LINK">
        <div class="txt-4xl green-txt" v-if="!customerExportSpinner"><i class="fal fa-file-excel"></i></div>
        <div class="txt-4xl green-txt" v-if="customerExportSpinner"><SpinnerVuex spinner-number="2" v-if="customerExportSpinner"></SpinnerVuex></div>
        <div :class="$t('font')" class="txt-xs1">{{$t('export')}}</div>
      </div>
      <div class="grid-row-2 t-center" v-if="EXPORT_LINK && !customerExportSpinner">
        <div class="txt-4xl blue-txt">
          <a  target="_blank" :href="EXPORT_LINK" class="blue-txt cursor" :class="$t('font')"><i class="fal fa-cloud-download"></i></a>
        </div>
      </div>
    </div>
  </div>

  <!-- Keyword -->
  <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
    <div class="flex-start">
      <input-switch-w-d-vuex @on-state-change="keywordSwitchChange" :default-state="true" :switch-number="0" :label="$t('search_keyword')" :desc="$t('keyword_filter_txt')" :pad="0"></input-switch-w-d-vuex>
    </div>
    <div class="flex-center wd-100p f-i-h" v-if="keywordSlideSwitch">
      <div class="input-group wd-100p input-group-no-label w-tb-margin">
        <div class="input-area"><input type="text"  :placeholder="$t('keyword')" v-model="submitForm.searchKeyword"></div>
      </div>
    </div>
  </div>

  <!-- Last Visit Date -->
  <div class="group grid-row-2  g-r-gap-20 w-tb-margin w-b-line">
    <div class="flex-start">
      <input-switch-w-d-vuex @on-state-change="lastVisitDaySwitchChange" :label="$t('last_visit')" :switch-number="1" :desc="$t('date_filter_txt')" :pad="0"></input-switch-w-d-vuex>
    </div>
    <div class="flex-center wd-100p " v-if="lastVisitDateSwitch" style="padding-bottom: 30px !important;">
      <div class="grid-row-2 f-i-h g-r-gap-20 wd-100p">

        <div class="grid-col-3 a-i-center" style="height: 24px; padding-top: 30px;">
          <div class="selection-type-item selection-left" :class="{'yellow':lastVisitDateSelect.today}" v-on:click="lastVisitDateSelectToday()">{{$t('today')}}</div>
          <div class="selection-type-item selection-center" :class="{'yellow':lastVisitDateSelect.lastSevenDays}" v-on:click="lastVisitDateSelectLastSevenDays()">{{$t('last_week')}}</div>
          <div class="selection-type-item selection-right" :class="{'yellow':lastVisitDateSelect.custom}" v-on:click="lastVisitDateSelectCustom()">{{$t('custom_date')}}</div>
        </div>

        <div class="grid-col-2 g-c-gap-20 j-c-end" style="padding-bottom: 30px;" v-if="lastVisitDateSelect.custom">
          <div class="flex-start">
            <div class="grid-row-2">
              <div class="grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_from')}}</div>
              <div><div class="input-area"><input v-model="submitForm.lastVisitDateStart" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
            </div>

          </div>
          <div class="flex-end">
            <div class="grid-row-2">
              <div class=" grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_to')}}</div>
              <div><div class="input-area"><input v-model="submitForm.lastVisitDateEnd" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Birthday -->
  <div class="group grid-row-2  g-r-gap-20 w-tb-margin w-b-line">
    <div class="flex-start">
      <input-switch-w-d-vuex @on-state-change="birthdaySwitchChange" :label="$t('birthday')" :switch-number="2" :desc="$t('date_filter_txt')" :pad="0"></input-switch-w-d-vuex>
    </div>
    <div class="flex-center wd-100p " v-if="birthdaySwitch" style="padding-bottom: 30px !important;">
      <div class="grid-row-2 f-i-h g-r-gap-20 wd-100p">

        <div class="grid-col-3 a-i-center" style="height: 24px; padding-top: 30px;">
          <div class="selection-type-item selection-left" :class="{'yellow':birthdaySelect.today}" v-on:click="birthdayToday()">{{$t('today')}}</div>
          <div class="selection-type-item selection-center" :class="{'yellow':birthdaySelect.tomorrow}" v-on:click="birthdayTomorrow()">{{$t('tomorrow')}}</div>
          <div class="selection-type-item selection-right" :class="{'yellow':birthdaySelect.custom}" v-on:click="birthdayCustom()">{{$t('custom_date')}}</div>
        </div>

        <div class="grid-col-2 g-c-gap-20 j-c-end" style="padding-bottom: 30px;" v-if="birthdaySelect.custom">
          <div class="flex-start">
            <div class="grid-row-2">
              <div class="grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_from')}}</div>
              <div><div class="input-area"><input v-model="submitForm.birthdayStart" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
            </div>

          </div>
          <div class="flex-end">
            <div class="grid-row-2">
              <div class=" grey5-txt txt-md1 txt-b pd-b-4" :class="$t('font')">{{$t('date_to')}}</div>
              <div><div class="input-area"><input v-model="submitForm.birthdayEnd" type="date" style="max-width: 36vw; height: 40px !important;"></div></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Gender -->
  <div class="group grid-row-2  g-r-gap-20 w-tb-margin w-b-line">
    <div class="flex-start">
      <input-switch-w-d-vuex :label="$t('gender')" @on-state-change="genderSwitchChange" :switch-number="3" :desc="$t('filter_data_by_gender')" :pad="0"></input-switch-w-d-vuex>
    </div>
    <div class="flex-center wd-100p " v-if="genderSwitch" style="padding-bottom: 30px !important;">
      <div class="grid-row-2 f-i-h g-r-gap-20 wd-100p">

        <div class="grid-col-3 a-i-center" style="height: 24px; padding-top: 30px;">
          <div class="selection-type-item selection-left" :class="{'yellow':genderSelect===1}" v-on:click="genderMale()">{{$t('male')}}</div>
          <div class="selection-type-item selection-center" :class="{'yellow':genderSelect===2}" v-on:click="genderFemale()">{{$t('female')}}</div>
          <div class="selection-type-item selection-right" :class="{'yellow':genderSelect===3}" v-on:click="genderOther()">{{$t('other')}}</div>
        </div>
      </div>
    </div>
  </div>


  <!-- Branch -->
  <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
    <div class="flex-start">
      <input-switch-w-d-vuex switch-number="4" @on-state-change="branchSwitchChange" :label="$t('branch')" :desc="$t('last_branch_visited')" :pad="0"></input-switch-w-d-vuex>
    </div>
    <div class="flex-center wd-100p f-i-h" v-if="branchSwitch">
      <div class="input-group wd-100p input-group-no-label w-tb-margin">
        <div class="input-area">
          <v-select :multiple="true" :options="branchData" @input="branchInput"></v-select>
        </div>
      </div>
    </div>
  </div>


  <!-- Employee -->
  <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
    <div class="flex-start">
      <input-switch-w-d-vuex switch-number="5" @on-state-change="employeeSwitchChange" :label="$t('employee')" :desc="$t('last_employee_served')" :pad="0"></input-switch-w-d-vuex>
    </div>
    <div class="flex-center wd-100p f-i-h" v-if="employeeSwitch">
      <div class="input-group wd-100p input-group-no-label w-tb-margin">
        <div class="input-area">
          <v-select :multiple="true" :options="employeeData" label="email" @input="employeeInput"></v-select>
        </div>
      </div>
    </div>
  </div>

  <!-- Card Type -->
  <div class="group grid-row-2 g-r-gap-10 a-i-center pd-b-20 w-tb-margin w-b-line">
    <div class="flex-start">
      <input-switch-w-d-vuex  @on-state-change="cardTypeSwitchChange" :label="$t('card_type')" switch-number="6" :desc="$t('filter_by_card_type')" :pad="0"></input-switch-w-d-vuex>
    </div>
    <div class="flex-center wd-100p f-i-h" v-if="cardTypeSwitch">
      <div class="input-group wd-100p input-group-no-label w-tb-margin">
        <div class="input-area">
          <v-select :multiple="true" :options="cardTypeData" @input="cardTypeInput"></v-select>
        </div>
      </div>
    </div>
  </div>

  <div  class="group flex-center flex-center-self" style="margin-top: 60px !important; margin-bottom: 40px !important;">
    <div v-if="!leftSpinner" class="yellow pd-20 txt-b cursor" style="border-radius: 30px !important;" v-on:click="getShowFilteredData()"><i class="fal fa-filter"></i> {{$t('show_filtered_data')}}</div>
    <div  v-if="leftSpinner" class="txt-7xl grey3-txt container t-center"><SpinnerVuex :spinner-number="0"></SpinnerVuex></div>
  </div>


</div>
</template>

<script>

import InputSwitchWDVuex from "@/components/STATIC/InputSwitchWDVuex";
import {ResetSlideSwitch} from "@/support/slide_switch";
import {ResetLoadingSpinner, ShowSpinner, RightSpinner} from "@/support/loading";
import {mapActions, mapGetters, mapState} from "vuex";
import vSelect from 'vue-select'
import SpinnerVuex from "@/components/STATIC/spinnerVuex";
export default {
  name: "CustomerLeftFilter",
  components: {SpinnerVuex, InputSwitchWDVuex,vSelect},
  data(){
    return {
      submitForm:{},
      lastVisitDateSelect:{
        today:false,
        lastSevenDays:false,
        custom:false
      },
      birthdaySelect:{
        today:false,
        tomorrow:false,
        custom:false
      },
      genderSelect:null,
    }
  },
  mounted() {
    ResetSlideSwitch();
    ResetLoadingSpinner();
    this.submitForm = this.SUBMIT_FORM_DEFAULT;
    this._setSubmitForm(this.SUBMIT_FORM_DEFAULT);
  },
  computed:{
    ...mapGetters(['_slideSwitchStatus','_loadingSpinnerStatus']),
    ...mapState('_branch',{branchCLOUD:'CLOUD'}),
    ...mapState('_employee',{employeeCLOUD:'CLOUD'}),
    ...mapState('_customers',['CLOUD','EXPORT','SUBMIT_FORM_DEFAULT','SUBMIT_FORM']),
    stateSubmitForm(){
      return this.SUBMIT_FORM;
    },
    keywordSlideSwitch(){
      return this._slideSwitchStatus(0);
    },
    lastVisitDateSwitch(){
      return this._slideSwitchStatus(1);
    },
    birthdaySwitch(){
      return this._slideSwitchStatus(2);
    },
    genderSwitch(){
      return this._slideSwitchStatus(3);
    },
    branchSwitch(){
      return this._slideSwitchStatus(4);
    },
    employeeSwitch(){
      return this._slideSwitchStatus(5);
    },
    cardTypeSwitch(){
      return this._slideSwitchStatus(6);
    },
    customerExportSpinner(){
      return this._loadingSpinnerStatus(2);
    },
    leftSpinner(){
      return this._loadingSpinnerStatus(0);
    },
    branchData(){
      return this.branchCLOUD;
    },
    employeeData(){
      return this.employeeCLOUD;
    },
    cardTypeData(){
      return [
        { id:1, label:this.$t('discount') },
        { id:2, label:this.$t('collector') },
      ];
    },
    EXPORT_LINK(){
      return (this.EXPORT.EXPORT_LINK.length>0) ? this.EXPORT.EXPORT_LINK : false;
    },
  },
  methods:{
      ...mapActions('_customers',['_getAllDataFromCloud','_exportCustomers','_findCustomer','_setSubmitForm','_resetExportLink']),
      updateForm(param,value){
        let form = this.submitForm;
        form[param]=value;
        return this._setSubmitForm(form);
      },
      getCustomerExportLink(){

        // this.submitForm.genderSelected = this.genderSelect;
        // this.submitForm.birthdaySelected = this.birthdaySelect;
        // this.submitForm.lastVisitDateSelected = this.lastVisitDateSelect;

        this.updateForm('genderSelected',this.genderSelect);
        this.updateForm('birthdaySelect',this.birthdaySelect);
        this.updateForm('lastVisitDateSelected',this.lastVisitDateSelect);

        ShowSpinner(2);
        return this._exportCustomers(this.stateSubmitForm).then(()=>{
          ShowSpinner(2,false);
        }).catch(()=>{
          ShowSpinner(2,false);
        });
      },
      getShowFilteredData(){
        this._resetExportLink();
        this.updateForm('genderSelected',this.genderSelect);
        this.updateForm('birthdaySelect',this.birthdaySelect);
        this.updateForm('lastVisitDateSelected',this.lastVisitDateSelect);
        // this.submitForm.genderSelected = this.genderSelect;
        // this.submitForm.birthdaySelected = this.birthdaySelect;
        // this.submitForm.lastVisitDateSelected = this.lastVisitDateSelect;

        RightSpinner();
        return this._findCustomer(this.stateSubmitForm).then(()=>{
                RightSpinner(false);
        }).catch(()=>{
                RightSpinner(false);
        });
      },
      //Last Visit Date
      lastVisitDateSelectToday(){
        this.lastVisitDateResetSelection();
        this.lastVisitDateSelect.today = true;
        this.updateForm('lastVisitDateStart',"01/01/2000");
        this.updateForm('lastVisitDateEnd',"01/01/2000");
        // this.submitForm.lastVisitDateStart = "01/01/2000";

        // this.submitForm.lastVisitDateEnd="01/01/2000";
      },
      lastVisitDateSelectLastSevenDays(){
        this.lastVisitDateResetSelection();
        this.lastVisitDateSelect.lastSevenDays = true;
        this.updateForm('lastVisitDateStart',"02/01/2000");
        this.updateForm('lastVisitDateEnd',"02/01/2000");
        // this.submitForm.lastVisitDateStart = "02/01/2000";
        // this.submitForm.lastVisitDateEnd="02/01/2000";
      },
      lastVisitDateSelectCustom(){
        this.lastVisitDateResetSelection();
        this.lastVisitDateSelect.custom = true;
      },
      lastVisitDateResetSelection(){
        this.updateForm('lastVisitDateStart',"");
        this.updateForm('lastVisitDateEnd',"");
        this.lastVisitDateSelect = {
              today:false,
              lastSevenDays:false,
              custom:false
        }
      },
    //Last Visit Date END

      //Birthday
      birthdayToday(){
        this.birthdayResetSelection();
        this.birthdaySelect.today = true;
        this.updateForm('birthdayStart',"01/01/2000");
        this.updateForm('birthdayEnd',"01/01/2000");
        // this.submitForm.birthdayStart = "01/01/2000";
        // this.submitForm.birthdayEnd="01/01/2000";
      },
      birthdayTomorrow(){
        this.birthdayResetSelection();
        this.birthdaySelect.tomorrow = true;
        this.updateForm('birthdayStart',"02/01/2000");
        this.updateForm('birthdayEnd',"02/01/2000");
        // this.submitForm.birthdayStart = "02/01/2000";
        // this.submitForm.birthdayEnd="02/01/2000";
      },
      birthdayCustom(){
        this.birthdayResetSelection();
        this.birthdaySelect.custom = true;
      },
      birthdayResetSelection(){
        this.birthdaySelect = {
          today:false,
          tomorrow:false,
          custom:false
        }
      },
      //Birthday END

      //Gender
      genderMale(){
        this.genderResetSelection();
        this.updateForm('genderSelected',1);
        this.genderSelect =1;
      },
      genderFemale(){
        this.genderResetSelection();
        this.updateForm('genderSelected',2);
        this.genderSelect=2;
      },
      genderOther(){
        this.genderResetSelection();
        this.updateForm('genderSelected',3);
        this.genderSelect=3;
      },
      genderResetSelection(){
        this.updateForm('genderSelected','');
        this.genderSelect = null;

      },
      //Gender END
      employeeSwitchChange(e){
        if(e){
          this.updateForm('employeeId',[]);
          this.submitForm.employeeId =[];
        }
      },
      branchSwitchChange(e){
        if(e){
          this.updateForm('branchId',[]);
          this.submitForm.branchId =[];
        }
      },
      cardTypeSwitchChange(e){
        if(e){
          this.updateForm('cardTypeId',[]);
          this.submitForm.cardTypeId =[];
        }
      },
      genderSwitchChange(e){

        if(e){
          this.updateForm('genderSelected',null);
          this.submitForm.genderSelected =null;
          this.genderResetSelection();
        }
      },
      keywordSwitchChange(e){
        if(e){
          this.updateForm('searchKeyword',null);
          this.submitForm.searchKeyword ='';
        }
      },
      birthdaySwitchChange(e){
        if(e){
          this.updateForm('birthdayStart','');
          this.updateForm('birthdayEnd','');
          this.submitForm.birthdaySelected =false;
          this.submitForm.birthdayStart='';
          this.submitForm.birthdayEnd='';
          this.birthdayResetSelection();
        }
      },
      lastVisitDaySwitchChange(e){
        if(e){
          this.updateForm('lastVisitDateStart','');
          this.updateForm('lastVisitDateEnd','');
          this.submitForm.lastVisitDateSelected =false;
          this.submitForm.lastVisitDateStart='';
          this.submitForm.lastVisitDateEnd='';
          this.lastVisitDateResetSelection();
        }
      },


      employeeInput(e){
        let set = [];
        e.map(function(selected){
          return set.push(selected.id);
        });
        this.updateForm('employeeId',set);
        this.submitForm.employeeId =set;
      },
      branchInput(e){
        let set = [];
        e.map(function(selected){
          return set.push(selected.id);
        });
        this.updateForm('branchId',set);
        this.submitForm.branchId =set;
      },
      cardTypeInput(e){
        let set = [];
        e.map(function(selected){
          return set.push(selected.id);
        });
        this.updateForm('cardTypeId',set);
        this.submitForm.cardTypeId = set;
      }


  }
}
</script>

<style scoped>

</style>
<template>
<i class="pof" :class="setClass">{{currency}}</i>
</template>

<script>
import {getDollar} from "@/support/support";

export default {
  name:'dollar',
  props:{
      setClass:{
        default(){
          return '';
        }
      }
  },
  computed:{
    currency() {
      return getDollar();
    }
  }
}
</script>

<style scoped>

</style>
<template>
<div>

  <div class="card-wraper">
    <div class="card-wraper">
      <div class="wallet-card" :class="card.color">
        <div class="card-skeleton" >
          <div class="company-logo en"><i class="pof portfel"></i></div>
          <div class="company-name txt-lg1" :class="{'ka':+card.label_en===0,'en-r':+card.label_en===1}">{{card.show_label}}</div>

          <div class="card-type" v-if="+card.type===1">%</div>
          <div class="card-type" v-if="+card.type===2"><i class="fal fa-coins"></i></div>

          <div class="pay-button yellow grey6-txt"><i class="fal fa-qrcode" style="font-size: var(--txt-4xl);"></i></div>
          <div class="account-number"><span class="acc">{{ space(card.account)}}</span></div>
          <div class="valid-date" :class="$t('font')">
            <span class="ka-b">{{$t('active')}}</span><br>
            <span class="en">{{ card.active_due}}</span>
          </div>
          <card-logo></card-logo>
        </div>
      </div>

    </div>
    <div class="space-div--20"></div>
    <div class="flex-center-raw">
      <div class="grid-col-2 wd-100p j-c-space-between">
        <div class="balance-text  grey5-txt txt-3xl" :class="$t('font')">
          <div class="group" v-if="+card.type===2">{{$t('balance')}}</div>
          <div class="group" v-if="+card.type===1">
            <div class="container flex" style="margin-top: -2px !important;">{{$t('spent')}}</div>
            <div class="container flex txt-md" style="padding-left: 2px !important; padding-top: 4px !important;"></div>
          </div>
        </div>

        <div class="balance-amount  txt-3xl">
          <div class="group ">
            <div class="text-short-m pof pd-t-2">{{card.balance}}<span class="pof" v-if="+card.type===1">{{dollar}}</span><i class="pof points" v-if="+card.type===2"></i>
            </div>
            <div class="text-short-m grey5-txt txt-md"></div>
          </div>
        </div>
      </div>
    </div>


    <div class="flex-center-raw">
    <button class="btn btn-sm red cursor mg-r-10" @click="suspendCard()"><span><i class="fas fa-ban"></i> {{$t('suspend')}}</span></button>
    <button class="btn btn-sm purple cursor mg-l-10" @click="userNotificationWindowOpen()"><span><i class="fal fa-bell"></i> {{$t('notify')}}</span></button>
    </div>
    <div class="space-div--20 w-b-line"></div>

  </div>
</div>
</template>

<script>
import CardLogo from "@/components/STATIC/cardLogo";
import {space,getDollar} from "@/support/support";
import  {userNotifyWindow,setNotifyUserId} from "@/support/userNotifications";


import {aw, awSetData} from "@/support/applicationWindow";


export default {
  name: "customerCard",
  components: {CardLogo},
  props:{
    card:{
      require:true,
    },
    user:{
      require:true,
    }
  },
  computed:{
    dollar(){
      return getDollar();
    },

  },

  methods:{
    space(text){
      return space(text);
    },
    suspendCard(){
      let data = {
        id:this.card.id,
      }
      aw();
      return awSetData(data);


    },
    userNotificationWindowOpen(){
      let data ={
        card_id:this.card.id,
      };

      setNotifyUserId(data);
      return userNotifyWindow();
    }
  }

}
</script>

<style scoped>

</style>
<template>

    <div class="flex-inline flex-center wd-100p">
      <div class="txt-3xl">
        <i class="fal fa-heart-rate"></i>
      </div>
      <div class="grid-row-2 j-i-start">
        <div class="txt-xs1">{{ $t('rate') }}<i @click="rateInfo()" class="cursor txt-xs blue-txt pd-l-4 fal fa-question-circle"></i></div>
        <div class="txt-m">
          <span class="pd-r-1">
            <span v-if="item.type===2" class="pof">1<i class="pd-l-2 pof points"></i> =</span>
            {{item.rate}}
          </span>
          <span v-if="item.type===1">%</span>
          <span v-if="item.type===2"><dollar></dollar></span>
      </div>
    </div>
    </div>
</template>

<script>
import Dollar from "@/components/STATIC/dollar";
import {awOpen} from "@/support/applicationWindow";


export default {
  components: {Dollar},
  props: {
    item: {
      default() {
        return {}
      }
    },
  },
  name: "rate",
  methods: {
    rateInfo() {
      return awOpen(3, {message: this.$t('rate_desc_txt')});
    }
  }
}
</script>

<style scoped>

</style>

export default {
    namespaced: true,
    //baseURL: "https://api-ge-nod-main.portfel.app/api",
    // baseURL: "http://api-ge-nod-main.portfel.test/api",
    baseURL:process.env.VUE_APP_API,
    publicURL:process.env.VUE_APP_PUBLIC_URL,

    tokenName:process.env.VUE_APP_TOKEN_NAME,
    dataVersion:'_last',
    dataUpdateIntervalInMinutes:process.env.VUE_APP_FETCH_INTERVAL,
    nightModeStarts:20,
    nightModeEnds:7,

    appVersion:process.env.VUE_APP_VERSION,
    paymentToken:'org_token', //Lock Token
    OS:process.env.VUE_APP_OS,

    debug:true,
    defaultLang:'en',

    ipInfoToken:process.env.VUE_APP_IPINFO_KEY,
    googleMapsToken:process.env.VUE_APP_GOOGLE_API_KEY,
    enableSmartsUpp:process.env.VUE_APP_ENABLE_SMARTSUPP==="true",

}

<template>
  <div>
    <div class="heading-group bg">
      <h6 :class="$t('font_b')" class="txt-uppercase txt-3xl mg-t-20 pd-l-8">{{$t('discount_card_models')}}</h6>
    </div>

    <div class="split-view">

      <div class="view-list">
        <div  v-if="showLeftSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div class="list-group" v-if="!showLeftSpinner">
          <input-switch
              :label="$t('discount_cards_enabled')"
              v-on:stateChange="toggleDiscount()"
              :set-state="discountsEnabled"
              pad="pad-t-20"
          ></input-switch>
          <div class="value-description">
            <span>{{$t('discount_cards_enabled_desc')}}</span>
          </div>
          <div class="space-div--20"></div>
          <div class="w-b-line"></div>
          <div class="space-div--10"></div>


          <div class="list-group-item list-group-item--addon" v-for="(card,index) in cards" :key="index">
            <div class="list-pre red-light-txt txt-md1 t-left pd-r-9 cursor" v-on:click="deleteItem(card.id)" v-if="showDeleteBtn===card.id"><i class="fas fa-minus-circle"></i></div>
            <div class="list-head txt-md1" v-on:click="setForEdit(card.id)" :class="$t('font')">{{card.label}}</div>
            <div class="list-pos en-r txt-xl" v-on:click="setForEdit(card.id)"><span class="blue-txt cursor"><i class="fal fa-info-circle"></i></span></div>
            <div class="list-head-sub grey4-txt en txt-sm">
              <span>-{{card.discount}}<i class="far fa-percent"></i> </span>
              <span> <i class="far fa-tag"></i> {{getTypeName(card)}}</span>
            </div>
          </div>

        </div>


      </div>

      <div class="view-action">
        <div  v-if="showRightSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div v-if="!showRightSpinner">
          <div class="selection-type-selector w-margin">
            <div class="selection-type-item selection-left cursor" :class="{'selected':cardType==='onetime'}" v-on:click="setCardType('onetime')"><span :class="$t('font')">{{$t('onetime')}}</span></div>
            <div class="selection-type-item selection-center cursor" :class="{'selected': cardType==='standard'}" v-on:click="setCardType('standard')"><span :class="$t('font')">{{$t('standard')}}</span></div>
            <div class="selection-type-item selection-right cursor" :class="{'selected':cardType==='dynamic'}" v-on:click="setCardType('dynamic')"><span :class="$t('font')">{{$t('dynamic')}}</span></div>
          </div>
          <div class="space-div--20"></div>
          <onetime v-if="cardType==='onetime'"  v-on:fresh="fresh()" v-on:submit="saveForm" :fetch-errors="fetchErrors"></onetime>
          <standard v-if="cardType==='standard'"  v-on:fresh="fresh()" v-on:submit="saveForm" :fetch-errors="fetchErrors"></standard>
          <dynamic v-if="cardType==='dynamic'"   v-on:fresh="fresh()" v-on:submit="saveForm" :fetch-errors="fetchErrors"></dynamic>
        </div>
      </div>


    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>

import spinner from "@/components/STATIC/spinner";
import {mapActions, mapState} from "vuex";
import onetime from "@/components/discount/onetime";
import dynamic from "@/components/discount/dynamic";
import standard from "@/components/discount/standard";
import Launchpad from "@/components/launchpad";
import InputSwitch from "@/components/STATIC/inputSwitch";
import support from "@/support/support";
import {warningMessage} from "@/support/helpers";


// import {validate} from "@/support/support";
export default {
  components:{

    InputSwitch,
    Launchpad,

    spinner,
    onetime,
    dynamic,
    standard,

  },
  data(){
    return {
      showLeftSpinner:false,
      showRightSpinner:false,
      cardType:'standard',
      selectForEdit:false,
      formErrors: {},
      fetchErrors:{},

    }
  },
  mounted(){
    this.showLeftSpinner=true;
    this.resetToDefaults();
    this._getAllDataFromCloud().then(()=>{
        this.hideSpinners();
    }).catch(()=>{
        this.hideSpinners();
    });
  },
  computed:{
    ...mapState('_discount',['CLOUD','FROM_DYNAMIC','FORM_STANDARD','FORM_ONETIME']),
    ...mapState('_general',{GCLOUD:'CLOUD'}),

    //Balance
    currentPackage(){
      let product = this.finProduct(this.GCLOUD.SUBSCRIPTIONS.product_id);
      return support.returnIfExpected(product,'object');
    },
    balance(){
      return this.GCLOUD.BALANCE;
    },
    //End Balance

    dynamicCardsCount(){
      let cards = this.cards.filter(card=>{
        return card.is_dynamic;
      })
      return cards.length;
    },
    cards(){ return this.CLOUD.cards ?? []; },

    showDeleteBtn(){
        let id = parseInt(this.selectForEdit);
        if(parseInt(this.FROM_DYNAMIC.id) ===id || parseInt(this.FORM_STANDARD.id)===id || parseInt(this.FORM_ONETIME.id)===id){
          return id;
        }
        return false;
    },
    discountsEnabled(){
      return this.GCLOUD.SETTINGS.discounts_enabled;
    }

  },
  methods:{
    ...mapActions('_discount',['_getAllDataFromCloud','_createOrUpdate','_delete','_setEditDynamic','_freshDynForm','_setEditOnetime','_setEditStandard',
    '_freshStandardForm','_freshOnetimeForm']),
    ...mapActions('_general',['_toggleSettings']),


    toggleDiscount(){
      let discountState = !this.GCLOUD.SETTINGS.discounts_enabled;
      let form = {
        toggle:'discount',
        state: discountState,
      }

      return this._toggleSettings(form);
    },




    resetToDefaults(){
        this._freshDynForm();
        this._freshOnetimeForm();
        return  this._freshStandardForm();
    },
    showSpinners(side=null){
        if(!side){
          this.showRightSpinner = true;
          return this.showLeftSpinner = true;
        }
        return (side==='left') ? this.showLeftSpinner = true :this.showRightSpinner = true;
    },
    hideSpinners(side=null){
      if(!side){
        this.showRightSpinner = false;
        return this.showLeftSpinner = false;
      }
      return (side==='left') ? this.showLeftSpinner = false :this.showRightSpinner = false;
    },

    /* Error Handling */
    hasErrors(errors){ //METHOD FOR Can Submit
      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
    /* END Error Handling */

    getItemById(id){
      return this.CLOUD.cards.find(item=>{
        return item.id === id;
      });

    },
    getTypeName(obj){
      let isDynamic = (typeof obj.is_dynamic === "object");
      let isOnetime = Boolean(obj.onetime);

      if(isDynamic){
        return this.$t('dynamic');
      }
      if(isOnetime){
        return this.$t('onetime');
      }
        return this.$t('standard');

    },

    setForEdit(id){

      if( +this.selectForEdit === +id){
            this.cardType='standard';
            this.selectForEdit=false;
            return this.resetToDefaults();
      }

      this.selectForEdit = id;
      let getData = Object.assign({},this.getItemById(id));

      let isDynamic = (typeof getData.is_dynamic === "object");
      let isOnetime = Boolean(getData.onetime);


      this.setCardType('standard');
      this._setEditStandard(id);


      if(isOnetime){
        this.setCardType('onetime');
        this._setEditOnetime(id);
      }
      if(isDynamic){
        this.setCardType('dynamic');
        this._setEditDynamic(id);
      }

      //Set Edit Indicator
      this.selectForEdit=id;


      return true;
    },



    deleteItem(id){
      this.showLeftSpinner = true;
      this._delete(id).then(()=>{
        this.showLeftSpinner = false;
        return this.resetToDefaults();
      }).catch(()=>{ this.showLeftSpinner = false; })
    },

    saveForm(e){

      //Balance
      if(e.id<=0 && +this.CLOUD.cards.length>=+this.currentPackage.discount_card && +this.currentPackage.discount_card !==-1){
        warningMessage(this.$t('you_have_reached_package_limit'));
        return false;
      }
      if(e.id<=0 && this.cardType==='dynamic' && +this.dynamicCardsCount>=+this.currentPackage.discount_dynamic && +this.currentPackage.discount_dynamic !==-1){
        warningMessage(this.$t('you_have_reached_package_limit'));
        return false;
      }
      //End Balance

      let form = e;
      this.showSpinners();
      this._createOrUpdate(form).then(()=>{
          return window.location.reload();
      }).catch((error)=>{
          this.hideSpinners();
          this.fetchErrors = error.errors;
      });

    },

    setCardType(type){
      //Reset Edit Indicator
      this.selectForEdit=false;
      this.resetToDefaults();

      if(type==='onetime'){
        this.cardType = type;
      }
      if(type==='standard'){
        this.cardType = type;
      }
      if(type==='dynamic'){
        this.cardType = type;
      }
    },

    /*
    *
    */
    fresh(){
      this.fetchErrors = {};
      this.selectForEdit=false;
    },
    //Balance

    finProduct(id){

      if(this.GCLOUD.PRODUCTS !==undefined){
        let __PRODUCT =  this.GCLOUD.PRODUCTS.filter(product=>{
          return +product.id ===+id;
        });

        if(__PRODUCT[0] !==undefined){
          return this.setProduct = __PRODUCT[0];
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    //End Balance
  }
}
</script>

<style scoped>
@import "../assets/css/cardsPage.css";

</style>
<template>
  <div class="container">
    <div class="heading-group bg">
      <h6 :class="$t('font')" class="txt-3xl mg-t-20 pd-l-8">{{$t('select_package')}}</h6>
    </div>
    <div class="split-view">
      <div class="view-list">

       <package-list-item v-for="(product,index) in products" :key="index"
                          :is-current-plan="isCurrentPackage(product.id)"
                          :product="product"
                          v-on:changePackage="selectPackage"
                          v-on:packageInfo="packageInfo"
       ></package-list-item>
      </div>
      <div class="view-action">
        <div v-if="!infoRequested" class="div t-center txt-xxl grey7-txt" style="margin-top: 30%;" :class="$t('font')">
          <h1 class="mg-b-10"><i class="fal fa-info-circle"></i></h1>
          <h6>{{$t('nothing_selected')}}</h6>
        </div>
        <package-info v-if="infoRequested"
                      :product="infoRequested"
                      :balance="balance"
                      :currency="currency"
                      :is-selected="selectedPackage"
                      section="selection"
                      :is-current-plan="currentPackage"
                      v-on:changePackage="selectPackage"
        ></package-info>
      </div>
    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import PackageListItem from "@/components/subscription/Static/PackageListItem";
import PackageInfo from "@/components/subscription/Static/PackageInfo";
import support from "@/support/support";

import payment from "@/apis/payment";
import Launchpad from "@/components/launchpad";

export default {
  components: {Launchpad, PackageInfo, PackageListItem},
  data(){
    return {
      selectedPackage:null,
      infoRequested:null,
    }
  },
  mounted(){
    this._getAllDataFromCloud();
    this.infoRequested = null;
    this.selectedPackage =null;
  },
  computed:{
    ...mapState('_general',['CLOUD']),
    products(){
      return (this.CLOUD.PRODUCTS !==undefined)
          ? this.CLOUD.PRODUCTS
          : [];
    },
    currentPackage(){
      return (this.CLOUD.SUBSCRIPTIONS !==undefined)
          ? this.CLOUD.SUBSCRIPTIONS.product_id
          : false;
    },
    balance(){
      return (this.CLOUD.BALANCE !==undefined)
          ? this.CLOUD.BALANCE
          : [];
    },
    currency(){
      return (this.CLOUD.SETTINGS.default_currency !==undefined)
          ? support.currencyToSymbol(this.CLOUD.SETTINGS.default_currency)
          : [];
    }
  },
  methods:{
     ...mapActions('_general',['_getAllDataFromCloud']),
     isCurrentPackage(id){
       return this.currentPackage === id;
     },
     packageInfo(id){
       this.selectedPackage = null;
       if(this.products.length>0){
         let __PRODUCT =  this.products.filter(product=>{
           return parseInt(product.id) ===parseInt(id);
         });
         return this.infoRequested = __PRODUCT[0];
       }
     },
    selectPackage(id){
        payment.changePackage({productId:id}).then(()=>{
          this.$store.dispatch('addNotification',{type:'success'});
          return this.$router.push({name:'service'});
        }).catch(error=>{
            this.selectedPackage = false;
            this.$store.dispatch('addNotification',{type:'error',message:error.message});
        });

    },



  },
}

</script>

<style scoped>

</style>
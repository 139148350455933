<template>
  <div>
    <div class="heading-group bg">
      <h6 :class="$t('font_b')" class=" txt-3xl mg-t-20 pd-l-8 txt-uppercase">{{$t('collector_card_model')}}</h6>
    </div>

    <div class="split-view">

      <div class="view-list">
        <div  v-if="showLeftSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div v-if="!showLeftSpinner">

          <input-switch
              :label="$t('collector_cards_enabled')"
              v-on:stateChange="updateActive()"
              :set-state="form.active"
              pad="pad-t-20"
          ></input-switch>
          <div class="value-description">
            <span>{{$t('collector_cards_enabled_desc')}}</span>
          </div>
          <div class="space-div--40"></div>
          <div class="w-b-line"></div>


          <div class="space-div--40"></div>
          <input-copy :hash="form.public" v-if="!publicLinkReload" label="public_distribution_link" :not-discount="true"></input-copy>
          <div v-if="publicLinkReload" @click="reload()" class="flex-center"><div class="txt-lg1 blue-txt"><i class="fad fa-repeat"></i></div></div>
          <div class="value-description" >
            <span>{{$t('public_distrib_link_desc')}}</span>
          </div>


          <div class="space-div--40"></div>
          <div class="input-group w-margin">
            <div class="input-desc" :class="$t('font')" v-html="labelOrError('active_from',errorMessage('active_from'))"></div>
            <div class="input-area"><input type="date"  autocomplete="off" v-model="form.active_from"></div>
          </div>
          <div class="value-description">
            <span>{{$t('active_from_date_desc')}}</span>
          </div>
          <div class="space-div--40"></div>

          <div class="input-group w-margin ">
            <div class="input-desc" :class="$t('font')" v-html="labelOrError('expiry_date',errorMessage('active_due'))"></div>
            <div class="input-area"><input type="date"  autocomplete="off" v-model="form.active_due"></div>
          </div>
          <div class="value-description">
            <span>{{$t('active_due_date_desc')}}</span>
          </div>
          <div class="space-div--40"></div>

          <input-number label="minimum_purchase" :req="true" :message="errorMessage('minimum_purchase')">
            <input type="number" @wheel="$event.target.blur()" v-on:dblclick="form.minimum_purchase =''" name="minimum_purchase" autocomplete="false" v-model="form.minimum_purchase">
          </input-number>
          <div class="value-description">
            <span>{{$t('minimum_purchase_desc')}}</span>
          </div>
          <div class="space-div--40"></div>

          <input-number label="spend_minimum_limit" :req="true" unit="Q" :message="errorMessage('spend_minimum_limit')">
            <input type="number" @wheel="$event.target.blur()" name="spend_minimum_limit" autocomplete="false" v-model="form.spend_minimum_limit">
          </input-number>
          <div class="value-description">
            <span>{{$t('spend_minimum_limit_desc')}}</span>
          </div>
          <div class="space-div--40"></div>

          <input-number label="minimum_purchase_power" :req="false" unit="Q" :message="errorMessage('minimum_purchase_power')">
            <input type="number" @wheel="$event.target.blur()"  name="minimum_purchase_power" autocomplete="false" v-model="form.minimum_purchase_power">
          </input-number>
          <div class="value-description">
            <span>{{$t('minimum_purchase_power_desc')}}</span>
          </div>
          <div class="space-div--40"></div>

          <input-number label="sell_rate_txt" :req="true" pre="1" pre-unit="L" unit="Q" :message="errorMessage('sell_rate')">
            <input type="number" @wheel="$event.target.blur()"  name="sell_rate_txt" autocomplete="false" v-model="form.sell_rate">
          </input-number>
          <div class="value-description">
            <span>{{$t('sell_rate_txt_desc')}}</span>
          </div>
          <div class="space-div--40"></div>

          <input-number  label="buy_rate_txt" :req="true" pre="1" pre-unit="Q" unit="L" :message="errorMessage('buy_rate')">
            <input type="number" @wheel="$event.target.blur()"  name="buy_rate_txt" autocomplete="false" v-model="form.buy_rate">
          </input-number>
          <div class="value-description">
            <span>{{$t('buy_rate_txt_desc')}}</span>
          </div>
          <div class="space-div--40"></div>
          <input-switch
              :label="$t('allow_point_sharing')"
              v-on:stateChange="updateCanShare()"
              :set-state="form.shareable"
              pad="pad-t-20"
          ></input-switch>
          <div class="value-description">
            <span>{{$t('allow_point_sharing_desc')}}</span>
          </div>
          <div class="space-div--40"></div>
          <input-switch
              :label="$t('points_lost_on_card_expire')"
              v-on:stateChange="updateExpireLost()"
              :set-state="form.expire_lost"
              pad="pad-t-20"
          ></input-switch>
          <div class="value-description">
            <span>{{$t('points_lost_on_card_expire_desc')}}</span>
          </div>
          <div class="space-div--40"></div>
          <div class="space-div--40"></div>




        </div>

      </div>
      <div class="view-action">
        <div  v-if="showRightSpinner" class="txt-7xl grey3-txt container t-center mg-t-30">
          <spinner></spinner>
        </div>
        <div class="container flex-self-end pd-t-20 pd-b-20" v-if="!showRightSpinner">
          <save-btn :can-submit="canSubmit" :show-right-spinner="showRightSpinner" v-on:click.native="saveForm()"></save-btn>
        </div>
        <div class="space-div--10 w-b-line"></div>
        <div v-if="!showRightSpinner">

          <input-slot label="card_display_name" :req="true" :message="errorMessage('show_label')">
            <input type="text" name="card_display_name" autocomplete="false" v-model="form.show_label">
          </input-slot>
          <div class="value-description">
            <span>{{$t('card_display_name_desc')}}</span>
          </div>
          <div class="space-div--40"></div>
          <input-switch
              :label="$t('name_latin_letters')"
              v-on:stateChange="updateLabelEn()"
              :set-state="form.label_en"
          ></input-switch>
          <div class="value-description">
            <span>{{$t('name_latin_letters_desc')}}</span>
          </div>

          <div class="space-div--40"></div>
          <Fontaswome_logos :edit-logo="form.logo_link" @onSet="setlogo"></Fontaswome_logos>
          <div class="heading-group--sub">
            <span class="txt-xl" :class="$t('font')">{{$t('card_design')}}</span>
            <span class="color-button cursor" v-on:click="showColors =!showColors"><i class="fal fa-paint-brush-alt"></i></span>
          </div>
          <div class="value-description">
            <span>{{$t('card_design_desc_txt')}}</span>
          </div>
          <div class="space-div--20"></div>


          <div class="card-color-group mg-b-10 pd-b-5" v-if="showColors">
            <div :class="color"  v-for="(color,index) in colorPalette" :key="index"
                 @click="form.color = color"
                 class="t-center pd-t-2">
              <i  v-if="form.color===color" class="fas fa-check t-white-txt txt-xl"></i>
            </div>
          </div>


          <div class="card-wraper">
            <div class="wallet-card" :class="form.color">
              <div class="card-skeleton" >
                <div class="company-logo en"><i :class="this.form.logo_link"></i></div>
                <div class="company-name txt-lg1" :class="{'ka':form.label_en==0,'en-r':form.label_en==1}">{{form.show_label}}</div>

                <div class="card-type" v-if="card_type==1">%</div>
                <div class="card-type" v-if="card_type==2"><i class="fal fa-coins"></i></div>

                <div class="pay-button yellow grey6-txt"><i class="fal fa-qrcode" style="font-size: var(--txt-4xl);"></i></div>
                <div class="account-number"><span class="acc">{{ addSpace(card_number)}}</span></div>
                <div class="valid-date ka ">
                  <span class="ka-b">{{$t('active')}}</span><br>
                  <span class="en">mm/yy</span>
                </div>
                <card-logo></card-logo>
              </div>
            </div>
          </div>





        </div>
      </div>
    </div>
    <launchpad></launchpad>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

import inputNumber from "@/components/STATIC/inputNumber";
// import vSelect from 'vue-select'

import spinner from "@/components/STATIC/spinner";
import inputSlot from "@/components/STATIC/inputSlot";
import cardLogo from "@/components/STATIC/cardLogo";
import inputSwitch from "@/components/STATIC/inputSwitch";
// import {validate,notValidMsg} from "@/support/support";
import support, {colorPalette, validator, displayErrors} from "@/support/support";
import Launchpad from "@/components/launchpad";

import SaveBtn from "@/components/STATIC/saveBtn";
import InputCopy from "@/components/STATIC/input-copy";
import Fontaswome_logos from "@/components/component/fontaswome_logos";
import {loading} from "@/support/helpers";
export default {
  components:{
    Fontaswome_logos,
    InputCopy,
    SaveBtn,

    Launchpad,
    // vSelect,
    cardLogo,

    spinner,
    inputSlot,
    inputSwitch,
    inputNumber,
  },
  data(){
    return {
      showLeftSpinner:false,
      showRightSpinner:false,
      showColors:false,
      card_type: 2,
      card_number: '2000000000000000',
      publicLinkReload:false,


      formErrors: {},
      fetchErrors:{},

      form:{
        id :'',
        active_from :'',
        active_due :'',
        shareable :'',
        active :'',
        minimum_purchase :'',
        spend_minimum_limit :'',
        sell_rate :'',
        buy_rate :'',
        minimum_purchase_power :'',
        color :'grcolor1',
        show_label :'',
        label_en :'',
        expire_lost:'',
        logo_link:'pof portfel',
      }
    }
  },
  mounted(){
    this.resetToDefaults();
    this.showLeftSpinner = true;
    this.showRightSpinner = true;


    this._getAllDataFromCloud().then(()=>{
      this.showLeftSpinner = false;
      this.showRightSpinner = false;
      this.form = Object.assign({},this.CLOUD);
    }).catch(()=>{
      this.showLeftSpinner = false;
      this.showRightSpinner = false;
    });
  },
  computed:{
    ...mapState('_collector',['CLOUD']),
    ...mapState('_general',{GCLOUD:'CLOUD'}),

    pointsEnabled(){
      return this.GCLOUD.SETTINGS.points_enabled;
    },
    error(){
      return this.errors;
    },
    colorPalette(){
        return colorPalette(this.CLOUD.color_palette_count)
    },
    canSubmit(){
      let valuesToValidate = [
        {
          name:'show_label',
          input:this.form.show_label,
          rule:'min:char',
          message:this.$t('min_char'),
        },
        {
          name:'spend_minimum_limit',
          input:this.form.spend_minimum_limit,
          rule:'min:num',
          message:this.$t('min_num'),
        },
        {
          name:'buy_rate',
          input:this.form.buy_rate,
          rule:'min:num',
          message:this.$t('min_num'),
        },
        {
          name:'sell_rate',
          input:this.form.sell_rate,
          rule:'min:num',
          message:this.$t('min_num'),
        },
        {
          name:'minimum_purchase',
          input:this.form.minimum_purchase,
          rule:'min:zero',
          message:this.$t('min_zero'),
        },
      ];
      return this.hasErrors(validator(valuesToValidate));
    },




  },
  methods:{
    ...mapActions('_collector',['_getAllDataFromCloud','_updateCloud']),
    ...mapActions('_general',['_toggleSettings']),

    togglePoints(){
      let form = {
        toggle:'points',
        state: this.form.active,
      }
      return this._toggleSettings(form);
    },
    /* Error Handling */
    errorMessage(input){ //METHOD FOR INPUT
      if(Object.keys(this.fetchErrors).length>0){
        let display = displayErrors(input,this.fetchErrors);
        if(display){
          return display;
        }
      }
      return displayErrors(input,this.formErrors);
    },
    hasErrors(errors){ //METHOD FOR Can Submit
      this.formErrors = errors;
      return (Object.entries(this.formErrors).length ===0);
    },
    labelOrError(label,errorMsg){
      if(errorMsg){
        return '<span class="red-light-txt">* '+errorMsg+'</span>';
      }
      return this.$t(label);
    },
    /* END Error Handling */
    addSpace(value) {
      if (value.length > 0) {
        return support.space(value);
      } else {
        return '';
      }
    },



    resetToDefaults(){
      this.publicLinkReload = false;
      this.showLeftSpinner = false;
      this.showRightSpinner = false;
      this.showDeleteBtn = false;
      this.resetForm();
    },
    resetForm(){
      this.formErrors= {};
      this.fetchErrors={};
      return this.form= {
        id :'',
        active_from :'',
        active_due :'',
        shareable :'',
        active :'',
        minimum_purchase :'',
        spend_minimum_limit :'',
        sell_rate :'',
        buy_rate :'',
        minimum_purchase_power :'',
        color :'',
        show_label :'',
        label_en :'',
        logo_link :'pof portfel',
        expire_lost:'',
      };

    },

    setForEdit(id){
      this.resetToDefaults();
      this.showDeleteBtn = id;
      return this.form = Object.assign({},this.getItemById(id));
    },

    saveForm(){
      if(!this.canSubmit){
        return false;
      }
      /*
      * Update Global Setting of Points enabled also
      */

      this.showRightSpinner = true;
      this.showLeftSpinner = true;
      this._updateCloud(this.form).then(()=>{
        return window.location.reload();
      }).catch(error=>{
        this.fetchErrors = error.errors;
        this.showRightSpinner = false;
        this.showLeftSpinner = false;
      })

    },
    updateCanShare(){
      this.form.shareable = !this.form.shareable;
    },
    updateExpireLost(){
      this.form.expire_lost = !this.form.expire_lost;
    },
    updateActive(){
      this.form.active = !this.form.active;
      this.togglePoints();
    },
    updateLabelEn(){
      this.form.label_en = !this.form.label_en;
    },
    setlogo(e){
      this.form.logo_link = e;
    },
    reload(){
      loading();
      return window.location.reload(true);
    }

  }
}
</script>

<style scoped>

@import "../assets/css/cardsPage.css";
#chat-application{
  visibility: hidden !important;
}
</style>
export default {
    /* Data Is Filed From Cloud */
    CLOUD:[],

    /* Static Hardcoded Data */
    STATIC:{
        /* Static Data For Page Generation */
        DATA:[],
    },
    /* Form Template Data */
    TEMPLATE:{

        firstName: {type:String,default:''},
        lastName: {type:String,default:''},
        personalId: {type:String,default:''},
        email: {type:String,default:''},
        password: {type:String,default:''},
        pointsLimit: {type:Number,default:''},
        belongsToBranch: {type:Number,default:''},
        branchName: {type:String,default:''},
        active: {type:Number,default:''},
        allowPoints: {type:Number,default:''},
        allowCards: {type:Number,default:''},
    },
    /* New Inputs Data */
    INPUTS:[],


}
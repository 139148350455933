export default {
    /* Data Is Filed From Cloud */
    CLOUD:{
        CUSTOMERS:[],
    },
    EXPORT:{
        EXPORT_LINK:'',
    },
    SUBMIT_FORM:{},
    SUBMIT_FORM_DEFAULT:{
        searchKeyword:'',
        lastVisitDateStart:'',
        lastVisitDateEnd:'',
        birthdayStart:'',
        birthdayEnd:'',
        branchId:[],
        employeeId:[],
        cardTypeId:[],
        genderSelected:null,
        page:1
    },
    OPTIONS:{
      SHOW_IMPORT:false,
    },

    /* Static Hardcoded Data */
    STATIC:{
        /* Static Data For Page Generation */
        DATA:[],
    },
    /* Form Template Data */
    TEMPLATE:{
        label: {type:String,default:''}, // SEARCH USES THIS AS UNIVERSAL TERM FOR DATA DISPLAY
        city: {type:String,default:''},
        region: {type:String,default:''},
        street: {type:Text,default:''},
        phone: {type:Number,default:''},
        num: {type:Number,default:''},
        additional: {type:Text,default:''},
        map: {type:Text,default:''},
    },
    /* New Inputs Data */
    INPUTS:[],


}
import Api from "./Api";

const END_POINT = 'setup/app/settings';

export default {

    get() {

        return  Api().get(`${END_POINT}/`);

    },

}
<template>
  <transition name="fade">
    <div class="notification-frame">

      <div v-if="notification.type==='success'" class="notification_window blur center">
        <div class="notification-icon green-txt"><i class="fal fa-check-circle"></i></div>
        <div class="notification-header " :class="$t('font')" style="padding-top: 20px;"><span class="txt-2xl ">{{ $t('finish') }}</span></div>
        <div class="notification-text " :class="$t('font')">
          <span v-if="!$te(notification.message)">{{notification.message}}</span>
          <span v-if="$te(notification.message)">{{ $t(notification.message) }}</span>
        </div>
        <div class="notification-button green t-white-txt" :class="$t('font_sb')"  @click="remove(notification)">{{ $t('close') }}</div>
      </div>


      <div v-if="notification.type==='error'" class="notification_window blur center">
        <div class="notification-icon red-light-txt"><i class="fal fa-times-circle"></i></div>
        <div class="notification-header" :class="$t('font')" style="padding-top: 20px;"><span class="txt-2xl ">{{ $t('error') }}</span></div>
        <div class="notification-text " :class="$t('font')" >
          <span v-if="!$te(notification.message)">{{notification.message}}</span>
          <span v-if="$te(notification.message)">{{ $t(notification.message) }}</span>
        </div>
        <div class="notification-button red-light t-white-txt" :class="$t('font_sb')"  @click="remove(notification)">{{ $t('close') }}</div>
      </div>

      <div v-if="notification.type==='warning'" class="notification_window blur center">
        <div class="notification-icon orange-txt"><i class="fal fa-info-circle"></i></div>
        <div class="notification-header" :class="$t('font')" style="padding-top: 20px;"><span class="txt-2xl ">{{ $t('warning') }}</span></div>
        <div class="notification-text " :class="$t('font')" >
          <span v-if="!$te(notification.message)">{{notification.message}}</span>
          <span v-if="$te(notification.message)">{{ $t(notification.message) }}</span>
        </div>
        <div class="notification-button orange t-white-txt" :class="$t('font_sb')"  @click="remove(notification)">{{ $t('close') }}</div>
      </div>

      <div v-if="notification.type==='info'" class="notification_window blur center">
        <div class="notification-icon blue-txt"><i class="fal fa-info-circle"></i></div>
        <div class="notification-header" :class="$t('font')" style="padding-top: 20px;"><span class="txt-2xl ">{{ $t('info') }}</span></div>
        <div class="notification-text " :class="$t('font')" >
          <span v-if="!$te(notification.message)">{{notification.message}}</span>
          <span v-if="$te(notification.message)">{{ $t(notification.message) }}</span>
        </div>
        <div class="notification-button blue t-white-txt" :class="$t('font_sb')"  @click="remove(notification)">{{ $t('close') }}</div>
      </div>

      <div v-if="notification.type==='update'" class="notification_window blur center">
        <div class="notification-icon indigo-txt">
          <i class="far fa-planet-ringed"></i>
        </div>
        <div class="notification-header txt-md text-short-l t-center pd-b-6" :class="$t('font')">{{ $t('update_available') }} </div>
        <div class="notification-text t-center txt-md1 pd-b-4 pd-t-6" :class="$t('font')">
          <span class="pd-2 indigo  radius pd-4 pd-l-10 pd-r-10"><span @click="update()"> {{$t('update')}} </span></span>
        </div>
        <div class="notify_close grey5-txt txt-3xl mg-t-15" :class="$t('font')" @click="remove(notification)"><i class="fas fa-times-circle"></i></div>
      </div>

    </div>
  </transition>




</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["notification"],

  data() {
    return {
      timeout: null
    };
  },

  computed: {
    typeClass() {
      return `alert-${this.notification.type}`;
    }
  },

  created() {
    if(this.notification.type==='success') {
      this.timeout = setTimeout(() => {
        this.removeNotification(this.notification);
      }, 3000);
    }

    if(this.notification.type==='update'){
      this.timeout = setTimeout(() => {
        this.removeNotification(this.notification);
      }, 10000);

    }
    if(this.notification.type!=='update' && this.notification.type!=='success'){
      this.timeout = setTimeout(() => {
        this.removeNotification(this.notification);
      }, 15000);
    }


  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    ...mapActions(["removeNotification"]),
    update(){
      return window.location.reload();
    },
    remove:function(notification){
      this.removeNotification(notification);
    },

  }
};
</script>
<style>
.notification-frame {
  -webkit-backdrop-filter: blur(150px);
  -moz-filter: blur(150px);
  -o-filter: blur(150px);
  -ms-filter: blur(150px);
  backdrop-filter: blur(150px);
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}
.blur {
  -webkit-backdrop-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.notification_window {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 80px auto 50px;
  grid-template-areas: "icon" "header" "text" "button";
  background-color: var(--white);
  border-radius: 12px;
  width: 300px;
  overflow: hidden;
  justify-items: center;
}
.notification-icon {
  grid-area: icon;
  margin-top: 40px;
  font-size: 60px;
}
.notification-header {
  grid-area: header;
  padding-top: 40px;
  color: var(--t-black);
}
.notification-text {
  grid-area: text;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.notification-button {
  grid-area: button;
  display: grid;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  color: var(--t-white);
  font-size: 24px;
  text-align: center;

}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>
<template>
<div class="pd-b-20 w-b-line">
<div class="grid-col-2 pd-20 import-area">
  <div class="template-area">
    <div class="grid-row-2 t-center cursor" @click="templateLink()">
      <div class="txt-4xl green-txt t-center"><i class="fal fa-file-excel"></i></div>
      <div :class="$t('font')" class="txt-xs1 wd-50 t-center">{{$t('download_template')}}</div>
    </div>
  </div>
  <div class="upload-area">
      <div class="grid-col-3 fileUpload">
        <input type="file" hidden ref="file" @change="inputFile">
        <div @click="browseFile()" v-if="!hasFile" class="browse-btn flex-center-raw j-c-center j-i-center">{{ $t('browse') }}<i class="fal fa-file-excel pd-l-4"></i></div>
        <div  v-if="hasFile" @click="resetForm()" class="browse-btn flex-center-raw j-c-center j-i-center"><i class="far fa-times-circle"></i></div>
        <div class="attached-filename">{{fileName}}</div>
        <div @click="importFile()" class="import-button">{{$t('import')}}</div>
      </div>
  </div>
</div>
<div class="grid-col-1">

  <div class="grid-row-3">
    <div class="flex-center wd-100p f-i-h">
      <div class="input-group wd-100p w-margin">
        <div class="input-desc" :class="$t('font')">
          <label>
            <span>{{$t('select_card')}}</span>
          </label>
        </div>
        <div class="input-area">
          <v-select :multiple="false" :options="cardData" label="label" @input="cardSelect"></v-select>
        </div>
      </div>
    </div>
    <div><input-copy :without-link="false" :hash="token"  label="import_token" :not-discount="true"></input-copy></div>
    <div><input-copy :without-link="false" :hash="cardSelected"  label="selected_card_id" :not-discount="true"></input-copy></div>

  </div>
</div>

</div>
</template>

<script>
import apiCall from "@/apis/customers";
import vSelect from "vue-select";
import InputCopy from "@/components/STATIC/input-copy";
import {mapState} from "vuex";
export default {
  name: "customerRightImport",
  components: {InputCopy, vSelect},
  data() {
    return {
        formData:'',
        fileName:'',
        cardSelected:'',
        token:'eb725e232ce52c5ed0a08299eb36ffdc8bcc0bd2b2c9a46854a4a1f8bafb3c0c'
    }
  },
  mounted() {
    this.formData = new FormData();
  },
  computed:{
    ...mapState('_collector',{COLLECTOR_CLOUD:'CLOUD'}),
    ...mapState('_discount',{DISCOUNT_CLOUD:'CLOUD'}),
    hasFile(){
      return this.fileName.length>0;
    },
    cardData(){
      let data = [
        { id:this.COLLECTOR_CLOUD.id, label:this.$t('collector') },
      ];
      if ( this.DISCOUNT_CLOUD.cards !==undefined){
        this.DISCOUNT_CLOUD.cards.forEach((card)=>{
          data.push( { id:card.id, label:card.label },)
        });
      }

      return data;
    }
  },
  methods:{
    inputFile(e){
      this.fileName = e.target.files[0].name;
      this.formData.append('importCustomers',e.target.files[0] , e.target.files[0].name);
    },
    importFile(){
      apiCall.importCustomers(this.formData);
    },
    browseFile(){
      let i = this.$refs.file;
      i.click();
    },
    resetForm(){
      this.formData = new FormData();
      this.fileName ='';
    },
    cardSelect(e){
      this.cardSelected = e.id;
    }
  }

}
</script>

<style scoped>
.template-area {
  max-width: 70px;
  margin-right:10px ;
}
.upload-area {
  margin-left:10px ;
  width: 100%;
}
.fileUpload {
  padding: 10px;
}
.fileUpload > .browse-btn {
  display: flex;
  justify-content: center;
  background-color: var(--grey4);
  color: var(--white);
  font-size: var(--txt-md1);
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: var(--radius) 0 0 var(--radius);
  padding: 10px 20px 10px 20px;



}
.fileUpload >.import-button{
  display: flex;
  justify-content: center;
  background-color: var(--yellow);
  color: var(--black);
  font-size: var(--txt-md1);
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius:  0 var(--radius) var(--radius) 0  ;

  padding: 10px 20px 10px 20px;

}
.fileUpload >.attached-filename{
    display: flex;
    background-color: var(--grey7);
    padding: 10px 20px 10px 20px;
    text-align: center;
    align-content: center;
    align-items: center;
    font-size: var(--txt-sm);
    text-wrap: normal;
    width: 100%;

    overflow: hidden;

}
.balance{
  padding: 10px 15px 10px 15px;
  background-color: var(--grey7);
  max-width: 200px;
  height: auto;
  font-size: 12px;
  border-radius: var(--radius);
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.import-area {
  width: 100%;
  min-width: 40vw;
  display: flex;
}

</style>